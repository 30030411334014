'use strict';

angular
  .module('kerp-forms.forms')
  .factory('addressFormatterService', ['stringUtils', function (stringUtils) {

    return {
      formatAsString: function (addressParts) {
        return stringUtils.joinTrimmed(addressParts, ', ');
      },

      /**
       * Splits an address array into three separate lines
       *
       * @param addressParts input address array
       * @param postcode
       * @param maxLineLength maximum length of each address line in characters
       * @returns {{addr1: string, addr2: string, addr3: string}}
       */
      formatAsObject: function (addressParts, postcode, maxLineLength) {

        var separator = ", ";

        var result = {
          addr1: "",
          addr2: "",
          addr3: "",
          postcode: postcode
        };

        function setAddressLines(line1, line2, line3) {
          result.addr1 = line1 || '';
          result.addr2 = line2 || '';
          result.addr3 = line3 || '';
        }

        var addressLines;

        function parseAddressLines() {
          // clone the address parts to prevent side-effects
          addressLines = addressParts.slice(0);

          // remove the postcode
          addressLines.pop();
        }

        parseAddressLines();

        function getNextLimitedLengthLine() {
          var line = '';

          // we need to clone the addressLines, because strange things happen if we remove (shift()) items from
          // the same array we're iterating over
          var addressLinesClone = addressLines.slice(0);
          addressLinesClone.slice(0).some(function (linePart) {

            linePart = line.length ? separator + linePart : linePart;
            var isTooLong = (line + linePart).length > maxLineLength;

            if (!isTooLong) {
              line += linePart;
              addressLines.shift();
            }

            return isTooLong;
          });

          return line;
        }

        function setUnlimitedLengthAddressLines() {
          if (addressLines.length === 2) {
            setAddressLines(addressLines[0], addressLines[1]);

          } else {
            // if we have 5 address elements (for example), join the first 3 together and store them in line 1
            var addressElementsInFirstLine = addressLines.length - 2;

            setAddressLines(
              addressLines.slice(0, addressElementsInFirstLine).join(separator),
              addressLines[addressElementsInFirstLine],
              addressLines[++addressElementsInFirstLine]
            );
          }
        }

        if (addressLines.length < 2) {
          return result;

        } else if (maxLineLength && addressLines.length > 3) {
          var line1 = getNextLimitedLengthLine();
          var line2 = getNextLimitedLengthLine();
          var line3 = getNextLimitedLengthLine();

          if (addressLines.length) {
            // We were unable to store the address in 3 limited-length lines. This could happen if (for example) a single
            // address element exceeds the limit. In this case, just treat the lines as though they are unlimited and
            // let the user sort it out by manually entering a valid address
            parseAddressLines();
            setUnlimitedLengthAddressLines();

          } else {
            setAddressLines(line1, line2, line3);
          }

        } else {
          setUnlimitedLengthAddressLines();
        }
        return result;
      }
    };
  }]);
