'use strict';

angular.module('kerp-forms.forms').controller('ReviewApplicationCtrl', [
  '$scope', 'formBaseService', 'configuration', '$stateParams', '$state', 'formModelService', 'Loader', 'objectUtils',
  '$uibModal', '$rootScope', '$filter', 'toaster', '$interpolate', 'FormConditions', 'formConverterService',
  '$templateCache', 'dynamicEvidenceService', 'htmlService', '$localStorage', '$q', 'FormModel', 'offlineFormService',
  '$translate', 'FormErrorType', '$exceptionHandler',


  function ($scope, formBaseService, configuration, $stateParams, $state, formModelService, Loader, objectUtils,
            $uibModal, $rootScope, $filter, toaster, $interpolate, FormConditions, formConverterService,
            $templateCache, dynamicEvidenceService, htmlService, $localStorage, $q, FormModel, offlineFormService,
            $translate, FormErrorType, $exceptionHandler) {

    $scope.isOfflineForm = function () {
      return $stateParams.offline;
    };

    $scope.customer = configuration.customer;

    $scope.vm = {
      newEvidence: {},
      showUnsubmit: configuration.ui.showTestingTools && !$scope.isOfflineForm(),
      sendEvidenceModel: {},
      maxSubmissionDate: new Date(),
      evidenceTypes: dynamicEvidenceService.getEvidenceTypes($stateParams.formId),
      evidenceLoaded: false,
      invalidFiles: false,
      maxFileSizeMB: 20
    };

    // get default values for phone number/email from user profile
    $rootScope.$watch('user.profile', function (userProfile) {
      if (userProfile) {
        $scope.vm.sendEvidenceModel.phoneNumber = $filter("ukLocalPhoneNumber")(userProfile.mobilePhoneNumber);
        $scope.vm.sendEvidenceModel.email = userProfile.email;
        $scope.vm.showUnsubmit = $scope.vm.showUnsubmit && $rootScope.app.hasRole('ROLE_ADMIN');
      }
    });

    var loader = new Loader($scope, 'initializingController');
    var offlineBehaviour = {};

    if ($scope.isOfflineForm()) {
      offlineBehaviour.onAbandon = function () {
        $state.go('main.forms.offline.list');
      };

      offlineBehaviour.formModelInstanceGetter = function (id) {
        return $q(function (resolve, reject) {

          if (!$localStorage.offlineforms) {
            $localStorage.offlineforms = {};
          }

          if (!$localStorage.offlineforms[id]) {
            reject('Form is not stored locally');

          } else if (!$localStorage.offlineforms[id].form) {
            reject('Form data is corrupted');

          } else {
            var localStorageForm = $localStorage.offlineforms[id];
            var formModelInstance = new FormModel(localStorageForm.form, localStorageForm.token);
            resolve(formModelInstance);
          }
        });
      };
    }

    try {
      formBaseService.decorateControllerScope($scope, $stateParams.formId, $stateParams.id, offlineBehaviour);
    } catch (e) {
      console.error('Redirecting to application list after error', e);
      $state.go('main.forms.list');
      return;
    }

    loader.start();
    $scope.loadForm()
      .then(function () {

        var reviewConfig = $scope.formDefinition.review || {};

        $scope.cloning = reviewConfig.animations === 'hide' ? false : true;
        $scope.cfaAnimations = reviewConfig.animations === 'hide' ? false : $templateCache.get('modules/forms/views/review/' + $scope.customer + '/cfaAnimations.html');
        $scope.cfaAnimationsPath = 'modules/forms/views/review/' + $scope.customer + '/cfaAnimations.html';

        const hasEvidenceTemplate = $templateCache.get('modules/forms/views/review/' + $scope.customer + '/requiredEvidence.html');
        $scope.requiredEvidencePath = hasEvidenceTemplate ? 'modules/forms/views/review/' + $scope.customer + '/requiredEvidence.html' : 'modules/forms/views/review/requiredEvidence.html';

        $scope.declaration = reviewConfig.declaration === 'show';
        $scope.declarationPath = $scope.declaration ? 'modules/forms/scripts/services/forms/' + $stateParams.formId + '/assets/declaration.pdf' : '';

        if (!$scope.formDefinition.is_submit_once) {
          throw new Error('This view should only be displayed for single submit forms');
        }

        if (!$scope.formModelInstance.isSubmitted()) {
          console.error('You cannot review an unsubmitted application');
          $state.go('^.show', {formId: $stateParams.formId}, {inherit: true});

        } else {
          var telephoneKey = 'forms.type.' + $scope.formModelInstance.formTypeCode + '.contact.telephone';
          var telephone = $translate.instant(telephoneKey);

          var addressKey1 = 'forms.type.' + $scope.formModelInstance.formTypeCode + '.contact.address1';
          var addressKey2 = 'forms.type.' + $scope.formModelInstance.formTypeCode + '.contact.address2';
          var addressKey3 = 'forms.type.' + $scope.formModelInstance.formTypeCode + '.contact.address3';
          var addressKey4 = 'forms.type.' + $scope.formModelInstance.formTypeCode + '.contact.address4';
          var postcodeKey = 'forms.type.' + $scope.formModelInstance.formTypeCode + '.contact.postcode';

          var address1 = ($translate.instant(addressKey1) !== addressKey1) ? $translate.instant(addressKey1) : null;
          var address2 = ($translate.instant(addressKey2) !== addressKey2) ? $translate.instant(addressKey2) : null;
          var address3 = ($translate.instant(addressKey3) !== addressKey3) ? $translate.instant(addressKey3) : null;
          var address4 = ($translate.instant(addressKey4) !== addressKey4) ? $translate.instant(addressKey4) : null;
          var postcode = ($translate.instant(postcodeKey) !== postcodeKey) ? $translate.instant(postcodeKey) : null;
          var address = [address1, address2, address3, address4, postcode].filter(Boolean).join('<br/>').trim();

          $scope.contactDetails = {
            email: $translate.instant('forms.type.' + $scope.formModelInstance.formTypeCode + '.contact.email'),
            telephone: telephone === telephoneKey ? null : telephone,
            address: address === '' ? null : address
          };

          $scope.vm.evidenceDescriptions = $scope.formDefinition.evidence && $scope.formDefinition.evidence.descriptions;
          $scope.$watchGroup(['form', 'schema', 'formModelInstance'], function (newValues) {

            var asfForm = newValues[0];
            var asfSchema = newValues[1];
            var formFields = newValues[2] && newValues[2].submittedFields;

            if (asfForm && asfSchema && formFields) {

              dynamicEvidenceService.getEvidenceFromJson($stateParams.formId, formFields)
                .then(function (allRequiredEvidence) {
                  var requiredEvidence = (allRequiredEvidence && Object.keys(allRequiredEvidence).length) ? allRequiredEvidence : false;
                  $scope.vm.requiredEvidence = requiredEvidence;
                  $scope.vm.evidenceLoaded = true;
                })
                .catch(function(e) {
                  var err = new Error(FormErrorType.EVIDENCE_GENERATION_ERROR);
                  $exceptionHandler(err, 'Evidence generation error', undefined, {
                    error: e,
                  });
                  $scope.vm.evidenceLoaded = true;
                });

              $scope.formAsHtml = formConverterService.toHtml(
                $scope[FormConditions.SCOPE_PROPERTY_NAME],
                asfForm,
                asfSchema,
                formFields,
                {
                  externalReference: $scope.formModelInstance.externalReference,
                  lastUpdated: $scope.formModelInstance.lastUpdated
                });

              $scope.vm.formHtmlViewReady = true;

              // load the content shown in the sidebar when the "Review application" button is clicked
              $scope.vm.applicationReviewHtml = htmlService.interpolateTemplate('modules/forms/views/review-application.html', {scope: $scope});
            }
          });
        }
      })
      .catch(function (e) {
        console.error('Redirecting to application list after error', e);
        $state.go('main.forms.list');
      })
      .finally(function () {
        loader.stop();
      });

    /**
     * Other public functions
     */

    $scope.sendEvidenceList = function (form) {

      var sendEvidenceModel = $scope.vm.sendEvidenceModel;
      delete sendEvidenceModel.successMsgs;
      delete sendEvidenceModel.serverErrors;

      if (form.$valid) {

        var requestParams = {
          phoneNumber: sendEvidenceModel.phoneNumber,
          formId: $stateParams.id,
          email: sendEvidenceModel.email
        };

        if (requestParams.email) {
          requestParams.emailBody = dynamicEvidenceService.generateEvidenceListHtmlEmail($scope.formModelInstance.formTypeCode, $scope.vm.requiredEvidence, $scope.contactDetails);
        }

        openModalForm.close();

        // need to resolve params in case emailBody is a promise
        return $q.all(requestParams)
          .then(function (params) {
            return dynamicEvidenceService.sendEvidence(params);
          })
          .then(function (messages) {
            $scope.vm.sendEvidenceModel.successMsgs = messages;
            messages.forEach(function (message) {
              toaster.pop('success', message);
            });

            $scope.vm.sendEvidenceModel.email = "";
            $scope.vm.sendEvidenceModel.phoneNumber = "";
          })
          .catch(function (response) {
            sendEvidenceModel.serverErrors = response.data ? response.data.errors : [{error: 'Internal server error'}];
          });
      }
    };

    var openModalForm = null;

    $scope.showModalForm = function (templateName, size) {
      $scope.vm.serverErrors = null;

      openModalForm = $uibModal.open({
        size: size || 'md',
        templateUrl: 'modules/forms/views/review/' + templateName,
        backdrop: true,
        scope: $scope
      });

      // reset the models so we don't see previous selections/errors when forms are reopened
      openModalForm.result.then(function () {

        // "remember" the benefit reference number
        $scope.vm.newEvidence = {
          benefitReference: $scope.vm.newEvidence.benefitReference
        };
        $scope.vm.sendEvidenceModel = {};
      });

      return openModalForm;
    };

    $scope.unsubmit = function () {
      return formModelService.unsubmitForm($stateParams.id)
        .then(function () {
          // if the form has been downloaded, mark the offline form as unsubmitted
          offlineFormService.updateForm($stateParams.id, function (offlineForm) {
            offlineForm.form.submittedFields = null;
          });
          $state.go('main.forms.update', {formId: $stateParams.formId, id: $stateParams.id});
        });
    };

    $scope.onFilesSelected = function ($files, $file, $newFiles) {
      $scope.vm.invalidFiles = $newFiles.find(function (file) {
        return file.size / Math.pow(2, 20) > $scope.vm.maxFileSizeMB;
      });
    };

    $scope.removeFiles = function (fileIndex) {
      if (angular.isNumber(fileIndex)) {
        $scope.vm.newEvidence.file.splice(fileIndex, 1);

      } else {
        $scope.vm.newEvidence.file = [];
      }
    };

    $scope.uploadEvidence = function (evidenceForm) {

      $scope.vm.serverErrors = null;

      if (evidenceForm.$valid) {
        $scope.vm.uploadInProgress = true;

        var customEvidenceFields = $scope.formDefinition.evidence.customFields;
        var newEvidence = $scope.vm.newEvidence;

        if (customEvidenceFields) {
          newEvidence.uprn = objectUtils.getPropertyPath($scope.model, customEvidenceFields.uprn_model_field);
          newEvidence.claimRef = objectUtils.getPropertyPath($scope.model, customEvidenceFields.claim_ref_field);
          newEvidence.filesystem = customEvidenceFields.anite_filesystem;
        }

        var types = $scope.formDefinition.evidence.types || [];

        if (types.length) { // evidence types are mapped object, not a list of strings
          var evidenceType = _.find($scope.formDefinition.evidence.types, function (evidenceType) {
            return evidenceType.id === newEvidence.evidenceType;
          });

          if (evidenceType) {
            newEvidence.description = $translate.instant(['evidence_type', $scope.formModelInstance.formTypeCode, evidenceType.id].join('.'));
            newEvidence.docTypeCode = evidenceType.docTypeCode;
          }
        }

        dynamicEvidenceService.uploadEvidenceFile($stateParams.id, newEvidence, $scope.formModelInstance.token)
          .then(function (response) {
            openModalForm.close();
            toaster.pop('success', "Your evidence has been submitted");
          })
          .catch(function (response) {
            $scope.vm.serverErrors = response.data ? response.data.errors : [{error: 'Internal server error'}];
          })
          .finally(function () {
            $scope.vm.uploadInProgress = false;
          });
      }
    };

    $scope.isFutureDate = function (date) {
      return date > new Date();
    };
  }
]);
