'use strict';
angular.module('kerp-forms.forms').factory('KS_MULTI_PAGE_FORM',
  ['FormUI', 'dateUtils', 'KS_CONDITIONS', 'schemaFormHelperService', '$injector',
    function (FormUI, dateUtils, conditions, schemaFormHelperService, $injector) {

      var formUI = new FormUI();

      var form = [

        {
          type: "fieldset",
          condition: "model.page === 1",
          page: 1,
          items: [
            {
              type: "fieldset",
              title: 'An array of objects',
              items: [
                {
                  key: 'children',
                  add: "Add Child",
                  title: 'Tell us about your children',
                  validationMessage: 'Complete all required fields for at least one child'
                }
              ]
            },
            {
              type: "fieldset",
              title: 'Hiding fields',
              items: [
                'textInputHide',
                'numberSelectionDateInputHide',
                {
                  type: 'help',
                  helpvalue: "<div class='alert alert-info'>Set both of the fields above to 'Yes' to skip the next page</div>"
                }
              ]
            }
          ]
        },

        {
          type: "fieldset",
          condition: "model.page === 2",
          page: 2,
          items: [
            {
              type: "fieldset",
              title: 'Text input with different regex constraints',
              condition: conditions.toString('textInputFieldSet'),
              items: [
                {
                  key: 'string.name',
                  condition: conditions.toString('string.name')
                },

                {
                  key: "string.nationalInsuranceNumber",
                  title: "National insurance number",
                  condition: conditions.toString('string.nationalInsuranceNumber')
                },
                {
                  key: "string.email",
                  title: "Email address",
                  condition: conditions.toString('string.email')
                }
              ]
            },

            {
              type: "fieldset",
              title: 'Number input fields',
              condition: conditions.toString('numberInputFieldSet'),
              items: [
                {
                  key: "number.minAndMaxInteger",
                  condition: conditions.toString('number.minAndMaxInteger')
                },
                {
                  key: "number.integer",
                  condition: conditions.toString('number.integer')
                },
                {
                  key: "number.positiveInteger",
                  condition: conditions.toString('number.positiveInteger')
                },
                {
                  key: "number.currency",
                  condition: conditions.toString('number.currency')
                },
                {
                  key: "number.decimal",
                  condition: conditions.toString('number.decimal')
                }
              ]
            },

            {
              type: "fieldset",
              htmlClass: 'form-group',
              title: 'Boolean fields',
              condition: conditions.toString('booleanFieldSet'),
              items: [
                {
                  key: "boolean.radios",
                  type: "radios",
                  condition: conditions.toString("boolean.radios")
                },
                {
                  key: "boolean.mustChooseYes",
                  condition: conditions.toString("boolean.mustChooseYes")
                }
              ]
            },
            {
              type: "fieldset",
              htmlClass: 'form-group',
              title: 'Single selection enum string fields',
              condition: conditions.toString('singleSelectionFieldSet'),
              items: [
                {
                  key: "string.genderRadios",
                  type: "radios",
                  condition: conditions.toString("string.genderRadios")
                },
                {
                  key: "string.genderSelect",
                  type: "select",
                  condition: conditions.toString("string.genderSelect")
                }
              ]
            },
            {
              type: "fieldset",
              htmlClass: 'form-group',
              title: 'Multi selection enum string array field',
              condition: conditions.toString('multiSelectionFieldset'),
              items: [
                {
                  key: "multiSelect",
                  type: "checkboxes",
                  condition: conditions.toString('multiSelect')
                }
              ]
            },
            {
              type: "fieldset",
              htmlClass: 'form-group',
              title: 'Date and time addons',
              condition: conditions.toString('dateTimeFieldSet'),
              items: [
                {
                  key: "string.pastDate",
                  condition: conditions.toString("string.pastDate")
                },
                {
                  key: "string.timePicker",
                  condition: conditions.toString("string.timePicker")
                },
                {
                  key: "string.memorableDate",
                  type: "memorabledate",
                  dateFormat: "YYYY-MM-DD",
                  validationMessage: "The date must be in the format dd/mm/yyyy and the age must be between 16 and 120.",
                  maxDate: dateUtils.getYearsBeforeNow(16),
                  minDate: dateUtils.getYearsBeforeNow(120),
                  condition: conditions.toString("string.memorableDate")
                }
              ]
            }
          ]
        },

        {
          type: "fieldset",
          condition: "model.page === 3",
          page: 3,
          items: [
            {
              type: "fieldset",
              title: 'Gazetteer Lookup/ address-suggestion directive',
              items: [
                {
                  type: "template",
                  templateUrl: "modules/forms/scripts/services/forms/kitchenSink/templates/addressSuggestion.html"
                },
                {
                  key: "addressSuggestionUPRN",
                  title: "UPRN",
                  type: "hidden"
                },
                {
                  key: "addressSuggestionLine1",
                  title: "Address line 1",
                  type: "hidden"
                },
                {
                  key: "addressSuggestionLine2",
                  title: "Address line 2",
                  type: "hidden"
                },
                {
                  key: "addressSuggestionLine3",
                  title: "Address line 3",
                  type: "hidden"
                },
                {
                  key: "addressSuggestionPostcode",
                  title: "Postcode",
                  type: "hidden"
                }
              ]
            },

            {
              type: "fieldset",
              title: 'School address lookup',
              items: [
                {
                  type: "template",
                  templateUrl: "modules/forms/scripts/services/forms/kitchenSink/templates/schoolAddressLookup.html"
                },
                "schoolLookupSchoolName",
                "schoolLookupLine1",
                "Address Line 1",
                "schoolLookupLine2",
                "Address line 2",
                "schoolLookupLine3",
                "schoolLookupPostcode"
              ]
            },

            {
              type: "fieldset",
              title: 'Landlord address lookup',
              items: [
                {
                  type: "template",
                  templateUrl: "modules/forms/scripts/services/forms/kitchenSink/templates/landlordAddressLookup.html"
                },
                "landlordLookupLandlordName",
                "landlordLookupLine1",
                "landlordLookupLine2",
                "landlordLookupLine3",
                "landlordLookupPostcode"
              ]
            }
          ]
        },
        {
          type: "fieldset",
          condition: "model.page === 4",
          page: 4,
          items: [
            {
              key: "string.chooseOrEnterTitle",
              type: 'selectWithInput',
              radios: true,
              options: ["Mr", "Ms", "Mrs", "Miss", "Other"],
              other: "Other",
              comment: "If other, please specify",
              title: 'Choose one of the following or enter an alternative'
            },
            {
              type: "fieldset",
              title: 'Mulitple field validation: in this example at least one of the three fields must be completed',
              items: [
                {
                  key: "localNumberApplicantDaytel",
                  type: "validationExternal",
                  title: "Daytime telephone number",
                  watchFields: ["localNumberApplicantDaytel", "fullNumberApplicantMob", "applicantEmail"],
                  hasErrorFn: function (value, model) {
                    if (!model.fullNumberApplicantMob && !model.applicantEmail && !value) {
                      return 'One of "Daytime telephone number", "Mobile telephone number" and "Email address" is required';
                    }
                  }
                },
                {
                  key: "fullNumberApplicantMob",
                  type: "validationExternal",
                  title: "Mobile telephone number",
                  watchFields: ["localNumberApplicantDaytel", "fullNumberApplicantMob", "applicantEmail"],
                  hasErrorFn: function (value, model) {
                    if (!model.localNumberApplicantDaytel && !model.applicantEmail && !value) {
                      return 'One of "Daytime telephone number", "Mobile telephone number" and "Email address" is required';
                    }
                  }
                },
                {
                  key: "applicantEmail",
                  type: "validationExternal",
                  title: "Email address",
                  watchFields: ["localNumberApplicantDaytel", "fullNumberApplicantMob", "applicantEmail"],
                  hasErrorFn: function (value, model) {
                    if (!model.fullNumberApplicantMob && !model.localNumberApplicantDaytel && !value) {
                      return 'One of "Daytime telephone number", "Mobile telephone number" and "Email address" is required';
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          type: "section",
          condition: "areFormNavigationButtonsVisible()",
          items: [
            {
              type: "actions",
              htmlClass: "formPaginationButtons",
              items: [
                {
                  type: "button",
                  style: "btn-default",
                  title: "Previous",
                  onClick: "prev()"
                },
                {
                  type: "button",
                  style: "btn-primary",
                  title: "Next",
                  onClick: "next()"
                }]
            }]
        }

      ];
      var mergedFormAndSchema = schemaFormHelperService.mergeFormAndSchema(form, $injector.get('KS_REQUIRED_SCHEMA').getSchema());

      formUI.getHiddenPages = function (model) {
        var hiddenPages = [];

        // the last element of the form is the section with the prev/next buttons rather than a page
        var formLength = form.length - 1;

        for (var pageIndex = 0; pageIndex < formLength; pageIndex++) {
          if (schemaFormHelperService.isPageHidden(model, conditions, mergedFormAndSchema[pageIndex])) {
            var hiddenPageNumber = pageIndex + 1;
            hiddenPages.push(hiddenPageNumber);
          }
        }

        return hiddenPages;
      };


      formUI.setForm(form);

      return formUI;

    }]);
