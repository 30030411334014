'use strict';
import { form } from './form.fn';
angular.module('kerp-forms.forms')
  .factory(
    'HA_FCA_FORM',
    [
      'FormUI',
      'haltonCareAssessmentCalculator',
      'htmlService',
      'fieldDefinitionService',
      'HA_FCA_CONDITIONS',
      '$window',

      form
    ]);
