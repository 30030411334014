'use strict';
(function () {

  var mod = angular.module('kerp-forms.forms');

  /**
   * A directive for select with an option other plus an input field
   */
  mod.directive('selectWithInput', ['sfValidator', function (sfValidator) {

    return {
      restrict: 'EA',
      replace: true,
      require: 'ngModel',
      scope: {
        form: '='
      },
      link: function (scope, elem, attrs, ngModelController) {

        function isPredefinedOption(val) {
          return _.without(scope.form.options, scope.form.other).indexOf(val) > -1;
        }

        /**
         * When a value is received, update the UI
         */
        scope.$watch(
          function () {
            return ngModelController.$modelValue;
          },
          function (newValue) {
            if (newValue) {
              scope.vm = isPredefinedOption(newValue) ?
                {selected: newValue} :
                {selected: scope.form.other, alternative: newValue};
            } else {
              scope.vm = {};
            }
          }
        );

        function validate(value) {
          var validationResult = sfValidator(scope.form, value);
          ngModelController.$setValidity("selectWithInput", validationResult.valid);
          return validationResult.valid;
        }

        function updateModelValue(newValue) {

          if (validate(newValue)) {
            ngModelController.$setViewValue(newValue);
            ngModelController.$setDirty();
          }
        }

        /**
         * When the value is changed in the UI, update model
         */
        scope.setAlternative = function () {
          updateModelValue(scope.vm.alternative);
        };

        scope.setSelected = function () {
          if (isPredefinedOption(scope.vm.selected)) {
            updateModelValue(scope.vm.selected);

          } else if (scope.form.required) {
            // When the "other" option is chosen the field should be marked invalid initially, because nothing
            // has been typed into the text field yet
            ngModelController.$setValidity("selectWithInput", false);
          }
        };
      },
      templateUrl: 'modules/forms/scripts/directives/selectWithInput/selectWithInputDirective.html'
    };
  }]);
}());
