'use strict';

angular.module('kerp-forms.forms').config(['schemaFormDecoratorsProvider', 'sfBuilderProvider',
  function (schemaFormDecoratorsProvider, sfBuilderProvider) {

    schemaFormDecoratorsProvider.defineAddOn(
      // Name of the decorator you want to add to
      'bootstrapDecorator',

      // Form type that should render this add-on
      'schemaversion',

      // Template name in $templateCache
      'modules/forms/scripts/directives/schemaVersion/schemaVersion.html',

      // List of builder functions to apply
      sfBuilderProvider.stdBuilders
    );
  }]);
