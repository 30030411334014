'use strict';

function LI_CA_SCHEMA(fieldDefinitionService, $window, configuration) {

  var enableResidentialCalcs = (configuration.featureFlags || {}).residentialCalcs;

  var constants = $window.kerpCfa.lincs.constants;

  var attendanceAllowances = constants.attendanceAllowance;
  var mobilityAllowances = constants.mobilityAllowance;
  var dlaCareAllowances = constants.dlaCare;
  var pipDailyAllowances = constants.pipDailyLiving;

  function toTitleMapEntry(level, amount) {
    return {value: level, name: amount.toFixed(2)};
  }

  function frequency(options) {
    return angular.extend({
      type: 'string',
      title: 'Frequency',
      "enum": ["Weekly", "Fortnightly", "4 Weekly", "Monthly", "Annually"],

      // this doesn't work for us, but it does if you try it here on the ASF example page
      // http://schemaform.io/examples/bootstrap-example.html
      "default": "Weekly"
    }, options);
  }

  function expenses () {
    var expenses = {
      type: 'object',
      title: 'Expenses',
      properties: {
        compulsoryPayments: fieldDefinitionService.number.currencyField({
          title: 'Court Ordered Payments'
        }),
        compulsoryPaymentsPeriod: frequency(),

        rent: fieldDefinitionService.number.currencyField({
          title: 'Rent'
        }),
        rentPeriod: frequency(),

        councilTax: fieldDefinitionService.number.currencyField({
          title: 'Annual Council Tax (after discounts, exemptions, and reductions)'
        }),

        mortgage: fieldDefinitionService.number.currencyField({
          title: 'Mortgage'
        }),
        mortgagePeriod: frequency(),

        mortgageInsurance: fieldDefinitionService.number.currencyField({
          title: 'Mortgage Endowment / Life Insurance'
        }),
        mortgageInsurancePeriod: frequency(),

        homeInsurance: fieldDefinitionService.number.currencyField({
          title: 'Buildings Insurance'
        }),
        homeInsurancePeriod: frequency(),

        isHomeInsuranceCombined: fieldDefinitionService.boolean.yesNoSchema({
          title: 'Is your buildings insurance a combined (buildings and content) policy?'
        }),

        groundRent: fieldDefinitionService.number.currencyField({
          title: 'Service Charges/Ground Rent'
        }),
        groundRentPeriod: frequency()
      },
      required: [
        'isHomeInsuranceCombined',
        'councilTax',
        // if an amount is entered for an expense, a corresponding frequency/periodicity must be specified
        'heatingCostsPeriod',
        'compulsoryPaymentsPeriod',
        'rentPeriod',
        'mortgagePeriod',
        'mortgageInsurancePeriod',
        'waterRatesPeriod',
        'homeInsurancePeriod',
        'groundRentPeriod'
      ].concat(requiredExpenses())
    };

    if (enableResidentialCalcs) {
      expenses.properties = Object.assign(expenses.properties, {
        waterRates: fieldDefinitionService.number.currencyField({
          title: 'Water'
        }),
        waterRatesPeriod: frequency(),

        gas: fieldDefinitionService.number.currencyField({
          title: 'Gas'
        }),
        gasPeriod: frequency(),

        electric: fieldDefinitionService.number.currencyField({
          title: 'Electricity'
        }),
        electricPeriod: frequency(),

        lifeline: fieldDefinitionService.number.currencyField({
          title: 'Lifeline'
        }),
        lifelinePeriod: frequency()
      });
    }

    return expenses;
  }

  function requiredExpenses() {
    var requiredExpenses = [];
    if (enableResidentialCalcs) {
      requiredExpenses = ['waterRatesPeriod', 'gasPeriod', 'electricPeriod', 'lifelinePeriod'];
    }

    return requiredExpenses;
  }

  function careAssessment() {
    var careAssessment = {
      type: 'object',
      properties: {
        nonResidential: {
          type: 'object',
          properties: {
            maxContribution: {
              type: 'number',
              minimum: 0,
              'x-schema-form': {
                fieldAddonLeft: '£'
              }
            }
          },
          required: ['maxContribution']
        }
      },
      required: ['nonResidential'].concat(requiredCareAssessments())
    };

    if (enableResidentialCalcs) {
      careAssessment.properties = Object.assign(careAssessment.properties, {
        temporaryResidential: {
          type: 'object',
          properties: {
            maxContribution: {
              type: 'number',
              minimum: 0,
              'x-schema-form': {
                fieldAddonLeft: '£'
              }
            }
          },
          required: ['maxContribution']
        },
        permanentResidential: {
          type: 'object',
          properties: {
            maxContribution: {
              type: 'number',
              minimum: 0,
              'x-schema-form': {
                fieldAddonLeft: '£'
              }
            }
          },
          required: ['maxContribution']
        }

      });
    }

    return careAssessment;
  }

  function requiredCareAssessments() {
    var requiredCareAssessments = [];
    if (enableResidentialCalcs) {
      requiredCareAssessments = ['temporaryResidential', 'permanentResidential'];
    }

    return requiredCareAssessments;
  }

  function universalCredit() {
    return angular.extend({
      type: 'object',
      properties: {
        standardAllowance: fieldDefinitionService.number.currencyField({
          title: 'Personal allowance'
        }),
        children: fieldDefinitionService.number.currencyField({
          title: 'Children'
        }),
        disabledChild: fieldDefinitionService.number.currencyField({
          title: 'Disabled child'
        }),
        housingElement: fieldDefinitionService.number.currencyField({
          title: 'Housing cost'
        }),
        limitedWorkCapability: fieldDefinitionService.number.currencyField({
          title: 'Limited capability for work'
        }),
        limitedWorkAndRelatedCapability: fieldDefinitionService.number.currencyField({
          title: 'Limited capability for work-related activity'
        }),
        carer: fieldDefinitionService.number.currencyField({
          title: 'Carer'
        }),
        childcare: fieldDefinitionService.number.currencyField({
          title: 'Childcare costs'
        }),
        ucHousing: fieldDefinitionService.number.currencyField({
          title: 'UC housing costs contribution'
        }),
        other: fieldDefinitionService.number.currencyField({
          title: 'Total taken off for other benefits'
        }),
        earnings: fieldDefinitionService.number.currencyField({
          title: 'Total taken off for take home pay'
        }),
        capitalTariff: fieldDefinitionService.number.currencyField({
          title: 'Total taken off for savings and capital'
        }),
        benefitLimit: fieldDefinitionService.number.currencyField({
          title: 'Total taken off for benefit cap limit'
        }),
        advanceRepayments: fieldDefinitionService.number.currencyField({
          title: 'Total taken off for advance repayments'
        }),
        debts: fieldDefinitionService.number.currencyField({
          title: 'Total taken off for debt and loan repayments'
        }),
        landlordPayment: fieldDefinitionService.number.currencyField({
          title: 'Total taken off for payment to landlord'
        })
      },
      required: [
        'standardAllowance',
        'children',
        'disabledChild',
        'housingElement',
        'limitedWorkCapability',
        'limitedWorkAndRelatedCapability',
        'carer',
        'childcare',
        'ucHousing',
        'other',
        'earnings',
        'capitalTariff',
        'benefitLimit',
        'advanceRepayments',
        'debts',
        'landlordPayment'
      ]
    });
  }

  var schema = {
    type: 'object',
    properties: {
      person: {
        type: 'object',
        title: 'About you',
        properties: {
          intro: {
            type: 'object',
            title: 'Details',
            properties: {

              havePartner: fieldDefinitionService.boolean.yesNoSchema({
                title: 'Do you have a partner?'
              }),
              partnerLivesAtSameAddress: fieldDefinitionService.boolean.yesNoSchema({
                title: 'Do you and your partner live at the same address?'
              }),

              partnerReceivesResidentialCare: fieldDefinitionService.boolean.yesNoSchema({
                title: "Is your partner currently in residential care?"
              }),
              partnerResidentialCareTerm: {
                title: 'Is your partner in residential care for a short term stay or long term stay?',
                type: 'string',
                'enum': [
                  'longTerm',
                  'shortTerm',
                  'unknown'
                ],
                'x-schema-form': {
                  titleMap: {
                    longTerm: 'Long term',
                    shortTerm: 'Short term',
                    unknown: 'Not sure'
                  }
                },
              },

              declarePartnerFinances: fieldDefinitionService.boolean.yesNoSchema({
                title: 'Do you want to declare your partner’s finances?'
              }),
              transferHalfPensionToPartner: fieldDefinitionService.boolean.yesNoSchema(),
              dob: fieldDefinitionService.time.dateOfBirth(),
              peopleLivingInHome: {
                title: 'How many people are living in your home?',
                type: 'integer',
                minimum: 1
              },
              propertyType: {
                title: 'Please indicate the type of property that you live in',
                type: 'string',
                enum: [
                  'flat',
                  'detached',
                  'semiDetached',
                  'terraced',
                  'detachedBungalow',
                  'semiDetachedBungalow',
                  'terracedBungalow',
                  'caravan',
                  'parkHome'
                ],
                'x-schema-form': {
                  type: "radios",
                  titleMap: [
                    {value: 'flat', name: 'Flat'},                                    // flat-terraced group
                    {value: 'detached', name: 'Detached House'},                      // detached group
                    {value: 'semiDetached', name: 'Semi-detached House'},             // semi-d group
                    {value: 'terraced', name: 'Terraced House'},                      // flat-terraced group
                    {value: 'detachedBungalow', name: 'Bungalow (detached)'},         // detached group
                    {value: 'semiDetachedBungalow', name: 'Bungalow (semi-detached)'},// semi-d group
                    {value: 'terracedBungalow', name: 'Bungalow (terraced)'},         // flat-terraced group
                    {value: 'caravan', name: 'Caravan'},                              // detached group
                    {value: 'parkHome', name: 'Park-home'}                            // detached group
                  ]
                }
              },
              chargingExemption: {
                type: 'string',
                enum: ['notExempt', 'exemptCJD', 'exemptSection117'],
                'x-schema-form': {
                  type: "radios",
                  titleMap: {
                    exemptCJD: "Exempt due to CJD",
                    exemptSection117: "Exempt due to Section 117 aftercare",
                    notExempt: "Not exempt"
                  }
                }
              }
            },
            required: [
              'peopleLivingInHome',
              'havePartner',
              'declarePartnerFinances',
              'transferHalfPensionToPartner',
              'dob',
              'chargingExemption',
              'propertyType'
            ]
          },
          capital: {
            type: 'object',
            title: 'Capital',
            properties: {
              joint: fieldDefinitionService.number.currencyField(
                {
                  title: 'Jointly held capital',
                },
                {
                  validationMessage: 'Please enter an amount in pounds and pence. If you are in your overdraft then you should enter 0'
                }
              ),
              individual: fieldDefinitionService.number.currencyField(
                {
                  title: 'Individual capital',
                },
                {
                  validationMessage: 'Please enter an amount in pounds and pence. If you are in your overdraft then you should enter 0'
                }
              )
            },
            required: ['individual']
          },
          income: {
            type: 'object',
            title: 'Income',
            properties: {
              retirementPensionReceived: fieldDefinitionService.number.currencyField({
                title: 'State Retirement Pension'
              }),
              retirementPensionReceivedPeriod: frequency(),

              attendanceAllowance: fieldDefinitionService.number.mappedCurrencyField({
                title: 'Attendance Allowance',
                'enum': [
                  'high',
                  'low',
                  'none'
                ]
              }, {
                type: 'select',
                titleMap: [
                  toTitleMapEntry('high', attendanceAllowances.high.amount),
                  toTitleMapEntry('low', attendanceAllowances.low.amount),
                  {value: 'none', name: '0'}
                ]
              }),

              dlaCare: fieldDefinitionService.number.mappedCurrencyField({
                title: 'Weekly Disability Living Allowance (DLA) Care',
                'enum': [
                  'high',
                  'low',
                  'none'
                ]
              }, {
                type: 'select',
                titleMap: [
                  toTitleMapEntry('high', dlaCareAllowances.high.amount),
                  toTitleMapEntry('medium', dlaCareAllowances.medium.amount),
                  toTitleMapEntry('low', dlaCareAllowances.low.amount),
                  {value: 'none', name: '0'}
                ]
              }),

              mobilityAllowance: fieldDefinitionService.number.mappedCurrencyField({
                title: 'DLA Mobility / PIP Mobility',
                'enum': [
                  'high',
                  'low',
                  'none'
                ]
              }, {
                type: 'select',
                titleMap: [
                  toTitleMapEntry('high', mobilityAllowances.high.amount),
                  toTitleMapEntry('low', mobilityAllowances.low.amount),
                  {value: 'none', name: '0'}
                ]
              }),

              pipDailyLiving: fieldDefinitionService.number.mappedCurrencyField({
                title: 'Personal Independence Payment (PIP) Daily Living',
                'enum': [
                  'high',
                  'low',
                  'none'
                ]
              }, {
                type: 'select',
                titleMap: [
                  toTitleMapEntry('high', pipDailyAllowances.high.amount),
                  toTitleMapEntry('low', pipDailyAllowances.low.amount),
                  {value: 'none', name: '0'}
                ]
              }),

              carersAllowance: fieldDefinitionService.number.currencyField({
                title: "Carer's Allowance"
              }),
              carersAllowancePeriod: frequency(),

              incapacityBenefit: fieldDefinitionService.number.currencyField({
                title: 'Incapacity Benefit'
              }),
              incapacityBenefitPeriod: frequency(),

              warPension: fieldDefinitionService.number.currencyField({
                title: 'War Pension'
              }),
              warPensionPeriod: frequency(),

              privatePension: fieldDefinitionService.number.currencyField({
                title: 'Private/Works Pension'
              }),
              privatePensionPeriod: frequency(),

              warSpecialPayment: fieldDefinitionService.number.currencyField({
                title: 'War Special Payment'
              }),
              warSpecialPaymentPeriod: frequency(),

              childBenefit: fieldDefinitionService.number.currencyField({
                title: 'Child Benefit'
              }),
              childBenefitPeriod: frequency(),

              otherBenefit: fieldDefinitionService.number.currencyField(
                {title: 'Any Other Benefit'},
                {description: 'Any other benefit you have not told us about'}
              ),
              otherBenefitPeriod: frequency(),

              workingTaxCredit: fieldDefinitionService.number.currencyField({
                title: 'Working Tax Credit'
              }),
              workingTaxCreditPeriod: frequency(),

              childTaxCredit: fieldDefinitionService.number.currencyField({
                title: 'Child Tax Credit'
              }),
              childTaxCreditPeriod: frequency(),

              pensionGuaranteeCredit: fieldDefinitionService.number.currencyField({
                title: 'Total Household Pension Credit Guarantee Element'
              }),
              pensionGuaranteeCreditPeriod: frequency(),
              pensionGuaranteeCreditIsJoint: fieldDefinitionService.boolean.yesNoSelectSchema({
                title: 'Is this a joint claim?'
              }),

              pensionSavingsCredit: fieldDefinitionService.number.currencyField({
                title: 'Total Household Pension Credit Savings Element'
              }),
              pensionSavingsCreditPeriod: frequency(),
              pensionSavingsCreditIsJoint: fieldDefinitionService.boolean.yesNoSelectSchema({
                title: 'Is this a joint claim?'
              }),

              incomeSupportPayment: fieldDefinitionService.number.currencyField({
                title: 'Income Support'
              }),
              incomeSupportPaymentPeriod: frequency(),
              incomeSupportPaymentIsJoint: fieldDefinitionService.boolean.yesNoSelectSchema({
                title: 'Is this a joint claim?'
              }),

              employmentSupport: fieldDefinitionService.number.currencyField({
                title: 'Employment Support Allowance'
              }),
              employmentSupportPeriod: frequency(),
              employmentSupportIsJoint: fieldDefinitionService.boolean.yesNoSelectSchema({
                title: 'Is this a joint claim?'
              }),

              jobSeekersAllowance: fieldDefinitionService.number.currencyField({
                title: "Jobseeker's Allowance"
              }),
              jobSeekersAllowancePeriod: frequency(),
              jobSeekersAllowanceIsJoint: fieldDefinitionService.boolean.yesNoSelectSchema({
                title: 'Is this a joint claim?'
              }),

              industrialInjuriesBenefit: fieldDefinitionService.number.currencyField({
                title: 'Industrial Injuries Benefit'
              }),
              industrialInjuriesBenefitPeriod: frequency(),

              severeDisablement: fieldDefinitionService.number.currencyField({
                title: 'Severe Disablement Allowance'
              }),
              severeDisablementPeriod: frequency(),

              warWidowsPension: fieldDefinitionService.number.currencyField({
                title: 'War Widow(er) Pension'
              }),
              warWidowsPensionPeriod: frequency(),

              maternityAllowance: fieldDefinitionService.number.currencyField({
                title: 'Maternity Allowance'
              }),
              maternityAllowancePeriod: frequency(),

              maintenance: fieldDefinitionService.number.currencyField({
                title: 'Maintenance - Spouse/Partner'
              }),
              maintenancePeriod: frequency(),

              exceptionalSevereDisablement: fieldDefinitionService.number.currencyField({
                title: 'Exceptional Severe Disablement Allowance'
              }),
              exceptionalSevereDisablementPeriod: frequency(),

              rentalIncome: fieldDefinitionService.number.currencyField({
                title: 'Income from Tenants and Boarders'
              }),
              rentalIncomePeriod: frequency(),

              annuitiesIncome: fieldDefinitionService.number.currencyField({
                title: 'Income from Annuities'
              }),
              annuitiesIncomePeriod: frequency(),

              trustFunds: fieldDefinitionService.number.currencyField({
                title: 'Trust Funds'
              }),
              trustFundsPeriod: frequency(),

              wages: fieldDefinitionService.number.currencyField({
                title: 'Wages'
              }),
              wagesPeriod: frequency(),

              otherIncome: fieldDefinitionService.number.currencyField({
                title: 'Any Other Income'
              }),
              otherIncomePeriod: frequency(),

              housingBenefit: fieldDefinitionService.number.currencyField(
                {title: 'Housing Benefit'},
                {description: "Enter the total amount of any Housing Benefit you receive."}
              ),
              housingBenefitPeriod: frequency(),
              housingBenefitIsJoint: fieldDefinitionService.boolean.yesNoSelectSchema({
                title: 'Is this a joint claim?'
              }),

              hasUniversalCredit: fieldDefinitionService.boolean.yesNoSchema(),
              universalCredit: universalCredit()
            },

            required: [
              'retirementPensionReceivedPeriod',
              'incapacityBenefitPeriod',
              'warPensionPeriod',
              'privatePensionPeriod',
              'warSpecialPaymentPeriod',
              'childBenefitPeriod',
              'otherBenefitPeriod',
              'carersAllowancePeriod',
              // the following are not applicable to partners
              'otherIncomePeriod',
              'wagesPeriod',
              'trustFundsPeriod',
              'annuitiesIncomePeriod',
              'rentalIncomePeriod',
              'industrialInjuriesBenefitPeriod',
              'hasUniversalCredit',
              'universalCredit',
              'jobSeekersAllowancePeriod',
              'exceptionalSevereDisablementPeriod',
              'workingTaxCreditPeriod',
              'childTaxCreditPeriod',
              'severeDisablementPeriod',
              'employmentSupportPeriod',
              'incomeSupportPaymentPeriod',
              'warWidowsPensionPeriod',
              'pensionGuaranteeCreditPeriod',
              'pensionSavingsCreditPeriod',
              'maternityAllowancePeriod',
              'maintenancePeriod',
              'housingBenefitPeriod',
              'pensionGuaranteeCreditIsJoint',
              'pensionSavingsCreditIsJoint',
              'incomeSupportPaymentIsJoint',
              'employmentSupportIsJoint',
              'jobSeekersAllowanceIsJoint',
              'housingBenefitIsJoint'
            ]
          },
          expenses: expenses(),
        },
        required: ['intro', 'capital', 'income', 'expenses', 'benefitsAdvice']
      },
      partner: {
        type: 'object',
        properties: {
          income: {
            type: 'object',
            properties: {
              hasUniversalCredit: fieldDefinitionService.boolean.yesNoSchema(),
              universalCredit: universalCredit()
            },
            required: [
              'hasUniversalCredit',
              'universalCredit'
            ]
          }
        },
        required: ['income']
      },
      careAssessment: careAssessment()
    },
    required: ['person', 'careAssessment']
  };

  return {
    getSchema: function () {
      return schema;
    }
  };
}

LI_CA_SCHEMA.$inject = ['fieldDefinitionService', '$window', 'configuration'];
angular.module('kerp-forms.forms').factory('LI_CA_SCHEMA', LI_CA_SCHEMA);
