'use strict';

/**
 * Extracts an agent name from agent details
 *
 */
angular
  .module('kerp-forms.forms')
  .directive(
    'jointPgcDwp', [
      'objectUtils',
      '$window',

      function (objUtils, $window) {

      return {
        scope: {
          model: '=',
          form: '=',
        },
        templateUrl: 'modules/forms/scripts/careAssessment/jointPGCDWP.html',
        link: function (scope, element, attrs) {
          scope.showMessage = false;

          const { careType } = scope.form || {};
          const { grossIncome } = scope.model.careAssessment[careType].workings;

          // when to show:
          // perm residential care types only
          if (['permWeek1Residential', 'permWeek13Residential'].includes(careType)) {

            // either SU or partner receives joint PGC
            var suReceivesPGC = objUtils.getPropertyPath(scope.model, 'person.income.pensionGuaranteeCredit');
            var suPGCIsJoint = objUtils.getPropertyPath(scope.model, 'person.income.pensionGuaranteeCreditIsJoint');
            var partnerReceivesPGC = objUtils.getPropertyPath(scope.model, 'partner.income.pensionGuaranteeCredit');
            var partnerPGCIsJoint = objUtils.getPropertyPath(scope.model, 'partner.income.pensionGuaranteeCreditIsJoint');

            var suGetsJointPGC = suReceivesPGC && suPGCIsJoint;
            var partnerGetsJointPGC = partnerReceivesPGC && partnerPGCIsJoint;

            if (suGetsJointPGC || partnerGetsJointPGC) {
              let threshold;
              try {
                threshold = $window.kerpCfa.lincolnshire.constants.dwpMIGIncomeThreshold;
              } catch(e) {
                threshold = 0;
              }

              // gross income is less than the DWP treshold
              if (grossIncome < threshold) {
                scope.showMessage = true;
              }
            }
          }
        }
      };
    }
  ]);
