'use strict';

angular.module('kerp-forms.forms').factory(
  'HE_FCA_CONDITIONS',
  [
    'FormConditions',

    function (
      FormConditions
    ) {


      function showCostOfCare(ctx){
        const alreadyInResidentialCare = ctx.lib.getModelValue('person.details.alreadyInPermanentCare') === 'yes';
        const ownsPrimaryProperty = ctx.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
        const ownsAdditionalProperty = ctx.lib.getModelValue('person.property.hasAdditionalProperties');

        return !(alreadyInResidentialCare && ownsPrimaryProperty && !ownsAdditionalProperty);
      }

      return FormConditions.clone({
        conditions: {
          'person.property.value': function () {
            return this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
          },

          'showNonResidentialAndRespite': function() {
            var excludeNonResidentialCaretypes = this.lib.getModelValue('person.intro.excludeNonResidentialCaretypes');
            var alreadyInPermanentCare = this.lib.getModelValue('person.details.alreadyInPermanentCare') === 'yes';

            return (!alreadyInPermanentCare && !excludeNonResidentialCaretypes);
          },

          'person.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'person.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'person.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'person.income.receivesExtraCare': function() {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return hasAA || hasDlaCare || hasPipDla;
          },

          'partner.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'partner.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'partner.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'partner.income.receivesExtraCare': function() {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return hasAA || hasDlaCare || hasPipDla;
          },

          'person.income.universalCredit': function () {
            var uc = this.lib.getModelValue('person.income.universalCredit');
            return uc > 0;
          },

          'person.income.otherConstantAttendanceAllowance': function () {
            const warDisablementIncludesCAA = this.lib.getModelValue('person.income.warDisablementIncludesCAA') === true;
            const otherConstantAttendanceAllowance = this.lib.getModelValue('person.income.constantAttendanceAllowance') === 'none';

            return warDisablementIncludesCAA && otherConstantAttendanceAllowance;
          },

          'partner.income.otherConstantAttendanceAllowance': function () {
            const warDisablementIncludesCAA = this.lib.getModelValue('partner.income.warDisablementIncludesCAA') === true;
            const otherConstantAttendanceAllowance = this.lib.getModelValue('partner.income.constantAttendanceAllowance') === 'none';

            return warDisablementIncludesCAA && otherConstantAttendanceAllowance;
          },

          'show12WeekDisclaimer': function() {
            const alreadyInResidentialCare = this.lib.getModelValue('person.details.alreadyInPermanentCare') === 'yes';
            const ownsPrimaryProperty = this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
            const ownsAdditionalProperty = this.lib.getModelValue('person.property.hasAdditionalProperties');

            return !alreadyInResidentialCare && ownsPrimaryProperty && !ownsAdditionalProperty;
          },

          'cannotShowCostOfCare': function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            return provideFinancialDetails === 'agree' && !(showCostOfCare(this));
          },

          'canShowCostOfCare': function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            return provideFinancialDetails === 'agree' && showCostOfCare(this);
          },

          'person.expenses.councilTaxShareCount': function() {
            var partnerCohabits = this.lib.getModelValue('partner.details.sameAddress') === true;
            var peopleSharing = this.lib.getModelValue('peopleLivingWithYou.hasOtherPeople') === true;

            return partnerCohabits || peopleSharing;
          },

          showCostOfCare: function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            return provideFinancialDetails === 'agree';
          },

          showBreakdown: function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            return provideFinancialDetails === 'agree';
          },

          costOfCarePayFullCost: function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            return provideFinancialDetails === 'payFullCost' || provideFinancialDetails === 'capitalOverThreshold';
          },

          costOfCareArrangeOwn: function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            return provideFinancialDetails === 'arrangeMyOwn';
          },

        }
      });
    }]);
