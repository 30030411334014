'use strict';

angular.module('kerp-forms.forms')
  .service('AA1A_MAPPINGS', ['FormFieldMappings', function (FormFieldMappings) {

    function getNeedProperty(facts, index, property) {
      if (facts.healthAndCare && facts.healthAndCare.needs && facts.healthAndCare.needs.length > index) {
        var need = facts.healthAndCare.needs[index];
        return need && need[property];
      }
    }

    function addNeed(allNeeds, model, disabilityKey, sinceWhenKey, treatmentKey) {
      if (model[disabilityKey]) {
        allNeeds.push({
          disability: model[disabilityKey],
          sinceWhen: model[sinceWhenKey],
          treatment: model[treatmentKey]
        });
      }
    }

    var needIndex = -1;

    this.mappings = function () {
      return FormFieldMappings.buildWithMappings({
        '1_1': 'lastName',
        '1_2': 'firstName',
        '1_3': 'title',
        '1_4': 'nationalInsuranceNumber',
        '1_5': 'nationalInsuranceNumber',
        '1_6': 'nationalInsuranceNumber',
        '1_7': 'dateOfBirth',
        '1_8': 'gender',
        '1_9': 'address.formatted',
        '1_10': 'phoneNumber',
        '1_11': 'alternativePhoneNumber',
        '5_1': 'healthAndCare',
        '5_2': 'healthAndCare',
        '5_3': 'healthAndCare',
        '5_5': 'healthAndCare',
        '5_6': 'healthAndCare',
        '5_7': 'healthAndCare',
        '5_9': 'healthAndCare',
        '5_10': 'healthAndCare',
        '5_11': 'healthAndCare',
        '5_13': 'healthAndCare',
        '5_14': 'healthAndCare',
        '5_15': 'healthAndCare',
        '5_17': 'healthAndCare',
        '5_18': 'healthAndCare',
        '5_19': 'healthAndCare',
        '5_21': 'healthAndCare',
        '5_22': 'healthAndCare',
        '5_23': 'healthAndCare'
      }).factToFieldConverters({
        '1_4': function (facts) {
          return this.getNationaInsuranceNumberPartsFromFacts()[0];
        },
        '1_5': function (facts) {
          return this.getNationaInsuranceNumberPartsFromFacts()[1];
        },
        '1_6': function (facts) {
          return this.getNationaInsuranceNumberPartsFromFacts()[2];
        },
        '1_7': function (facts) {
          return this.ISOStringToDDMMYYYY(facts.dateOfBirth);
        },
        '5_1': function (facts) {
          return getNeedProperty(facts, ++needIndex, 'disability');
        },
        '5_2': function (facts) {
          return getNeedProperty(facts, needIndex, 'sinceWhen');
        },
        '5_3': function (facts) {
          return getNeedProperty(facts, needIndex, 'treatment');
        },
        '5_5': function (facts) {
          return getNeedProperty(facts, ++needIndex, 'disability');
        },
        '5_6': function (facts) {
          return getNeedProperty(facts, needIndex, 'sinceWhen');
        },
        '5_7': function (facts) {
          return getNeedProperty(facts, needIndex, 'treatment');
        },
        '5_9': function (facts) {
          return getNeedProperty(facts, ++needIndex, 'disability');
        },
        '5_10': function (facts) {
          return getNeedProperty(facts, needIndex, 'sinceWhen');
        },
        '5_11': function (facts) {
          return getNeedProperty(facts, needIndex, 'treatment');
        },
        '5_13': function (facts) {
          return getNeedProperty(facts, ++needIndex, 'disability');
        },
        '5_14': function (facts) {
          return getNeedProperty(facts, needIndex, 'sinceWhen');
        },
        '5_15': function (facts) {
          return getNeedProperty(facts, needIndex, 'treatment');
        },
        '5_17': function (facts) {
          return getNeedProperty(facts, ++needIndex, 'disability');
        },
        '5_18': function (facts) {
          return getNeedProperty(facts, needIndex, 'sinceWhen');
        },
        '5_19': function (facts) {
          return getNeedProperty(facts, needIndex, 'treatment');
        },
        '5_21': function (facts) {
          return getNeedProperty(facts, ++needIndex, 'disability');
        },
        '5_22': function (facts) {
          return getNeedProperty(facts, needIndex, 'sinceWhen');
        },
        '5_23': function (facts) {
          return getNeedProperty(facts, needIndex, 'treatment');
        }
      }).fieldToFactConverters({
        dateOfBirth: function (model) {
          return this.DDMMYYYYToISOString(model['1_7']);
        },
        nationalInsuranceNumber: function (model) {
          return model['1_4'] + model['1_5'] + model['1_6'];
        },
        healthAndCare: function (model) {

          var allNeeds = [];
          addNeed(allNeeds, model, '5_1', '5_2', '5_3');
          addNeed(allNeeds, model, '5_5', '5_6', '5_7');
          addNeed(allNeeds, model, '5_9', '5_10', '5_11');
          addNeed(allNeeds, model, '5_13', '5_14', '5_15');
          addNeed(allNeeds, model, '5_17', '5_18', '5_19');
          addNeed(allNeeds, model, '5_21', '5_22', '5_23');

          return {
            needs: allNeeds
          };
        }
      });
    };
  }]);
