'use strict';

(function (angular) {

  var mod = angular.module('kerp-forms.forms');

  mod.directive('schemaFormErrors', ['$timeout', 'sfPath', '$log', function ($timeout, sfPath, $log) {

    function animateToElement($el) {
      $('html, body').animate({scrollTop: $el.offset().top}, 500);
    }

    /**
     * Collect form fields from form definition
     * @param item
     */
    function traverseForm(item, cache) {

      if (!cache) {
        cache = [];
      }

      var isArray = Array.isArray(item);
      var isObject = typeof item === 'object';

      if (isArray) {
        item.forEach(function (i) {
          traverseForm(i, cache);
        });
      } else if (isObject) {

        if (item.items) {
          item.items.forEach(function (i) {
            traverseForm(i, cache);
          });
        } else if (item.key) {
          cache.push(item);
        }
      }

      return cache;
    }

    function invertForm(form) {
      return traverseForm(form)
        .reduce(function (memo, item, idx) {

          // This needs to be kept in sync with how we generate our field names/IDs in our templates. Currently we use fieldId(false, false)
          // The first argument to fieldId and the 3rd argument to sfPath.name specify that the form name should not be prepended
          var key = sfPath.name(item.key, '-', '', false);

          if (!key) {
            throw new Error('Failed to generate key for field', item.key);
          }
          item._position = idx;
          memo[key] = item;
          return memo;
        }, {});
    }

    /**
     * chech if keys of objects are completely different
     * @param o1
     * @param o2
     * @returns {boolean}
     */
    function areAllKeysDifferent(o1, o2) {

      if (!o1 || !o2 || !angular.isObject(o1) || !angular.isObject(o2)) {
        return true;
      }

      var o1Keys = Object.keys(o1);
      var o2Keys = Object.keys(o2);

      if ((!o1Keys.length && o2Keys.length) || (o1Keys.length && !o2Keys.length)) {
        return true;
      }

      if (!o1Keys.length && !o2Keys.length) {
        return false;
      }

      return !o1Keys.some(function (o1Key) {
        return o2Keys.indexOf(o1Key) > -1;
      });

    }

    return {
      scope: {
        schema: '=',
        form: '=',
        model: '='
      },
      controller: ['$rootScope', '$scope', '$element', 'sfErrorMessage', function ($rootScope, $scope, $element, sfErrorMessage) {

        var invertedFormFields = {};

        $scope.$watch('form', function (newVal) {
          if (newVal) {
            invertedFormFields = invertForm($scope.form);
          }
        });

        /**
         * Convert and simplify form field errors
         * @param formInstanceErrors {Object<String,Object<String, NgModelController>>} - object of form errors FormController.$error
         * @returns {Array}
         */
        function getVisibleFormErrors(formInstanceErrors) {

          if (!formInstanceErrors || !angular.isObject(formInstanceErrors) || !Object.keys(formInstanceErrors).length) {
            return [];
          }

          var errors = Object.keys(formInstanceErrors).reduce(function (allErrors, errCode) {

            formInstanceErrors[errCode].forEach(function (ngModelCtrl) {
              var fieldDefinition = invertedFormFields[ngModelCtrl.$name];

              if (fieldDefinition) {
                // args: error, value, viewValue, form, global
                var errorMsg = sfErrorMessage.interpolate(errCode, ngModelCtrl.$modelValue, ngModelCtrl.$viewValue, fieldDefinition);
                allErrors[ngModelCtrl.$name] = {
                  fieldName: ngModelCtrl.$name,
                  title: fieldDefinition.errorLabel || fieldDefinition.title,
                  error: errorMsg,
                  position: fieldDefinition._position
                };
              }
            });

            return allErrors;
          }, {});

          return Object.keys(errors)
            .map(function (k) {
              return errors[k];
            })
            .sort(function (a, b) {
              return a._position > b._position;
            });
        }

        var observableFormListener = null;

        function getFormControllerFields(formController) {
          var fields = {};
          angular.forEach(formController, function (v, k) {
            if (typeof v === 'object' && v.hasOwnProperty('$modelValue')) {
              fields[k] = k;
            }
          });
          return fields;
        }

        function observeVisibleForm(formInstance, callback) {

          if (observableFormListener) {
            observableFormListener();
          }

          $scope.formInstance = formInstance;

          observableFormListener = $scope.$watch(function () {
            return {
              errors: getVisibleFormErrors(formInstance.$error),
              fields: getFormControllerFields(formInstance)
            };
          }, function (newVal, oldVal) {
            // if form fields change then disable listener and wait
            // for another call from event.
            // it means that form page changed

            $log.debug("observableFormListener", newVal, oldVal);

            if (newVal && oldVal && angular.isObject(newVal.fields) && angular.isObject(oldVal.fields) && areAllKeysDifferent(newVal.fields, oldVal.fields)) {
              observableFormListener();
              $scope.visibleErrors = [];
            } else {
              $scope.visibleErrors = newVal.errors;
            }
          }, true);

          if (angular.isFunction(callback)) {
            callback();
          }
        }

        function onCheckSchemaFormErrors(event, optionalFormInstance) {
          $log.debug("onCheckSchemaFormErrors", optionalFormInstance);
          if (optionalFormInstance) {
            observeVisibleForm(optionalFormInstance, function () {
              $scope.$broadcast('schemaFormValidate');
              $scope.$parent.$broadcast('schemaFormValidate');
              $scope.$emit('schemaFormValidate');
              $scope.$parent.$emit('schemaFormValidate');

              if (!optionalFormInstance.$valid) {
                animateToElement($element);
              }
            });
          }
        }

        $rootScope.$on('checkSchemaFormErrors', onCheckSchemaFormErrors);

        function onRenderSchemaFormPageError(event, pageNumber, formInstance) {
          $scope.otherPageErrors = [{
            title: 'Page ' + pageNumber,
            pageNumber: pageNumber,
            formInstance: formInstance
          }];
          animateToElement($element);
        }

        $rootScope.$on('renderSchemaFormPageError', onRenderSchemaFormPageError);

        function onClearSchemaFormErrors(event) {
          $scope.otherPageErrors = [];
          $scope.visibleErrors = [];
        }

        $rootScope.$on('clearSchemaFormErrors', onClearSchemaFormErrors);

        $scope.focusOnElement = function (errorObj) {
          var anchor$ = $(document.getElementById('form_element_' + errorObj.fieldName));

          if (anchor$.length) {
            animateToElement(anchor$);
            anchor$.parent().find('input, select, textarea').not('[type="hidden"]').first().focus();
          }
        };

        $scope.openPageWithErrors = function (err) {

          $log.debug("openPageWithErrors", err);

          // kill form observer
          if (observableFormListener) {
            observableFormListener();
          }
          // move to other page
          $scope.model.page = err.pageNumber;

          // remove page error
          $scope.otherPageErrors = [];

          // render errors
          $timeout(function () {
            $log.debug("openPageWithErrors $timeout", err);
            onCheckSchemaFormErrors(null, err.formInstance);
          }, 50);

        };

      }],
      templateUrl: 'modules/forms/scripts/directives/schemaFormErrors/schemaFormErrorsTmpl.html',
      replace: false,
      restrict: 'EA'
    };

  }]);

}(angular));
