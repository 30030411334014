'use strict';

angular.module('kerp-forms.forms')
  .service('ESA50_MAPPINGS', ['FormFieldMappings', function (FormFieldMappings) {
    this.mappings = function () {
      return FormFieldMappings.buildWithMappings({
        '2_2': 'lastName',
        '2_3': 'firstName',
        '2_4': 'title',
        '2_5': 'address.formatted',
        '2_6': 'dateOfBirth',
        '2_7': 'nationalInsuranceNumber',

        // list of disabilities are mapped from the facts into the form, but not vice versa
        '5_1': 'healthAndCare',
        '16_4': 'alternativePhoneNumber',
        '16_5': 'alternativePhoneNumber',
        '16_6': 'phoneNumber'
      }).factToFieldConverters({
        '2_6': function (facts) {
          return this.ISOStringToDDMMYYYY(facts.dateOfBirth);
        },
        '5_1': function (facts) {
          if (facts.healthAndCare && facts.healthAndCare.needs) {
            return facts.healthAndCare.needs.map(function (need) {
              return need.disability;
            }).join(', ');
          }
        },
        '16_4': function (facts) {
          if (facts.alternativePhoneNumber) {
            return facts.alternativePhoneNumber.substr(0, 3);
          }
        },
        '16_5': function (facts) {
          if (facts.alternativePhoneNumber) {
            return facts.alternativePhoneNumber.substr(3);
          }
        }
      }).fieldToFactConverters({
        dateOfBirth: function (model) {
          return this.DDMMYYYYToISOString(model['2_6']);
        },
        alternativePhoneNumber: function (model) {
          return model['16_4'] + model['16_5'];
        }
      });
    };
  }]);
