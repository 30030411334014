'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'FCA_FORM',
    [
      'FormUI',
      'fieldDefinitionService',
      'FCA_CONDITIONS',
      'kirkleesCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      '$window',

      function (
        FormUI,
        fieldDefinitionService,
        conditions,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        $window
      ) {

        // this module only uses the care types defined in constants,
        // so does not need to pass in an effective date.
        const constants = $window.kerpCfa.getConstants('FCA');
        const annualRatesLookupRules = $window.kerpCfa.kirklees.annualRatesLookupRules;

        var formUI = new FormUI();

        formUI.getHiddenPages = function (model) {
          var intro = (model.person || {}).intro || {};
          var skipPages = intro.provideFinancialDetails !== 'agree';
          return skipPages ? [2, 3, 4, 5, 6, 7] : [];
        };

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel, false);
        };

        const generateForm = function() {
          return [
            {
              type: 'section',
              condition: 'model.page === 1',
              page: 1,
              htmlClass: "row",
              items: [
                {
                  type: "fieldset",
                  htmlClass: "col-md-12",
                  items: [
                    {
                      key: 'schemaVersion',
                      type: 'schemaversion',
                    },
                    {
                      type: 'template',
                      htmlClass: 'divTitle',
                      templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_01_intro.html'
                    },
                    {
                      key: 'clientReference',
                      description: 'You will find this on any letters you have received from Adult Social Care Services. If you do not know your client reference please leave this blank.'
                    },
                    'backdateRequested',
                    {
                      key: 'effectiveDate',
                      condition: 'model.backdateRequested',
                    }
                  ]
                },
                {
                  type: "fieldset",
                  title: 'About you',
                  htmlClass: "col-md-6",
                  items: [
                    {
                      type: 'template',
                      htmlClass: 'divTitle',
                      templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_02_about-you.html'
                    },
                    'person.details.title',
                    'person.details.firstName',
                    'person.details.lastName',
                    'person.details.nationalInsuranceNumber',
                    {
                      key: 'person.details.dob',
                      description: 'This online assessment form is only for people over the age of 18. We need your date of birth to calculate your State Pension age.'
                    },
                    'person.details.phoneNumber',
                    {
                      key: 'person.details.email',
                      description: 'If you provide an email address then we can use this to contact you in the future if we need more information.'
                    },
                    {
                      key: 'person.intro.hasPartner',
                      description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/FCA/templates/partnerDescription.html')
                    },
                    {
                      key: 'partner.intro.discloseFinances',
                      description: "By declaring your partner's finances you may benefit from extra allowances.",
                      condition: 'model.person.intro.hasPartner'
                    },
                    {
                      type: "template",
                      templateUrl: "modules/forms/scripts/services/forms/FCA/templates/addressLookup.html"
                    },
                    // FIXME: these fields are already defined in the addressSuggestion directive's template, but they are not included in the submitted model unless they are repeated here
                    {
                      key: 'person.details.address.line1',
                      title: 'Address line 1',
                      type: "hidden"
                    },
                    {
                      key: 'person.details.address.line2',
                      title: 'Address line 2',
                      type: "hidden"
                    },
                    {
                      key: 'person.details.address.line3',
                      title: 'Address line 3',
                      type: "hidden"
                    },
                    {
                      key: 'person.details.address.postcode',
                      title: 'Postcode',
                      type: "hidden"
                    },
                    {
                      key: 'person.details.address.uprn',
                      title: 'Property Reference',
                      type: "hidden"
                    },

                    'person.property.ownership',
                    {
                      key: 'person.property.propertyTenancyAdditionalDetails',
                      condition: "model.person.property.ownership === 'other'"
                    }
                  ]
                },
                {
                  type: "fieldset",
                  title: 'About your partner',
                  htmlClass: "col-md-6",
                  items: [
                    {
                      title: false,
                      key: 'partner.details'
                    }
                  ],
                  condition: 'model.person.intro.hasPartner'
                },
                {
                  type: 'fieldset',
                  title: "Declaring your financial circumstances",
                  htmlClass: "col-md-12",
                  items: [
                    'person.intro.provideFinancialDetails'
                  ]
                }
              ]
            },

            {
              type: 'section',
              condition: 'model.page === 2',
              page: 2,
              items: [
                {
                  type: 'fieldset',
                  title: 'People living with you',
                  items: [
                    {
                      key: 'peopleLivingWithYou.hasDependentChildren',
                      description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/FCA/templates/dependentChildrenDescription.html')
                    },
                    {
                      key: 'peopleLivingWithYou.dependentChildren',
                      condition: 'model.peopleLivingWithYou.hasDependentChildren',
                      add: "Add Child",
                      title: 'Dependent children details',
                      validationMessage: 'Complete all required fields for at least one child'
                    },
                    {
                      key: 'peopleLivingWithYou.hasOtherPeople',
                      description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/FCA/templates/nonDependentsDescription.html')
                    },
                    {
                      key: 'peopleLivingWithYou.otherPeople',
                      condition: 'model.peopleLivingWithYou.hasOtherPeople',
                      add: "Add Person",
                      title: 'Details of other people living with you',
                      validationMessage: 'Complete all required fields for at least one person'
                    }
                  ]
                }
              ]
            },
            {
              type: 'section',
              condition: 'model.page === 3',
              page: 3,
              items: [
                {
                  type: 'section',
                  htmlClass: "row",
                  items: [
                    {
                      type: "fieldset",
                      title: 'Your income',
                      htmlClass: "col-md-6",
                      items: [
                        {
                          type: 'template',
                          htmlClass: 'divTitle',
                          templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_04_income-intro.html'
                        },
                        {
                          type: "help",
                          helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                        },
                        {
                          key: 'person.income.hasWage',
                          title: 'Are you currently employed?'
                        },
                        {
                          key: 'person.income.wages',
                          condition: 'model.person.income.hasWage',
                          add: "Add salary/wage",
                          title: 'Wages/Salary',
                          validationMessage: 'Enter details of at least one employer'
                        },
                        {
                          type: "help",
                          helpvalue: "<h4 class='section-title'>Private/Works Pensions</h4>"
                        },
                        {
                          key: 'person.income.hasPrivatePension',
                          title: 'Are you in receipt of private/works pension(s)?'
                        },
                        {
                          key: 'person.income.privatePensions',
                          condition: 'model.person.income.hasPrivatePension',
                          add: "Add more",
                          title: 'Private/Works Pensions',
                          validationMessage: 'Enter details of at least one private/works pension'
                        },
                        {
                          type: "help",
                          helpvalue: "<h4 class='section-title'>Universal Credit</h4>"
                        },
                        {
                          key: 'person.income.hasUniversalCredit',
                          title: 'Are you in receipt of Universal Credit?'
                        },
                        {
                          type: 'template',
                          templateUrl: 'modules/forms/scripts/services/forms/FCA/templates/universalCreditIntro.html',
                          condition: 'model.person.income.hasUniversalCredit'
                        },
                        {
                          key: 'person.income.universalCreditBreakdown',
                          notitle: true,
                          title: 'Universal Credit Allowances',
                          condition: 'model.person.income.hasUniversalCredit'
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      title: "Your partner's income",
                      htmlClass: "col-md-6",
                      items: [
                        {
                          type: "help",
                          helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                        },
                        {
                          key: 'partner.income.hasWage',
                          title: 'Is your partner currently employed?'
                        },
                        {
                          key: 'partner.income.wages',
                          condition: 'model.partner.income.hasWage',
                          add: "Add salary/wage",
                          title: 'Wages/Salary',
                          validationMessage: 'Enter details of at least one employer'
                        },
                        {
                          type: "help",
                          helpvalue: "<h4 class='section-title'>Private/Works Pensions</h4>"
                        },
                        {
                          key: 'partner.income.hasPrivatePension',
                          title: 'Is your partner in receipt of private/works pension(s)?'
                        },
                        {
                          key: 'partner.income.privatePensions',
                          condition: 'model.partner.income.hasPrivatePension',
                          add: "Add more",
                          title: 'Private/Works Pensions',
                          validationMessage: 'Enter details of at least one private/works pension'
                        }
                      ],
                      condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances'
                    }
                  ]
                },
                {
                  type: 'section',
                  htmlClass: "row",
                  items: [
                    {
                      type: "fieldset",
                      title: 'Your benefits',
                      htmlClass: "col-md-6",
                      items: [
                        fieldDefinitionService.groups.paymentFormField({key: 'person.income.disabilityBenefits'}),
                        fieldDefinitionService.groups.paymentFormField({key: 'person.income.mobilityAllowance'}),
                        {
                          key: 'person.income.benefitsPaidAsCouple',
                          condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                          description: "Answer Yes if your JSA/ESA payment includes an amount for your partner. If you do not receive JSA or ESA or if the payment is only for you, answer No"
                        },
                        fieldDefinitionService.groups.periodicPaymentFormFields({
                          key: 'person.income.incapacityBenefit',
                          description: "This includes Jobseeker’s Allowance, Employment and Support Allowance, Incapacity Benefit, or Income Support."
                        }),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.retirementPension'}),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.warPension'}),
                        fieldDefinitionService.groups.periodicPaymentFormFields({
                          key: 'person.income.warSpecialPayment',
                          condition: 'model.person.income.warPension'
                        }),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.warDisablementPension'}),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.carersAllowance'}),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.childBenefit'}),

                        fieldDefinitionService.groups.periodicPaymentFormFields({
                          key: 'person.income.pensionGuaranteeCredit',
                          description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/FCA/templates/pensionCredit.html')
                        }),

                        fieldDefinitionService.groups.periodicPaymentFormFields({
                          key: 'person.income.pensionSavingsCredit',
                          description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/FCA/templates/pensionCredit.html')
                        }),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.workingTaxCredit'}),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.childTaxCredit'}),

                        'person.income.hasOtherBenefit',
                        {
                          key: 'person.income.otherBenefit',
                          condition: 'model.person.income.hasOtherBenefit'
                        },

                        {
                          key: 'person.intro.carersAllowanceIsEntitled',
                          title: "Do you have an underlying entitlement to Carer’s Allowance?",
                          description: 'Answer Yes if you have claimed Carer’s Allowance but are not receiving the payment because you are receiving another benefit instead. This will be shown as “underlying entitlement” on your benefit award letter.'
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      title: "Your partner's benefits",
                      htmlClass: "col-md-6",
                      items: [
                        fieldDefinitionService.groups.paymentFormField({key: 'partner.income.disabilityBenefits'}),
                        fieldDefinitionService.groups.paymentFormField({key: 'partner.income.mobilityAllowance'}),
                        {
                          type: 'section',
                          htmlClass: 'row frequency',
                          condition: '!model.person.income.benefitsPaidAsCouple',
                          items: [
                            {
                              key: 'partner.income.incapacityBenefit',
                              htmlClass: "col-md-8",
                              description: "This includes Jobseeker’s Allowance, Employment and Support Allowance, Incapacity Benefit, or Income Support.",
                              feedback: false
                            },
                            {
                              key: 'partner.income.incapacityBenefitPeriod',
                              condition: 'model.partner.income.incapacityBenefit',
                              htmlClass: "col-md-4  col-freq top"
                            }
                          ]
                        },
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.retirementPension'}),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.warPension'}),
                        fieldDefinitionService.groups.periodicPaymentFormFields({
                          key: 'partner.income.warSpecialPayment',
                          condition: 'model.partner.income.warPension'
                        }),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.warDisablementPension'}),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.carersAllowance'}),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.childBenefit'}),

                        'partner.income.hasOtherBenefit',
                        {
                          key: 'partner.income.otherBenefit',
                          condition: 'model.partner.income.hasOtherBenefit'
                        },

                      ],
                      condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances'
                    }
                  ]
                },
                {
                  type: 'section',
                  htmlClass: "row",
                  items: [
                    {
                      type: "fieldset",
                      title: 'Personal Injury Claim',
                      htmlClass: "col-md-12",
                      items: [
                        {
                          key: 'person.personalInjury.hasReceivedCompensation',
                          title: 'Have you received compensation for any form of personal injury?',
                        },
                        {
                          type: 'template',
                          templateUrl: 'modules/forms/scripts/services/forms/FCA/templates/personalInjury.html',
                          condition: 'model.person.personalInjury.hasReceivedCompensation'
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'section',
                  htmlClass: "row",
                  items: [
                    {
                      type: "fieldset",
                      title: 'Private Health Insurance/Hospital Fund Scheme',
                      htmlClass: "col-md-12",
                      items: [
                        {
                          key: 'person.healthInsurance.hasInsurance',
                          title: 'Do you have Private Health Insurance which covers you for Home Care or Residential Care?',
                          description: 'If you have Private Insurance or hospital fund scheme you may be asked to contribute the full amount of any payment you receive towards the service provided.'
                        },
                        {
                          key: 'person.healthInsurance.details',
                          condition: 'model.person.healthInsurance.hasInsurance',
                          notitle: true
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              type: 'section',
              condition: 'model.page === 4',
              page: 4,
              items: [
                {
                  type: 'section',
                  htmlClass: "row",
                  items: [
                    {
                      type: "fieldset",
                      title: 'Your capital',
                      htmlClass: "col-md-4",
                      items: [
                        {
                          type: 'template',
                          htmlClass: 'divTitle',
                          templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_03_capital-intro.html'
                        },
                        {
                          key: 'person.capital.hasSavingsInvestments',
                          title: 'Do you have any money or investments which are held in accounts where you are the sole named account holder?'
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                      title: "Your partner's capital",
                      htmlClass: "col-md-4",
                      items: [
                        {
                          key: 'partner.capital.hasSavingsInvestments',
                          title: 'Does your partner have any money or investments which are held in accounts where they are the sole named account holder?'
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      title: "Joint capital",
                      htmlClass: "col-md-4",
                      items: [
                        {
                          key: 'joint.capital.hasSavingsInvestments',
                          title: 'Do you have any money or investments which are held in accounts where you and someone else are both named account holders?'
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'template',
                  templateUrl: 'modules/forms/scripts/services/forms/FCA/templates/capitalDescription.html'
                },
                {
                  type: 'section',
                  htmlClass: "row",
                  items: [
                    {
                      type: "fieldset",
                      htmlClass: "col-md-4",
                      items: [
                        {
                          condition: 'model.person.capital.hasSavingsInvestments',
                          key: 'person.capital.savingsInvestments',
                          add: "Add account",
                          title: 'Give details of your savings and investments',
                          validationMessage: 'Enter details of at least one account'
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                      htmlClass: "col-md-4",
                      items: [
                        {
                          condition: 'model.partner.capital.hasSavingsInvestments',
                          key: 'partner.capital.savingsInvestments',
                          add: "Add account",
                          title: "Give details of your partner's savings and investments",
                          validationMessage: 'Enter details of at least one account'
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      htmlClass: "col-md-4",
                      items: [
                        {
                          condition: 'model.joint.capital.hasSavingsInvestments',
                          key: 'joint.capital.savingsInvestments',
                          add: "Add account",
                          title: 'Give details of jointly owned savings and investments',
                          validationMessage: 'Enter details of at least one account'
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'section',
                  htmlClass: "row",
                  items: [
                    {
                      type: "fieldset",
                      title: 'Your shareholdings',
                      htmlClass: "col-md-4",
                      items: [
                        {
                          key: 'person.capital.hasShareholdings',
                          title: 'Are you the sole owner of any company shares?'
                        },
                        {
                          key: 'person.capital.shareholdings',
                          condition: 'model.person.capital.hasShareholdings',
                          add: "Add shareholding",
                          title: 'Give details of your shareholdings',
                          validationMessage: 'Enter details of at least one shareholding'
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                      title: "Your partner's shareholdings",
                      htmlClass: "col-md-4",
                      items: [
                        {
                          key: 'partner.capital.hasShareholdings',
                          title: 'Is your partner the sole owner of any company shares?'
                        },
                        {
                          key: 'partner.capital.shareholdings',
                          condition: 'model.partner.capital.hasShareholdings',
                          add: "Add shareholding",
                          title: "Give details of your partner's shareholdings",
                          validationMessage: 'Enter details of at least one shareholding'
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                      title: "Joint shareholdings",
                      htmlClass: "col-md-4",
                      items: [
                        {
                          key: 'joint.capital.hasShareholdings',
                          title: 'Do you and your partner jointly own any company shares?'
                        },
                        {
                          key: 'joint.capital.shareholdings',
                          condition: 'model.joint.capital.hasShareholdings',
                          add: "Add shareholding",
                          title: 'Give details of jointly owned shareholdings',
                          validationMessage: 'Enter details of at least one shareholding'
                        }
                      ]
                    }
                  ]
                },

                {
                  type: 'section',
                  htmlClass: "row",
                  items: [
                    {
                      type: "fieldset",
                      title: 'Additional Property',
                      htmlClass: "col-md-12",
                      items: [
                        'person.property.hasAdditionalProperties',
                        {
                          key: 'person.property.additionalProperties',
                          condition: 'model.person.property.hasAdditionalProperties',
                          add: "Add property",
                          title: 'We need to know details and value(s) of any property/properties, including land, owned and/or jointly owned by you other than your main home',
                          validationMessage: 'Enter details of at least one property'
                        }
                      ]
                    }
                  ]
                }
              ]
            },

            {
              type: 'section',
              condition: 'model.page === 5',
              page: 5,
              items: [
                {
                  type: 'section',
                  htmlClass: "row",
                  items: [
                    {
                      type: "fieldset",
                      title: 'Your payments and outgoings',
                      htmlClass: "col-md-6",
                      items: [
                        {
                          type: 'template',
                          htmlClass: 'divTitle',
                          templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_05_outgoings-intro.html'
                        },
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.expenses.compulsoryCourtPayments'}),
                        fieldDefinitionService.groups.paymentFormField({
                          key: 'person.expenses.councilTax',
                        }),
                        {
                          type: 'template',
                          htmlClass: 'divTitle',
                          templateUrl: 'modules/forms/scripts/services/forms/FCA/templates/annualCouncilTaxDescription.html'
                        },
                        fieldDefinitionService.groups.periodicPaymentFormFields({
                          key: 'person.expenses.rent',
                          condition: conditions.toString('person.expenses.rent')
                        }),
                        fieldDefinitionService.groups.periodicPaymentFormFields({
                          key: 'person.expenses.mortgage',
                          condition: conditions.toString('person.expenses.mortgage')
                        }),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.expenses.waterRates'}),
                        fieldDefinitionService.groups.periodicPaymentFormFields({
                          key: 'person.expenses.contentsInsurance',
                          description: 'If you have a combined buildings and contents insurance policy, enter the total amount you pay.'
                        }),
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.expenses.groundRent'})
                      ]
                    },
                    {
                      type: "fieldset",
                      condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                      title: "Your partner's payments and outgoings",
                      htmlClass: "col-md-6",
                      items: [
                        fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.expenses.compulsoryCourtPayments'})
                      ]
                    }
                  ]
                }
              ]
            },

            {
              type: 'fieldset',
              condition: 'model.page === 6',
              page: 6,
              items: [
                {
                  type: "fieldset",
                  title: 'Deprivation of Assets',
                  items: [
                    {
                      type: 'template',
                      htmlClass: 'divTitle',
                      templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_12_capital_transfers-gifting-intro.html'
                    },
                    'longTermResidents.deprivationOfAssets.hasDisposedOfAssets',
                    {
                      key: 'longTermResidents.deprivationOfAssets.disposedAssets',
                      title: false,
                      condition: 'model.longTermResidents.deprivationOfAssets.hasDisposedOfAssets',
                      validationMessage: 'Enter details of at least one asset'
                    },
                    {
                      type: "template",
                      templateUrl: "modules/forms/scripts/services/forms/FCA/templates/transferredAssets.html",
                      condition: 'model.longTermResidents.deprivationOfAssets.hasDisposedOfAssets'
                    },
                    {
                      title: "Authority to obtain information",
                      key: 'longTermResidents.deprivationOfAssets.advisor',
                      condition: 'model.longTermResidents.deprivationOfAssets.hasDisposedOfAssets'
                    }
                  ]
                }
              ]
            },

            {
              type: 'fieldset',
              condition: 'model.page === 7',
              page: 7,
              items: [
                {
                  type: 'template',
                  templateUrl: 'modules/forms/scripts/services/forms/FCA/templates/costOfCareIntro.html'
                },
                {
                  type: 'section',
                  htmlClass: "row",
                  items: [
                    {
                      type: "fieldset",
                      htmlClass: "col-md-12",
                      items: [
                        {
                          key: "careAssessment.costOfCareFilter",
                          type: "radios",
                          description: 'Select the types of care that you are interested in.'
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'section',
                  htmlClass: "row",
                  condition: 'model.careAssessment.costOfCareFilter && ((model.careAssessment.costOfCareFilter === "all") || (model.careAssessment.costOfCareFilter === "nonResidential"))',
                  items: [
                    {
                      type: "fieldset",
                      title: 'Non-residential',
                      htmlClass: "col-md-6",
                      items: [
                        {
                          key: 'careAssessment.nonResidential.maxContribution',
                          type: 'careAssessmentResult',
                          formTypeCode: 'FCA',
                          calculator: careAssessmentCalculator,
                          careType: constants.careTypes.nonResidential,
                          title: 'Maximum non-residential care contribution'
                        },
                        {
                          key: 'careAssessment.nonResidential.isActualCostKnown',
                          title: 'I know the actual cost of my non-residential care'
                        },
                        {
                          key: 'careAssessment.nonResidential.cost',
                          feedback: false,
                          condition: 'model.careAssessment.nonResidential.isActualCostKnown',
                          title: 'Actual weekly non-residential care cost'
                        },
                        {
                          // This field needs to be included in the form, or it will be removed when the model is cleaned.
                          // We don't show the field itself, a template is used to display it's value instead
                          key: 'careAssessment.nonResidential.actualContribution',
                          htmlClass: 'invisible',
                          title: 'Actual weekly non-residential care contribution'
                        },
                        {
                          type: "fieldset",
                          htmlClass: 'margin-top20 collapsible',
                          notitle: true,
                          collapse: true,
                          items: [
                            'careAssessment.nonResidential.workings'
                          ]
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      title: "Respite",
                      htmlClass: "col-md-6",
                      items: [
                        {
                          key: 'careAssessment.respite.maxContribution',
                          type: 'careAssessmentResult',
                          formTypeCode: 'FCA',
                          calculator: careAssessmentCalculator,
                          careType: constants.careTypes.respite,
                          title: 'Maximum respite care contribution'
                        },
                        {
                          key: 'careAssessment.respite.isActualCostKnown',
                          title: 'I know the actual cost of my respite care'
                        },
                        {
                          key: 'careAssessment.respite.cost',
                          feedback: false,
                          condition: 'model.careAssessment.respite.isActualCostKnown',
                          title: 'Actual weekly respite care cost'
                        },
                        {
                          // This field needs to be included in the form, or it will be removed when the model is cleaned.
                          // We don't show the field itself, a template is used to display it's value instead
                          key: 'careAssessment.respite.actualContribution',
                          htmlClass: 'invisible',
                          title: 'Actual weekly respite care contribution'
                        },
                        {
                          type: "fieldset",
                          htmlClass: 'margin-top20 collapsible',
                          notitle: true,
                          collapse: true,
                          items: [
                            'careAssessment.respite.workings'
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'section',
                  htmlClass: "row",
                  condition: 'model.careAssessment.costOfCareFilter && ((model.careAssessment.costOfCareFilter === "all") || (model.careAssessment.costOfCareFilter === "residential"))',
                  items: [
                    {
                      type: "fieldset",
                      title: "Residential, first 4 weeks",
                      htmlClass: "col-md-4",
                      items: [
                        {
                          key: 'careAssessment.week1Residential.maxContribution',
                          type: 'careAssessmentResult',
                          formTypeCode: 'FCA',
                          calculator: careAssessmentCalculator,
                          careType: constants.careTypes.week1Residential,
                          title: 'Maximum residential care contribution'
                        },
                        {
                          key: 'careAssessment.week1Residential.isActualCostKnown',
                          title: 'I know the actual cost of my residential care for the first 4 weeks'
                        },
                        {
                          key: 'careAssessment.week1Residential.cost',
                          feedback: false,
                          condition: 'model.careAssessment.week1Residential.isActualCostKnown',
                          title: 'Actual weekly residential care cost'
                        },
                        {
                          // This field needs to be included in the form, or it will be removed when the model is cleaned.
                          // We don't show the field itself, a template is used to display it's value instead
                          key: 'careAssessment.week1Residential.actualContribution',
                          htmlClass: 'invisible',
                          title: 'Actual weekly residential care contribution'
                        },
                        {
                          type: "fieldset",
                          htmlClass: 'margin-top20 collapsible',
                          notitle: true,
                          collapse: true,
                          items: [
                            'careAssessment.week1Residential.workings'
                          ]
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      title: "Residential, weeks 5-12",
                      htmlClass: "col-md-4",
                      items: [
                        {
                          key: 'careAssessment.week5Residential.maxContribution',
                          type: 'careAssessmentResult',
                          formTypeCode: 'FCA',
                          calculator: careAssessmentCalculator,
                          careType: constants.careTypes.week5Residential,
                          title: 'Maximum residential care contribution'
                        },
                        {
                          key: 'careAssessment.week5Residential.isActualCostKnown',
                          title: 'I know the actual cost of my residential care for weeks 5-12'
                        },
                        {
                          key: 'careAssessment.week5Residential.cost',
                          feedback: false,
                          condition: 'model.careAssessment.week5Residential.isActualCostKnown',
                          title: 'Actual weekly residential care cost'
                        },
                        {
                          // This field needs to be included in the form, or it will be removed when the model is cleaned.
                          // We don't show the field itself, a template is used to display it's value instead
                          key: 'careAssessment.week5Residential.actualContribution',
                          htmlClass: 'invisible',
                          title: 'Actual weekly residential care contribution'
                        },
                        {
                          type: "fieldset",
                          htmlClass: 'margin-top20 collapsible',
                          notitle: true,
                          collapse: true,
                          items: [
                            'careAssessment.week5Residential.workings'
                          ]
                        }
                      ]
                    },
                    {
                      type: "fieldset",
                      title: "Residential, after 12 weeks",
                      htmlClass: "col-md-4",
                      items: [
                        {
                          key: 'careAssessment.week13Residential.maxContribution',
                          type: 'careAssessmentResult',
                          formTypeCode: 'FCA',
                          calculator: careAssessmentCalculator,
                          careType: constants.careTypes.week13Residential,
                          title: 'Maximum residential care contribution'
                        },
                        {
                          key: 'careAssessment.week13Residential.isActualCostKnown',
                          title: 'I know the actual cost of my residential care after 12 weeks'
                        },
                        {
                          key: 'careAssessment.week13Residential.cost',
                          feedback: false,
                          condition: 'model.careAssessment.week13Residential.isActualCostKnown',
                          title: 'Actual weekly residential care cost'
                        },
                        {
                          // This field needs to be included in the form, or it will be removed when the model is cleaned.
                          // We don't show the field itself, a template is used to display it's value instead
                          key: 'careAssessment.week13Residential.actualContribution',
                          htmlClass: 'invisible',
                          title: 'Actual weekly residential care contribution'
                        },
                        {
                          type: "fieldset",
                          htmlClass: 'margin-top20 collapsible',
                          notitle: true,
                          collapse: true,
                          items: [
                            'careAssessment.week13Residential.workings'
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'template',
                  templateUrl: 'modules/forms/scripts/services/forms/FCA/templates/costOfCareHelp.html'
                },
                {
                  key: "careAssessment.careDecision",
                  title: "Care package preference",
                  type: "radios",
                  titleMap: {
                    continue: "I want to submit this full care financial assistance application to Kirklees Council and proceed with an assessment of my care needs.",
                    continueWithoutFinancialAssistance: "I have savings or capital above £23,250 in my sole name OR I do not wish to disclose my finances, but agree for Kirklees Council to arrange care services for me. I understand this means I have to pay the full cost of my care, and that this might incur an additional charge of £250 per year for the Council to arrange the care on my behalf.",
                    cancel: "I do not wish to continue with my care needs assessment at this time."
                  },
                  description: 'Based on the calculations above please select one of these options'
                }
              ]
            },

            {
              type: 'fieldset',
              condition: 'model.page === 8',
              page: 8,
              title: "Filling in the form on someone else's behalf",
              items: [
                {
                  type: 'template',
                  htmlClass: 'divTitle',
                  templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_07_advocate-intro.html'
                },
                'agent.isCompletedByAgent',
                {
                  key: 'agent.reason',
                  condition: 'model.agent.isCompletedByAgent'
                },
                {
                  key: 'agent.details',
                  condition: 'model.agent.isCompletedByAgent',
                  title: 'Details of the person who filled the form'
                },
                {
                  key: 'agent.applicantConfirmationDeclaration',
                  validationMessage: 'You must agree to this declaration',
                  condition: conditions.toString('agent.applicantConfirmationDeclaration')
                },
                {
                  key: 'agent.agentDeclaration',
                  validationMessage: 'You must agree to this declaration',
                  condition: conditions.toString('agent.agentDeclaration')
                },
                {
                  type: "fieldset",
                  title: "Declaration and Undertaking",
                  items: [
                    {
                      type: "template",
                      templateUrl: "modules/forms/scripts/services/forms/FCA/templates/declaration.html"
                    },
                    {
                      key: 'declaration.isAccepted',
                      validationMessage: 'You must indicate that you agree to and understand the above declaration'
                    }
                  ]
                }
              ]
            },

            {
              type: 'fieldset',
              condition: 'model.page === 9',
              page: 9,
              title: "Letter of Authority",
              items: [
                {
                  type: "template",
                  templateUrl: "modules/forms/scripts/services/forms/FCA/templates/letterOfAuthority.html"
                },
                'letterOfAuthority.isPersonNominated',
                {
                  type: 'fieldset',
                  title: 'Details of nominated person or agency',
                  condition: 'model.letterOfAuthority.isPersonNominated',
                  items: [
                    'letterOfAuthority.nominatedPersonDetails.name',
                    'letterOfAuthority.nominatedPersonDetails.phoneNumber',
                    'letterOfAuthority.nominatedPersonDetails.emailAddress',
                    'letterOfAuthority.nominatedPersonDetails.relationship'
                  ]
                },
                {
                  key: 'letterOfAuthority.permitDiscussion',
                  validationMessage: 'You must agree to this declaration',
                  condition: 'model.letterOfAuthority.isPersonNominated'
                },
                {
                  key: 'letterOfAuthority.permitCorrespondence',
                  condition: 'model.letterOfAuthority.isPersonNominated'
                },
                {
                  type: 'fieldset',
                  title: 'Address of nominated person or agency',
                  condition: 'model.letterOfAuthority.isPersonNominated && model.letterOfAuthority.permitCorrespondence',
                  items: [
                    'letterOfAuthority.nominatedPersonDetails.addressLine1',
                    'letterOfAuthority.nominatedPersonDetails.addressLine2',
                    'letterOfAuthority.nominatedPersonDetails.addressLine3',
                    'letterOfAuthority.nominatedPersonDetails.postcode'
                  ]
                }
              ]
            },

            {
              type: "section",
              condition: "areFormNavigationButtonsVisible()",
              items: [
                {
                  type: "actions",
                  htmlClass: "formPaginationButtons",
                  items: [
                    {
                      type: "button",
                      style: "btn-default",
                      title: "Previous",
                      onClick: "prev()"
                    },
                    {
                      type: "button",
                      style: "btn-primary",
                      title: "Next",
                      onClick: "next()"
                    }]
                }
              ]
            }
          ];
        };

        formUI.setForm(generateForm());

        // from the asf docs:
        // "Schema Form watches sf-form and sf-schema and will redraw the form if one or both changes, but only if they change completly, i.e. not the same object and/or form instance."
        // so to redraw the form, we have to change the instance
        formUI.getUpdatedForm = function() {
          return generateForm();
        };

        return formUI;
      }]);
