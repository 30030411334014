'use strict';

angular.module('kerp-forms.forms').factory('KS_CONDITIONS', ['FormConditions', function (FormConditions) {

  return FormConditions.clone({
    conditions: {
      textInputFieldSet: function () {
        return !this.lib.getModelVisible('textInputHide');
      },

      'string.name': ['textInputFieldSet'],
      'string.nationalInsuranceNumber': ['textInputFieldSet'],
      'string.email': ['textInputFieldSet'],
      numberInputFieldSet: function () {
        return !this.lib.getModelVisible('numberSelectionDateInputHide');
      },
      'number.minAndMaxInteger': ['numberInputFieldSet'],
      'number.integer': ['numberInputFieldSet'],
      'number.positiveInteger': ['numberInputFieldSet'],
      'number.currency': ['numberInputFieldSet'],
      'number.decimal': ['numberInputFieldSet'],
      booleanFieldSet: ['numberInputFieldSet'],
      'boolean.radios': ['numberInputFieldSet'],
      "boolean.mustChooseYes": ['numberInputFieldSet'],
      'singleSelectionFieldSet': ['numberInputFieldSet'],
      "string.genderRadios": ['numberInputFieldSet'],
      "string.genderSelect": ['numberInputFieldSet'],
      multiSelectionFieldset: ['numberInputFieldSet'],
      multiSelect: ['numberInputFieldSet'],
      dateTimeFieldSet: ['numberInputFieldSet'],
      'string.pastDate': ['numberInputFieldSet'],
      'string.timePicker': ['numberInputFieldSet'],
      'string.memorableDate': ['numberInputFieldSet']
    }
  });
}]);
