'use strict';

angular.module('kerp-forms.forms')
  .service('LI_CA_MAPPINGS', ['FormFieldMappings',
    function (FormFieldMappings) {

      this.mappings = function () {
        return FormFieldMappings
          .buildWithMappings({
            'person.intro.havePartner': 'careAssessment.hasPartner',
            'person.intro.dob': 'dateOfBirth',
            'person.intro.declarePartnerFinances': 'careAssessment.declarePartnerFinances',

            'person.income.retirementPension': 'careAssessment.retirementPension.amount',
            'person.income.retirementPensionPeriod': 'careAssessment.retirementPension.frequency',

            'person.income.incapacityBenefit': 'careAssessment.incapacityBenefit.amount',
            'person.income.incapacityBenefitPeriod': 'careAssessment.incapacityBenefit.frequency',

            'person.income.warPension': 'careAssessment.warPension.amount',
            'person.income.warPensionPeriod': 'careAssessment.warPension.frequency',

            'person.income.warSpecialPayment': 'careAssessment.warSpecialPayment.amount',
            'person.income.warSpecialPaymentPeriod': 'careAssessment.warSpecialPayment.frequency',

            'person.income.childBenefit': 'careAssessment.childBenefit.amount',
            'person.income.childBenefitPeriod': 'careAssessment.childBenefit.frequency',

            'person.income.carersAllowance': 'careAssessment.carersAllowance.amount',
            'person.income.carersAllowancePeriod': 'careAssessment.carersAllowance.frequency',

            'person.income.pensionGuaranteeCredit': 'careAssessment.pensionGuaranteeCredit.amount',
            'person.income.pensionGuaranteeCreditPeriod': 'careAssessment.pensionGuaranteeCredit.frequency',

            'person.income.pensionSavingsCredit': 'careAssessment.pensionSavingsCredit.amount',
            'person.income.pensionSavingsCreditPeriod': 'careAssessment.pensionSavingsCredit.frequency',

            'person.income.attendanceAllowance': 'careAssessment.attendanceAllowance',
            'person.income.mobilityAllowance': 'careAssessment.mobilityAllowance',

            'person.expenses.councilTax': 'careAssessment.councilTax',

            'person.expenses.compulsoryPayments': 'careAssessment.compulsoryPayments.amount',
            'person.expenses.compulsoryPaymentsPeriod': 'careAssessment.compulsoryPayments.frequency',

            'person.expenses.rent': 'careAssessment.rent.amount',
            'person.expenses.rentPeriod': 'careAssessment.rent.frequency',

            'person.expenses.mortgage': 'careAssessment.mortgage.amount',
            'person.expenses.mortgagePeriod': 'careAssessment.mortgage.frequency',

            'person.expenses.waterRates': 'careAssessment.waterRates.amount',
            'person.expenses.waterRatesPeriod': 'careAssessment.waterRates.frequency',

            'person.expenses.homeInsurance': 'careAssessment.homeContentsInsurance.amount',
            'person.expenses.homeInsurancePeriod': 'careAssessment.homeContentsInsurance.frequency',

            'person.expenses.groundRent': 'careAssessment.groundRent.amount',
            'person.expenses.groundRentPeriod': 'careAssessment.groundRent.frequency'
          })
          .factToFieldConverters({
            'person.intro.dob': function (facts) {
              return this.ISOStringToDDMMYYYY(facts.dateOfBirth);
            }
          })
          .fieldToFactConverters({
            dateOfBirth: function (model) {
              var careAssessmentDob = model.person.intro.dob;
              return this.DDMMYYYYToISOString(careAssessmentDob);
            }
          });
      };
    }]);
