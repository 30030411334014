'use strict';
(function () {
    var mod = angular.module('kerp-forms.forms');
    /**
     * This directive is for the purpose of achieving validation involving an equation of four fields' values. What it
     * does is it provides a wrapper on top of the angular schema form model, giving the user a view in which the the
     * field values always stay even if they are destroyed in the angular schema form model when they are invalid as
     * well as providing the possibility of doing the equation which requires values from fields which are already invalid
     * as this directive would preserve the invalidated and destroyed values on top of the angular schema form model.
     */
    mod.directive('sumOfAdults', [function () {
      return {
        restrict: 'E',
        require: 'ngModel',
        scope: {
          model: "="
        },
        templateUrl: 'modules/forms/scripts/directives/sumOfAdults/sumOfAdultsDirective.html',
        link: function (scope, element, attrs, ngModelController) {

          scope.other_adultsno = scope.model.other_adultsno;
          scope.jointtenants = scope.model.jointtenants;
          scope.boarders = scope.model.boarders;
          scope.nondependants = scope.model.nondependants;

          scope.isTotalValid = true;

          function toInt(str) {
            var res = parseInt(str);
            return isNaN(res) ? 0 : res;
          }

          //Synchronizes the view values from this directive onto form model
          function synchronizeToModel() {
            scope.model.other_adultsno = toInt(scope.other_adultsno);
            scope.model.jointtenants = toInt(scope.jointtenants);
            scope.model.boarders = toInt(scope.boarders);
            scope.model.nondependants = toInt(scope.nondependants);
          }

          //Destroys model values
          function destroyModel() {
            delete scope.model.other_adultsno;
            delete scope.model.jointtenants;
            delete scope.model.boarders;
            delete scope.model.nondependants;
          }

          // Checks if the equation for validating the four fields at once is valid
          function validateSumOfAdults() {
            return toInt(scope.other_adultsno) ===
              toInt(scope.jointtenants) +
              toInt(scope.boarders) +
              toInt(scope.nondependants);
          }

          function validate() {

            var isValid = validateSumOfFields() &&
              isInRange("other_adultsno", 1, 12) &&
              isInRange("jointtenants", 0, 4) &&
              isInRange("boarders", 0, 4) &&
              isInRange("nondependants", 0, 4);

            ngModelController.$setValidity("required", isValid);
          }

          scope.$on('schemaFormValidate', validate);

          /**
           * Validates if a field is in the correct range.
           *
           * @param fieldName
           * @param min Minimum value of the field
           * @param max Maximum value of field
           */
          function isInRange(fieldName, min, max) {

            var fieldValue = scope[fieldName];
            var isValid = angular.isDefined(fieldValue) && fieldValue >= min && fieldValue <= max;
            scope[fieldName + "_invalid"] = !isValid;

            if (!isValid) {
              delete scope.model[fieldName];
            }

            return isValid;
          }

          scope.$watch("other_adultsno", function (newValue, oldValue) {
            if (oldValue !== newValue) {
              isInRange("other_adultsno", 1, 12);
              validateSumOfFields();
            }
          });

          scope.$watch("jointtenants", function (newValue, oldValue) {
            if (oldValue !== newValue) {
              isInRange("jointtenants", 0, 4);
              validateSumOfFields();
            }
          });

          scope.$watch("boarders", function (newValue, oldValue) {
            if (oldValue !== newValue) {
              isInRange("boarders", 0, 4);
              validateSumOfFields();
            }
          });

          scope.$watch("nondependants", function (newValue, oldValue) {
            if (oldValue !== newValue) {
              isInRange("nondependants", 0, 4);
              validateSumOfFields();
            }
          });

          /**
           * Validates if the sum of fields equation on the four fields
           */
          function validateSumOfFields() {
            scope.isTotalValid = validateSumOfAdults();

            if (scope.isTotalValid) {
              synchronizeToModel();
            } else {
              destroyModel();
            }

            return scope.isTotalValid;
          }
        }
      };
    }]);
  }()
);
