'use strict';
(function () {
    var mod = angular.module('kerp-forms.forms');
    /**
     * This directive helps to include a partial view without creating a new scope
     */
    mod.directive('staticInclude', function () {
      return {
        restrict: 'AE',
        templateUrl: function (ele, attrs) {
          return attrs.templatePath;
        }
      };
    });
  }()
);
