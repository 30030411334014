'use strict';

angular
  .module('kerp-forms.forms')
  .factory('SSCS1_SCHEMA', ['fieldDefinitionService', '$translate', function (fieldDefinitionService, $translate) {

    var requiredValidationMessage = $translate.instant('forms.validation.required');

    var schema = {
      type: 'object',
      properties: {
        'Check_Box1': {
          type: 'string',
          enum: [
            'no',
            'Yes'
          ],
          validationMessage: requiredValidationMessage
        },
        'Check_Box2': {
          type: 'string',
          enum: [
            'no',
            'Yes'
          ],
          validationMessage: requiredValidationMessage
        },
        'Text15': {
          type: 'string',
          validationMessage: 'You have to enter the name of the benefit your appeal relates to in the box above'
        },
        'Check_Box3': {
          type: 'string',
          enum: [
            'mr',
            'mrs',
            'miss',
            'ms'
          ],
          validationMessage: requiredValidationMessage
        },
        'Text4': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text5': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text6': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text7': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text8': {
          type: 'string',
          validationMessage: 'Enter first 2-4 postcode characters (letters and numbers before space)',
          pattern: '^[a-zA-Z0-9]{2,4}$'
        },
        'Text1': {
          type: 'string',
          validationMessage: 'Enter last 3 postcode characters (letters and numbers after space)',
          pattern: '^[0-9][a-zA-Z]{2}$'
        },
        'Text13': fieldDefinitionService.phone.fullNumber(),
        'Text14': fieldDefinitionService.phone.fullNumber(),
        'Text9': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text10': fieldDefinitionService.NINO.firstTwoLetters(),
        'Text11': {
          type: 'string',
          pattern: '^[0-9]{6}$',
          validationMessage: '6 digits are expected'
        },
        'Text12': fieldDefinitionService.NINO.lastLetter(),
        'Check_Box15': {
          type: 'string',
          enum: [
            'no',
            'Yes'
          ],
          validationMessage: requiredValidationMessage
        },
        'Check_Box16': {
          type: 'string',
          enum: [
            'mr',
            'mrs',
            'miss',
            'ms'
          ],
          validationMessage: requiredValidationMessage
        },
        'Text17': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text18': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text19': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text20': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text21': {
          type: 'string',
          validationMessage: 'Enter first 2-4 postcode characters (letters and numbers before space)',
          pattern: '^[a-zA-Z0-9]{2,4}$'
        },
        'Text2': {
          type: 'string',
          validationMessage: 'Enter last 3 postcode characters (letters and numbers after space)',
          pattern: '^[0-9][a-zA-Z]{2}$'
        },
        'Text22': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text23': fieldDefinitionService.NINO.firstTwoLetters(),
        'Text24': {
          type: 'string',
          pattern: '^[0-9]{6}$',
          validationMessage: '6 digits are expected'
        },
        'Text25': fieldDefinitionService.NINO.lastLetter(),
        'Check_Box26': {
          type: 'string',
          enum: [
            'no',
            'Yes'
          ],
          validationMessage: requiredValidationMessage
        },
        'Text27': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text28': fieldDefinitionService.phone.fullNumber(),
        'Text29': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text30': {
          type: 'string',
          validationMessage: 'Enter first 2-4 postcode characters (letters and numbers before space)',
          pattern: '^[a-zA-Z0-9]{2,4}$'
        },
        'Text3': {
          type: 'string',
          validationMessage: 'Enter last 3 postcode characters (letters and numbers after space)',
          pattern: '^[0-9][a-zA-Z]{2}$'
        },
        'Check_Box31': {
          type: 'string',
          enum: [
            'mr',
            'mrs',
            'miss',
            'ms'
          ],
          validationMessage: requiredValidationMessage
        },
        'Text32': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text33': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text34': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text35': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Check_Box36': {
          type: 'string',
          enum: [
            'no',
            'Yes'
          ],
          validationMessage: requiredValidationMessage
        },
        'Text37': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Check_Box38': {
          type: 'string',
          enum: [
            'no',
            'Yes'
          ],
          validationMessage: requiredValidationMessage
        },
        'Check_Box39': {
          type: 'string',
          enum: [
            'no',
            'Yes'
          ],
          validationMessage: requiredValidationMessage
        },
        'Text40': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Check_Box41': {
          type: 'string',
          enum: [
            'no',
            'Yes'
          ],
          validationMessage: requiredValidationMessage
        },
        'Text42': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Check_Box43': {
          type: 'string',
          enum: [
            'no',
            'Yes'
          ],
          validationMessage: requiredValidationMessage
        },
        'Text44': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Text45': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        'Check_Box46': {
          type: 'string',
          enum: [
            'no',
            'Yes'
          ],
          validationMessage: requiredValidationMessage
        }
      },
      required: [
        'Check_Box1',
        'Check_Box2',
        'Check_Box15',
        'Check_Box26',
        'Check_Box31',
        'Check_Box36',
        'Check_Box38',
        'Check_Box39',
        'Check_Box41',
        'Check_Box43',
        'Check_Box46',
        'Text1',
        'Text2',
        'Text3',
        'Text5',
        'Text6',
        'Text7',
        'Text8',
        'Text9',
        'Text10',
        'Text11',
        'Text12',
        'Text13',
        'Text14',
        'Text15',
        'Text18',
        'Text19',
        'Text20',
        'Text21',
        'Text22',
        'Text23',
        'Text24',
        'Text25',
        'Text27',
        'Text28',
        'Text29',
        'Text30',
        'Text31',
        'Text33',
        'Text34',
        'Text35',
        'Text36',
        'Text37',
        'Text40',
        'Text42',
        'Text44',
        'Text45'

      ]
    };

    return {
      getSchema: function () {
        return schema;
      }
    };
  }]);
