'use strict';

angular
  .module('kerp-forms.forms')
  .factory('formModelService', ['UserProfileService', '$rootScope', '$injector', '$q', '$localStorage', 'Forms', 'API',
    'authSessionService', 'schemaFormHelperService', 'sfSelect', 'objectUtils', 'FormModel', '$window',
    function (UserProfileService, $rootScope, $injector, $q, $localStorage, Forms, API,
              authSessionService, schemaFormHelperService, sfSelect, objectUtils, FormModel, $window) {

      /**
       * Form model should be defined with following separator
       * eg: 1_1: { ... }, 1_2: { ... }, etc.
       * This gets overwritten later based on form config `server_key_separator`
       * @type {string}
       */
      var client_key_separator = '_';

      /**
       * @param formModelInstance {FormModel}
       * @private
       */
      function setLastValidPageNumber(formModelInstance) {
        formModelInstance = formModelInstance || {};
        if (formModelInstance.dirtyFields && angular.isDefined(formModelInstance.lastValidPage)) {
          formModelInstance.lastValidPage = Math.max(formModelInstance.lastValidPage, formModelInstance.dirtyFields.page);
        } else {
          formModelInstance.lastValidPage = 0;
        }
      }

      /**
       * @param formModelInstance {FormModel}
       * @returns {boolean}
       */
      function allPagesValid(formModelInstance) {
        return !!formModelInstance.allPagesValidated;
      }

      /**
       * @param formModelInstance {FormModel}
       */
      function setAllPagesInvalid(formModelInstance) {
        formModelInstance.allPagesValidated = false;
      }

      /**
       * Get list of available forms
       * @returns {Promise<Array<FormModel>>}
       * @param queryParams
       */
      function listForms(queryParams) {

        queryParams = queryParams || {};

        return API.get('/form', {params: queryParams})
          .then(function (response) {
            return (response.data.data || []).map(function (responseData) {
              var formModelInstance = FormModel.fromApiResponse(responseData);
              formModelInstance.token = authSessionService.getTokenHeaderValue();
              return formModelInstance;
            });
          });
      }

      /**
       * Get for meta data from DB, optionally pass access token
       * @param id {string} form id in database
       * @param [optionalApiToken] {string}
       * @returns {Promise<FormModel>}
       */
      function getForm(id, optionalApiToken) {

        var apiHttpConfig = {}, apiTokenUsed;

        if (optionalApiToken) {
          apiHttpConfig = {
            customToken: optionalApiToken
          };
        }

        return API.get('/form/' + id, apiHttpConfig)
          .then(function (response) {
            apiTokenUsed = response.config.headers.Authorization;
            return FormModel.fromApiResponse(response.data.data);
          })
          .then(function (formModelInstance) {
            if (formModelInstance.token) {
              throw new Error('token property is not expected to be set by server');
            }
            formModelInstance.token = apiTokenUsed; // token might change if optional one expired
            return formModelInstance;
          });
      }

      /**
       * @param formModelInstance {FormModel} stored in sql form details/meta info
       * @returns {Promise<FormModel>}
       */
      function createForm(formModelInstance) {

        if (!formModelInstance) {
          throw new Error('database representation of form is missing');
        }

        return API.post('/form', formModelInstance.toApiJson())
          .then(function (response) {
            if (response && response.data && response.data.data) {
              return FormModel.fromApiResponse(response.data.data);
            }
            return $q.reject();
          });
      }

      /**
       * Update user facts in database
       * @param formModelInstanceId {String} form id in database
       * @param newFacts {Object} new form facts
       * @param customToken
       * @returns {Promise<HttpResponse>}
       */
      function updateFactsByFormId(formModelInstanceId, newFacts, customToken) {
        return API.post('/form/' + formModelInstanceId + '/updateUserFacts', newFacts, {customToken: customToken});
      }

      /**
       * Update user facts in database
       * @param formModelInstance {object} stored in sql form details/meta info
       * @returns {Promise}
       */
      function updateFacts(formModelInstance) {
        var formDescription = Forms.getForm(formModelInstance.formTypeCode);
        if (formModelInstance.isSubmitted() && allPagesValid(formModelInstance)) {
          return getUpdatedFieldValues(formModelInstance.dirtyFields, formDescription)
            .then(function (newFacts) {
              if (newFacts && angular.isObject(newFacts)) {
                return API.post('/facts', newFacts);
              }
              return $q.resolve();
            });
        }
        return $q.resolve();
      }

      /**
       * Update form in DB
       * @param formModelInstance {FormModel} stored in sql form details/meta info
       * @param {string} [optionalApiToken]
       * @returns {Promise<FormModel>}
       */
      function updateForm(formModelInstance, optionalApiToken) {

        if (!formModelInstance) {
          throw new Error('database representation of form is missing');
        }
        var apiHttpConfig = {suppressFlashErrors: true};

        if (optionalApiToken) {
          apiHttpConfig.customToken = optionalApiToken;
          // apiHttpConfig.ignoreAuthModule = true;
        }

        // if the current page was abandoned causing an automatic save to occur, don't increment the last valid
        // page number, because validation hasn't been performed
        if (!formModelInstance.isAbandoned) {
          setLastValidPageNumber(formModelInstance);
        }

        var formConfig = Forms.getForm(formModelInstance.formTypeCode);

        return API.put('/form/' + formModelInstance.id, formModelInstance.toApiJson(), apiHttpConfig)
          .then(function (response) {
            return FormModel.fromApiResponse(response.data.data);
          })
          .then(function (formModelInstance) {
            if (formModelInstance.token) {
              throw new Error('token property is not expected to be set by server');
            }
            formModelInstance.token = optionalApiToken || authSessionService.getTokenHeaderValue();
            return formModelInstance;
          })
          .catch(function (error) {
            // KERP-2096 if the form has already been submitted reload the current page. This will cause the state
            // to transition to "review application".
            if (error.data && error.data.errorCode === 'illegalFormUpdate' && formConfig.is_submit_once) {
              $window.location.reload();

            } else {
              return $q.reject(error);
            }
          });
      }

      /**
       * Submit a single-submit application
       * @param formId {String}
       * @param postParams {Object}
       * @param customToken
       * @returns {Promise<HttpResponse>}
       */
      function submitForm(formId, postParams, customToken) {

        if (!formId) {
          throw new Error('form id is missing');
        }
        var apiHttpConfig = {suppressFlashErrors: true, customToken: customToken};
        return API.post('/form/' + formId + '/remoteSubmission', postParams, apiHttpConfig);
      }

      /**
       * Unsubmit a single-submit application
       * @param formId {String}
       * @returns {Promise<HttpResponse>}
       */
      function unsubmitForm(formId) {

        if (!formId) {
          throw new Error('form id is missing');
        }
        return API.delete('/form/' + formId + '/remoteSubmission');
      }

      /**
       * Update form in DB, also send updated form facts in case form is
       * complete and submitted
       * @param formModelInstance {FormModel} stored in sql form details/meta info
       * @param prevFormFieldsHash the hash of the form fields the last time the model was updated/retrieved
       * @returns {Promise<FormModel>}
       */
      function updateFormAndFacts(formModelInstance, prevFormFieldsHash) {

        if (!formModelInstance) {
          throw new Error('database representation of form is missing');
        }

        var formConfig = Forms.getForm(formModelInstance.formTypeCode);
        var canUpdate = !formConfig.is_submit_once || !formModelInstance.isSubmitted();
        if (!canUpdate) {
          return $q.reject('Form is already submitted and cannot be updated.');
        }

        var submitStatusToggled = !formConfig.is_submit_once && formModelInstance.submitStatusToggled;
        var fieldsDidNotChange = prevFormFieldsHash === formFieldsHashCode(formModelInstance.dirtyFields);

        if (submitStatusToggled) {
          return API.put('/form/' + formModelInstance.id + '/toggleSubmit', {submittedFields: formModelInstance.submittedFields})
            .then(function (response) {
              return FormModel.fromApiResponse(response.data.data);
            });

          // KERP-1859 If the fields haven't been changed, just update the metadata. This prevents silently overwriting
          // changes from another user/device/window
        } else if (fieldsDidNotChange) {

          var updateMetaDataParams = {
            page: formModelInstance.dirtyFields.page,
            allPagesValidated: formModelInstance.allPagesValidated
          };

          return API.put('/form/' + formModelInstance.id + '/updateMetadata', updateMetaDataParams)
            .then(function (response) {
              return FormModel.fromApiResponse(response.data.data);
            });
        } else {
          var updated;
          return updateForm(formModelInstance)
            .then(function (updatedFormInstance) {
              updated = updatedFormInstance;
              return updateFacts(formModelInstance);
            })
            .then(function () {
              return updated;
            });
        }
      }

      /**
       * Remove form from sql and localstorage
       * @param formModelInstance {object} stored in sql form details/meta info
       * @returns {Promise}
       */
      function deleteForm(formModelInstance) {
        return API['delete']('/form/' + formModelInstance.id)
          .then(function () {
            return $q(function (resolve) {
              if ($localStorage.offlineforms) {
                delete $localStorage.offlineforms[formModelInstance.id];
              }
              resolve();
            });
          });
      }

      /**
       * Clone form model and remove meta keys and remove values that do not pass custom condition
       * @param formType
       * @param formFields
       * @returns {object}
       */
      function getSubmittableFormModelFields(formType, formFields) {

        formFields = formFields || {};
        var cleanedModel = {};

        var schemaFormForm = $injector.get(formType.formService).get();
        var schemaFormSchema = $injector.get(formType.formSchema).getSchema();
        var formConditionsService;
        if (formType.formConditions) {
          formConditionsService = $injector.get(formType.formConditions);
          if (angular.isFunction(formConditionsService.setModelGetter) && !formConditionsService.getModel()) {
            formConditionsService.setModelGetter(function () {
              return formFields;
            });
          }
        }

        var aggregateFormDefinition = schemaFormHelperService.mergeFormAndSchema(schemaFormForm, schemaFormSchema);

        schemaFormHelperService.forEachFieldInForm(aggregateFormDefinition, formFields, {
          conditionLibrary: formConditionsService,
          fieldHandler: function (field) {

            // get the value of the field and add it to the "cleaned" model. field.key could be either a string or an
            // array thereof representing a property path
            var fieldValue = sfSelect(field.key, formFields);
            sfSelect(field.key, cleanedModel, fieldValue);
          }
        });

        return cleanedModel;
      }

      /**
       * @param formFields {object}
       * @param formConfig {object} form details/description
       * @returns {object}
       */
      function toPdfModel(formFields, formConfig) {

        var model = getSubmittableFormModelFields(formConfig, formFields);

        var formService = $injector.get(formConfig.formService);
        if (angular.isDefined(formService.transformPdfModel)) {
          formService.transformPdfModel(model);
        }

        var pdfModel = {}, server_key_separator = formConfig.server_key_separator;

        /*Create keys compatible with the server side*/
        angular.forEach(model, function (value, key) {

          if (angular.isArray(value)) {
            //it's an array, we are dealing with checkboxes, arrays must all contain only one element
            if (value.length > 1) {
              throw "field '" + key + "' has a value '" + value + "' that contains more than one element";

            } else if (value.length === 1) {
              value = value[0];
            }
          }

          var newKey = key.replace(client_key_separator, server_key_separator);

          pdfModel[newKey] = value;

        });
        return pdfModel;
      }

      /**
       * For a given form set default values
       * which are called form facts.
       * @param formFields {object}
       * @param formType {object}
       * @returns {Promise}
       */
      function setDefaultFieldValues(formFields, formType) {

        var formFieldMappings;

        if (formType.formMappings) {
          formFieldMappings = $injector.get(formType.formMappings).mappings();
        }

        return $q(
          function (resolve, reject) {
            if (!angular.isDefined(formFieldMappings)) {
              return reject();
            }
            resolve(formFieldMappings);
          })
          .then(function () {
            return UserProfileService.getUserFacts();
          })
          .then(function (data) {
            formFieldMappings.setFacts(data);
            var facts = formFieldMappings.getFieldFacts();
            angular.forEach(facts, function (fieldValue, fieldKey) {
              objectUtils.setPropertyPath(formFields, fieldKey, fieldValue, false);
            });

            return formFields;
          })
          .catch(function (e) {
            console.warn('Default fields are not going to be set', e);
          })
          .then(function () {
            return formFields;
          });
      }

      function getFormFacts(dirtyFields, formTypeCode) {
        var formConfig = Forms.getForm(formTypeCode);
        var cleanModel = getSubmittableFormModelFields(formConfig, dirtyFields);
        return getUpdatedFieldValues(cleanModel, formConfig);
      }

      /**
       * For a given form get entered values
       * which are called form facts.
       * @param formFields {object}
       * @param formType {object}
       * @returns {Promise}
       */
      function getUpdatedFieldValues(formFields, formType) {

        var formFieldMappings;

        if (formType.formMappings) {
          formFieldMappings = $injector.get(formType.formMappings).mappings();
        }

        if (!angular.isDefined(formFieldMappings)) {
          return $q.resolve();
        }

        formFieldMappings.setValues(formFields);
        var newFacts = formFieldMappings.getValueFacts();
        return $q.resolve(newFacts);
      }

      /**
       * Sync scope values to local storage
       * @param scope {$rootScope.Scope}
       * @param scopeFormFieldsVariable
       * @param formModelInstance {FormModel}
       * @return {*}
       */
      function syncScopeFormFieldsToLocalStorage(scope, scopeFormFieldsVariable, formModelInstance) {

        if (!$localStorage.offlineforms) {
          $localStorage.offlineforms = {};
        }

        if (!$localStorage.offlineforms[formModelInstance.id]) {
          $localStorage.offlineforms[formModelInstance.id] = {
            form: formModelInstance,
            token: formModelInstance.token
          };
        }

        return $q(function (resolve, reject) {

          scope.$watch(function () {
            return angular.toJson(scope[scopeFormFieldsVariable]);
          }, function (jsonString, oldJsonString) {
            if (jsonString && oldJsonString !== jsonString) {
              $localStorage.offlineforms[formModelInstance.id].form.dirtyFields = JSON.parse(jsonString);
            }
          });

          resolve(scope[scopeFormFieldsVariable]);
        });
      }

      /**
       * Calculates hash of all form fields, ignoring
       * the page number
       * @param {Object} fieldsModel - form fields, eg: {firstname: 'Bob', lastname: 'Dude', page: 3, ... }
       * @return {String}
       */
      function formFieldsHashCode(fieldsModel) {
        // by using angular.toJson, get rid of `$$hashKey` keys in object
        // https://stackoverflow.com/questions/18826320/what-is-the-hashkey-added-to-my-json-stringify-result
        var modelClone = JSON.parse(angular.toJson(fieldsModel));
        delete modelClone.page;
        return objectHash(modelClone);
      }

      /**
       * Add an item to an array without side-effects
       * @param array
       * @param item
       */
      function appendElement(array, item) {
        var arrayClone = array.slice(0);
        arrayClone.push(item);
        return arrayClone;
      }

      /**
       * sfSelect can only set a simple property (string, number, etc.) of the ASF model. This recursive function
       * can be used to set more complex objects
       * @param keyParts the path in the model where the object should be set
       * @param object the source object
       * @param model the target object
       */
      function setModelProperties(keyParts, object, model) {

        angular.forEach(object, function (value, name) {

          if (angular.isObject(value)) {
            if (Object.keys(value).length) {
              var objectKey = appendElement(keyParts, name);
              setModelProperties(objectKey, value);
            }
          } else {
            var propertyKey = appendElement(keyParts, name);
            sfSelect(propertyKey, model, value);
          }
        });
      }

      return {
        setModelProperties: setModelProperties,
        listForms: listForms,
        allPagesValid: allPagesValid,
        setAllPagesInvalid: setAllPagesInvalid,
        getForm: getForm,
        createForm: createForm,
        updateForm: updateForm,
        submitForm: submitForm,
        unsubmitForm: unsubmitForm,
        updateFactsByFormId: updateFactsByFormId,
        updateFacts: updateFacts,
        updateFormAndFacts: updateFormAndFacts,
        deleteForm: deleteForm,
        getSubmittableFormModelFields: getSubmittableFormModelFields,
        toPdfModel: toPdfModel,
        setDefaultFieldValues: setDefaultFieldValues,
        getUpdatedFieldValues: getUpdatedFieldValues,
        syncScopeFormFieldsToLocalStorage: syncScopeFormFieldsToLocalStorage,
        formFieldsHashCode: formFieldsHashCode,
        getFormFacts: getFormFacts
      };
    }])
;
