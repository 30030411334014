module.exports = {
  customer: 'demo-care',
  environment: 'production',
  isBetterCare: true,
  googleAnalyticsId: 'G-R53X6W2JX2',
  chatWidget: {
    writeScript: 'userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/04d4f2c30c882c0672511412419b1965f5937b9b141c614422b82e41e2a46b49.js'
  },
  requirePostcode: false,

  app: {
    home: 'https://demo.bettercare.org.uk'
  },
  serverBaseUrl: 'https://api-demo.bettercare.org.uk',
  api: {
    baseUrl: 'https://api-demo.bettercare.org.uk/internal/v1'
  },
  authGateway: 'https://account.demo.bettercare.org.uk',
  loginServices: [
    {name: 'kerp'},
    {name: 'phone'},
    {name: 'google', postLogoutUrl: 'https://accounts.google.com/Logout'},
    {name: 'facebook'},
    {name: 'agent'},
    {name: 'referenceNumber'}
  ],
  ui: {
    showJobSearchJournal: false,
    showOfflineForms: true,
    showTestingTools: true,
    showAssessmentMutators: false,
    showDraftForms: true,
    restrictions: {
      applications: false
    }
  },

  airbrake: {
    enabled: true,
    projectId: 131082,
    projectKey: 'facc9a060872b357453b677f15d319e3'
  }
};
