'use strict';

/**
 * The intent of this service is to encapsulate the details of how offline forms are stored.
 * @param $localStorage
 * @param OfflineFormModel
 * @constructor
 */
function OfflineFormService($localStorage, OfflineFormModel) {

  if (!$localStorage.offlineforms) {
    $localStorage.offlineforms = {};
  }

  this.getForm = function (formId) {
    if (formId) {
      return $localStorage.offlineforms[formId.toString()];
    }
  };

  this.deleteForm = function (formId) {
    if (formId) {
      delete $localStorage.offlineforms[formId.toString()];
    }
  };

  this.saveForm = function (offlineForm) {
    $localStorage.offlineforms[offlineForm.getId()] = offlineForm;
  };

  this.updateForm = function (formId, formUpdater) {
    var form = this.getForm(formId);

    if (form) {
      formUpdater(form);
      $localStorage.offlineforms[formId] = form;
    }
  };

  this.countForms = function () {
    return Object.keys($localStorage.offlineforms).length;
  };

  this.deleteFormsWithoutChanges = function () {
    var self = this;
    this.getFormsIds(false).forEach(function (formId) {
      self.deleteForm(formId);
    });
  };

  this.getFormsIds = function (hasLocalChanges) {
    var self = this;
    return Object.keys($localStorage.offlineforms).filter(function (formId) {
      var offlineFormJson = self.getForm(formId);
      var offlineForm = OfflineFormModel.fromObject(offlineFormJson);
      return offlineForm.hasChanges() === hasLocalChanges;
    });
  };

}

OfflineFormService.$inject = ['$localStorage', 'OfflineFormModel'];

angular.module('kerp-forms.forms').service('offlineFormService', OfflineFormService);
