'use strict';

angular.module('kerp-forms.forms')
  .service('SSCS1_MAPPINGS', ['FormFieldMappings', function (FormFieldMappings) {
    this.mappings = function () {
      return FormFieldMappings.buildWithMappings({
        Check_Box3: 'title',
        Text5: 'firstName',
        Text6: 'lastName',
        Text7: 'address.formatted',
        Text14: 'phoneNumber',
        Text13: 'alternativePhoneNumber',
        Text9: 'dateOfBirth',
        Text10: 'nationalInsuranceNumber',
        Text11: 'nationalInsuranceNumber',
        Text12: 'nationalInsuranceNumber'

      }).factToFieldConverters({
        Check_Box3: function (facts) {
          return facts.title.toLowerCase();
        },
        Text9: function (facts) {
          return this.ISOStringToDDMMYYYY(facts.dateOfBirth);
        },
        Text10: function (facts) {
          return this.getNationaInsuranceNumberPartsFromFacts()[0];
        },
        Text11: function (facts) {
          return this.getNationaInsuranceNumberPartsFromFacts()[1];
        },
        Text12: function (facts) {
          return this.getNationaInsuranceNumberPartsFromFacts()[2];
        }

      }).fieldToFactConverters({
        title: function (model) {
          var selectedTitle = model.Check_Box3 && model.Check_Box3.trim();

          if (selectedTitle.length > 1) {
            return selectedTitle[0].toUpperCase() + selectedTitle.substr(1);
          }
        },
        dateOfBirth: function (model) {
          return this.toISOString(model.Text9);
        },
        nationalInsuranceNumber: function (model) {
          return model.Text10 + model.Text11 + model.Text12;
        }
      });
    };
  }]);
