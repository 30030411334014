/*global window */
'use strict';

var Helpers = window.kerpCfa.common.Helpers;

angular.module('kerp-forms.forms').factory(
  'calculatorHelpers',
  ['configuration',
    function (configuration) {
      return new Helpers(configuration);
    }
  ]);
