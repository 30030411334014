/*global window */
'use strict';

class CalculationResultService {

  constructor(sfSelect) {

    this.saveCalcResults = (careType, calcResults, model = {}) => {

      // kirklees legacy code
      var isActualCostKnown = ((model.careAssessment || {})[careType] || {}).isActualCostKnown;
      var actualCost = ((model.careAssessment || {})[careType] || {}).cost;

      // overwite existing model (sfSelect does so by reference)
      sfSelect('careAssessment.' + careType, model, {});
      sfSelect('careAssessment.' + careType + '.entitlements', model, calcResults.entitlements);
      sfSelect('careAssessment.' + careType + '.workings', model, calcResults.workings);
      sfSelect('careAssessment.' + careType + '.maxContribution', model, calcResults.contributions.maxContribution);

      // when a customer appllies a max cost (i.e. a cap, rather than "full cost"), this flag will be true
      sfSelect('careAssessment.' + careType + '.isMaxCost', model, !!(calcResults.isMaxCost));

      // non-legacy customers need this, but we can safely apply it to all customers
      // because `calcResults.expensesAboveMaxThreshold` property will remain undefined if customer doesn't use it
      sfSelect('person.expenses.disabilityRelated.expensesAboveMaxThreshold', model, calcResults.expensesAboveMaxThreshold);

      // only used by kirklees legacy calc code
      // but has no side affect for other customers, since calcResults.contributions.actualContribution will be undefined
      sfSelect('careAssessment.' + careType + '.isActualCostKnown', model, isActualCostKnown);
      sfSelect('careAssessment.' + careType + '.actualContribution', model, calcResults.contributions.actualContribution);
      sfSelect('careAssessment.' + careType + '.cost', model, actualCost);
    };
  }
}


CalculationResultService.$inject = ['sfSelect'];

angular.module('kerp-forms.forms').service('calculationResultService', CalculationResultService);
