'use strict';

angular.module('kerp-forms.forms')
  .service('HAM_FCA_MAPPINGS', ['FormFieldMappings',
    function (FormFieldMappings) {

      this.mappings = function () {
        return FormFieldMappings
          .buildWithMappings({});
      };
    }]);
