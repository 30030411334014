'use strict';

(function (angular) {

  var mod = angular.module('kerp-forms.forms');

  function downloadFormXmlButtonDirective(Forms, formModelService, API) {

    return {
      restrict: 'EA',
      scope: {
        databaseApplication: "&",
        title: "@"
      },
      replace: true,
      template: '<a href="javascript:void(0)" ng-click="download()">{{title}}</a>',
      link: function (scope, elem, attrs) {

        function rewriteScope() {

          scope.inProgress = true;
          scope.isError = false;
          scope.formModelInstance = scope.databaseApplication();

          if (!angular.isObject(scope.formModelInstance)) {
            return;
          }

          var XML;
          var FORM_CONFIG = Forms.getForm(scope.formModelInstance.formTypeCode);

          scope.download = function () {
            scope.inProgress = true;
            return API.post('/form/' + scope.formModelInstance.id + '/xml', JSON.stringify(formModelService.getSubmittableFormModelFields(FORM_CONFIG, scope.formModelInstance.dirtyFields)))
              .then(function (response) {
                XML = response.data;
              })
              .then(function () {
                var blob = new Blob([XML], {type: 'text/xml;charset=utf-8'});
                saveAs(blob, "govtech.xml");
              })
              .catch(function (error) {
                console.error("Download error", error);
                scope.isError = true;
              })
              .finally(function () {
                scope.inProgress = false;
              });
          };


          scope.inProgress = false;
        }

        scope.$watch(function () {
          return scope.databaseApplication();
        }, rewriteScope);

      }
    };
  }

  downloadFormXmlButtonDirective.$inject = ['Forms', 'formModelService', 'API'];

  mod.directive('downloadFormXmlButton', downloadFormXmlButtonDirective);

}(angular));
