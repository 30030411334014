'use strict';

/**
 * Populates a hidden form field with a fixed value
 * - reads schema version number which is hard coded into the schema
 * - writes the schema version to form data
 *
 */
angular
  .module('kerp-forms.forms')
  .directive(
    'schemaVersion', [
      function () {

        return {
          restrict: 'EA',
          require: 'ngModel',
          scope: {
            form: "="
          },
          templateUrl: 'modules/forms/scripts/directives/schemaVersion/schemaVersionDirective.html',
          link: function (scope, element, attrs, ngModelController) {
            const { version } = scope.form.schema;
            ngModelController.$setViewValue(version);
          },
        };
      }
    ]);
