'use strict';

(function () {

  var mod = angular.module('kerp-forms.forms');

  /**
   * A directive for address look up with prompt list, and population of data from clicking on prompt list items
   */
  mod.directive('addressLookup', ['$http', 'configuration', 'addressFormatterService',
    function ($http, configuration, addressFormatterService) {

      return {
        restrict: 'EA',
        scope: {
          postcode: '=',
          name: '=',
          accountno: '=',
          address1: '=',
          address2: '=',
          address3: '=',
          type: "@"
        },
        templateUrl: 'modules/forms/scripts/directives/addressLookup/addressLookupDirective.html',
        link: function ($scope, element, attrs) {

          // clear the suggestions if the escape key is clicked
          element.keyup(function (e) {
            if (e.keyCode === 27) {
              $scope.addresses = [];
            }
          });

          /**
           * This class that wraps behaviors related to landlord address lookup
           */
          function LandlordBehavior() {
            this.addressesFile = 'LL_address_refs.json';
            this.title = "Search landlords by name";
            this.nameFieldKey = 1;

            /**
             * Populates address fields from a full address
             */
            this.addItem = function (address) {
              $scope.name = address[1];
              var addresses = address[2].split(",");
              for (var i = 0; i < addresses.length; i++) {
                address = addressFormatterService.formatAsObject(addresses);
                $scope.address1 = address.addr1;
                $scope.address2 = address.addr2;
                $scope.address3 = address.addr3;
              }
              $scope.postcode = (addresses[addresses.length - 1] || "").trim();
            };
          }

          /**
           * This class wraps behaviors related to school address lookup
           */
          function SchoolBehavior() {
            this.addressesFile = 'school_lookup.json';
            this.title = "Search schools by name";
            this.nameFieldKey = "School name";

            /**
             * Populates address fields from a full address
             */
            this.addItem = function (address) {

              $scope.name = address.name;
              $scope.address1 = address['Address 1'];
              $scope.address2 = address['Address 2'];
              $scope.address3 = address['Address 3'];
              $scope.postcode = address.Postcode;
            };
          }

          // Delegates behaviors of different functions
          var behavior = $scope.type === "landlord" ? new LandlordBehavior() : new SchoolBehavior();
          $scope.title = behavior.title;
          var addressDatabase = configuration.app.home + '/_content/customer/kirklees/addresses/' + behavior.addressesFile;

          $http.get(addressDatabase).then(function (resp) {
            $scope.$watch("searchName", function (newValue, oldValue) {
              if (newValue !== oldValue) {
                $scope.addresses = (newValue || "").trim().length ? getMatchingAddresses(resp.data, newValue) : [];
              }
            });
          });

          /**
           * Populates address lines from a full address
           */
          $scope.addItem = function (address) {
            behavior.addItem(address);
            $scope.addresses = [];
            $scope.searchName = null;
          };

          var getMatchingAddresses = function (addresses, nameQuery) {
            var matchingAddresses = [];

            // this could be made more concise by using the underscore lib and a literal regexp
            for (var i = 0; i < addresses.length; i++) {
              var nameMatcher = new RegExp(nameQuery, 'i');

              if (addresses[i][behavior.nameFieldKey].match(nameMatcher)) {
                addresses[i].name = addresses[i][behavior.nameFieldKey];
                matchingAddresses.push(addresses[i]);
              }
            }
            return matchingAddresses;
          };
        }
      };
    }]);
}());
