'use strict';

angular
  .module('kerp-forms.forms', ['kerp-forms', 'schemaForm', 'ui.bootstrap', 'ui.router', 'ngFileUpload', 'kerp-forms.util', 'kerp-forms.config', 'pascalprecht.translate', 'ngStorage', 'toaster'])
  .config(['$stateProvider', '$urlRouterProvider', 'schemaFormDecoratorsProvider',
    function ($stateProvider, $urlRouterProvider, schemaFormDecoratorsProvider) {

      var APP_PATH = 'modules/forms/views';
      var ASF_TEMPLATES_DIR = APP_PATH + '/asfTemplates';

      // Replace the default ASF templates for various field types. The default templates can be found in
      // angular-schema-form/src/directives/decorators/bootstrap
      var asfTemplateReplacements = {
        textarea: ASF_TEMPLATES_DIR + '/kerpTextarea.html',
        'default': ASF_TEMPLATES_DIR + '/kerpDefault.html',
        number: ASF_TEMPLATES_DIR + '/kerpDefault.html',
        radios: ASF_TEMPLATES_DIR + '/kerpRadios.html',
        checkboxes: ASF_TEMPLATES_DIR + '/kerpCheckboxes.html',
        checkbox: ASF_TEMPLATES_DIR + '/kerpCheckbox.html',
        select: ASF_TEMPLATES_DIR + '/kerpSelect.html',
        array: ASF_TEMPLATES_DIR + '/kerpArray.html',
        fieldset: ASF_TEMPLATES_DIR + '/kerpFieldset.html'
      };

      var defaultDecorator = schemaFormDecoratorsProvider.decorator();

      angular.forEach(asfTemplateReplacements, function (templateUrl, fieldType) {
        defaultDecorator[fieldType].template = templateUrl;
      });

      // For any unmatched url, redirect to /forms/list
      $urlRouterProvider.otherwise('/forms/list');

      // Now set up the states
      $stateProvider
        .state('main.forms', {
          url: 'forms',
          template: '<div ui-view class="container"></div>',
          abstract: true
        })
        .state('main.forms.list', {
          url: '/list',
          templateUrl: APP_PATH + '/list.html',
          controller: 'FormListCtrl'
        })
        .state('main.forms.show', {
          url: '/:formId/show',
          templateUrl: APP_PATH + '/show.html',
          controller: 'ShowCtrl'
        })
        .state('main.forms.update', {
          url: '/:formId/update/:id?populateUserDetails',
          templateUrl: APP_PATH + '/update.html',
          controller: 'UpdateCtrl',
          data: {
            requireAuth: true
          }
        })
        .state('main.forms.review', {
          url: '/:formId/review/:id?offline',
          templateUrl: APP_PATH + '/review/reviewApplication.html',
          controller: 'ReviewApplicationCtrl'
        })
        .state('main.forms.evidenceLinkHandler', {
          url: '/evidenceLinkHandler?token&formId',
          controller: ['$state', 'toaster', '$stateParams', 'formModelService', 'authProviderService', 'authSessionService',
            function ($state, toaster, $stateParams, formModelService, authProviderService, authSessionService) {
              var showEvidence = function () {
                formModelService.getForm($stateParams.formId)
                  .then(function (formModelInstance) {
                    $state.go('main.forms.review', {
                      id: $stateParams.formId,
                      formId: formModelInstance.formTypeCode
                    });
                  })
                  .catch(function (error) {
                    $state.go('main.forms.list');
                    toaster.pop('error', 'Failed to display evidence. It is possible that the link has expired.');
                  });
              };

              if (authSessionService.hasSession()) {
                showEvidence();

              } else {
                authProviderService.login('agent', {token: $stateParams.token}).then(
                  showEvidence,
                  function (error) {
                    $state.go('main.home').then(function () {
                      toaster.pop('error', 'Failed to display evidence. It is possible that the link has expired.');
                    });
                  }
                );
              }
            }
          ]
        })

        .state('main.forms.offline', {
          url: '/offline',
          template: '<div ui-view></div>',
          abstract: true
        })
        .state('main.forms.offline.list', {
          url: '/list',
          templateUrl: APP_PATH + '/offline.list.html',
          controller: 'FormOfflineCtrl'
        })
        .state('main.forms.offline.edit', {
          url: '/edit/:formId/:id',
          templateUrl: APP_PATH + '/offline.edit.html',
          controller: 'FormOfflineEditCtrl'
        });
    }]);
