'use strict';

/**
 * Extracts an agent name from agent details
 *
 */
angular
  .module('kerp-forms.forms')
  .directive(
    'fullCostOfCare', [
    'objectUtils',
    function (objUtils) {

      return {
        scope: {
          model: '=',
          form: '=',
        },
        templateUrl: 'modules/forms/scripts/careAssessment/fullCostOfCare.html',
        link: function (scope, element, attrs) {

          const careType = scope.form.careType;
          const applyPropertyFullCostTemplate = scope.form.applyPropertyFullCostTemplate;

          const propertyOwnership = objUtils.getPropertyPath(scope.model, 'person.property.ownership');
          const ownsPrimaryProperty = ['ownedByYou', 'ownedJointly'].includes(propertyOwnership);

          const protectedCohabitants = (objUtils.getPropertyPath(scope.model, 'peopleLivingWithYou.otherPeople') || []).filter(person => person.relationshipType !== 'other');
          const partnerCohabits = objUtils.getPropertyPath(scope.model, 'partner.details.sameAddress') === true;
          const mandatoryDisregardsApply = protectedCohabitants.length || partnerCohabits;

          scope.careTypeKey = 'app.care.' + careType;
          scope.propertyIncluded = ownsPrimaryProperty && !mandatoryDisregardsApply && applyPropertyFullCostTemplate;
          scope.noPropertyIncluded = !scope.propertyIncluded;
        }
      };
    }
  ]);
