'use strict';

angular.module('kerp-forms.forms').directive('formPanel', function () {

    return {
      restrict: 'EA',
      scope: {
        forms: "=",
        title: "@"
      },
      templateUrl: 'modules/forms/scripts/directives/formPanel/formPanel.html'
    };
  }
);
