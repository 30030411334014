'use strict';

function kitchenSinkSchemaService(fieldDefinitionService) {

  var requiredAddressLine = fieldDefinitionService.address.requiredLine();

  return {
    getSchema: function (isRequired) {
      var schema = {
        type: 'object',
        properties: {

          textInputHide: fieldDefinitionService.boolean.yesNoSchema({title: 'Hide all text fields'}),
          numberSelectionDateInputHide: fieldDefinitionService.boolean.yesNoSchema({title: 'Hide all number, selection and date fields'}),

          children: {
            type: 'array',
            minItems: 0,
            items: {
              type: "object",
              properties: {
                name: {
                  title: 'Name',
                  type: 'string'
                },
                dateOfBirth: fieldDefinitionService.time.dateOfBirth({
                  validationMessage: 'Future dates are prohibited',
                  'x-schema-form': {
                    type: 'memorabledate',
                    dateFormat: "DD/MM/YYYY",
                    maxDate: new Date()
                  }
                })
              }
            }
          },

          string: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                title: 'No constraint'
              },
              nationalInsuranceNumber: fieldDefinitionService.NINO.fullNumber({title: "National insurance number"}),
              email: fieldDefinitionService.string.email({
                title: "Email",
                'x-schema-form': {
                  description: 'When the form is submitted, the data will be sent to this address (if email is enabled on the server)'
                }
              }),

              genderRadios: {
                type: 'string',
                title: "Radio buttons",
                "enum": ["Male", "Female"],
                'x-schema-form': {
                  type: "radios"
                }
              },

              genderSelect: {
                type: 'string',
                title: "Dropdown",
                "enum": ["Male", "Female"],
                'x-schema-form': {
                  type: "select"
                }
              },

              pastDate: fieldDefinitionService.time.pastDateSchema({
                title: 'Enter a date in the past',
                validationMessage: "The date cannot be a future date.",
                'x-schema-form': {
                  type: "kerpdate",
                  dateFormat: "YYYY-MM-DD",
                  maxDate: new Date()
                }
              }),

              timePicker: {
                type: 'string',
                'x-schema-form': {
                  title: "Enter a time",
                  type: 'kerptime',
                  validationMessage: 'The time is not valid'
                }
              },
              memorableDate: fieldDefinitionService.time.dateOfBirth({
                dateFormat: "YYYY-MM-DD",
                validationMessage: 'The age range must be between 18 and 120',
                pattern: '(19|20)\\d\\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])',
                title: "Adult's date of birth"
              }),
              chooseOrEnterTitle: {
                type: 'string'
              }
            }
          },

          number: {
            type: 'object',
            properties: {
              currency: fieldDefinitionService.number.currencyField({title: 'Currency - positive number with max. 2 decimal places'}),
              decimal: {
                title: 'Any decimal',
                type: 'number'
              },
              minAndMaxInteger: {
                title: "Integer between 1 and 4",
                type: 'integer',
                minimum: 1,
                maximum: 4,
                validationMessage: "The number must be between 1 and 4"
              },
              integer: {
                title: "Any integer",
                type: 'integer',
                validationMessage: "Must be a number"
              },
              positiveInteger: {
                title: "Positive integer",
                minimum: 0,
                type: 'integer'
              }
            }
          },

          boolean: {
            type: 'object',
            properties: {
              radios: fieldDefinitionService.boolean.yesNoSchema({title: "Yes/No radio buttons"})
            }
          },
          schoolLookupSchoolName: {
            type: 'string',
            title: 'School name'
          },
          schoolLookupLine1: fieldDefinitionService.address.optionalLine({title: 'Address Line 1'}),
          schoolLookupLine2: fieldDefinitionService.address.optionalLine({title: 'Address Line 2'}),
          schoolLookupLine3: fieldDefinitionService.address.optionalLine({title: 'Address Line 3'}),

          schoolLookupPostcode: fieldDefinitionService.address.postcode({title: 'Postcode'}),

          landlordLookupLandlordName: {
            title: 'Landlord name',
            type: 'string',
            description: "By landlord we mean the person or organisation who <b>owns the property</b> you live in."
          },
          landlordLookupLine1: fieldDefinitionService.address.optionalLine({
            title: 'Address Line 1'
          }),
          landlordLookupLine2: fieldDefinitionService.address.optionalLine({title: 'Address Line 2'}),
          landlordLookupLine3: fieldDefinitionService.address.optionalLine({title: 'Address Line 3'}),
          landlordLookupPostcode: fieldDefinitionService.address.postcode({title: 'Postcode'}),

          multiSelect: fieldDefinitionService.string.array(["Alone", "Afraid", "Hungry"], isRequired, {
            title: "Please describe yourself (choose all that apply)"
          })
        }
      };

      if (isRequired) {
        schema.properties.string.required = [
          'name',
          'nationalInsuranceNumber',
          'email',
          'genderRadios',
          'genderSelect',
          'pastDate',
          'timePicker',
          'memorableDate',
          'chooseOrEnterTitle'
        ];
        schema.properties.number.required = ['currency', 'decimal', 'minAndMaxInteger', 'integer', 'positiveInteger'];
        schema.properties.boolean.required = ['radios', 'mustChooseYes'];

        // it doesn't make sense to add this to the optional schema because the field can't be optional if
        // you're forced to choose yes
        schema.properties.boolean.properties.mustChooseYes = fieldDefinitionService.boolean.yesRequiredSchema({
          validationMessage: 'I told you to choose "Yes"',
          title: 'You must choose "Yes"'
        });

        schema.properties.addressSuggestionLine1 = requiredAddressLine;
        schema.properties.addressSuggestionLine2 = requiredAddressLine;
        schema.properties.addressSuggestionLine3 = fieldDefinitionService.address.optionalLine();
        schema.properties.addressSuggestionPostcode = fieldDefinitionService.address.postcode();

        schema.properties.addressSuggestionUPRN = {
          type: 'string'
        };

        schema.properties.schoolLookupLine1 = requiredAddressLine;
        schema.properties.schoolLookupLine2 = requiredAddressLine;
        schema.properties.landlordLookupLine1 = requiredAddressLine;
        schema.properties.landlordLookupLine2 = requiredAddressLine;
        schema.required = [
          'string',
          'number',
          'boolean',
          'multiSelect',
          'addressSuggestionLine1',
          'addressSuggestionLine2',
          'addressSuggestionPostcode',
          'addressSuggestionUPRN',
          'schoolLookupSchoolName',
          'schoolLookupLine1',
          'schoolLookupLine2',
          'schoolLookupPostcode',
          'landlordLookupLandlordName',
          'landlordLookupLine1',
          'landlordLookupLine2',
          'landlordLookupLinePostcode',
          'textInputHide',
          'numberSelectionDateInputHide',
          'children'
        ];

        schema.properties.children.minItems = 1;
        schema.properties.children.items.required = ["name", "dateOfBirth"];

        // Because you are forced to complete at least one of these fields, they are not added to the optional form
        schema.properties.localNumberApplicantDaytel = fieldDefinitionService.phone.localNumber();
        schema.properties.fullNumberApplicantMob = fieldDefinitionService.phone.fullNumber();
        schema.properties.applicantEmail = fieldDefinitionService.string.email();
      }
      return schema;
    }
  };
}

kitchenSinkSchemaService.$inject = ['fieldDefinitionService'];

angular.module('kerp-forms.forms').factory('kitchenSinkSchemaService', kitchenSinkSchemaService);
