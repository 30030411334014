'use strict';

(function (angular) {

  var mod = angular.module('kerp-forms.forms');

  function schemaFormHelperService($rootScope, schemaForm) {

    /**
     * Merge form and schema for further validation
     * @param {Array} form
     * @param {Object} schema
     * @return {*}
     */
    function mergeFormAndSchema(form, schema) {
      return schemaForm.merge(schema, form);
    }

    /**
     * Traverse schema and return specified schema config if found
     * @param {Object} schema
     * @param {string[]} key - ordered array of schema key names
     * @return {*}
     */
    function findConfigInSchema(schema, keys) {
      if (keys.length === 0) {
        return schema;
      }
      var nextKey = keys[0];
      var childSchema = ((schema || {}).properties || {})[nextKey];

      return findConfigInSchema(childSchema, keys.slice(1,keys.length));
    }

    /**
     * Calls given handler on each form field
     * If any of the form children have conditions, they are evaluated
     * @param {Object} form merged form and schema
     * @param {Object} model the field keys and values
     * @param {Object} options
     *
     * @param {Boolean} options.fieldHandler - handles is going to be called for valid field
     * @param {Boolean} options.skipCondition - should it skip conditional defined in form
     * @param {Boolean} options.skipAllConditions - should it skip all conditional defined in form
     * @param {Object} options.scope to evaluate condition against
     * @param {Object} options.conditionLibrary - if form conditions rely on one
     * @returns {Array}
     */
    function forEachFieldInForm(form, model, options) {

      var scope = options.scope = options.scope || $rootScope.$new(true);
      var skipCondition = options.skipCondition = options.skipCondition === true;
      var handlerFunction = options.fieldHandler = options.fieldHandler || angular.noop;
      var htmlHandlerFunction = options.htmlHandler = options.htmlHandler || angular.noop;
      var skipAllConditions = options.skipAllConditions = options.skipAllConditions === true;

      options.conditionLibrary = options.conditionLibrary || {};
      if (angular.isFunction(options.conditionLibrary.setModelGetter) && !options.conditionLibrary.getModel()) {
        options.conditionLibrary.setModelGetter(function () {
          return model;
        });
      }
      var conditionLibrary = options.conditionLibrary;

      if (angular.isArray(form)) {
        form.forEach(function (childField) {
          options.skipCondition = false;
          forEachFieldInForm(childField, model, options);
        });
      } else if (!form || !angular.isObject(form)) {
        // form field definition must be an object

      } else if (angular.isString(form.condition) && !skipCondition) {

        // if the condition is truthy, or can be ignored, evaluate this field again with condition evaluation disabled
        if (skipAllConditions || scope.$eval(form.condition, {model: model, lib: conditionLibrary}) ||
          form.condition.match(/model\.page/)) {

          options.skipCondition = true;
          forEachFieldInForm(form, model, options);
        }
      } else if (form.type === 'help') {
        htmlHandlerFunction(form.helpvalue, form);

      } else if (form.type === 'template') {
        htmlHandlerFunction(form.template, form);

      } else if (form.type === 'section' || form.type === 'fieldset') {

        if (form.type === 'fieldset' && form.title) {
          htmlHandlerFunction(form.title, form);
        }
        options.skipCondition = false;
        forEachFieldInForm(form.items, model, options);

      } else if (angular.isString(form.key) || angular.isArray(form.key)) {
        handlerFunction(form);
      }
    }

    /**
     * A page is deemed to be an info page if it only contains informational fields (help text, templates, etc).
     * A page that has a mixture of info and input fields is not considered to be an info page even if all the
     * input fields are hidden.
     *
     * The reason for this distinction is that a page with a mixture of fields (with all inputs hidden) will be
     * skipped in pagination/PDF generation, because the information on the page likely relates to the hidden
     * input fields and therefore should be omitted if the input fields are all hidden.
     *
     * @param formFields the field keys and values
     * @param formConditionsService
     * @param mergedFormAndSchema
     * @returns {boolean}
     */
    function isInfoPage(formFields, formConditionsService, mergedFormAndSchema) {
      var isInfo = true;
      forEachFieldInForm(mergedFormAndSchema, formFields, {
        conditionLibrary: formConditionsService,
        skipAllConditions: true,
        fieldHandler: function () {
          isInfo = false;
        }
      });
      return isInfo;
    }

    /**
     * Checks if the page with specified pageNumber has visible input fields and/or visible help texts
     *
     * @param formFields the field keys and values
     * @param mergedFormAndSchema
     * @param formConditionsService
     * @param hideUndefinedFields specifies whether undefined fields should be hidden
     * @returns {{input: boolean, help: boolean}}
     */
    function hasVisibleFields(formFields, mergedFormAndSchema, formConditionsService, hideUndefinedFields) {

      var visibleFieldTypes = {input: false, help: false};

      forEachFieldInForm(mergedFormAndSchema, formFields, {
        conditionLibrary: formConditionsService,
        fieldHandler: function (field) {
          if (angular.isDefined(formFields[field.key]) || !hideUndefinedFields) {
            visibleFieldTypes.input = true;
          }
        },
        htmlHandler: function (fieldContent, fieldDefinition) {

          // KERP-2374 htmlHandler is called for the root element of a page (a fieldset) if it has a title attribute.
          // Therefore, the if-statement below prevents a page on which all fields are hidden from being considered
          // visible if it has a title
          if (!fieldDefinition.page) {
            visibleFieldTypes.help = true;
          }
        }
      });
      return visibleFieldTypes;
    }


    /**
     * Checks if a page is hidden
     */
    function isPageHidden(model, formConditionsService, mergedFormAndSchema) {
      var hasVisible = hasVisibleFields(model, mergedFormAndSchema, formConditionsService);

      // Checks if this page is an info page - if so display it only if it has visible help texts
      if (isInfoPage(model, formConditionsService, mergedFormAndSchema)) {
        return !hasVisible.help;
      } else {
        // If a page doesn't have any visible fields, treat the page itself as hidden
        return !hasVisible.input;
      }
    }


    return {
      forEachFieldInForm: forEachFieldInForm,
      mergeFormAndSchema: mergeFormAndSchema,
      findConfigInSchema: findConfigInSchema,
      isInfoPage: isInfoPage,
      isPageHidden: isPageHidden,
      hasVisibleFields: hasVisibleFields
    };

  }

  schemaFormHelperService.$inject = ['$rootScope', 'schemaForm'];

  mod.factory('schemaFormHelperService', schemaFormHelperService);

}(angular));
