'use strict';

angular.module('kerp-forms.forms').factory(
  'DO_FCA_CONDITIONS',
  [
    'FormConditions',
    function (
      FormConditions
    ) {


      var showBreakdown = function(ctx) {
        const hasPartner = ctx.lib.getModelValue('person.intro.hasPartner');
        const hasCohabitingPartner = hasPartner && ctx.lib.getModelValue('partner.details.sameAddress');
        const partnerDisclosesFinances = hasCohabitingPartner && ctx.lib.getModelValue('partner.intro.discloseFinances');

        return partnerDisclosesFinances === false;
      };

      return FormConditions.clone({
        conditions: {
          'person.property.value': function () {
            return this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
          },

          'person.intro.excludeNonResidentialCaretypes': function() {
            var excludeNonResidentialCaretypes = this.lib.getModelValue('person.intro.excludeNonResidentialCaretypes');
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');

            // residential care types should be visible only if the user has chosen to complete DRE
            // OR they have chosen not to agree to provide their financial details
            return !excludeNonResidentialCaretypes || provideFinancialDetails !== 'agree';
          },

          'person.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'person.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'person.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'partner.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'partner.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'partner.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'person.income.universalCredit': function () {
            var uc = this.lib.getModelValue('person.income.universalCredit');
            return uc > 0;
          },

          'showBreakdown': function () {
            const showBD = showBreakdown(this);
            return showBD;
          },

          'noBreakdown': function () {
            const noBreakdown = !(showBreakdown(this));
            return noBreakdown;
          }
        }
      });
    }]);
