'use strict';

function FormOfflineEditCtrl($scope, $q, $state, $stateParams, $timeout, formBaseService, $localStorage, FormModel, dynamicEvidenceService, $rootScope) {

  $scope.requiredEvidence = {};
  $scope.isEvidenceVisible = false;

  $scope.allowEvidenceDisplay = function () {
    return Object.keys($scope.requiredEvidence).length && $rootScope.app.isOffline;
  };

  $scope.toggleEvidence = function () {
    $scope.isEvidenceVisible = !$scope.isEvidenceVisible;
  };

  function updateEvidenceList(formFields) {

    dynamicEvidenceService.getEvidenceFromJson($stateParams.formId, formFields)
      .then(function (allRequiredEvidence) {
        $scope.requiredEvidence = allRequiredEvidence || {};
      });
  }

  try {
    formBaseService.decorateControllerScope($scope, $stateParams.formId, $stateParams.id, {
      syncToLocalStorage: true,
      allowNavigationWhenInvalid: true,
      formModelInstanceGetter: function (id) {
        return $q(function (resolve, reject) {

          if (!$localStorage.offlineforms) {
            $localStorage.offlineforms = {};
          }

          if (!$localStorage.offlineforms[id]) {
            reject('Form is not stored locally');
          } else if (!$localStorage.offlineforms[id].form) {
            reject('Form data is corrupted');
          } else {
            var localStorageForm = $localStorage.offlineforms[id];
            var formModelInstance = new FormModel(localStorageForm.form, localStorageForm.token);
            resolve(formModelInstance);
          }
        });
      },

      formModelInstanceUpdateHandler: function (formModelInstance, formFields, formDescription, formFormDefinition) {
        return $q(function (resolve, reject) {
          if (!$localStorage.offlineforms) {
            $localStorage.offlineforms = {};
          }
          if (!$localStorage.offlineforms[formModelInstance.id]) {
            reject('Form is not stored locally');
          } else if (!$localStorage.offlineforms[formModelInstance.id].form) {
            reject('Form data is corrupted');
          } else {
            $timeout(function () {
              updateEvidenceList(formFields);
              $localStorage.offlineforms[formModelInstance.id].form = formModelInstance;
              resolve();
            });
          }
        });
      },

      areFormNavigationButtonsVisibleHandler: function (formModelInstance) {
        return !formModelInstance.allPagesValidated;
      },

      onPrevPagination: function (formService, model, formDefinition) {
        if (model.page > 1) {
          formService.prev(model);

        } else {
          $state.go('main.forms.offline.list');
        }
      },

      onNextPagination: function (formService, formFields, formModelInstance) {
        if (formFields.page < formService.get().length - 1) {
          formService.next(formFields);

        } else {
          $scope.validateAllPages()
            .then(function () {
              formModelInstance.allPagesValidated = true;
            });
        }
      },

      onAbandon: function () {
        $state.go('main.forms.offline.list');
      }
    });
  } catch (e) {
    console.error('Redirecting to application list after error', e);
    $state.go('main.forms.offline.list');
    return;
  }

  $scope.isFormVisible = function() {
    return !$scope.loadingMessage && !$scope.errorState && !$scope.formResultError;
  };

  $scope.isErrorVisible = function() {
    return $scope.errorState;
  };

  $rootScope.$on('form-error-fatal', function(e, args) {
    $scope.errorState = true;
    if (args && args.error) {
      $scope.errorTitle = args.error.title;
      $scope.errorMessage = args.error.message;
    }
  });

  $scope.loadForm()
    .then(function () {
      var formFields = $scope[$scope.formModelFieldsVariable];
      updateEvidenceList(formFields);
    })
    .catch(function (e) {
      console.error('Redirecting to application list after error', e);
      $state.go('main.forms.offline.list');
    });
}

FormOfflineEditCtrl.$inject =
  ['$scope', '$q', '$state', '$stateParams', '$timeout', 'formBaseService', '$localStorage', 'FormModel', 'dynamicEvidenceService', '$rootScope'];

angular.module('kerp-forms.forms').controller('FormOfflineEditCtrl', FormOfflineEditCtrl);
