'use strict';

angular
  .module('kerp-forms.forms')
  .factory('AA1A_FORM', ['FormUI', 'htmlService', function (FormUI, htmlService) {
    var formUI = new FormUI();

    var form = [
      {
        type: 'section',
        condition: 'model.page === 1',
        page: 1,
        items: [
          {
            type: 'template',
            htmlClass: 'divTitle',
            templateUrl: 'modules/forms/scripts/services/forms/AA1A/templates/help1_1.html'
          },
          {
            type: 'help',
            helpvalue: '<div><h2>About you</h2></div>'
          },
          {
            key: '1_1',
            title: 'Surname or family name',
            description: 'Please enter your personal details. If you are filling in this form for someone else, enter their details, not yours.'
          },
          {
            key: '1_2',
            title: 'All other names in full'
          },

          {
            key: '1_3',
            type: 'select',
            title: 'Title'
          },

          {
            type: "section",
            htmlClass: 'form-group',
            items: [
              {
                type: "help",
                helpvalue: '<label>National Insurance number</label>'
              },
              {
                key: "1_4",
                fieldHtmlClass: 'narrow',
                notitle: true,
                feedback: false,
                placeholder: 'QQ'
              },
              {
                key: "1_5",
                notitle: true,
                feedback: false,
                placeholder: '123456'
              },
              {
                key: "1_6",
                fieldHtmlClass: 'narrow',
                notitle: true,
                feedback: false,
                placeholder: 'C'
              },
              {
                type: "help",
                htmlClass: 'alert alert-success',
                helpvalue: '<div>For instance QQ 123456 C</div>'
              }
            ]
          },

          {
            key: '1_7',
            type: 'memorabledate',
            title: 'Date of birth',
            validationMessage: "Attendance Allowance can only be claimed by people who have reached State Pension age.",
            maxDate: (function () {
              // persons under 65 are ineligible: KERP-572
              var sixtyFiveYearsAgo = new Date();
              sixtyFiveYearsAgo.setFullYear(sixtyFiveYearsAgo.getFullYear() - 65);
              return sixtyFiveYearsAgo;
            }())
          },
          {
            key: '1_8',
            type: 'radios',
            title: 'Sex',
            "titleMap": [
              {"value": "Male", "name": "Male"},
              {"value": "Female", "name": "Female"}
            ]
          },
          {
            key: '1_9',
            title: 'The full address where you live',
            type: 'textarea'
          },
          {
            key: '1_10',
            title: 'Daytime phone number',
            description: 'Please include the dialling code.'
          },
          {
            key: '1_11',
            title: 'Mobile phone number',
            description: 'If it\'s different from your daytime phone number.'
          },
          {
            key: '1_12',
            title: 'Do you have speech or hearing difficulties and want us to contact you by textphone?'
          },
          {
            type: 'string',
            condition: 'model["1_12"]',
            key: '1_13',
            title: 'Textphone number'
          },
          {
            key: '1_14',
            title: 'What is your nationality',
            description: 'For example, British'
          }

        ]
      },
      {
        type: 'section',
        condition: 'model.page === 2',
        page: 2,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About you (continued)</h2></div>'
          },
          {
            key: '2_1',
            type: 'radios',
            title: 'Do you normally live in Great Britain?',
            "titleMap": [
              {"value": "Yes", "name": "Yes"},
              {"value": "No, go to question 9", "name": "No"}
            ],
            description: 'Great Britain is England, Scotland and Wales.'
          },
          {
            condition: 'model["2_1"] === "Yes"',
            title: "If you live in Wales, in future, would you like us to contact you in Welsh?",
            key: "2_2"
          },
          {
            key: '2_3',
            type: 'radios',
            title: 'Have you been abroad for more than 4 weeks at a time in the last 3 years?',
            "titleMap": [
              {"value": "Yes", "name": "Yes"},
              {"value": "No, go to question 10", "name": "No"}
            ],
            description: "Abroad means out of Great Britain."
          },
          {
            type: 'section',
            condition: 'model["2_3"] === "Yes"',
            items: [
              {
                type: "help",
                htmlClass: 'helpTitle',
                helpvalue: "<div>Please tell us when you went abroad.</div>"
              },
              {
                key: "2_4",
                type: "kerpdate",
                mode: "year",
                title: "From"
              },
              {
                key: "2_5",
                type: "kerpdate",
                mode: "year",
                title: "To"
              },
              {
                key: "2_6",
                title: "Tell us where you went."
              },
              {
                key: "2_7",
                title: "Tell us why you went.",
                type: 'textarea',
                description: 'If you have been abroad more than once in the last 3 years, please tell us the dates you went, where you went and why you went at question 49 Extra information.'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 3',
        page: 3,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About you (continued)</h2></div>'
          },
          {
            key: '3_1',
            type: 'radios',
            title: 'Are you, your wife, husband or civil partner receiving any pensions or benefits from another EEA state or Switzerland?',
            description: 'EEA states are Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, also Iceland, Liechtenstein, Norway and the UK',
            "titleMap": [
              {"value": "Yes, we will contact you about this.", "name": "Yes, you will be contacted about this."},
              {"value": "No, go to question 11.", "name": "No"},
              {
                "value": "Don't know. we will contact you about this",
                "name": "Don't know, you will be contacted about this"
              }
            ]
          },
          {
            key: '3_2',
            type: 'radios',
            title: 'Are you, your wife, husband or civil partner working in or paying insurance to another EEA state or Switzerland?',
            "titleMap": [
              {"value": "Yes, we will contact you about this.", "name": "Yes, you will be contacted about this."},
              {"value": "No, go to question 12", "name": "No"},
              {
                "value": "Don't know. We will contact you about this,",
                "name": "Don't know, you will be contacted about this"
              }
            ],
            description: "By insurance we mean connected to work, like UK National Insurance."
          },
          {
            key: '3_3',
            title: 'What type of accommodation do you live in?',
            description: 'For example, you may live in a house, bungalow, flat, supported housing, residential care home, nursing home or somewhere else.'
          },
          {
            type: 'help',
            helpvalue: '<div><h2>Signing the form for someone else</h2></div>'
          },
          {
            key: '3_4',
            type: 'radios',
            title: 'Are you signing the form for someone else?',
            "titleMap": [
              {"value": "Yes, continue below", "name": "Yes"},
              {"value": "No, go to question 14", "name": "No"}
            ],
            description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/AA1A/templates/description3_4.html')
          },
          {
            type: 'section',
            condition: 'model["3_4"] === "Yes, continue below"',
            items: [
              {
                key: '3_5',
                type: 'checkboxes',
                title: 'Why are you signing the form for them?',
                description: 'You may wish to tell the person you have claimed for that you have made a claim to this benefit on their behalf. This is because they will receive letters about their Attendance Allowance claim. There is no mention of terminal illness or the special rules in our notifications.',
                "titleMap": [
                  {"value": "Yes", "name": "I’m claiming for them under the special rules for terminally ill people."}
                ]
              },
              {
                key: '4_1',
                type: 'checkboxes',
                notitle: true,
                "titleMap": [
                  {"value": "Yes", "name": "I’m an appointee, appointed by the Department for Work and Pensions"}
                ]
              },
              {
                key: '4_2',
                type: 'checkboxes',
                notitle: true,
                "titleMap": [
                  {"value": "Yes", "name": "I hold Power of Attorney"}
                ]
              },
              {
                key: '4_3',
                type: 'checkboxes',
                notitle: true,
                "titleMap": [
                  {"value": "Yes", "name": "I’m a Deputy"}
                ]
              },
              {
                key: '4_4',
                type: 'checkboxes',
                notitle: true,
                "titleMap": [
                  {"value": "Yes", "name": "I’m a Tutor (under Scottish law)"}
                ]
              },
              {
                key: '4_6',
                type: 'checkboxes',
                notitle: true,
                "titleMap": [
                  {"value": "Yes", "name": "I’m a Curator bonis or Judicial factor (under Scottish law)"}
                ]
              },
              {
                key: '4_7',
                type: 'checkboxes',
                notitle: true,
                "titleMap": [
                  {"value": "Yes", "name": "I’m a Corporate Acting Body or Corporate Appointee"}
                ]
              },
              {
                type: 'section',
                condition: 'model["4_7"] && model["4_7"].indexOf("Yes") !== -1',
                items: [
                  {
                    key: "4_8",
                    title: 'Please tell us the name of your organisation.',
                    description: 'For example, an organisation appointed to act on behalf of the person the benefit is for, such as a local authority or firm of solicitors.'

                  }
                ]
              },
              {
                type: 'help',
                htmlClass: 'divTitle',
                helpvalue: '<div><p class=\'alert alert-warning\'>Unless the Department for Work and Pensions have already seen this authority they will need to see it before they can process the claim. Please send your power of attorney or the relevant documents with this claim. You can send the original or a certified copy.</p></div>'
              },
              {
                key: '4_9',
                title: 'I want to be appointed to act on their behalf.',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/AA1A/templates/description4_9.html')
              },
              {
                key: '4_10',
                title: 'Your name',
                name: 'surname',
                autocomplete: 'surname'
              },
              {
                type: "help",
                htmlClass: 'helpTitle',
                helpvalue: '<div>National Insurance number</div>'

              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-3 col-md-2",
                    items: [{
                      key: "4_11",
                      notitle: true,
                      feedback: false
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-md-5",
                    items: [{
                      key: "4_12",
                      notitle: true,
                      feedback: false
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-3 col-md-2",
                    items: [{
                      key: "4_13",
                      notitle: true,
                      feedback: false
                    }]
                  }
                ]
              },
              {
                type: "help",
                htmlClass: 'alert alert-success',
                helpvalue: '<div>For Instance QQ 123456 C</div>'
              },
              {
                key: '4_14',
                type: 'memorabledate',
                title: 'Date of birth',
                validationMessage: 'The date of birth is incorrect',
                maxDate: new Date()
              },
              {
                key: '4_15',
                title: 'Your full address',
                type: 'textarea',
                name: 'address',
                autocomplete: 'street-address'
              },
              {
                key: '4_16',
                title: 'Daytime phone number',
                autocomplete: 'phone'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 4',
        page: 4,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About your illnesses or disabilities and the treatment or help you receive</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'well',
            helpvalue: '<div>By illnesses or disabilities we mean physical, sight, hearing or speech difficulty or mental-health problems.<p><b>If you have a spare up-to-date printed prescription list<b/>, please send it in with this form. If you send in your prescription list you do not need to tell us about your medicines and dosage in the table below, but we still need to know your illness or disability.</p><p>You can find the dosage on the label on your medicine.</p>By treatments we mean things like physiotherapy, speech therapy, occupational therapy or visiting a day-care centre or a mental-health professional for counselling or other treatments.</div>'
          },
          {
            type: 'help',
            helpvalue: '<div><h3>Please list separately details of your illnesses or disabilities below.</h3></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><h4>First condition</h4></div>'
          },
          {
            key: '5_1',
            title: 'Name of illness or disability'
          },
          {
            key: '5_2',
            title: 'How long have you had this illness or disability?'
          },
          {
            key: '5_3',
            title: 'What medicines or treatments (or both) have you been prescribed for this illness or disability?'
          },
          {
            key: '5_4',
            title: 'What is the dosage and how often do you take each of the medicines or receive treatment?'
          },
          {
            type: 'help',
            helpvalue: '<div><h4>Second condition</h4></div>'
          },
          {
            key: '5_5',
            title: 'Name of illness or disability'
          },
          {
            key: '5_6',
            title: 'How long have you had this illness or disability?'
          },
          {
            key: '5_7',
            title: 'What medicines or treatments (or both) have you been prescribed for this illness or disability?'
          },
          {
            key: '5_8',
            title: 'What is the dosage and how often do you take each of the medicines or receive treatment?'
          },
          {
            type: 'help',
            helpvalue: '<div><h4>Third condition</h4></div>'
          },
          {
            key: '5_9',
            title: 'Name of illness or disability'
          },
          {
            key: '5_10',
            title: 'How long have you had this illness or disability?'
          },
          {
            key: '5_11',
            title: 'What medicines or treatments (or both) have you been prescribed for this illness or disability?'
          },
          {
            key: '5_12',
            title: 'What is the dosage and how often do you take each of the medicines or receive treatment?'
          },


          {
            type: 'help',
            helpvalue: '<div><h4>Fourth condition</h4></div>'
          },
          {
            key: '5_13',
            title: 'Name of illness or disability'
          },
          {
            key: '5_14',
            title: 'How long have you had this illness or disability?'
          },
          {
            key: '5_15',
            title: 'What medicines or treatments (or both) have you been prescribed for this illness or disability?'
          },
          {
            key: '5_16',
            title: 'What is the dosage and how often do you take each of the medicines or receive treatment?'
          },


          {
            type: 'help',
            helpvalue: '<div><h4>Fifth condition</h4></div>'
          },
          {
            key: '5_17',
            title: 'Name of illness or disability'
          },
          {
            key: '5_18',
            title: 'How long have you had this illness or disability?'
          },
          {
            key: '5_19',
            title: 'What medicines or treatments (or both) have you been prescribed for this illness or disability?'
          },
          {
            key: '5_20',
            title: 'What is the dosage and how often do you take each of the medicines or receive treatment?'
          },


          {
            type: 'help',
            helpvalue: '<div><h4>Sixth condition</h4></div>'
          },
          {
            key: '5_21',
            title: 'Name of illness or disability'
          },
          {
            key: '5_22',
            title: 'How long have you had this illness or disability?'
          },
          {
            key: '5_23',
            title: 'What medicines or treatments (or both) have you been prescribed for this illness or disability?'
          },
          {
            key: '5_24',
            title: 'What is the dosage and how often do you take each of the medicines or receive treatment?'
          },


          {
            type: 'template',
            htmlClass: 'alert alert-success',
            template: '<div>If you need more space to tell us about your illnesses or disabilities, please continue in the Extra information section. <a href="http://www.youtube.com/watch?v=UPMeWYFLe4I">Watch a video</a></div>'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 5',
        page: 5,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About your illnesses or disabilities and the treatment or help you receive (continued)</h2></div>'
          },
          {
            key: '6_1',
            type: 'radios',
            title: 'Apart from your GP, in the last 12 months have you seen anyone about your illnesses or disabilities?',
            "titleMap": [
              {"value": "Yes", "name": "Yes"},
              {"value": "No, go to question 16", "name": "No"}
            ],
            description: 'For example, a hospital doctor or consultant, district or specialist nurse, community psychiatric nurse, occupational therapist, physiotherapist, audiologist or social worker.'
          },
          {
            type: 'section',
            condition: 'model["6_1"] === "Yes"',
            items: [
              {
                key: '6_2',
                type: 'select',
                title: 'Title',
                "titleMap": [
                  {"value": "Mr", "name": "Mr"},
                  {"value": "Mrs", "name": "Mrs"},
                  {"value": "Miss", "name": "Miss"},
                  {"value": "Ms", "name": "Ms"},
                  {"value": "Dr", "name": "Dr"},
                  {"value": "Rev", "name": "Rev"}
                ]
              },
              {
                key: '6_3',
                title: 'Their name'
              },
              {
                key: '6_4',
                title: 'Their profession or specialist area'
              },
              {
                key: '6_5',
                type: 'textarea',
                title: 'The full address where you see them',
                description: 'For example, the address of the health centre or hospital'
              },
              {
                key: '6_6',
                title: 'Their phone number',
                description: 'Please include the dialling code.'
              },
              {
                key: '6_7',
                title: 'Your hospital record number',
                description: 'You can find this on your appointment card or letter.'
              },
              {
                key: '6_8',
                type: 'textarea',
                title: 'Which of your illnesses or disabilities do you see them about?'
              },
              {
                key: '6_9',
                title: 'How often do you usually see them because of your illnesses or disabilities?'
              },
              {
                key: '6_10',
                type: 'kerpdate',
                mode: 'month',
                title: 'When did you last see them because of your illnesses or disabilities?'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-success',
                helpvalue: '<div>If you have seen more than one professional, please tell us their contact details, what they treat you for and when you last saw them in the Extra information section.</div>'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 6',
        page: 6,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About your illnesses or disabilities and the treatment or help you receive (continued)</h2></div>'
          },
          {
            key: '7_1',
            type: 'radios',
            title: 'Does anyone else help you because of your illnesses or disabilities?',
            "titleMap": [
              {"value": "Yes, please continue below.", "name": "Yes"},
              {"value": "No, please go to question 17.", "name": "No"}
            ],
            description: "For example, a carer, support worker, nurse, friend, neighbour or family member."
          },
          {
            type: 'section',
            condition: 'model["7_1"] === "Yes, please continue below."',
            items: [
              {
                key: '7_2',
                title: 'Their name'
              },
              {
                key: '7_3',
                type: 'textarea',
                title: 'Their full address'
              },
              {
                key: '7_4',
                title: 'Their phone number',
                description: 'Please include the dialling code.'
              },
              {
                key: '7_5',
                type: 'textarea',
                title: 'What help do you get from them?'
              },
              {
                key: '7_6',
                title: 'Their relationship to you'
              },
              {
                key: '7_7',
                title: 'How often do you see them?'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-success',
                helpvalue: '<div>If more than one person helps you, please tell us their name and how they help you in the Extra information section.</div>'
              }
            ]
          },
          {
            type: 'help',
            helpvalue: '<div><h2>About your illnesses or disabilities and the treatment or help you receive (continued)</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'helpTitle',
            helpvalue: '<div>About your GP</div>'
          },
          {
            key: '7_8',
            title: 'Their name',
            description: 'The GP only gives details of medical fact. They do not decide if you can get Attendance Allowance.'
          },
          {
            key: '7_9',
            type: 'textarea',
            title: 'Their full address',
            description: 'If you do not know the name of your GP please give the name of the surgery or health centre.'
          },
          {
            key: '7_10',
            title: 'Their phone number',
            description: 'Please include the dialling code'

          },
          {
            key: '7_11',
            type: 'kerpdate',
            mode: 'month',
            title: 'When did you last see them because of your illnesses or disabilities?'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 7',
        page: 7,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About your illnesses or disabilities and the treatment or help you receive (continued)</h2></div>'
          },
          {
            type: 'template',
            htmlClass: 'well',
            templateUrl: 'modules/forms/scripts/services/forms/AA1A/templates/help7_1.html'
          },
          {
            key: '8_1',
            type: 'radios',
            title: 'I agree to you contacting the people or organisations described in the statement above.',
            "titleMap": [
              {"value": "Yes", "name": "Yes"},
              {"value": "No", "name": "No"}
            ],
            description: "For example, a carer, support worker, nurse, friend, neighbour or family member."
          },
          {
            key: '8_2',
            type: 'kerpdate',
            title: 'Date'
          },
          {
            type: 'help',
            htmlClass: 'helpTitle',
            helpvalue: '<div>Special rules</div>'
          },
          {
            type: 'help',
            htmlClass: 'well',
            helpvalue: '<div>The special rules are for people who have a progressive disease and are not expected to live longer than another six months.</div>'
          },
          {
            key: '8_3',
            title: 'Are you claiming under the special rules?',
            description: 'If you are claiming under the special rules please send this form to us with a DS1500 report. You can get the report from your doctor or specialist.<br>If you have not got your DS1500 report by the time you have filled in the claim form, send the claim form straight away. If you wait, you could lose money. Please send the DS1500 report when you can.<br>Please make sure you answer all the questions above.'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 8',
        page: 8,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About your illnesses or disabilities and the treatment or help you receive (continued)</h2></div>'
          },
          {
            key: '9_1',
            type: 'radios',
            title: 'Do you have any reports about your illnesses or disabilities?',
            "titleMap": [
              {
                "value": "Yes, please send us a copy if you have one.",
                "name": "Yes, please send us a copy if you have one."
              },
              {"value": "No, go to question 21", "name": "No"}
            ],
            description: "These may be from a person who treats you, for example, an occupational therapist, hospital doctor or counsellor. It may be an assessment report, a care plan or something like this."
          },
          {
            key: '9_2',
            type: 'radios',
            title: 'Are you on a waiting list for surgery?',
            "titleMap": [
              {
                "value": "Yes, tell us about this in the table below.",
                "name": "Yes, enter the details of your surgeries below"
              },
              {"value": "No, go to question 22.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["9_2"] === "Yes, tell us about this in the table below."',
            items: [
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>First surgery</div>'
              },
              {
                key: '9_3',
                type: 'kerpdate',
                title: 'The date you were put on the waiting list'
              },
              {
                key: '9_4',
                title: 'What surgery are you going to have?'
              },
              {
                key: '9_5',
                title: 'When is the surgery planned for, if you know this?',
                type: 'kerpdate',
                mode: 'month'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Second surgery</div>'
              },
              {
                key: '9_6',
                type: 'kerpdate',
                title: 'The date you were put on the waiting list'
              },
              {
                key: '9_7',
                title: 'What surgery are you going to have?'
              },
              {
                key: '9_8',
                title: 'When is the surgery planned for, if you know this?',
                type: 'kerpdate',
                mode: 'month'
              }
            ]
          },
          {
            key: '9_9',
            type: 'radios',
            title: 'Have you had any tests for your illnesses or disabilities?',
            "titleMap": [
              {"value": "Yes, tell us about these in the table below.", "name": "Yes"},
              {"value": "No, go to question 23.", "name": "No"}
            ],
            description: "For example, a peak flow, a treadmill exercise, a hearing or sight test or something else."
          },
          {
            type: 'section',
            condition: 'model["9_9"] === "Yes, tell us about these in the table below."',
            items: [
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>First test</div>'
              },
              {
                key: '9_10',
                title: 'Date and type of test'
              },
              {
                key: '9_11',
                type: 'textarea',
                title: 'Results'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Second test</div>'
              },
              {
                key: '9_12',
                title: 'Date and type of test'
              },
              {
                key: '9_13',
                type: 'textarea',
                title: 'Results'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 9',
        page: 9,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About your illnesses or disabilities and the treatment or help you receive (continued)</h2></div>'
          },
          {
            key: '10_1',
            type: 'checkboxes',
            title: 'Where is there a toilet in your home?',
            "titleMap": [
              {"value": "Yes", "name": "Upstairs"}
            ]
          },
          {
            key: '10_2',
            type: 'checkboxes',
            notitle: true,
            "titleMap": [
              {"value": "Yes", "name": "Downstairs"}
            ]
          },
          {
            key: '10_3',
            notitle: true,
            description: 'Other, tell us where'
          },
          {
            key: '10_4',
            type: 'checkboxes',
            title: 'Where do you sleep in your home?',
            "titleMap": [
              {"value": "Yes", "name": "Upstairs"}
            ]
          },
          {
            key: '10_5',
            type: 'checkboxes',
            notitle: true,
            "titleMap": [
              {"value": "Yes", "name": "Downstairs"}
            ]
          },
          {
            key: '10_6',
            notitle: true,
            description: 'Other, tell us where'
          },
          {
            type: 'help',
            htmlClass: 'helpTitle',
            helpvalue: '<div>Please list any aids or adaptations you use. Do you use any aids or adaptations to help you do things. For example:<ul><li>a hoist, monkey pole or bed-raiser may help you get out of bed</li><li>a commode, raised toilet seat or rails may help you with your toilet needs</li><li>bath rails, a shower seat or a hoist may help you bath or shower</li><li>a long-handled shoehorn, button hook, zip pull or sock aid may help you dress</li><li>a stairlift, raised chair, wheelchair or rails may help you move about indoors</li><li>a walking stick, walking frame, crutches or artificial limbs may help you get around</li><li>special cutlery or a feeding cup may help you eat and drink, or</li><li>a hearing aid, textphone, magnifier or braille terminal may help you communicate.</li></ul>Do you need help to use the aids or adaptations, and if you do, what help do you get from another person?</div>'
          },
          {
            type: 'help',
            helpvalue: '<div><h4>First help.</h4></div>'
          },
          {
            key: '10_7',
            title: 'Aids and adaptations'
          },
          {
            key: '10_8',
            title: 'Has it been prescribed by a health care professional, for example an occupational therapist?'
          },
          {
            key: '10_9',
            title: 'How does this help you?'
          },
          {
            key: '10_10',
            title: 'What difficulty do you have using this aids or adaptation?'
          },
          {
            type: 'help',
            helpvalue: '<div><h4>Second help.</h4></div>'
          },
          {
            key: '10_11',
            title: 'Aids and adaptations'
          },
          {
            key: '10_12',
            title: 'Has it been prescribed by a health care professional, for example an occupational therapist?'
          },
          {
            key: '10_13',
            title: 'How does this help you?'
          },
          {
            key: '10_14',
            title: 'What difficulty do you have using this aids or adaptation?'
          },
          {
            type: 'help',
            helpvalue: '<div><h4>Third help.</h4></div>'
          },
          {
            key: '10_15',
            title: 'Aids and adaptations'
          },
          {
            key: '10_16',
            title: 'Has it been prescribed by a health care professional, for example an occupational therapist?'
          },
          {
            key: '10_17',
            title: 'How does this help you?'
          },
          {
            key: '10_18',
            title: 'What difficulty do you have using this aids or adaptation?'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 10',
        page: 10,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Care needs</h2></div>'
          },
          {
            key: '11_1',
            type: 'memorabledate',
            validationMessage: 'The date is incorrect',
            title: 'Please tell us the date your care needs started.',
            description: 'Normally you can only get Attendance Allowance if you have had difficulty or needed help for six months. If you cannot remember the exact date, tell us roughly when this was.'
          },
          {
            type: 'template',
            htmlClass: 'well',
            templateUrl: 'modules/forms/scripts/services/forms/AA1A/templates/help10_1.html'
          },
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the day</h2></div>'
          },
          {
            key: '11_2',
            title: 'Do you usually have difficulty or do you need help getting out of bed in the morning or getting into bed at night?',
            type: 'radios',
            "titleMap": [
              {"value": "Yes, tick the boxes below that apply to you.", "name": "Yes"},
              {"value": "No, go to question 27", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["11_2"] === "Yes, tick the boxes below that apply to you."',
            items: [
              {
                key: '11_3',
                title: 'I have difficulty:',
                type: 'checkboxes',
                "titleMap": [
                  {"value": "Yes", "name": "getting into bed"}
                ]
              },
              {
                key: '11_4',
                type: 'checkboxes',
                notitle: true,
                "titleMap": [
                  {"value": "Yes", "name": "getting out of bed"}
                ]
              },
              {
                key: '11_5',
                title: 'I need help:',
                type: 'checkboxes',
                "titleMap": [
                  {"value": "Yes", "name": "getting into bed"}
                ]
              },
              {
                key: '11_6',
                type: 'checkboxes',
                notitle: true,
                "titleMap": [
                  {"value": "Yes", "name": "getting out of bed"}
                ]
              },
              {
                key: '11_7',
                title: 'I have difficulty concentrating or motivating myself and need:',
                type: 'checkboxes',
                notitle: true,
                "titleMap": [
                  {"value": "Yes", "name": "encouraging to get out of bed in the morning"}
                ]
              },
              {
                key: '11_8',
                type: 'checkboxes',
                notitle: true,
                "titleMap": [
                  {"value": "Yes", "name": "encouraging to go to bed at night"}
                ]
              },
              {
                key: '12_1',
                title: 'Is there anything else you want to tell us about the difficulty you have or the help you need getting in or out of bed?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes", "name": "Yes"},
                  {"value": "No, go to question 27", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["12_1"] === "Yes"',
                items: [
                  {
                    title: 'Tell us about the difficulty you have or the help you need getting in or out of bed',
                    description: 'Talk about any pain or discomfort you suffer when getting in or out of bed. Can you get in and out of bed repeatedly? If not, explain why.',
                    key: '12_2',
                    type: 'textarea'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 11',
        page: 11,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the day (continued)</h2></div>'
          },
          {
            key: '12_3',
            title: 'Do you usually have difficulty or do you need help with your toilet needs?',
            type: 'radios',
            description: 'This means things like getting to the toilet, or using the toilet, commode, bedpan or bottle. It also means using or changing incontinence aids, or a catheter or cleaning yourself.',
            "titleMap": [
              {"value": "Yes, continue below.", "name": "Yes"},
              {"value": "No, go to question 28.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["12_3"] === "Yes, continue below."',
            items: [
              {
                key: '12_4',
                title: 'How often each day do you have difficulty with your toilet needs?'
              },
              {
                key: '12_5',
                title: 'How often each day do you have difficulty with your incontinence needs?'
              },
              {
                key: '12_6',
                title: 'How often each day do you need help with your toilet needs?'
              },
              {
                key: '12_7',
                title: 'How often each day do you need help with your incontinence needs?'
              },
              {
                key: '12_8',
                title: 'How often each day do you have difficulty concentrating or motivating yourself and need encouraging with your toilet needs?'
              },
              {
                key: '12_9',
                title: 'How often each day do you have difficulty concentrating or motivating yourself and need encouraging with your incontinence needs?'
              },
              {
                key: '13_1',
                title: 'Is there anything else you want to tell us about the difficulty you have or the help you need with your toilet needs?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, tell us in the box below", "name": "Yes"},
                  {"value": "No, go to question 28.", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["13_1"] === "Yes, tell us in the box below"',
                items: [
                  {
                    title: 'Tell us about the difficulty you have or the help you need with your toilet needs',
                    key: '13_2',
                    type: 'textarea',
                    description: 'Describe any problems you have with managing your toilet needs. Include all information on this form that describes your difficulties. Please do not feel embarrassed by this. The details you give will help the Department for Work and Pensions to understand your situation fully.'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 12',
        page: 12,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the day (continued)</h2></div>'
          },
          {
            key: '13_3',
            title: 'Do you usually have difficulty or do you need help with washing, bathing, showering or looking after your appearance?',
            type: 'radios',
            description: 'This means things like getting into or out of the bath or shower, checking your appearance or looking after your personal hygiene. Personal hygiene includes things like cleaning your teeth, washing your hair, shaving or something like this.',
            "titleMap": [
              {"value": "Yes, please continue below.", "name": "Yes"},
              {"value": "No, go to question 29.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["13_3"] === "Yes, please continue below."',
            items: [
              {
                key: '13_4',
                title: 'How often each day do you have difficulty looking after your appearance?'
              },
              {
                key: '13_5',
                title: 'How often each day do you have difficulty getting in and out of the bath?'
              },
              {
                key: '13_6',
                title: 'How often each day do you have difficulty washing and drying myself or looking after your personal hygiene?'
              },
              {
                key: '13_7',
                title: 'How often each day do you have difficulty using a shower?'
              },
              {
                key: '13_8',
                title: 'How often each day do you need help looking after your appearance?'
              },
              {
                key: '13_9',
                title: 'How often each day do you need help getting in and out of the bath?'
              },
              {
                key: '13_10',
                title: 'How often each day do you need help washing and drying myself or looking after your personal hygiene?'
              },
              {
                key: '13_11',
                title: 'How often each day do you need help using a shower?'
              },
              {
                key: '14_1',
                title: 'How often each day do you have difficulty concentrating or motivating yourself and need encouraging to look after your appearance?'
              },
              {
                key: '14_2',
                title: 'How often each day do you have difficulty concentrating or motivating yourself and need encouraging or reminding about washing, bathing, showering, drying or looking after your personal hygiene'
              },
              {
                key: '14_3',
                title: 'Is there anything else you want to tell us about the difficulty you have or the help you need washing, bathing, showering or looking after your appearance or personal hygiene?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, please continue below.", "name": "Yes"},
                  {"value": "No, go to question 29.", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["14_3"] === "Yes, please continue below."',
                items: [
                  {
                    title: 'Tell us about the difficulty you have or the help you need washing, bathing, showering or looking after your appearance or personal hygiene?',
                    key: '14_4',
                    type: 'textarea',
                    description: 'Describe the difficulties that you have in detail. Do these activities cause you pain or discomfort? Do you find it hard to motivate yourself to look after your personal hygiene? Have you had any accidents whilst showering or bathing? If this varies, explain how.'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 13',
        page: 13,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the day (continued)</h2></div>'
          },
          {
            key: '14_5',
            title: 'Do you usually have difficulty or do you need help with dressing or undressing',
            type: 'radios',
            "titleMap": [
              {"value": "Yes, please continue below.", "name": "Yes"},
              {"value": "No, go to question 30.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["14_5"] === "Yes, please continue below."',
            items: [
              {
                key: '14_6',
                title: 'How often each day do you have difficulty with putting on or fastening clothes or footwear?'
              },
              {
                key: '14_7',
                title: 'How often each day do you have difficulty with taking off clothes or footwear?'
              },
              {
                key: '14_8',
                title: 'How often each day do you have difficulty with choosing the appropriate clothes?'
              },
              {
                key: '14_9',
                title: 'How often each day do you need help with putting on or fastening clothes or footwear'
              },
              {
                key: '14_10',
                title: 'How often each day do you need help with taking off clothes or footwear?'
              },
              {
                key: '14_11',
                title: 'How often each day do you need help with choosing the appropriate clothes?'
              },
              {
                key: '15_1',
                title: 'How often each day do you have difficulty concentrating or motivating yourself and need encouraging to get dressed or undressed?'
              },
              {
                key: '15_2',
                title: 'How often each day do you have difficulty concentrating or motivating yourself and need reminding to change your clothes?'
              },
              {
                key: '15_3',
                title: 'Is there anything else you want to tell us about the difficulty you have or the help you need dressing or undressing?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, tell us in the box below.", "name": "Yes"},
                  {"value": "No, go to question 30.", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["15_3"] === "Yes, tell us in the box below."',
                items: [
                  {
                    title: 'Tell us about the difficulty you have or the help you need dressing or undressing?',
                    key: '15_4',
                    type: 'textarea',
                    description: 'For example, you may get breathless, feel pain or it may take you a long time. Describe the difficulties that you have in detail. Does getting dressed or undressed cause you any pain or discomfort? Do you find it hard to motivate yourself to get dressed or undressed? Have you had any accidents when dressing or undressing? If this varies, explain how.'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 14',
        page: 14,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the day (continued)</h2></div>'
          },
          {
            key: '15_5',
            title: 'Do you usually have difficulty or do you need help with moving around indoors?',
            type: 'radios',
            description: 'By indoors we mean anywhere inside, not just the place where you live.',
            "titleMap": [
              {"value": "Yes, tick the boxes below that apply to you.", "name": "Yes"},
              {"value": "No, go to question 31.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["15_5"] === "Yes, tick the boxes below that apply to you."',
            items: [
              {
                key: '15_6',
                title: 'I have difficulty walking around indoors'
              },
              {
                key: '15_7',
                title: 'I have difficulty going up or down stairs'
              },
              {
                key: '15_8',
                title: 'I have difficulty getting in or out of a chair'
              },
              {
                key: '15_9',
                title: 'I have difficulty transferring to and from a wheelchair'
              },
              {
                key: '15_10',
                title: 'I need help walking around indoors'
              },
              {
                key: '15_11',
                title: 'I need help going up or down stairs'
              },
              {
                key: '15_12',
                title: 'I need help getting in or out of a chair'
              },
              {
                key: '15_13',
                title: 'I need help transferring to and from a wheelchair '
              },
              {
                key: '16_1',
                title: 'I have difficulty concentrating or motivating myself and need encouraging or reminding to move around indoors '
              },
              {
                key: '16_2',
                title: 'Is there anything else you want to tell us about the difficulty you have or the help you need with moving around indoors?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, tell us in the box below.", "name": "Yes"},
                  {"value": "No, go to question 31.", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["16_2"] === "Yes, tell us in the box below."',
                items: [
                  {
                    title: 'Tell us about the difficulty you have or the help you need with moving around indoors',
                    key: '16_3',
                    type: 'textarea',
                    description: 'For example, you may hold on to furniture to get about or it may take you a long time. If you experience pain or discomfort when moving around indoors describe where the pain is and how bad it gets. If you cannot move around without someone helping you mention that here. If you have trouble motivating yourself to move around because of anxiety, depression or mental illness talk about that here.'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 15',
        page: 15,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the day (continued)</h2></div>'
          },
          {
            key: '16_4',
            type: 'checkboxes',
            title: 'Do you fall or stumble because of your illnesses or disabilities?',
            "titleMap": [
              {"value": "Yes", "name": "Fall"}
            ]
          },
          {
            key: '16_5',
            type: 'checkboxes',
            notitle: true,
            "titleMap": [
              {"value": "Yes", "name": "Stumble"}
            ]
          },
          {
            key: '16_6',
            type: 'checkboxes',
            notitle: true,
            "titleMap": [
              {"value": "Yes", "name": "No"}
            ]
          },
          {
            type: 'help',
            htmlClass: 'alert alert-success',
            helpvalue: "<div>For example, you may fall or stumble because you have weak muscles, stiff joints or your knee gives way, or you may have problems with your sight, or you may faint, feel dizzy, blackout or have a fit.</div>"
          },
          {
            type: 'section',
            condition: 'model["16_5"].indexOf("Yes") > -1 || model["16_4"].indexOf("Yes") > -1',
            items: [
              {
                key: '16_7',
                title: 'What happens when you fall or stumble?',
                description: 'Tell us why you fall or stumble and if you hurt yourself.',
                type: 'textarea'
              },
              {
                key: '16_8',
                type: 'radios',
                title: 'Have you been referred to a Falls Clinic?',
                "titleMap": [
                  {"value": "Yes", "name": "Yes"},
                  {"value": "No", "name": "No"}
                ]
              },
              {
                key: '17_1',
                type: 'radios',
                title: 'Do you need help to get up after a fall?',
                description: 'Tell us if you have difficulty getting up after a fall and the help you need from someone else.',
                "titleMap": [
                  {"value": "Yes, tell us in the box below.", "name": "Yes"},
                  {"value": "No", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["17_1"] === "Yes, tell us in the box below."',
                items: [
                  {
                    key: '17_2',
                    title: 'Tell us if you have difficulty getting up after a fall and the help you need from someone else.',
                    type: 'textarea'

                  }
                ]
              },
              {
                key: '17_3',
                title: 'When did you last fall or stumble?',
                type: 'kerpdate',
                mode: 'month',
                description: 'If you don’t know the exact date, tell us roughly when this was.'
              },
              {
                key: '17_4',
                title: 'How often did you fall or stumble last month?'
              },
              {
                key: '17_5',
                title: 'How often did you fall or stumble last year?',
                description: 'Tell us roughly how many times you have fallen or stumbled in the last month or year.'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 16',
        page: 16,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the day (continued)</h2></div>'
          },
          {
            key: '17_6',
            title: 'Do you usually have difficulty or do you need help with cutting up food, eating or drinking?',
            type: 'radios',
            description: 'This means things like getting food or drink into your mouth or identifying food on your plate.',
            "titleMap": [
              {"value": "Yes, please continue below.", "name": "Yes"},
              {"value": "No, go to question 33.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["17_6"] === "Yes, please continue below."',
            items: [
              {
                key: '17_7',
                title: 'How often each day do you have difficulty eating or drinking?'
              },
              {
                key: '17_8',
                title: 'How often each day do you have difficulty with cutting up food on your plate?'
              },
              {
                key: '17_9',
                title: 'How often each day do you need help with eating or drinking?'
              },
              {
                key: '17_10',
                title: 'How often each day do you need help with cutting up food on your plate'
              },
              {
                key: '17_11',
                title: 'How often each day do you have difficulty concentrating or motivating yourself and need encouraging or reminding to eat or drink?'
              },
              {
                key: '18_1',
                title: 'Is there anything else you want to tell us about the difficulty you have or the help you need with cutting up food, eating or drinking?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, tell us in the box below.", "name": "Yes"},
                  {"value": "No, go to question 33.", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["18_1"] === "Yes, tell us in the box below."',
                items: [
                  {
                    title: 'Tell us about the difficulty you have or the help you need with cutting up food, eating or drinking',
                    key: '18_2',
                    type: 'textarea'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 17',
        page: 17,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the day (continued)</h2></div>'
          },
          {
            key: '18_3',
            title: 'Do you usually have difficulty or do you need help with taking your medicines or with your medical treatment?',
            type: 'radios',
            description: 'This means things like injections, an inhaler, eye drops, physiotherapy, oxygen therapy, speech therapy, monitoring treatment, coping with side effects, and help from mental-health services. It includes handling medicine and understanding which medicines to take, how much to take and when to take them.',
            "titleMap": [
              {"value": "Yes, please continue below.", "name": "Yes"},
              {"value": "No, go to question 34.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["18_3"] === "Yes, please continue below."',
            items: [
              {
                key: '18_4',
                title: 'How often each day do you have difficulty taking your medication?'
              },
              {
                key: '18_5',
                title: 'How often each day do you have difficulty with your treatment or therapy?'
              },
              {
                key: '18_6',
                title: 'How often each day do you need help taking your medication?'
              },
              {
                key: '18_7',
                title: 'How often each day do you need help with your treatment or therapy'
              },
              {
                key: '18_8',
                title: 'How often each day do you have difficulty concentrating or motivating yourself and need encouraging or reminding to take your medication?'
              },
              {
                key: '18_9',
                title: 'How often each day do you have difficulty concentrating or motivating yourself and need encouraging or reminding about your treatment or therapy?'
              },
              {
                key: '19_1',
                title: 'Is there anything else you want to tell us about the difficulty you have or the help you need taking your medication or with medical treatment?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, tell us in the box below.", "name": "Yes"},
                  {"value": "No, go to question 34", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["19_1"] === "Yes, tell us in the box below."',
                items: [
                  {
                    title: 'Tell us about the difficulty you have or the help you need taking your medication or with medical treatment?',
                    key: '19_2',
                    type: 'textarea'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 18',
        page: 18,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the day (continued)</h2></div>'
          },
          {
            key: '19_3',
            title: 'Do you usually need help from another person to communicate with other people?',
            type: 'radios',
            description: 'For example, you may have a mental-health problem, learning disability, sight, hearing or speech difficulty and need help to communicate. Please answer as if using your normal aids, such as glasses or a hearing aid.',
            "titleMap": [
              {"value": "Yes, please tick the boxes that apply to you.", "name": "Yes"},
              {"value": "No, go to question 35.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["19_3"] === "Yes, please tick the boxes that apply to you."',
            items: [
              {
                key: '19_4',
                title: 'I have difficulty understanding people I do not know well'
              },
              {
                key: '19_5',
                title: 'I have difficulty being understood by people who do not know me well'
              },
              {
                key: '19_6',
                title: 'I have difficulty concentrating or remembering things'
              },
              {
                key: '19_7',
                title: 'I have difficulty answering or using the phone'
              },
              {
                key: '19_8',
                title: 'I have difficulty reading letters, filling in forms, replying to mail'
              },
              {
                key: '19_9',
                title: 'I have difficulty asking for help when I need it'
              },
              {
                key: '20_1',
                title: 'I need help understanding people I do not know well'
              },
              {
                key: '20_2',
                title: 'I need help being understood by people who do not know me well'
              },
              {
                key: '20_3',
                title: 'I need help concentrating or remembering things'
              },
              {
                key: '20_4',
                title: 'I need help answering or using the phone'
              },
              {
                key: '20_5',
                title: 'I need help reading letters, filling in forms, replying to mail'
              },
              {
                key: '20_6',
                title: 'I need help asking for help when I need it'
              },
              {
                key: '20_7',
                title: 'Is there anything else you want to tell us about the difficulty you have or the help you need from another person to communicate with other people?',
                description: 'For example, you use BSL (British Sign Language).',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, tell us about your communication needs in the box below.", "name": "Yes"},
                  {"value": "No, go to question 35.", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["20_7"] === "Yes, tell us about your communication needs in the box below."',
                items: [
                  {
                    title: 'Tell us about your communication needs in the box below.',
                    key: '20_8',
                    type: 'textarea',
                    description: 'Give examples of when you have needed help communicating and how someone has helped you. Talk about any aids you use such as hearing aids.'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 19',
        page: 19,
        items: [
          {
            key: '20_9',
            title: 'How many days a week do you have difficulty or need help with the care needs you have told us about?'
          },
          {
            key: '21_1',
            title: 'Do you usually need help from another person to actively take part in hobbies, interests, social or religious activities?',
            description: 'We need this information because we can take into account the help you need or would need to take part in these activities, as well as the other help you need during the day',
            type: 'radios',
            "titleMap": [
              {"value": "Yes, please continue below.", "name": "Yes"},
              {"value": "No, go to question 37.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["21_1"] === "Yes, please continue below."',
            items: [
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Tell us about the activities and the help you need from another person at home</div>'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Activity 1</div>'
              },
              {
                key: '21_2',
                title: 'What you do or would like to do.'
              },
              {
                key: '21_3',
                title: 'What help do you need or would you need from another person to do this?'
              },
              {
                key: '21_4',
                title: 'How often do you or would you do this?'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Activity 2</div>'
              },
              {
                key: '21_5',
                title: 'What you do or would like to do.'
              },
              {
                key: '21_6',
                title: 'What help do you need or would you need from another person to do this?'
              },
              {
                key: '21_7',
                title: 'How often do you or would you do this?'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Activity 3</div>'
              },
              {
                key: '21_8',
                title: 'What you do or would like to do.'
              },
              {
                key: '21_9',
                title: 'What help do you need or would you need from another person to do this?'
              },
              {
                key: '21_10',
                title: 'How often do you or would you do this?'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Tell us about the activities and the help you need from another person when you go out.</div>'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Activity 1</div>'
              },
              {
                key: '21_11',
                title: 'What you do or would like to do.'
              },
              {
                key: '21_12',
                title: 'What help do you need or would you need from another person to do this?'
              },
              {
                key: '21_13',
                title: 'How often do you or would you do this?'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Activity 2</div>'
              },
              {
                key: '21_14',
                title: 'What you do or would like to do.'
              },
              {
                key: '21_15',
                title: 'What help do you need or would you need from another person to do this?'
              },
              {
                key: '21_16',
                title: 'How often do you or would you do this?'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-success',
                helpvalue: '<div>If you need some more space to tell us about your hobbies, interests, social or religious activities please continue in the Extra information section.</div>'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 20',
        page: 20,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the day (continued)</h2></div>'
          },
          {
            key: '22_1',
            title: 'Do you usually need someone to keep an eye on you?',
            type: 'radios',
            description: 'For example, you may have a mental-health problem, learning disability, sight, hearing or speech difficulty and need supervision.',
            "titleMap": [
              {"value": "Yes, please tick the boxes that apply to you.", "name": "Yes"},
              {"value": "No, go to question 38.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["22_1"] === "Yes, please tick the boxes that apply to you."',
            items: [
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Please tell us why you need supervision.</div>'
              },
              {
                key: '22_2',
                title: 'To prevent danger to myself or others.'
              },
              {
                key: '22_3',
                title: 'I am not aware of common dangers.'
              },
              {
                key: '22_4',
                title: 'I am at risk of neglecting myself.'
              },
              {
                key: '22_5',
                title: 'I am at risk of harming myself.'
              },
              {
                key: '22_6',
                title: 'I may wander.'
              },
              {
                key: '22_7',
                title: 'To discourage antisocial or aggressive behaviour.'
              },
              {
                key: '22_8',
                title: 'I may have fits, dizzy spells or blackouts.'
              },
              {
                key: '22_9',
                title: 'I may get confused.'
              },
              {
                key: '22_10',
                title: 'I may hear voices or experience thoughts that disrupt my thinking.'
              },
              {
                key: '22_11',
                title: 'How long can you be safely left for at a time?'
              },
              {
                key: '22_12',
                title: 'Is there anything else you want to tell us about the supervision you need from another person?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, tell us in the box below.", "name": "Yes"},
                  {"value": "No, go to question 38", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["22_12"] === "Yes, tell us in the box below."',
                items: [
                  {
                    title: 'Tell us about your supervision needs in the box below.',
                    key: '22_13',
                    type: 'textarea',
                    description: 'Talk about why you need supervising, and how long you can be left alone for. Describe any incidents that have happened when you have been alone. Have you caused harm to yourself or others?'
                  }
                ]
              },
              {
                key: '22_14',
                title: 'How many days a week do you need someone to keep an eye on you?'
              }
            ]
          }

        ]
      },
      {
        type: 'section',
        condition: 'model.page === 21',
        page: 21,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the night</h2></div>'
          },
          {
            key: '23_1',
            title: 'Do you usually have difficulty or need help during the night?',
            description: 'This means things like settling, getting into position to sleep, being propped up or getting your bedclothes back on the bed if they fall off, getting to the toilet, using the toilet, using a commode, bedpan or bottle, getting to and taking the tablets or medicines prescribed for you and having any treatment or therapy.',
            type: 'radios',
            "titleMap": [
              {"value": "Please continue below", "name": "Yes"},
              {"value": "No, go to question 40", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["23_1"] === "Please continue below"',
            items: [
              {
                type: 'help',
                htmlClass: 'well',
                helpvalue: '<div>Please tell us what help you need, how often and how long each time you need this help for.</div>'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>I have difficulty or need help turning over or changing position in bed</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: ["23_2"]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: [{
                      key: "23_3",
                      title: 'For how many minutes each time?'
                    }]
                  }
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>I have difficulty or need help sleeping comfortably</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: ["23_4"]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: [{
                      key: "23_5",
                      title: 'For how many minutes each time?'
                    }]
                  }
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>I have difficulty or need help with my toilet needs</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: ["23_6"]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: [{
                      key: "23_7",
                      title: 'For how many minutes each time?'
                    }]
                  }
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>I have difficulty or need help with my incontinence needs</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: ["23_8"]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: [{
                      key: "23_9",
                      title: 'For how many minutes each time?'
                    }]
                  }
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>I have difficulty or need help taking my medication</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: ["23_10"]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: [{
                      key: "23_11",
                      title: 'For how many minutes each time?'
                    }]
                  }
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>I have difficulty or need help with treatment or therapy</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: ["23_12"]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: [{
                      key: "23_13",
                      title: 'For how many minutes each time?'
                    }]
                  }
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>I have difficulty concentrating or motivating myself and need encouraging or reminding about my toilet or incontinence needs</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: ["23_14"]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: [{
                      key: "23_15",
                      title: 'For how many minutes each time?'
                    }]
                  }
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>I have difficulty concentrating or motivating myself and need encouraging or reminding about medication or medical treatment</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: ["23_16"]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-6 subLabel",
                    items: [{
                      key: "23_17",
                      title: 'For how many minutes each time?'
                    }]
                  }
                ]
              },
              {
                key: '23_18',
                title: 'Is there anything else you want to tell us about the difficulty you have or the help you need during the night?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, tell us in the box below.", "name": "Yes"},
                  {"value": "No, go to question 40.", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["23_18"] === "Yes, tell us in the box below."',
                items: [
                  {
                    title: 'Tell us about the difficulty you have or the help you need during the night.',
                    key: '23_19',
                    type: 'textarea',
                    description: 'Describe examples of when you have needed help or experienced difficulty during the night. Talk about any problems with sleeping comfortably, your toilet or incontinence needs, and taking medication.'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 22',
        page: 22,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with your care needs during the night (continued)</h2></div>'
          },
          {
            title: 'How many nights a week do you have difficulty or need help with your care needs?',
            key: '24_1'
          },
          {
            key: '24_2',
            title: 'Do you usually need someone to watch over you?',
            description: 'For example, you may have a mental-health problem, learning disability, sight, hearing or speech difficulty and need another person to be awake to watch over you.',
            type: 'radios',
            "titleMap": [
              {"value": "Yes, please tick the boxes that apply to you.", "name": "Yes"},
              {"value": "No, go to question 43.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["24_2"] === "Yes, please tick the boxes that apply to you."',
            items: [
              {
                type: 'help',
                htmlClass: 'well',
                helpvalue: '<div>Please tell us why you need watching over</div>'
              },
              {
                key: '24_3',
                title: 'To prevent danger to myself or others'
              },
              {
                key: '24_4',
                title: 'I am not aware of common dangers'
              },
              {
                key: '24_5',
                title: 'I am at risk of harming myself'
              },
              {
                key: '24_6',
                title: 'I may wander'
              },
              {
                key: '24_7',
                title: 'To discourage antisocial or aggressive behaviour'
              },
              {
                key: '24_8',
                title: 'I may get confused'
              },
              {
                key: '24_9',
                title: 'I may hear voices or experience thoughts that disrupt my thinking'
              },
              {
                key: '24_10',
                title: 'How many times a night does another person need to be awake to watch over you?'
              },
              {
                key: '24_11',
                title: 'How long on average does another person need to be awake to watch over you at night? (minutes)'
              },
              {
                key: '24_12',
                title: 'Is there anything else you want to tell us about why you need someone to watch over you?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, tell us in the box below.", "name": "Yes"},
                  {"value": "No, go to question 42.", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["24_12"] === "Yes, tell us in the box below."',
                items: [
                  {
                    title: 'Tell us more about why you need someone to watch over you.',
                    key: '24_13',
                    type: 'textarea',
                    description: 'Describe why you need someone to watch over you at night. Talk about any incidents that have happened when you have been alone at night, for example, hurting yourself, experiencing confusion or placing others in danger.'
                  }
                ]
              },
              {
                key: '24_14',
                title: 'How many nights a week do you need someone to watch over you?'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 23',
        page: 23,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Help with care needs</h2></div>'
          },
          {
            key: '25_1',
            type: 'textarea',
            title: 'Please tell us anything else you think we should know about the difficulty you have or the help you need.',
            description: 'If you need some more space to tell us about the help you need or the difficulty you have with your care needs, please continue in the Extra information section.'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 24',
        page: 24,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About time spent in hospital, a care home or a similar place</h2></div>'
          },
          {
            key: '26_1',
            title: 'Are you in hospital, a care home or similar place now?',
            description: 'For example, a residential care home, nursing home, hospice or similiar place.<br>The DWP need to know if:<ul><li>you are in a hospital, a care home or similar place when you make your claim, and</li><li>the local authority or NHS pay anything towards the cost of your stay.</li></ul>If you are awarded Attendance Allowance when you are in hospital, a care home or a similar place, the DWP cannot pay you until you come out. But if you are a private patient or resident, paying for your stay without help from public funds, the DWP will be able to pay you.<p>The DWP may still be able to pay you if you are claiming under the special rules and you are in a hospice.</p>',
            type: 'radios',
            "titleMap": [
              {"value": "Yes, tell us when you went in.", "name": "Yes"},
              {"value": "No, go to question 45.", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["26_1"] === "Yes, tell us when you went in."',
            items: [
              {
                key: '26_2',
                title: 'Tell us when you went in',
                type: 'kerpdate',
                mode: 'month'
              },
              {
                key: '26_3',
                title: 'Please tell us the full name and address of the place where you are staying.',
                type: 'textarea'
              },
              {
                key: '26_4',
                title: 'If you are in hospital, why did you go into hospital?',
                type: 'textarea'
              },
              {
                key: '26_5',
                title: 'Does a local authority, health authority, education authority or a government department give you, or the place where you stay, any money towards the costs of your stay?',
                type: 'radios',
                "titleMap": [
                  {
                    "value": "Yes. Does the local authority, NHS trust, primary care trust or a government department pay any of the costs for you to live there?",
                    "name": "Yes"
                  },
                  {"value": "No, go to question 45", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["26_5"] === "Yes. Does the local authority, NHS trust, primary care trust or a government department pay any of the costs for you to live there?"',
                items: [
                  {
                    key: '26_6',
                    title: 'Which authority or government department pays?'
                  }
                ]
              },
              {
                key: '26_7',
                title: 'Have you come out of hospital, a care home or similar place in the past six weeks?',
                type: 'radios',
                "titleMap": [
                  {"value": "Yes, tell us when you went in.", "name": "Yes"},
                  {"value": "No, go to question 46.", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["26_7"] === "Yes, tell us when you went in."',
                items: [
                  {
                    key: '26_8',
                    title: 'Tell us when you went in',
                    type: 'kerpdate',
                    mode: 'month'
                  },
                  {
                    key: '26_9',
                    title: 'Tell us when you came out',
                    type: 'kerpdate',
                    mode: 'month'
                  },
                  {
                    key: '26_10',
                    title: 'Please tell us the full name and address of the place where you were staying',
                    type: 'textarea'
                  },
                  {
                    key: '26_11',
                    title: 'If you have been in hospital, why did you go into hospital?',
                    type: 'textarea'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 25',
        page: 25,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About time spent in hospital, Constant Attendance Allowance and How we pay you</h2></div>'
          },
          {
            key: '27_1',
            type: 'checkboxes',
            title: 'Please tick the box if you are getting or waiting to hear about:',
            "titleMap": [
              {"value": "Yes", "name": "War Pension Constant Attendance Allowance"}
            ]
          },
          {
            key: '27_2',
            type: 'checkboxes',
            notitle: true,
            "titleMap": [
              {"value": "No", "name": "Industrial Injuries Disablement Benefit Constant Attendance Allowance"}
            ]
          },
          {
            type: 'help',
            htmlClass: 'helpTitle',
            helpvalue: '<div>How we pay you</div>'
          },
          {
            type: 'help',
            helpvalue: '<div><h3>Please tell us the account details below.</h3><p class="well">It’s very important you fill in all the boxes correctly, including the building society roll or reference number, if you have one. If you tell us the wrong account details your payment may be delayed or you may lose money.</p></div>'
          },
          {
            key: '27_3',
            title: 'Name of the account holder',
            description: 'Please write the name of the account holder exactly as it is shown on the chequebook or statement.'
          },
          {
            key: '27_4',
            title: 'Full name of bank or building society'
          },
          {
            type: 'help',
            htmlClass: 'helpTitle',
            helpvalue: '<div>Sort code</div>'
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-4",
                items: [{
                  notitle: true,
                  key: "27_5"
                }]
              },
              {
                type: "section",
                htmlClass: "col-xs-4",
                items: [{
                  notitle: true,
                  key: "27_6"
                }]
              },
              {
                type: "section",
                htmlClass: "col-xs-4",
                items: [{
                  notitle: true,
                  key: "27_7"
                }]
              }
            ]
          },
          {
            type: 'help',
            htmlClass: 'alert alert-success',
            helpvalue: '<div>Please tell us all 6 numbers, for example: 12-34-56.</div>'
          },
          {
            key: '27_8',
            title: 'Account number',
            description: 'Most account numbers are 8 numbers long.'
          },
          {
            key: '27_9',
            title: 'Building society roll or reference number',
            description: 'If you are using a building society account you may need to tell us a roll or reference number. This may be made up of letters and numbers, and may be up to 18 characters long. If you are not sure if the account has a roll or reference number, ask the building society.'
          },
          {
            key: '27_10',
            title: 'You may get other benefits and entitlements we do not pay into an account. Do you want us to pay them into the account above?'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 26',
        page: 26,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Statement from someone who knows you</h2></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><h3>Please note, this statement does not have to be filled in.</h3><p class="well">If you do want this statement to be filled in, the best person to do it is the one who is most involved with your treatment or care. This may be someone you have already told us about on this form.<p>If you are signing this form on behalf of the disabled person, please get someone else to fill in this section.</p></div>'
          },
          {
            key: '28_1',
            title: 'How often do you see the person this form is about?'
          },
          {
            key: '28_2',
            title: 'Please tell us what their illnesses and disabilities are, and how they are affected by them.',
            type: 'textarea'
          },
          {
            key: '28_3',
            title: 'Tell us your job, profession or relationship to the person this form is about.'
          },
          {
            key: '28_4',
            title: 'Your full name'
          },
          {
            key: '28_5',
            title: 'Your full address',
            type: 'textarea'
          },
          {
            key: '28_6',
            title: 'Daytime phone number',
            type: 'textarea',
            description: 'Please provide a phone number we can use to contact you during the day, or leave a message.'
          },
          {
            key: '28_7',
            title: 'Date',
            type: 'kerpdate'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 27',
        page: 27,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Extra information</h2></div>'
          },
          {
            key: '29_1',
            type: 'textarea',
            title: 'Please tell us anything else you think we should know about your claim.'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 28',
        page: 28,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Declaration</h2></div>'
          },
          {
            type: 'help',
            helpvalue: '<div>We cannot pay any benefit until you have signed the declaration, and returned the form to us. Please return the signed form straight away.<br><br><b>I declare that the information I have given on this form is correct and complete as far as I know and believe.<br><br>I understand that if I knowingly give false information, I may be liable to prosecution or other action.<br><br>I understand that I must promptly tell the office that pays my Attendance Allowance of anything that may affect my entitlement to, or the amount of, that benefit.<br><br>I understand that the Department for Work and Pensions may use the information which it has now or may get in the future to decide whether I am entitled to:<ul><li>the benefit I am claiming</li><li>any other benefit I have claimed</li><li>any other benefit I may claim in the future.</li></ul>This is my claim for Attendance Allowance.</div>'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 29',
        page: 29,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>What to do now</h2></div>'
          },
          {
            type: 'template',
            templateUrl: 'modules/forms/scripts/services/forms/AA1A/templates/help29_1.html'
          },
          {
            key: '31_7',
            type: 'textarea',
            title: 'Please list all the documents you are sending with this claim form below.'
          },
          {
            type: 'template',
            templateUrl: 'modules/forms/scripts/services/forms/AA1A/templates/help29_2.html'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 30',
        page: 30,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Where to send the completed form</h2></div>'
          },
          {
            type: 'template',
            templateUrl: 'modules/forms/scripts/services/forms/AA1A/templates/help30_1.html'
          }
        ]
      },
      {
        type: 'section',
        condition: 'areFormNavigationButtonsVisible()',
        htmlClass: 'text-center margin-top15',
        items: [{
          type: 'actions',
          items: [
            {type: 'button', style: 'btn-default', title: 'Previous', onClick: 'prev()'},
            {type: 'button', style: 'btn-primary', title: 'Next', onClick: 'next()'}
          ]
        }]
      }
    ];
    formUI.setForm(form);

    return formUI;
  }]);
