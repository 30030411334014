'use strict';

function FormOfflineCtrl($window, $q, $scope, $localStorage, $uibModal, formModelService, OfflineFormModel, toaster, Forms, API, stringUtils, offlineFormService) {

  $scope.vm = {
    enableUserDetailsHiding: false,
    allUsersVisible: false,
    visibleUserDetails: {}
  };

  $scope.toggleUserVisible = function (userId) {
    $scope.vm.visibleUserDetails[userId] = !$scope.vm.visibleUserDetails[userId];
  };

  $scope.isUserVisible = function (userId) {
    return !$scope.vm.enableUserDetailsHiding || $scope.vm.visibleUserDetails[userId];
  };

  $scope.toggleAllUsersVisible = function () {
    // we can't get all the user IDs from the keys of $scope.vm.visibleUserDetails if a new user's forms
    // have been downloaded after the page is loaded
    Object.keys($scope.forms).forEach(function (userId) {
      $scope.vm.visibleUserDetails[userId] = $scope.vm.allUsersVisible;
    });
  };

  /**
   * Read the forms in local storage, create an OfflineFormModel for each one and store them in a scope object
   * that groups them by owner ID.
   */
  $scope.$watch(function () {
    return angular.toJson($localStorage.offlineforms);
  }, function () {
    var offlineForms = $localStorage.offlineforms || {};

    $scope.forms = Object.keys(offlineForms).reduce(function (offlineFormsByUser, id) {

      try {
        var form = OfflineFormModel.fromObject(offlineForms[id]);
        var userId = form.owner.id;

        // when reading the form from local storage into scope, set the facts from the current model
        formModelService.getFormFacts(form.getDirtyFields(), form.form.formTypeCode).then(function (latestFacts) {
          form.facts = latestFacts;
        });
        offlineFormsByUser[userId] = offlineFormsByUser[userId] || [];
        offlineFormsByUser[userId].push(form);

      } catch (e) {
        // we can get here only if forms are not compatible anymore
        // at the same time cannot delete data as it might be used elsewhere
      }
      $scope.vm.enableUserDetailsHiding = Object.keys(offlineFormsByUser).length > 1;

      return offlineFormsByUser;
    }, {});
  });


  function getOfflineFormIds() {
    return Object.keys($localStorage.offlineforms);
  }

  /**
   * Download a user's unsubmited forms, create an OfflineFormModel for each one, and store them in local storage
   */
  $scope.downloadForms = function () {

    var skippedFormsCount, storedFormsCount = 0;
    $scope.downloadInProgress = true;

    formModelService.listForms({unsubmittedOnly: true})
    // get the user's unsubmitted forms
      .then(function (formModelInstances) {

        var allUnsubmittedForms = [];

        for (var i = 0; i < formModelInstances.length; i++) {
          var formModelInstance = formModelInstances[i];
          var form = new OfflineFormModel();

          try {
            form.setStoredFormMetaData(formModelInstance);
            form.setToken(formModelInstance.token);
            allUnsubmittedForms.push(form);

          } catch (e) {
            $scope.errorMessage = e.message || 'Could not setup form with ID: ' + formModelInstance.id;
            return $q.reject();
          }
        }

        return allUnsubmittedForms;
      })
      // filter out forms that are already stored and add the form owner (user) data to the remainder
      .then(function (allUnsubmittedForms) {
        if (!$localStorage.offlineforms) {
          $localStorage.offlineforms = {};
        }

        return API.get('/user').then(function (formsOwner) {

          var newOfflineForms = allUnsubmittedForms.filter(function (form) {
            return getOfflineFormIds().indexOf(form.getId()) === -1;
          });

          skippedFormsCount = allUnsubmittedForms.length - newOfflineForms.length;

          return angular.forEach(newOfflineForms, function (newOfflineForm) {
            newOfflineForm.owner = formsOwner.data;
          });
        });
      })
      // store the forms in local storage and tell the user what happened
      .then(function (newOfflineForms) {
        angular.forEach(newOfflineForms, function (form) {

          if (getOfflineFormIds().indexOf(form.getId()) > -1) {
            throw new Error('Form with ID ' + form.getId() + ' is already downloaded');
          }
          $localStorage.offlineforms[form.getId()] = form;
          ++storedFormsCount;
        });

        var toasterMsg;

        if (storedFormsCount || !skippedFormsCount) {
          toasterMsg = storedFormsCount === 1 ? '1 application downloaded' : storedFormsCount + ' applications downloaded';
          toaster.pop('success', toasterMsg);
        }

        if (skippedFormsCount) {
          toasterMsg = skippedFormsCount === 1 ? '1 application skipped because it is already downloaded' :
            skippedFormsCount + ' applications skipped because they are already downloaded';
          toaster.pop('warning', toasterMsg);
        }
      })
      .finally(function () {
        $scope.downloadInProgress = false;
      })
      .catch(function () {
        toaster.pop('error', 'Failed to download applications');
      });
  };

  function deleteForm(formId, showToaster) {
    delete $localStorage.offlineforms[formId];

    if (showToaster) {
      toaster.pop('success', 'The offline copy of the application has been removed');
    }
  }

  $scope.deleteUnmodifiedForm = function () {
    deleteForm($scope.deleteUnmodifiedFormId, true);
    $scope.deleteUnmodifiedFormId = null;
  };

  $scope.deleteAllUnmodifiedForms = function () {

    var startFormsCount = getOfflineFormIds().length;

    angular.forEach($scope.forms, function (offlineFormsByOwner) {
      angular.forEach(offlineFormsByOwner, function (offlineForm) {
        if (!offlineForm.hasChanges()) {
          deleteForm(offlineForm.getId());
        }
      });
    });

    var deletedFormsCount = startFormsCount - getOfflineFormIds().length;
    $scope.deleteAllUnmodified = false;
    toaster.pop('success', deletedFormsCount + (deletedFormsCount === 1 ?
      ' offline application was removed' : ' offline applications were removed'));
  };

  function getRelevantFacts(form) {

    var allFacts = form.facts;
    var applicantFacts = {};

    if (allFacts) {
      // Retain only the relevant facts
      if (allFacts.firstName || allFacts.lastName) {
        applicantFacts.fullName = stringUtils.joinTrimmed([allFacts.firstName, allFacts.lastName], ' ');
      }
      if (allFacts.clientReference) {
        applicantFacts.clientReference = allFacts.clientReference;
      }
      if (allFacts.address && allFacts.address.formatted) {
        applicantFacts.address = allFacts.address.formatted;
      }
    }
    return applicantFacts;
  }

  $scope.getFact = function (form, factName) {
    return getRelevantFacts(form)[factName];
  };

  $scope.formsCount = function () {
    return offlineFormService.countForms();
  };

  $scope.hideWarning = function () {
    $localStorage.hideOfflineWarning = true;
  };

  $scope.isWarningHidden = function () {
    return $localStorage.hideOfflineWarning;
  };

  function isSingleSubmit(offlineForm) {
    if (!offlineForm || !offlineForm.form || !offlineForm.form.formTypeCode) {
      return false;
    }
    return Forms.getForm(offlineForm.form.formTypeCode).is_submit_once;
  }

  $scope.isEditable = function (form) {
    return !(form.form.isSubmitted() && isSingleSubmit(form));
  };

  var mergeFormModalPromise = null;

  function openHandleMergeDialog(currentOfflineFormInstance, remoteFormModelInstance) {
    if (mergeFormModalPromise) {
      return mergeFormModalPromise;
    }

    mergeFormModalPromise = $uibModal.open({
      size: 'md',
      templateUrl: 'modules/forms/views/modals/mergeForm.html',
      backdrop: true,
      controller: 'FormOfflineMergeModalCtrl',
      resolve: {
        currentOfflineFormInstance: function () {
          return currentOfflineFormInstance;
        },
        remoteFormModelInstance: function () {
          return remoteFormModelInstance;
        }
      }
    }).result.finally(function () {
      mergeFormModalPromise = null;
    });

    return mergeFormModalPromise;
  }

  $scope.openHandleMergeDialog = openHandleMergeDialog;
  $scope.formsCurrentlySynchronizing = 0;

  $scope.synchronize = function (form) {

    if (!form.inProgress) {
      ++$scope.formsCurrentlySynchronizing;
      form.inProgress = true;

      // 1. get form data and generate hash
      // 2. check if hash changed after form was downloaded locally
      // 3. suggest user to merge changes if any
      // 4. update form meta
      // 5. update remote form model

      return formModelService.getForm(form.getId(), form.token)
        .catch(function (e) {
          toaster.pop('error', 'Could not retrieve the online form data.');
          throw e;
        })
        .then(function (remoteFormModelInstance) {

          form.token = remoteFormModelInstance.token || form.token;
          form.form.submittedFields = remoteFormModelInstance.submittedFields;

          if (remoteFormModelInstance.isSubmitted() && Forms.getForm(remoteFormModelInstance.formTypeCode).is_submit_once) {
            $localStorage.offlineforms[form.getId()] = form;
            toaster.pop('warning', 'This application has already been submitted online. Any local changes have been discarded');
            return $q.reject('Form was already submitted');
          }

          var previousRemoteHash = form.serverHash;
          var currentRemoteHash = formModelService.formFieldsHashCode(remoteFormModelInstance.dirtyFields);
          var formChangedRemotely = previousRemoteHash !== currentRemoteHash;

          if (!form.hasChanges() && formChangedRemotely) {
            // form has only been changed remotely
            form.setStoredFormMetaData(remoteFormModelInstance);

          } else if (form.hasChanges() && formChangedRemotely) {
            // form has changed locally and remotely
            return openHandleMergeDialog(form, remoteFormModelInstance).then(function (updatedForm) {
              form = updatedForm;
              return form;
            });
          }
          return form;
        })
        .then(function (updatedOfflineForm) {
          return formModelService.updateForm(updatedOfflineForm.form, form.token);
        })
        .then(function (updatedFormModelInstance) {

          form.updateHashFromFields();
          $localStorage.offlineforms[form.getId()] = form;
          toaster.pop('success', 'The online and offline applications were successfully synchronized');
        })
        .finally(function () {
          --$scope.formsCurrentlySynchronizing;
          form.inProgress = false;
        });
    }
  };

  $scope.setUnmodifiedFormDeleteCandidate = function (form) {
    $scope.deleteUnmodifiedFormId = form ? form.getId() : null;
  };

  var removePromise = null;
  $scope.removeForm = function (form) {

    if (form.hasChanges()) {
      if (removePromise) {
        return removePromise;
      }
      removePromise = $uibModal.open({
        size: 'sm',
        templateUrl: 'modules/forms/views/modals/deleteFormConfirmation.html'

      }).result.then(function () {
        deleteForm(form.getId(), true);

      }).finally(function () {
        removePromise = null;
      });
      return removePromise;
    } else {
      $scope.setUnmodifiedFormDeleteCandidate(form);
    }
  };

}

FormOfflineCtrl.$inject = ['$window', '$q', '$scope', '$localStorage', '$uibModal', 'formModelService', 'OfflineFormModel', 'toaster', 'Forms', 'API', 'stringUtils', 'offlineFormService'];
angular.module('kerp-forms.forms').controller('FormOfflineCtrl', FormOfflineCtrl);
