'use strict';

function UpdateCtrl(
  $scope,
  $state,
  $stateParams,
  $rootScope,
  formBaseService,
  formModelService,
  $templateCache,
  configuration,
  $injector,
  schemaFormHelperService
) {

  var urlParams = {formId: $stateParams.formId, id: $stateParams.id};

  try {
    formBaseService.decorateControllerScope($scope, $stateParams.formId, $stateParams.id);
  } catch (e) {
    console.error('Redirecting to application list after error', e);
    $state.go('main.forms.list');
    return;
  }

  $scope.model = {};

  $scope.errorState = false;
  $scope.formResultError = false;

  // default error message
  $scope.errorTitle = '';
  $scope.errorMessage = '';

  $scope.formSubmissionWithoutClientIDEnabled = function () {
    $scope.submissionWarningPath = '';
    $scope.submissionDisabledPath = '';
    // some customers disable submission until user has a client ID
    // (in order to avoid creation of subjectless steps in mosaic)
    if ($scope.formDefinition.blockSubmissionWithoutClientID) {
      //  if blockSubmissionWithoutClientID is true
      // then they MUST have a client ID in order to submit

      const submissionEnabled = $scope.model.hasClientID !== false;

      if (!submissionEnabled) {
        const customer = configuration.customer;
        $scope.submissionWarningPath = 'modules/forms/views/update/submissionWarning.html';
        $scope.submissionDisabledPath = 'modules/forms/views/update/submissionDisabledWithoutClientID.html';

        const hasSubmissionDisabledTemplate = $templateCache.get('modules/forms/views/update/' + customer + '/submissionDisabledWithoutClientID.html');
        if (hasSubmissionDisabledTemplate) {
          $scope.submissionDisabledPath = 'modules/forms/views/update/' + customer + '/submissionDisabledWithoutClientID.html';
        }
        const hasSubmissionWarningTemplate = $templateCache.get('modules/forms/views/update/' + customer + '/submissionWarning.html');
        if (hasSubmissionWarningTemplate) {
          $scope.submissionWarningPath = 'modules/forms/views/update/' + customer + '/submissionWarning.html';
        }
      }

      return submissionEnabled;
    }
    // default is to enable submission
    return true;
  };

  $scope.loadForm()
    .then(function () {
      if ($scope.formDefinition.is_submit_once && $scope.formModelInstance.isSubmitted() && !$rootScope.app.hasRole('ROLE_ADMIN')) {
        $state.go('^.review', urlParams, {location: 'replace'});
      } else if ($stateParams.populateUserDetails === 'true') {
        return formModelService.setDefaultFieldValues($scope[$scope.formModelFieldsVariable], $scope.formDefinition);
      }
    })
    .catch(function (e) {
      console.error('Redirecting to application list after error', e);
      $state.go('main.forms.list');
    });

  $scope.isFormVisible = function() {
    return !$scope.loadingMessage && !$scope.errorState && !$scope.formResultError;
  };

  $scope.isErrorVisible = function() {
    return $scope.errorState;
  };

  /**
   * Save any pending changes to the form fields if the user leaves by clicking the back button, a link etc.
   */
  $scope.$on("$destroy", function () {
    // single-submit forms' data should never be changed after they've been submitted.
    var isSingleSubmit = $scope.formDefinition && $scope.formDefinition.is_submit_once;
    var isSubmitted = $scope.formModelInstance && $scope.formModelInstance.isSubmitted();
    if (!isSingleSubmit && !isSubmitted) {
      $scope.updateForm(true);
    }
  });

  $scope.showDebugModel = false;

  $scope.toggleDebugModel = function () {
    $scope.showDebugModel = !$scope.showDebugModel;
  };

  $rootScope.$on('form-error-fatal', function(e, args) {
    $scope.errorState = true;
    if (args && args.error) {
      $scope.errorTitle = args.error.title;
      $scope.errorMessage = args.error.message;
    }
  });

  $rootScope.$on('form-result-validation-error', function(e, args) {
    $scope.formResultError = true;
  });

  $rootScope.$on('form-result-clear', function(e, args) {
    $scope.formResultError = false;
  });


  /* Effective date handling */
  let backdateRequested, effectiveDate;
  function effectiveDateHandler(value) {
    const unchanged = value === effectiveDate;

    if (unchanged) {
      return;
    }

    // overwrite local copy
    effectiveDate = value;

    // overwrite form model copy
    $scope.model.effectiveDate = effectiveDate;

    // invoke a form update
    $scope.schema = $injector.get($scope.formDefinition.formSchema).getSchema(effectiveDate);

    const { getUpdatedForm, get } = $injector.get($scope.formDefinition.formService);
    if (getUpdatedForm) {
      // this customer's form supports regeneration so x-schema-form items will get refreshed
      $scope.form = getUpdatedForm();
    } else {
      // this customer's form does not regeneration so only schema native elements get refreshed
      $scope.form = get();
    }

    $scope.mergedFormAndSchema = schemaFormHelperService.mergeFormAndSchema($scope.form, $scope.schema);

    $scope.$broadcast('schemaFormRedraw');
  }

  // triggered when the form model changes.
  // we need to know when backdateRequested field control is changed
  // if the user selects no, we need to update the form / schema
  // since it may have been generated with an effective date which now needs to be removed
  $scope.$watchCollection('model', (model) => {
    const { backdateRequested: old } = model;
    const unchanged = old === backdateRequested;

    if (unchanged) {
      return;
    }

    backdateRequested = $scope.model.backdateRequested;
    if (!backdateRequested) {
      effectiveDateHandler(undefined);
    } else {
      // the effective date has just been switched on. It needs a default.
      const schema = schemaFormHelperService.findConfigInSchema($scope.schema, ['effectiveDate']);
      effectiveDateHandler(schema.default);
    }
  });

  // triggered when form data is loaded
  // we may not be on the page of the form with the effective date field when the form is opened
  // so update the effective date when the form data is loaded
  $scope.$on('form-data-loaded', (e, dirtyFields) => {
    const { effectiveDate: ed } = dirtyFields;
    backdateRequested = dirtyFields.backdateRequested;
    effectiveDateHandler(ed);
  });

  // triggered by the effective date directive controller when the value is changed
  $scope.$on('effectiveDate-change', function(e, args) {
    effectiveDateHandler(args);
  });

}

UpdateCtrl.$inject = [
  '$scope', '$state', '$stateParams', '$rootScope', 'formBaseService', 'formModelService', '$templateCache', 'configuration', '$injector','schemaFormHelperService',
];

angular.module('kerp-forms.forms').controller('UpdateCtrl', UpdateCtrl);
