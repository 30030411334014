'use strict';

function EnumTranslatorModelFactory($translate) {

  function EnumTranslatorModel(prefix, enumValue) {
    this.code = enumValue;
    this.translationKey = prefix + '.' + enumValue.toLowerCase();
    this.text = $translate.instant(this.translationKey);
  }

  return EnumTranslatorModel;
}

EnumTranslatorModelFactory.$inject = ['$translate'];

angular.module('kerp-forms.forms').factory('EnumTranslatorModel', EnumTranslatorModelFactory);
