'use strict';

angular
  .module('kerp-forms.forms')
  .factory('AA1A_SCHEMA', ['fieldDefinitionService', '$translate', function (fieldDefinitionService, $translate) {

    var requiredValidationMessage = $translate.instant('forms.validation.required');

    function frequency(customTitleMap) {

      var titleMap = customTitleMap || [
        {value: "Once a night", name: "Once"},
        {value: "Twice a night", name: "Twice"},
        {value: "Three or more times a night", name: "Three or more times"}];

      // KERP-1964 this option doesn't map to anything in the PDF, but allows the user to effectively deselect
      // an option that they chose accidentally
      titleMap.unshift({value: 'Off', name: 'Never'});

      var enumValues = titleMap.map(function (titleMapEntry) {
        return titleMapEntry.value;
      });

      return {
        type: "string",
        enum: enumValues,
        'x-schema-form': {
          title: 'How often each night?',
          type: 'radios',
          titleMap: titleMap
        }
      };
    }

    var schema = {
      type: 'object',
      properties: {
        "1_1": {
          type: "string",
          validationMessage: requiredValidationMessage
        },
        "1_2": {
          type: "string",
          validationMessage: requiredValidationMessage
        },
        "1_3": {
          type: "string",
          enum: ["Mr", "Mrs", "Miss", "Ms"]
        },
        "1_4": fieldDefinitionService.NINO.firstTwoLetters(),
        "1_5": {
          type: "string",
          pattern: "^[0-9]{6}$",
          validationMessage: "6 digits are expected"
        },
        "1_6": fieldDefinitionService.NINO.lastLetter(),
        "1_7": fieldDefinitionService.time.date(),
        "1_8": {
          type: "string",
          enum: ["Male", "Female"]
        },
        "1_9": {
          type: "string",
          validationMessage: requiredValidationMessage
        },
        "1_10": fieldDefinitionService.phone.fullNumber(),
        "1_11": fieldDefinitionService.phone.fullNumber(),
        "1_12": fieldDefinitionService.boolean.yesNoSchema(),
        "1_13": fieldDefinitionService.phone.fullNumber(),
        "1_14": {
          type: "string",
          validationMessage: requiredValidationMessage
        },
        "2_1": {
          type: "string",
          enum: ["Yes", "No, go to question 9"]
        },
        "2_2": fieldDefinitionService.boolean.yesNoSchema(),
        "2_3": {
          type: "string",
          enum: ["Yes", "No, go to question 10"]
        },
        "2_4": fieldDefinitionService.time.date(),
        "2_5": fieldDefinitionService.time.date(),
        "2_6": {
          type: "string",
          validationMessage: requiredValidationMessage
        },
        "2_7": {
          type: "string",
          validationMessage: requiredValidationMessage
        },
        "3_1": {
          type: "string",
          enum: ["Yes, we will contact you about this.", "No, go to question 11.", "Don't know. we will contact you about this"]
        },
        "3_2": {
          type: "string",
          enum: ["Yes, we will contact you about this.", "No, go to question 12", "Don't know. We will contact you about this,"]
        },
        "3_3": {
          type: "string"
        },
        "3_4": {
          type: "string",
          enum: ["Yes, continue below", "No, go to question 14"]
        },
        "3_5": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "4_1": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "4_2": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "4_3": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "4_4": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "4_6": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "4_7": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "4_8": {
          type: "string"
        },
        "4_9": fieldDefinitionService.boolean.yesNoSchema(),
        "4_10": {
          type: "string"
        },
        "4_11": fieldDefinitionService.NINO.firstTwoLetters(),
        "4_12": {
          type: "string",
          pattern: "^[0-9]{6}$",
          validationMessage: "6 digits are expected"
        },
        "4_13": fieldDefinitionService.NINO.lastLetter(),
        "4_14": fieldDefinitionService.time.date(),
        "4_15": {
          type: "string"
        },
        "4_16": {
          type: "string"
        },
        "5_1": {
          type: "string"
        },
        "5_2": {
          type: "string"
        },
        "5_3": {
          type: "string"
        },
        "5_4": {
          type: "string"
        },
        "5_5": {
          type: "string"
        },
        "5_6": {
          type: "string"
        },
        "5_7": {
          type: "string"
        },
        "5_8": {
          type: "string"
        },
        "5_9": {
          type: "string"
        },
        "5_10": {
          type: "string"
        },
        "5_11": {
          type: "string"
        },
        "5_12": {
          type: "string"
        },
        "5_13": {
          type: "string"
        },
        "5_14": {
          type: "string"
        },
        "5_15": {
          type: "string"
        },
        "5_16": {
          type: "string"
        },
        "5_17": {
          type: "string"
        },
        "5_18": {
          type: "string"
        },
        "5_19": {
          type: "string"
        },
        "5_20": {
          type: "string"
        },
        "5_21": {
          type: "string"
        },
        "5_22": {
          type: "string"
        },
        "5_23": {
          type: "string"
        },
        "5_24": {
          type: "string"
        },
        "6_1": {
          type: "string",
          enum: ["Yes", "No, go to question 16"]
        },
        "6_2": {
          type: "string",
          enum: ["Mr", "Mrs", "Miss", "Ms", "Dr", "Rev"]
        },
        "6_3": {
          type: "string"
        },
        "6_4": {
          type: "string"
        },
        "6_5": {
          type: "string"
        },
        "6_6": fieldDefinitionService.phone.fullNumber(),
        "6_7": {
          type: "string"
        },
        "6_8": {
          type: "string"
        },
        "6_9": {
          type: "string"
        },
        "6_10": fieldDefinitionService.time.date(),
        "7_1": {
          type: "string",
          enum: ["Yes, please continue below.", "No, please go to question 17."]
        },
        "7_2": {
          type: "string"
        },
        "7_3": {
          type: "string"
        },
        "7_4": fieldDefinitionService.phone.fullNumber(),
        "7_5": {
          type: "string"
        },
        "7_6": {
          type: "string"
        },
        "7_7": {
          type: "string"
        },
        "7_8": {
          type: "string"
        },
        "7_9": {
          type: "string"
        },
        "7_10": fieldDefinitionService.phone.fullNumber(),
        "7_11": fieldDefinitionService.time.date(),
        "8_1": {
          type: "string",
          enum: ["Yes", "No"]
        },
        "8_2": fieldDefinitionService.time.date(),
        "8_3": fieldDefinitionService.boolean.yesNoSchema(),
        "9_1": {
          type: "string",
          enum: ["Yes, please send us a copy if you have one.", "No, go to question 21"]
        },
        "9_2": {
          type: "string",
          enum: ["Yes, tell us about this in the table below.", "No, go to question 22."]
        },
        "9_3": fieldDefinitionService.time.date(),
        "9_4": {
          type: "string"
        },
        "9_5": fieldDefinitionService.time.date(),
        "9_6": fieldDefinitionService.time.date(),
        "9_7": {
          type: "string"
        },
        "9_8": fieldDefinitionService.time.date(),
        "9_9": {
          type: "string",
          enum: ["Yes, tell us about these in the table below.", "No, go to question 23."]
        },
        "9_10": {
          type: "string"
        },
        "9_11": {
          type: "string"
        },
        "9_12": {
          type: "string"
        },
        "9_13": {
          type: "string"
        },
        "10_1": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "10_2": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "10_3": {
          type: "string"
        },
        "10_4": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "10_5": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "10_6": {
          type: "string"
        },
        "10_7": {
          type: "string"
        },
        "10_8": fieldDefinitionService.boolean.yesNoSchema(),
        "10_9": {
          type: "string"
        },
        "10_10": {
          type: "string"
        },
        "10_11": {
          type: "string"
        },
        "10_12": fieldDefinitionService.boolean.yesNoSchema(),
        "10_13": {
          type: "string"
        },
        "10_14": {
          type: "string"
        },
        "10_15": {
          type: "string"
        },
        "10_16": fieldDefinitionService.boolean.yesNoSchema(),
        "10_17": {
          type: "string"
        },
        "10_18": {
          type: "string"
        },
        "11_1": fieldDefinitionService.time.date(),
        "11_2": {
          type: "string",
          enum: ["Yes, tick the boxes below that apply to you.", "No, go to question 27"]
        },
        "11_3": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "11_4": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "11_5": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "11_6": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "11_7": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "11_8": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "12_1": {
          type: "string",
          enum: ["Yes", "No, go to question 27"]
        },
        "12_2": {
          type: "string"
        },
        "12_3": {
          type: "string",
          enum: ["Yes, continue below.", "No, go to question 28."]
        },
        "12_4": {
          type: "number",
          minimum: 0
        },
        "12_5": {
          type: "number",
          minimum: 0
        },
        "12_6": {
          type: "number",
          minimum: 0
        },
        "12_7": {
          type: "number",
          minimum: 0
        },
        "12_8": {
          type: "number",
          minimum: 0
        },
        "12_9": {
          type: "number",
          minimum: 0
        },
        "13_1": {
          type: "string",
          enum: ["Yes, tell us in the box below", "No, go to question 28."]
        },
        "13_2": {
          type: "string"
        },
        "13_3": {
          type: "string",
          enum: ["Yes, please continue below.", "No, go to question 29."]
        },
        "13_4": {
          type: "number",
          minimum: 0
        },
        "13_5": {
          type: "number",
          minimum: 0
        },
        "13_6": {
          type: "number",
          minimum: 0
        },
        "13_7": {
          type: "number",
          minimum: 0
        },
        "13_8": {
          type: "number",
          minimum: 0
        },
        "13_9": {
          type: "number",
          minimum: 0
        },
        "13_10": {
          type: "number",
          minimum: 0
        },
        "13_11": {
          type: "number",
          minimum: 0
        },
        "14_1": {
          type: "number",
          minimum: 0
        },
        "14_2": {
          type: "number",
          minimum: 0
        },
        "14_3": {
          type: "string",
          enum: ["Yes, please continue below.", "No, go to question 29."]
        },
        "14_4": {
          type: "string"
        },
        "14_5": {
          type: "string",
          enum: ["Yes, please continue below.", "No, go to question 30."]
        },
        "14_6": {
          type: "number",
          minimum: 0
        },
        "14_7": {
          type: "number",
          minimum: 0
        },
        "14_8": {
          type: "number",
          minimum: 0
        },
        "14_9": {
          type: "number",
          minimum: 0
        },
        "14_10": {
          type: "number",
          minimum: 0
        },
        "14_11": {
          type: "number",
          minimum: 0
        },
        "15_1": {
          type: "number",
          minimum: 0
        },
        "15_2": {
          type: "number",
          minimum: 0
        },
        "15_3": {
          type: "string",
          enum: ["Yes, tell us in the box below.", "No, go to question 30."]
        },
        "15_4": {
          type: "string"
        },
        "15_5": {
          type: "string",
          enum: ["Yes, tick the boxes below that apply to you.", "No, go to question 31."]
        },
        "15_6": fieldDefinitionService.boolean.yesNoSchema(),
        "15_7": fieldDefinitionService.boolean.yesNoSchema(),
        "15_8": fieldDefinitionService.boolean.yesNoSchema(),
        "15_9": fieldDefinitionService.boolean.yesNoSchema(),
        "15_10": fieldDefinitionService.boolean.yesNoSchema(),
        "15_11": fieldDefinitionService.boolean.yesNoSchema(),
        "15_12": fieldDefinitionService.boolean.yesNoSchema(),
        "15_13": fieldDefinitionService.boolean.yesNoSchema(),
        "16_1": fieldDefinitionService.boolean.yesNoSchema(),
        "16_2": {
          type: "string",
          enum: ["Yes, tell us in the box below.", "No, go to question 31."]
        },
        "16_3": {
          type: "string"
        },
        "16_4": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "16_5": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "16_6": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "16_7": {
          type: "string"
        },
        "16_8": {
          type: "string",
          enum: ["Yes", "No"]
        },
        "17_1": {
          type: "string",
          enum: ["Yes, tell us in the box below.", "No"]
        },
        "17_2": {
          type: "string"
        },
        "17_3": fieldDefinitionService.time.date(),
        "17_4": {
          type: "number",
          minimum: 0
        },
        "17_5": {
          type: "number",
          minimum: 0
        },
        "17_6": {
          type: "string",
          enum: ["Yes, please continue below.", "No, go to question 33."]
        },
        "17_7": {
          type: "number",
          minimum: 0
        },
        "17_8": {
          type: "number",
          minimum: 0
        },
        "17_9": {
          type: "number",
          minimum: 0
        },
        "17_10": {
          type: "number",
          minimum: 0
        },
        "17_11": {
          type: "number",
          minimum: 0
        },
        "18_1": {
          type: "string",
          enum: ["Yes, tell us in the box below.", "No, go to question 33."]
        },
        "18_2": {
          type: "string"
        },
        "18_3": {
          type: "string",
          enum: ["Yes, please continue below.", "No, go to question 34."]
        },
        "18_4": {
          type: "number",
          minimum: 0
        },
        "18_5": {
          type: "number",
          minimum: 0
        },
        "18_6": {
          type: "number",
          minimum: 0
        },
        "18_7": {
          type: "number",
          minimum: 0
        },
        "18_8": {
          type: "number",
          minimum: 0
        },
        "18_9": {
          type: "number",
          minimum: 0
        },
        "19_1": {
          type: "string",
          enum: ["Yes, tell us in the box below.", "No, go to question 34"]
        },
        "19_2": {
          type: "string"
        },
        "19_3": {
          type: "string",
          enum: ["Yes, please tick the boxes that apply to you.", "No, go to question 35."]
        },
        "19_4": fieldDefinitionService.boolean.yesNoSchema(),
        "19_5": fieldDefinitionService.boolean.yesNoSchema(),
        "19_6": fieldDefinitionService.boolean.yesNoSchema(),
        "19_7": fieldDefinitionService.boolean.yesNoSchema(),
        "19_8": fieldDefinitionService.boolean.yesNoSchema(),
        "19_9": fieldDefinitionService.boolean.yesNoSchema(),
        "20_1": fieldDefinitionService.boolean.yesNoSchema(),
        "20_2": fieldDefinitionService.boolean.yesNoSchema(),
        "20_3": fieldDefinitionService.boolean.yesNoSchema(),
        "20_4": fieldDefinitionService.boolean.yesNoSchema(),
        "20_5": fieldDefinitionService.boolean.yesNoSchema(),
        "20_6": fieldDefinitionService.boolean.yesNoSchema(),
        "20_7": {
          type: "string",
          enum: ["Yes, tell us about your communication needs in the box below.", "No, go to question 35."]
        },
        "20_8": {
          type: "string"
        },
        "20_9": {
          type: "number",
          minimum: 0
        },
        "21_1": {
          type: "string",
          enum: ["Yes, please continue below.", "No, go to question 37."]
        },
        "21_2": {
          type: "string"
        },
        "21_3": {
          type: "string"
        },
        "21_4": {
          type: "string"
        },
        "21_5": {
          type: "string"
        },
        "21_6": {
          type: "string"
        },
        "21_7": {
          type: "string"
        },
        "21_8": {
          type: "string"
        },
        "21_9": {
          type: "string"
        },
        "21_10": {
          type: "string"
        },
        "21_11": {
          type: "string"
        },
        "21_12": {
          type: "string"
        },
        "21_13": {
          type: "string"
        },
        "21_14": {
          type: "string"
        },
        "21_15": {
          type: "string"
        },
        "21_16": {
          type: "string"
        },
        "22_1": {
          type: "string",
          enum: ["Yes, please tick the boxes that apply to you.", "No, go to question 38."]
        },
        "22_2": fieldDefinitionService.boolean.yesNoSchema(),
        "22_3": fieldDefinitionService.boolean.yesNoSchema(),
        "22_4": fieldDefinitionService.boolean.yesNoSchema(),
        "22_5": fieldDefinitionService.boolean.yesNoSchema(),
        "22_6": fieldDefinitionService.boolean.yesNoSchema(),
        "22_7": fieldDefinitionService.boolean.yesNoSchema(),
        "22_8": fieldDefinitionService.boolean.yesNoSchema(),
        "22_9": fieldDefinitionService.boolean.yesNoSchema(),
        "22_10": fieldDefinitionService.boolean.yesNoSchema(),
        "22_11": {
          type: "string"
        },
        "22_12": {
          type: "string",
          enum: ["Yes, tell us in the box below.", "No, go to question 38"]
        },
        "22_13": {
          type: "string"
        },
        "22_14": {
          type: "number",
          minimum: 0
        },
        "23_1": {
          type: "string",
          enum: ["Please continue below", "No, go to question 40"]
        },
        "23_2": frequency(),
        "23_3": {
          type: "number",
          minimum: 0
        },
        "23_4": frequency([
            {"value": "Please continue below", "name": "Once"},
            {"value": "Twice a night", "name": "Twice"},
            {"value": "Three or more times a night", "name": "Three or more times"}
          ]
        ),
        "23_5": {
          type: "number",
          minimum: 0
        },
        "23_6": frequency(),
        "23_7": {
          type: "number",
          minimum: 0
        },
        "23_8": frequency(),
        "23_9": {
          type: "number",
          minimum: 0
        },
        "23_10": frequency(),
        "23_11": {
          type: "number",
          minimum: 0
        },
        "23_12": frequency(),
        "23_13": {
          type: "number",
          minimum: 0
        },
        "23_14": frequency([
          {"value": "Once a night", "name": "Once"},
          {"value": "Twice a night", "name": "Twice"},

          // This is not a typo, "Three mor more..." is the field name in the PDF
          {"value": "Three mor more times a night", "name": "Three or more times"}
        ]),
        "23_15": {
          type: "number",
          minimum: 0
        },
        "23_16": frequency([
          {"value": "Please continue below", "name": "Once"},
          {"value": "Twice a night", "name": "Twice"},
          {"value": "Three or more times a night", "name": "Three or more times"}
        ]),
        "23_17": {
          type: "number",
          minimum: 0
        },
        "23_18": {
          type: "string",
          enum: ["Yes, tell us in the box below.", "No, go to question 40."]
        },
        "23_19": {
          type: "string"
        },
        "24_1": {
          type: "number",
          minimum: 0
        },
        "24_2": {
          type: "string",
          enum: ["Yes, please tick the boxes that apply to you.", "No, go to question 43."]
        },
        "24_3": fieldDefinitionService.boolean.yesNoSchema(),
        "24_4": fieldDefinitionService.boolean.yesNoSchema(),
        "24_5": fieldDefinitionService.boolean.yesNoSchema(),
        "24_6": fieldDefinitionService.boolean.yesNoSchema(),
        "24_7": fieldDefinitionService.boolean.yesNoSchema(),
        "24_8": fieldDefinitionService.boolean.yesNoSchema(),
        "24_9": fieldDefinitionService.boolean.yesNoSchema(),
        "24_10": {
          type: "number",
          minimum: 0
        },
        "24_11": {
          type: "number",
          minimum: 0
        },
        "24_12": {
          type: "string",
          enum: ["Yes, tell us in the box below.", "No, go to question 42."]
        },
        "24_13": {
          type: "string"
        },
        "24_14": {
          type: "number",
          minimum: 0
        },
        "25_1": {
          type: "string"
        },
        "26_1": {
          type: "string",
          enum: ["Yes, tell us when you went in.", "No, go to question 45."]
        },
        "26_2": fieldDefinitionService.time.date(),
        "26_3": {
          type: "string"
        },
        "26_4": {
          type: "string"
        },
        "26_5": {
          type: "string",
          enum: [
            "Yes. Does the local authority, NHS trust, primary care trust or a government department pay any of the costs for you to live there?",
            "No, go to question 45"
          ]
        },
        "26_6": {
          type: "string"
        },
        "26_7": {
          type: "string",
          enum: ["Yes, tell us when you went in.", "No, go to question 46."]
        },
        "26_8": fieldDefinitionService.time.date(),
        "26_9": fieldDefinitionService.time.date(),
        "26_10": {
          type: "string"
        },
        "26_11": {
          type: "string"
        },
        "27_1": {
          type: "array",
          items: {
            type: "string",
            enum: ["Yes"]
          }
        },
        "27_2": {
          type: "array",
          items: {
            type: "string",
            enum: ["No"]
          }
        },
        "27_3": {
          type: "string"
        },
        "27_4": {
          type: "string"
        },
        "27_5": {
          type: "string",
          pattern: "^[0-9]{2}$",
          validationMessage: "2 digits are expected"
        },
        "27_6": {
          type: "string",
          pattern: "^[0-9]{2}$",
          validationMessage: "2 digits are expected"
        },
        "27_7": {
          type: "string",
          pattern: "^[0-9]{2}$",
          validationMessage: "2 digits are expected"
        },
        "27_8": {
          type: "string",
          pattern: "^[a-zA-Z0-9]{7,10}$",
          validationMessage: "Between 7 and 10  digits are expected",
          maxLength: 10
        },
        "27_9": {
          type: "string"
        },
        "27_10": fieldDefinitionService.boolean.yesNoSchema(),
        "28_1": {
          type: "string"
        },
        "28_2": {
          type: "string"
        },
        "28_3": {
          type: "string"
        },
        "28_4": {
          type: "string"
        },
        "28_5": {
          type: "string"
        },
        "28_6": fieldDefinitionService.phone.fullNumber(),
        "28_7": fieldDefinitionService.time.date(),
        "29_1": {
          type: "string"
        },
        "31_7": {
          type: "string"
        }
      },
      required: [
        '1_1',
        '1_2',
        '1_3',
        '1_4',
        '1_5',
        '1_6',
        '1_7',
        '1_8',
        '1_9',
        '1_10',
        '1_11',
        '1_13',
        '1_14',
        '2_1',
        '2_2',
        '2_3',
        '2_4',
        '2_5',
        '2_6',
        '2_7',
        '4_9',
        '8_3',
        '10_8',
        '10_12',
        '10_16',
        '15_6',
        '15_7',
        '15_8',
        '15_9',
        '15_10',
        '15_11',
        '15_12',
        '15_13',
        '16_1',
        "19_4",
        "19_5",
        "19_6",
        "19_7",
        "19_8",
        "19_9",
        "20_1",
        "20_2",
        "20_3",
        "20_4",
        "20_5",
        "20_6",
        "22_2",
        "22_3",
        "22_4",
        "22_5",
        "22_6",
        "22_7",
        "22_8",
        "22_9",
        "22_10",
        '23_2',
        "23_4",
        "23_6",
        "23_8",
        "23_10",
        "23_12",
        "23_14",
        "23_16",
        "24_3",
        "24_4",
        "24_5",
        "24_6",
        "24_7",
        "24_8",
        "24_9",
        "27_10"
      ]
    };

    return {
      getSchema: function () {
        return schema;
      }
    };
  }]);
