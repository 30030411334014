'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'LI_CA_FORM',
    [
      'FormUI',
      'fieldDefinitionService',
      'lincsCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      'LI_CA_CONDITIONS',
      '$window',
      'configuration',

      function (
        FormUI,
        fieldDefinitionService,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        conditions,
        $window,
        configuration
      ) {

        var enableResidentialCalcs = (configuration.featureFlags || {}).residentialCalcs;

        var constants = $window.kerpCfa.lincs.constants;

        var formUI = new FormUI();

        var nonResidential = constants.careTypes.nonResidential;

        function expenses() {
          var expenses = [
            fieldDefinitionService.groups.paymentFormField({
              key: 'person.expenses.councilTax',
              description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/annualCouncilTaxDescription.html')
            }),
            fieldDefinitionService.groups.periodicPaymentFormFields({
              key: 'person.expenses.compulsoryPayments',
              description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/courtOrderedPayments.html')
            }),
            fieldDefinitionService.groups.periodicPaymentFormFields({
              key: 'person.expenses.rent',
              description: "<p>Enter the total amount of rent, as shown on your tenancy agreement or most recent rent letter.</p><p><strong>Please note:</strong> If you are paying rent to a family member that you live with and there is no formal rental agreement in place, this amount will be disregarded from the calculation so you should <strong>not</strong> enter it here.</p>"
            }),
            fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.expenses.mortgage'}),
            fieldDefinitionService.groups.periodicPaymentFormFields({
              key: 'person.expenses.mortgageInsurance',
              description: "For any endowment/life insurance policies then these have to be directly linked to your mortgage (i.e. it can't be stand alone life insurance). We may ask you for evidence of this."
            }),
            fieldDefinitionService.groups.periodicPaymentFormFields({
              key: 'person.expenses.homeInsurance',
              description: "<p>If you have a combined buildings and contents insurance policy, enter the total amount you pay.</p><p><strong>Please note:</strong> Building insurance details are only required on the property that you currently reside in (or for your former home if you still own it but are now residing in a care home setting). Insurance details are not required on rented property.</p>"
            }),
            {
              key: 'person.expenses.isHomeInsuranceCombined',
              condition: 'model.person.expenses.homeInsurance'
            },
            fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.expenses.groundRent'})
          ];

          var resExpenses = [
            fieldDefinitionService.groups.periodicPaymentFormFields({
              key: 'person.expenses.waterRates',
            }),

            fieldDefinitionService.groups.periodicPaymentFormFields({
              key: 'person.expenses.gas',
            }),

            fieldDefinitionService.groups.periodicPaymentFormFields({
              key: 'person.expenses.electric',
            }),

            fieldDefinitionService.groups.periodicPaymentFormFields({
              key: 'person.expenses.lifeline',
            })
          ];

          if (enableResidentialCalcs) {
            expenses = expenses.concat(resExpenses);
          }

          return expenses;
        }

        function costOfCareItems() {
          var costOfCareItems = [
            {
              type: 'template',
              templateUrl: 'modules/forms/scripts/services/forms/LI_CA/templates/costOfCareIntro.html'
            }
          ];

          costOfCareItems = costOfCareItems.concat([
            {
              type: "fieldset",
              title: 'Non-residential',
              items: [
                {
                  key: 'careAssessment.nonResidential.maxContribution',
                  type: 'careAssessmentResult',
                  formTypeCode: 'LI_CA',
                  calculator: careAssessmentCalculator,
                  careType: nonResidential,
                  title: 'Maximum non-residential care contribution'
                }
              ]
            }
          ]);

          if (enableResidentialCalcs) {
            costOfCareItems = costOfCareItems.concat([
              {
                type: "fieldset",
                title: 'Temporary residential',
                items: [
                  {
                    key: 'careAssessment.temporaryResidential.maxContribution',
                    type: 'careAssessmentResult',
                    formTypeCode: 'LI_CA',
                    calculator: careAssessmentCalculator,
                    careType: constants.careTypes.temporaryResidential,
                    title: 'Maximum temporary residential care contribution'
                  }
                ]
              },
              {
                type: "fieldset",
                title: 'Permanent residential',
                items: [
                  {
                    key: 'careAssessment.permanentResidential.maxContribution',
                    type: 'careAssessmentResult',
                    formTypeCode: 'LI_CA',
                    calculator: careAssessmentCalculator,
                    careType: constants.careTypes.permanentResidential,
                    title: 'Maximum permanent residential care contribution'
                  }
                ]
              }
            ]);
          }

          costOfCareItems = costOfCareItems.concat([
            {
              type: 'template',
              templateUrl: 'modules/forms/scripts/services/forms/LI_CA/templates/costOfCareHelp.html'
            }
          ]);

          return costOfCareItems;
        }

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel, true);
        };

        formUI.setForm([
          {
            type: 'section',
            condition: 'model.page === 1',
            page: 1,
            items: [
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/LI_CA/templates/intro.html'
              },
              {
                type: "fieldset",
                title: 'Care contribution exemption',
                items: [
                  {
                    title: 'With respect to care contributions, are you?',
                    key: 'person.intro.chargingExemption',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/chargingExemption.html')
                  },
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/LI_CA/templates/exempt.html',
                    condition: 'model.person.intro.chargingExemption === "exemptCJD" || model.person.intro.chargingExemption === "exemptSection117"'
                  }
                ]
              },
              {
                type: "fieldset",
                title: 'Your details',
                items: [
                  {
                    key: 'person.intro.havePartner',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/partnerDescription.html')
                  },
                  {
                    key: 'person.intro.partnerLivesAtSameAddress',
                    condition: 'model.person.intro.havePartner',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/partnerSameAddress.html')
                  },

                  {
                    key: 'person.intro.partnerReceivesResidentialCare',
                    condition: 'model.person.intro.havePartner'
                  },

                  {
                    key: 'person.intro.partnerResidentialCareTerm',
                    condition: 'model.person.intro.havePartner && model.person.intro.partnerReceivesResidentialCare'
                  },

                  {
                    key: 'person.intro.declarePartnerFinances',
                    description: "By declaring your partner's finances you may benefit from extra allowances.",
                    condition: 'model.person.intro.havePartner'
                  },
                  {
                    key: 'person.intro.transferHalfPensionToPartner',
                    title: 'Disregard half pension and transfer to partner?',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/transferHalfPensionToPartner.html'),
                    condition: 'model.person.intro.havePartner'
                  },
                  'person.intro.propertyType',
                  {
                    key: 'person.intro.dob',
                    description: 'We need this information to calculate your State Pension age'
                  },
                  {
                    key: 'person.intro.peopleLivingInHome',
                    validationMessage: 'Enter a whole number greater than zero'
                  }
                ]
              },
              {
                type: "fieldset",
                title: 'Your capital',
                items: [
                  {
                    key: 'person.capital.individual',
                    description: 'Include any capital you hold in bank accounts, building societies, stocks, shares, investments and any second properties where you are the sole named owner.'
                  },
                  {
                    key: 'person.capital.joint',
                    description: 'Include any capital you and someone else hold in joint bank accounts, building societies, stocks, shares, investments and any second properties, where you are both the named owners.'
                  }
                ]
              },


              {
                type: 'section',
                condition: 'model.page === 1',
                page: 1,
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: "Universal Credit",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'person.income.hasUniversalCredit',
                        title: 'Are you in receipt of Universal Credit?',
                        description: 'Universal Credit (UC) is made up of several elements, not all of these will be used in your financial assessment for adult care. The elements you receive are listed on your UC award. In order to work out the amount of UC to use in your assessment, you need to enter all the elements of UC you receive and all the amounts deducted from your UC payment, as listed on your award letter.',
                        condition: '!model.partner.income.hasUniversalCredit'
                      },
                      fieldDefinitionService.boolean.noRequiredForm({
                        key: 'person.income.hasUniversalCredit',
                        title: 'Are you in receipt of Universal Credit?',
                        description: 'Universal Credit can be claimed either by you or your partner',
                        condition: 'model.partner.income.hasUniversalCredit'
                      }),
                      {
                        key: 'person.income.universalCredit',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.person.income.hasUniversalCredit'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Partner's Universal Credit",
                    htmlClass: "col-md-6",
                    condition: 'model.person.intro.havePartner',
                    items: [
                      {
                        key: 'partner.income.hasUniversalCredit',
                        title: 'Is your partner in receipt of Universal Credit?',
                        description: 'Universal Credit (UC) is made up of several elements, not all of these will be used in your financial assessment for adult care. The elements you receive are listed on your UC award. In order to work out the amount of UC to use in your assessment, you need to enter all the elements of UC you receive and all the amounts deducted from your UC payment, as listed on your award letter.',
                        condition: '!model.person.income.hasUniversalCredit'
                      },
                      fieldDefinitionService.boolean.noRequiredForm({
                        key: 'partner.income.hasUniversalCredit',
                        title: 'Is your partner in receipt of Universal Credit?',
                        description: 'Universal Credit can be claimed either by you or your partner',
                        condition: 'model.person.income.hasUniversalCredit'
                      }),
                      {
                        key: 'partner.income.universalCredit',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.partner.income.hasUniversalCredit'
                      }
                    ]
                  }
                ]
              },

              {
                type: "fieldset",
                title: 'Your income and benefits',
                items: [
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.retirementPensionReceived'}),
                  fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.incomeSupportPayment'}, {}, {
                    condition: 'model.person.intro.havePartner'
                  }),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.incapacityBenefit'}),
                  fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.employmentSupport'}, {}, {
                    condition: 'model.person.intro.havePartner'
                  }),
                  fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.jobSeekersAllowance'}, {}, {
                    condition: 'model.person.intro.havePartner'
                  }),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.industrialInjuriesBenefit'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.rentalIncome'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.annuitiesIncome'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.trustFunds'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.severeDisablement'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.workingTaxCredit'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.childTaxCredit'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.maternityAllowance'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.maintenance'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.exceptionalSevereDisablement'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.warPension'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.warWidowsPension'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.privatePension'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.warSpecialPayment'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.childBenefit'}),
                  fieldDefinitionService.groups.periodicJointPaymentFormFields({
                    key: 'person.income.housingBenefit'
                  }, {}, {
                    condition: 'model.person.intro.havePartner'
                  }),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.carersAllowance'}),

                  fieldDefinitionService.groups.paymentFormField({
                    key: 'person.income.attendanceAllowance',
                    condition: conditions.toString('person.income.attendanceAllowance')
                  }),
                  fieldDefinitionService.groups.paymentFormField({
                    key: 'person.income.dlaCare',
                    condition: conditions.toString('person.income.dlaCare')
                  }),
                  fieldDefinitionService.groups.paymentFormField({
                    key: 'person.income.pipDailyLiving',
                    condition: conditions.toString('person.income.pipDailyLiving')
                  }),

                  fieldDefinitionService.groups.paymentFormField({key: 'person.income.mobilityAllowance'}),
                  fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.pensionGuaranteeCredit'}, {}, {
                    condition: 'model.person.intro.havePartner'
                  }),
                  fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.pensionSavingsCredit'}, {}, {
                    condition: 'model.person.intro.havePartner'
                  }),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.otherBenefit'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.wages'}),
                  fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.otherIncome'})
                ]
              },
              {
                type: "fieldset",
                title: 'Your expenses',
                items: expenses()
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 2',
            page: 2,
            title: 'Cost of care',
            items: costOfCareItems()
          },
          {
            type: "section",
            condition: "areFormNavigationButtonsVisible()",
            items: [
              {
                type: "actions",
                htmlClass: "formPaginationButtons",
                items: [
                  {
                    type: "button",
                    style: "btn-default",
                    title: "Previous",
                    onClick: "prev()"
                  },
                  {
                    type: "button",
                    style: "btn-primary",
                    title: "Next",
                    onClick: "next()"
                  }]
              }
            ]
          }
        ]);

        return formUI;
      }]);
