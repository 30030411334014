'use strict';

(function (angular) {

  var mod = angular.module('kerp-forms.forms');

  function downloadFormButtonDirective(configuration, Forms, $injector, $sce, $timeout, formConverterService, formModelService, analyticsService, $q) {

    return {
      restrict: 'EA',
      scope: {
        formId: "@",
        btnClass: "@",
        btnTitle: "@",
        onDownload: "&",
        hideUndefinedFields: "@"
      },
      templateUrl: 'modules/forms/scripts/directives/downloadFormButton/downloadFormButtonDirective.html',
      link: function (scope, elem, attrs) {

        function prepare(formModelInstance) {

          var deferred = $q.defer();

          $timeout(function () {
            if (!angular.isObject(formModelInstance)) {
              return deferred.reject();
            }

            scope.postParams = {formTypeCode: formModelInstance.formTypeCode};
            var formService, schemaService, formFormDefinition, formSchemaDefinition;
            var FORM_CONFIG = Forms.getForm(formModelInstance.formTypeCode);

            if (FORM_CONFIG.has_web_form) {
              formService = $injector.get(FORM_CONFIG.formService);
              schemaService = $injector.get(FORM_CONFIG.formSchema);
              formFormDefinition = formService.get();
              formSchemaDefinition = schemaService.getSchema();
            }

            if (FORM_CONFIG.is_submit_once) {
              scope.downloadUrl = $sce.trustAsResourceUrl(configuration.api.baseUrl + '/htmlToPdf');
              var conditionsService = $injector.get(FORM_CONFIG.formConditions);
              if (angular.isFunction(conditionsService.setModelGetter) && !conditionsService.getModel()) {
                conditionsService.setModelGetter(function () {
                  return formModelInstance.dirtyFields;
                });
              }

              var pdfOptions = scope.hideUndefinedFields ? {hideUndefinedFields: true} : {};
              if (formModelInstance.externalReference) {
                pdfOptions.externalReference = formModelInstance.externalReference;
              }
              if (formModelInstance.lastUpdated) {
                pdfOptions.lastUpdated = formModelInstance.lastUpdated;
              }
              scope.postParams.html = formConverterService.toPdfHtml(conditionsService, formFormDefinition, formSchemaDefinition, formModelInstance.dirtyFields, pdfOptions);
            } else {
              scope.downloadUrl = $sce.trustAsResourceUrl(formModelInstance.link);
              scope.postParams.attachedData = JSON.stringify(formModelService.toPdfModel(formModelInstance.dirtyFields, FORM_CONFIG));
            }

            deferred.resolve();
          });

          return deferred.promise;
        }

        scope.trackApplicationDownloadEvent = function () {
          (scope.onDownload || angular.noop)();
          analyticsService.sendEvent('form', 'download', scope.formId);
          console.log('download event sent for form', scope.formId);
        };

        scope.downloadApplication = function () {
          return formModelService.getForm(scope.formId)
            .then(prepare)
            .then(function () {
              $timeout(function () {
                elem.find('form').submit();
              });
            });
        };
      }
    };
  }

  downloadFormButtonDirective.$inject = ['configuration', 'Forms', '$injector', '$sce', '$timeout', 'formConverterService', 'formModelService', 'analyticsService', '$q'];

  mod.directive('downloadFormButton', downloadFormButtonDirective);

}(angular));
