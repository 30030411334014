'use strict';

/**
 * Extracts an agent name from agent details
 *
 */
angular
  .module('kerp-forms.forms')
  .directive(
    'agentName', [
      'objectUtils',
      function (objUtils) {

      return {
        scope: {
          data: '='
        },
        templateUrl: 'modules/forms/scripts/directives/agentName/agentName.html',
        link: function (scope, element, attrs) {

          const agentDetails = objUtils.getPropertyPath(scope.data, 'agent');
          const personDetails = objUtils.getPropertyPath(scope.data, 'person.details');

          const applicantName = `${personDetails.firstName} ${personDetails.lastName}`;

          let agentName = '';

          const agentList = [];
          if (agentDetails.hasAppointee) {
            agentList.push(agentDetails.appointeeDetails);
          }

          if (agentDetails.hasDeputy) {
            agentList.push(agentDetails.deputyDetails);
          }

          if (agentDetails.hasPowerOfAttorney) {
            // poa could be an array of agents, or just an agent
            // cf wokingham === array, hants = object
            const powerOfAttorneyDetails = angular.isArray(agentDetails.powerOfAttorneyDetails) ? agentDetails.powerOfAttorneyDetails : [agentDetails.powerOfAttorneyDetails];
            agentList.push(...powerOfAttorneyDetails);
          }

          const makeName = ({ name, surname }) => `${name} ${surname}`;

          // only used if schema defines `agent.isCompletedBy`
          let hasAgent = agentList.some((agent) => agent.isCompletedBy && (agentName = makeName(agent)));

          // priority agent if exists
          if (agentDetails.isCompletedByAgent) {
            hasAgent = true;
            agentName = makeName(agentDetails.completedByDetails);
          }

          scope.applicantName = applicantName;
          scope.agentName = agentName;
          scope.isCompletedByAgent = hasAgent;
        }
      };
    }
  ]);
