'use strict';

/**
 * All YouTube links should open in the content panel
 */
angular.module('kerp-forms.forms').directive('a', ['contentPanelService', function (contentPanelService) {

  return {
    restrict: 'E',
    link: function (scope, element, attrs) {

      element.on('click', function ($event) {

        if (attrs.href && attrs.href.match(/youtube\.com/)) {
          var videoId = attrs.href.substr(attrs.href.lastIndexOf("?v=") + 3).trim();

          if (videoId) {
            var contentPanelTitle = attrs.title ? attrs.title : 'Video guide';

            contentPanelService.show({
              title: contentPanelTitle,
              html: '<iframe width="100%" height="460" src="https://www.youtube-nocookie.com/embed/' + videoId + '?autoplay=1&rel=0" frameborder="0" allowfullscreen></iframe>'
            });

            // prevent the default action, i.e. opening the YouTube web page in the current window/tab
            $event.preventDefault();
            return false;
          }
        }
      });
    }
  };
}]);
