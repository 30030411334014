'use strict';

/**
 * Wrap common functionality among form UI services
 *
 * This service is going to output form UI service with
 * common methods like `getForm`, `next`, `prev`.
 */

function FormUI() {
  this.form = null;
}

FormUI.constructor = FormUI;
FormUI.prototype = {

  get: function getForm() {
    return this.form;
  },

  getForm: function getForm() {
    return this.form;
  },

  setForm: function setForm(form) {
    this.form = form;
    return this;
  },

  getHiddenPages: function getHiddenPages(model) {
    return [];
  },

  getNextVisiblePageNumber: function (model) {
    var form = this.getForm();
    var hiddenPages = this.getHiddenPages(model);
    for (var i = model.page + 1; i < form.length; i++) {
      if (hiddenPages.indexOf(i) === -1) {
        return i;
      }
    }
  },

  preSubmissionHandler: angular.noop,

  next: function onNext(model) {
    var nextVisiblePageNumber = this.getNextVisiblePageNumber(model);

    if (nextVisiblePageNumber) {
      model.page = nextVisiblePageNumber;
    }

    return !!nextVisiblePageNumber;
  },

  prev: function onPrev(model) {
    var currentPage = model.page;
    var hiddenPages = this.getHiddenPages(model);

    while (--currentPage > 1 && hiddenPages.indexOf(currentPage) !== -1) {
      // deliberately empty
    }
    model.page = currentPage;
  }
};

angular.module('kerp-forms.forms').factory('FormUI', function () {
  return FormUI;
});
