'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'SG_FCA_FORM',
    [
      'FormUI',
      'southglosCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      'fieldDefinitionService',
      'SG_FCA_CONDITIONS',
      '$window',

      function (
        FormUI,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        fieldDefinitionService,
        conditions,
        $window
      ) {

        var constants = $window.kerpCfa.southglos.constants;

        var formUI = new FormUI();

        function periodicPaymentField(fieldName, options) {
          return {
            type: 'section',
            htmlClass: 'row',
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-4"
              }
            ]
          };
        }

        function fullWidthPeriodicPaymentField(fieldName, options, condition) {
          condition = condition || 'true';

          return {
            type: 'section',
            htmlClass: 'row',
            condition: condition,
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-7 col-sm-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-3 col-sm-4"
              }
            ]
          };
        }

        function paymentField(fieldName, overrides) {
          return angular.extend({
            key: fieldName,
            feedback: false
          }, overrides);
        }

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel);
        };

        formUI.getHiddenPages = function (model) {
          var intro = (model.person || {}).intro || {};
          var withholdFinancialDetailsAndPayFullCost = intro.provideFinancialDetails === 'payFullCost';
          var withholdFinancialDetailsAndArrangeOwnCare = intro.provideFinancialDetails === 'arrangeMyOwn';
          var excludeNonResidentialCaretypes = intro.excludeNonResidentialCaretypes === true;

          let hiddenPages = [];

          if (withholdFinancialDetailsAndPayFullCost) {
            hiddenPages = [3, 4, 5, 6, 7, 8, 9];
          } else if (withholdFinancialDetailsAndArrangeOwnCare) {
            hiddenPages = [3, 4, 5, 6, 7, 8, 9, 10, 11];
          } else {
            hiddenPages = [];
          }

          if (excludeNonResidentialCaretypes) {
            hiddenPages = hiddenPages.concat([9]);
          }

          return hiddenPages;
        };

        formUI.setForm([
          {
            type: 'section',
            condition: 'model.page === 1',
            page: 1,
            htmlClass: 'row',
            items: [
              {
                key: 'schemaVersion',
                type: 'schemaversion',
              },
              {
                type: 'fieldset',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_01_intro.html'
                  },
                ]
              },
              {
                type: 'fieldset',
                title: 'People acting on your behalf',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/agentVideoGuidance.html'
                  },
                  'agent.hasAppointee',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>Appointeeship for State Benefits gives a person the right to deal with the benefits of someone who cannot manage their own affairs because they\'re mentally incapable or severely disabled. Additional information is available on <a href=\"https://www.gov.uk/become-appointee-for-someone-claiming-benefits\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasAppointee',
                    key: 'agent.appointeeDetails'
                  },
                  'agent.hasDeputy',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>You are someone’s deputy if they \'lack mental capacity\'. This means they cannot make a decision for themselves at the time it needs to be made. They may still be able to make decisions for themselves at certain times. Additional information is available on <a href=\"https://www.gov.uk/become-deputy\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasDeputy',
                    key: 'agent.deputyDetails'
                  },
                  'agent.hasPowerOfAttorney',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>A power of attorney is a legal document that allows someone to make decisions for you, or act on your behalf, if you\'re no longer able to or if you no longer want to make your own decisions. Additional information is available on <a href=\"https://www.gov.uk/power-of-attorney\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasPowerOfAttorney',
                    key: 'agent.powerOfAttorneyDetails'
                  },
                  'agent.isCompletedByAgent',
                  {
                    condition: 'model.agent.isCompletedByAgent',
                    key: 'agent.completedByDetails'
                  }
                ]
              },
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 2',
            page: 2,
            htmlClass: "row",
            items: [
              {
                type: "fieldset",
                htmlClass: "col-md-12",
                items: ['clientID']
              },
              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About you',
                items: [
                  {
                    type: 'template',
                    htmlClass: 'divTitle',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_02_about-you.html'
                  },
                  'person.details.title',
                  'person.details.firstName',
                  'person.details.middleName',
                  'person.details.lastName',
                  'person.intro.excludeNonResidentialCaretypes',
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/SG_FCA/templates/addressLookup.html",
                    homeAddressDescription: "If you have moved into a care home please put the address that you were living at before your move."
                  },
                  // FIXME: these fields are already defined in the addressSuggestion directive's template, but they are not included in the submitted model unless they are repeated here
                  {
                    key: 'person.details.address.line1',
                    title: 'Address line 1',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line2',
                    title: 'Address line 2',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line3',
                    title: 'Address line 3',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.postcode',
                    title: 'Postcode',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.uprn',
                    title: 'Property Reference',
                    type: "hidden"
                  },
                  'person.details.homePhoneNumber',
                  'person.details.mobilePhoneNumber',
                  'person.details.email',
                  'person.details.alreadyInPermanentCare',
                  {
                    type: "fieldset",
                    condition: 'model.person.details.alreadyInPermanentCare === "yes"',
                    items: [
                      {
                        type: "help",
                        helpvalue: "<div class=\"html-view-remove\"><p>Please enter the address of the care or nursing home</p></div"
                      },
                      {
                        key: 'person.details.careHomeAddress.line1',
                        title: 'Address line 1',
                      },
                      {
                        key: 'person.details.careHomeAddress.line2',
                        title: 'Address line 2',
                      },
                      {
                        key: 'person.details.careHomeAddress.line3',
                        title: 'Address line 3',
                      },
                      {
                        key: 'person.details.careHomeAddress.postcode',
                        title: 'Postcode',
                      },

                    ]
                  },
                  'person.details.nhsNumber',
                  {
                    key: 'person.details.dob',
                    description: 'We need this information to calculate your State Pension age'
                  }
                ]
              },


              {
                type: 'fieldset',
                title: "Declaring your financial circumstances",
                htmlClass: "col-md-12",
                items: [
                  'person.intro.provideFinancialDetails',
                  {
                    key: 'person.details.nationalInsuranceNumber',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.hasPartner',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/SG_FCA/templates/partnerDescription.html')
                  },
                  {
                    key: 'person.intro.partnerStatus',
                    condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'partner.details.partnerReceivesResidentialCare',
                    condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.otherRecievesCarersAllowance',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"'
                  },
                  {
                    key: 'person.intro.carersAllowanceIsEntitled',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/SG_FCA/templates/carersAllowanceEntitlement.html')
                  },
                ]
              },

              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About your partner',
                condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                items: [
                  'partner.intro.alreadyReceivingCare',
                  'partner.details.sameAddress',
                  {
                    type: 'fieldset',
                    condition: 'model.partner.details.sameAddress === true',
                    items: [
                      'partner.details.firstName',
                      'partner.details.middleName',
                      'partner.details.lastName',
                      'partner.details.homePhoneNumber',
                      'partner.details.mobilePhoneNumber',
                      'partner.details.email',
                      'partner.details.dob',
                      {
                        key: 'partner.intro.discloseFinances',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/SG_FCA/templates/partnersFinancesDescription.html')
                      },
                      {
                        key: 'partner.details.nationalInsuranceNumber',
                        condition: 'model.partner.intro.discloseFinances'
                      },
                      {
                        key: 'partner.intro.carersAllowanceIsEntitled',
                        condition: 'model.partner.intro.discloseFinances'
                      },
                      {
                        key: 'partner.intro.otherRecievesCarersAllowance',
                        condition: 'model.partner.intro.discloseFinances'
                      }
                    ],
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 3',
            page: 3,
            title: 'People living with you',
            items: [
              {
                key: 'peopleLivingWithYou.hasDependentChildren',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/SG_FCA/templates/dependentChildrenDescription.html')
              },
              {
                key: 'peopleLivingWithYou.dependentChildren',
                condition: 'model.peopleLivingWithYou.hasDependentChildren',
                add: "Add Child",
                title: 'Dependent children details',
                validationMessage: 'Complete all required fields for at least one child'
              },
              {
                key: 'peopleLivingWithYou.hasOtherPeople',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/SG_FCA/templates/nonDependentsDescription.html')
              },
              {
                key: 'peopleLivingWithYou.otherPeople',
                condition: 'model.peopleLivingWithYou.hasOtherPeople',
                add: "Add Person",
                title: 'Details of other people living with you',
                validationMessage: 'Complete all required fields for at least one person'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 4',
            page: 4,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your income and benefits',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_04_income-intro.html'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages and salary</h4>"
                      },
                      {
                        key: 'person.income.hasWage',
                        title: 'Are you currently employed?'
                      },
                      {
                        key: 'person.income.wages',
                        condition: 'model.person.income.hasWage',
                        add: "Add salary or wage",
                        title: 'Wages and salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private and works pensions or annuity</h4>"
                      },
                      {
                        key: 'person.income.hasPrivatePension',
                        title: 'Are you in receipt of private and works pensions or annuity?'
                      },
                      {
                        key: 'person.income.privatePensions',
                        condition: 'model.person.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private and works pensions',
                        validationMessage: 'Enter details of at least one private or works pension'
                      },
                      {
                        key: 'person.details.transferHalfPensionToPartner',
                        title: 'Disregard half pension and transfer to partner?',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/SG_FCA/templates/transferHalfPensionToPartner.html'),
                        condition: 'model.person.income.hasPrivatePension && model.person.intro.hasPartner && model.partner.details.sameAddress'
                      },
                      {
                        type: "fieldset",
                        condition: '!(model.partner.income.hasUniversalCredit && model.partner.income.universalCreditIsJoint)',
                        items: [
                          {
                            key: 'person.income.hasUniversalCredit',
                            title: 'Are you in receipt of Universal Credit?',
                            description: 'Universal Credit is a payment to help with your living costs. Additional information is available on <a href="https://www.gov.uk/universal-credit">the government website</a>.'
                          },

                          fieldDefinitionService.groups.periodicJointPaymentFormFields({
                            key: 'person.income.universalCredit',
                            condition: 'model.person.income.hasUniversalCredit'
                          }, {}, {
                            condition: 'model.person.intro.hasPartner && model.partner.details.sameAddress'
                          }),


                        ]
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Are you in receipt of any of the benefits listed below?</h4>"
                      },

                      periodicPaymentField('person.income.retirementPension'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.incomeSupportPayment',
                        condition: '!model.partner.income.incomeSupportPaymentIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('person.income.incapacityBenefit'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.employmentSupport',
                        condition: '!model.partner.income.employmentSupportIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.jobSeekersAllowance',
                        condition: '!model.partner.income.jobSeekersAllowanceIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('person.income.industrialInjuriesBenefit'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.workingTaxCredit',
                        condition: '!model.partner.income.workingTaxCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.childTaxCredit',
                        condition: '!model.partner.income.childTaxCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.maintenance'),
                      periodicPaymentField('person.income.veteranWarPension'),
                      periodicPaymentField('person.income.warPension'),
                      periodicPaymentField('person.income.warWidowsPension'),
                      periodicPaymentField('person.income.warSpecialPayment'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.childBenefit',
                        condition: '!model.partner.income.childBenefitIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.carersAllowance'),

                      paymentField('person.income.attendanceAllowance', {
                        condition: conditions.toString('person.income.attendanceAllowance')
                      }),

                      paymentField('person.income.dlaCare', {
                        condition: conditions.toString('person.income.dlaCare')
                      }),
                      paymentField('person.income.pipDailyLiving', {
                        condition: conditions.toString('person.income.pipDailyLiving')
                      }),

                      {
                        key: 'person.income.receivesExtraCare',
                        condition: conditions.toString('person.income.receivesExtraCare')
                      },

                      paymentField('person.income.mobilityAllowance'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.pensionGuaranteeCredit',
                        condition: '!model.partner.income.pensionGuaranteeCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.pensionSavingsCredit',
                        condition: '!model.partner.income.pensionSavingsCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('person.income.childMaintenance'),

                      'person.income.hasOtherBenefit',
                      {
                        key: 'person.income.otherBenefit',
                        condition: 'model.person.income.hasOtherBenefit'
                      },
                      'person.details.inEducation',
                      {
                        key: 'person.details.educationDetails',
                        condition: 'model.person.details.inEducation'
                      },
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Your partner's income and benefits",
                    htmlClass: "col-md-6",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    items: [
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages and salary</h4>"
                      },
                      {
                        key: 'partner.income.hasWage',
                        title: 'Is your partner currently employed?'
                      },
                      {
                        key: 'partner.income.wages',
                        condition: 'model.partner.income.hasWage',
                        add: "Add salary or wage",
                        title: 'Wages and salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private and works pensions or annuity</h4>"
                      },
                      {
                        key: 'partner.income.hasPrivatePension',
                        title: 'Is your partner in receipt of private and works pensions or annuity?'
                      },
                      {
                        key: 'partner.income.privatePensions',
                        condition: 'model.partner.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private and works pensions',
                        validationMessage: 'Enter details of at least one private or works pension'
                      },

                      {
                        type: "fieldset",
                        condition: '!(model.person.income.hasUniversalCredit && model.person.income.universalCreditIsJoint)',
                        items: [

                          {
                            key: 'partner.income.hasUniversalCredit',
                            title: 'Is your partner in receipt of Universal Credit?'
                          },


                          fieldDefinitionService.groups.periodicJointPaymentFormFields({
                            key: 'partner.income.universalCredit',
                            condition: 'model.partner.income.hasUniversalCredit'
                          }, {}, {
                            condition: 'model.person.intro.hasPartner && model.partner.details.sameAddress'
                          }),

                        ]
                      },

                      periodicPaymentField('partner.income.retirementPension'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.incomeSupportPayment',
                        condition: '!model.person.income.incomeSupportPaymentIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('partner.income.incapacityBenefit'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.employmentSupport',
                        condition: '!model.person.income.employmentSupportIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.jobSeekersAllowance',
                        condition: '!model.person.income.jobSeekersAllowanceIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('partner.income.industrialInjuriesBenefit'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.workingTaxCredit',
                        condition: '!model.person.income.workingTaxCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.childTaxCredit',
                        condition: '!model.person.income.childTaxCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('partner.income.maintenance'),
                      periodicPaymentField('partner.income.veteranWarPension'),
                      periodicPaymentField('partner.income.warPension'),
                      periodicPaymentField('partner.income.warWidowsPension'),
                      periodicPaymentField('partner.income.warSpecialPayment'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.childBenefit',
                        condition: '!model.person.income.childBenefitIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('partner.income.carersAllowance'),

                      paymentField('partner.income.attendanceAllowance', {
                        condition: conditions.toString('partner.income.attendanceAllowance')
                      }),
                      paymentField('partner.income.dlaCare', {
                        condition: conditions.toString('partner.income.dlaCare')
                      }),
                      paymentField('partner.income.pipDailyLiving', {
                        condition: conditions.toString('partner.income.pipDailyLiving')
                      }),

                      paymentField('partner.income.mobilityAllowance'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.pensionGuaranteeCredit',
                        condition: '!model.person.income.pensionGuaranteeCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.pensionSavingsCredit',
                        condition: '!model.person.income.pensionSavingsCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('partner.income.childMaintenance'),

                      'partner.income.hasOtherBenefit',
                      {
                        key: 'partner.income.otherBenefit',
                        condition: 'model.partner.income.hasOtherBenefit'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 5',
            page: 5,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your money or investments',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/moneyInvestmentsIntro.html'
                      },
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_03_capital-intro.html'
                      },
                      {
                        key: 'person.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you are the sole named account holder?',
                        description: 'Give details of all monies held, including bank/building society accounts, savings and investments (refer to the blue information box below).'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's money or investments",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/moneyInvestmentsIntro.html'
                      },
                      {
                        key: 'partner.capital.hasSavingsInvestments',
                        title: 'Does your partner have any money or investments which are held in accounts where they are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint money or investments",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/moneyInvestmentsIntro.html'
                      },
                      {
                        key: 'joint.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you and someone else are both named account holders?',
                        description: 'Only include joint accounts where the amount is owned by more than one person. Accounts with additional names for access-only reasons are treated as single owner accounts.'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/capitalDescription.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.person.capital.hasSavingsInvestments',
                        key: 'person.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of your savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.partner.capital.hasSavingsInvestments',
                        key: 'partner.capital.savingsInvestments',
                        add: "Add account",
                        title: "Give details of your partner's savings and investments",
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.joint.capital.hasSavingsInvestments',
                        key: 'joint.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of jointly owned savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your shareholdings',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'person.capital.hasShareholdings',
                        title: 'Are you the sole owner of any company shares?'
                      },
                      {
                        key: 'person.capital.shareholdings',
                        condition: 'model.person.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of your shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasShareholdings',
                        title: 'Is your partner the sole owner of any company shares?'
                      },
                      {
                        key: 'partner.capital.shareholdings',
                        condition: 'model.partner.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: "Give details of your partner's shareholdings",
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasShareholdings',
                        title: 'Do you and someone else jointly own any company shares?'
                      },
                      {
                        key: 'joint.capital.shareholdings',
                        condition: 'model.joint.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of jointly owned shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 6',
            page: 6,
            title: 'Property or land you own or rent',
            items: [
              'person.property.ownership',
              {
                key: 'person.property.jointPropertyDetails',
                condition: 'model.person.property.ownership === "ownedJointly"',
                title: 'Joint Property Details'
              },
              'person.property.propertyType',
              {
                key: 'person.property.exercisedRightToBuy',
                title: 'Did you purchase your property under the Right to Buy scheme?',
                condition: 'model.person.property.ownership === "ownedByYou" || model.person.property.ownership === "ownedByPartner" || model.person.property.ownership === "ownedJointly"'
              },
              {
                key: 'person.property.rightToBuyInformation',
                condition: 'model.person.property.exercisedRightToBuy'
              },
              {
                key: 'person.property.otherProperty',
                condition: 'model.person.property.ownership === "other"'
              },
              {
                key: 'person.property.estimatedValue',
                description: "Enter the approximate value of your property if it is owned.",
                condition: conditions.toString('person.property.value')
              },
              {
                key: 'person.property.outstandingMortgage',
                description: "Enter any outstanding mortgage balance for this property",
                condition: conditions.toString('person.property.value')
              },
              'person.property.hasAdditionalProperties',
              {
                key: 'person.property.additionalProperties',
                condition: 'model.person.property.hasAdditionalProperties',
                add: "Add property",
                title: 'We need to know value(s) of any property/properties, including land, owned and/or jointly owned by you in this country or abroad other than your main home.',
                validationMessage: 'Enter details of at least one property'
              },
              'person.property.additionalInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 7',
            page: 7,
            title: 'Sale or transfer of property, money or investments',
            items: [
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_12_capital_transfers-gifting-intro.html'
              },
              'deprivationOfAssets.hasDisposedOfAssets',
              {
                key: 'deprivationOfAssets.disposedAssets.assetValue',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.dateOfDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.assetsDisposed',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/deprivationOfAssetsDescription.html',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.reasonForDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/deprivationOfAssetsReason.html',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 8',
            page: 8,
            title: 'Household expenditure',
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-8",
                    items: [
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/householdExpenditureGuidance.html'
                      },
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_05_outgoings-intro.html'
                      },
                      fullWidthPeriodicPaymentField('person.expenses.mortgageLessHousing', {}, '!model.person.income.hasUniversalCredit'),
                      fullWidthPeriodicPaymentField('person.expenses.mortgage', {}, 'model.person.income.hasUniversalCredit'),

                      fullWidthPeriodicPaymentField('person.expenses.rentLessHousing', {}, '!model.person.income.hasUniversalCredit'),
                      fullWidthPeriodicPaymentField('person.expenses.rent', {}, 'model.person.income.hasUniversalCredit'),

                      paymentField('person.expenses.councilTax'),
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/councilTaxDescription.html'
                      },

                      fullWidthPeriodicPaymentField('person.expenses.waterRates'),

                      fullWidthPeriodicPaymentField('person.expenses.serviceCharge'),

                      fullWidthPeriodicPaymentField('person.expenses.groundRent'),

                      'person.expenses.isHomeInsuranceCombined',

                      fullWidthPeriodicPaymentField('person.expenses.homeInsurance'),

                      'person.expenses.hasAdditionalExpenses',

                      {
                        condition: 'model.person.expenses.hasAdditionalExpenses',
                        key: 'person.expenses.additionalExpenses'
                      }

                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 9',
            page: 9,
            title: 'Disability-related expenses',
            items: [
              {
                type: 'fieldset',
                condition: 'model.person.intro.excludeNonResidentialCaretypes === false',
                items: [
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/SG_FCA/templates/dreIntro.html"
                  },
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/SG_FCA/templates/dre.html"
                  },
                  'person.expenses.disabilityRelated.hasOtherDRE',
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/dreGuidance.html',
                    condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                  },
                  {
                    key: 'person.expenses.disabilityRelated.otherDRE',
                    condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                  },
                  {
                    key: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                    htmlClass: 'invisible'
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 10',
            page: 10,
            title: 'Other information',
            items: [
              'otherInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 11',
            page: 11,
            title: 'Declaration',
            items: [
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SG_FCA/templates/declaration-intro.html"
              },
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SG_FCA/templates/declaration-financial-assessment.html"
              },
              'declarations.financialAssessmentDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SG_FCA/templates/declaration-changes.html"
              },
              'declarations.financialChangesDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SG_FCA/templates/declaration-failure-to-pay.html"
              },
              'declarations.failureToPayDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SG_FCA/templates/declaration-deprivation.html"
              },
              'declarations.deprivationDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SG_FCA/templates/declaration-evidence.html"
              },
              'declarations.declaration'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 12',
            page: 12,
            title: 'Cost of care',
            items: [
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/SG_FCA/templates/costOfCareIntro.html'
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Non-residential',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.nonResidential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'SG_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.nonResidential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum non-residential care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.nonResidential.workings']
                      }
                    ]
                  },
                ]
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Short breaks',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.respite.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'SG_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.respite,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum short breaks care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.respite.workings']
                      }
                    ]
                  }

                ]
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Residential weeks 1-4',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.week1Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'SG_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.week1Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential weeks 1-4 care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.week1Residential.workings']
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: 'Residential weeks 5-12',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.week5Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'SG_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.week5Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential weeks 5-12 care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.week5Residential.workings']
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: 'Residential weeks 13+',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.week13Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'SG_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.week13Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential weeks 13+ care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.week13Residential.workings']
                      }
                    ]
                  }
                ]
              }

            ]
          },

          {
            type: "section",
            condition: "areFormNavigationButtonsVisible()",
            items: [
              {
                type: "actions",
                htmlClass: "formPaginationButtons",
                items: [
                  {
                    type: "button",
                    style: "btn-default",
                    title: "Previous",
                    onClick: "prev()"
                  },
                  {
                    type: "button",
                    style: "btn-primary",
                    title: "Next",
                    onClick: "next()"
                  }]
              }
            ]
          }
        ]);

        return formUI;
      }]);
