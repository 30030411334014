'use strict';

function OfflineFormModelFactory(FormModel, formModelService) {

  function OfflineFormModel() {
    this.form = {};
    this.token = null;
    this.serverHash = null;
    this.owner = null;
    this.facts = {};
  }

  OfflineFormModel.fromObject = function (json) {
    json = json || {};

    var offlineFormModel = new OfflineFormModel();
    offlineFormModel.setHash(json.serverHash);
    offlineFormModel.setStoredFormMetaData(json.form);
    offlineFormModel.setToken(json.token);
    offlineFormModel.owner = json.owner;
    return offlineFormModel;
  };

  OfflineFormModel.prototype = {

    constructor: OfflineFormModel,

    getId: function () {
      if (this.form.id) {
        return this.form.id.toString();
      }
    },

    getTokenHeaderValue: function () {
      return this.token;
    },

    setHash: function (hash) {
      if ('string' === typeof hash) {
        this.serverHash = hash;
      }
      return this;
    },

    updateHashFromFields: function () {
      this.serverHash = formModelService.formFieldsHashCode(this.getDirtyFields());
      return this;
    },

    currentHash: function () {
      return formModelService.formFieldsHashCode(this.getDirtyFields());
    },

    getDirtyFields: function () {
      return (this.form && this.form.dirtyFields) || {};
    },

    setStoredFormMetaData: function (form) {
      if ('object' === typeof form && form) {

        this.form = new FormModel(form);

        if (!this.serverHash) {
          this.updateHashFromFields();
        }

        return this;
      }
      throw new Error('form must be object');
    },

    setToken: function (token) {
      if ('string' === typeof token) {
        this.token = token;
        return this;
      }
      throw new Error('token must be string');
    },

    toString: function () {
      angular.toJson(this);
    },

    hasChanges: function () {
      return this.currentHash() !== this.serverHash;
    }
  };

  return OfflineFormModel;

}

OfflineFormModelFactory.$inject = ['FormModel', 'formModelService'];

angular.module('kerp-forms.forms').factory('OfflineFormModel', OfflineFormModelFactory);
