'use strict';

/**
 * This directive is used to toggle the visibility of a fieldset in an ASF form. To use it, add a "collapse: true"
 * property to the fieldset definition.
 */
angular.module('kerp-forms.forms').directive('collapseFieldset', [function () {

  return {
    restrict: 'A',
    scope: true,
    link: function (scope, element, attrs) {

      if (scope.form.collapse) {
        var explainButton = $('<button type="button" class="btn btn-default margin-bottom20">Show breakdown</button>');
        element.parent().append(explainButton);

        explainButton.on('click', function () {
          element.toggleClass('invisible');
          var explainButtonLabel = element.hasClass('invisible') ? 'Show breakdown' : 'Hide breakdown';
          $(this).html(explainButtonLabel);
        });

        scope.$on('$destroy', function () {
          if (explainButton) {
            explainButton.remove();
          }
        });
      }
    }
  };
}]);
