'use strict';
angular.module('kerp-forms.forms').factory('FCR_FORM', [
  'fieldDefinitionService', '$state', '$stateParams', 'FCR_CONDITIONS', 'dateUtils', 'schemaFormHelperService',
  '$injector', '$translate', 'FormUI', 'formModelService', 'htmlService',
  function (fieldDefinitionService, $state, $stateParams, conditions, dateUtils, schemaFormHelperService, $injector,
            $translate, FormUI, formModelService, htmlService) {

    var formUI = new FormUI();

    var form = [
      {
        type: "fieldset",
        condition: "model.page === 1",
        page: 1,
        title: "Tell us who you are",
        items: [
          {
            key: "claim_reference",
            title: "Claim reference",
            description: "You can find this on your latest Housing Benefit and/or Council Tax Reduction correspondence"
          },
          {
            key: "applicant_title",
            type: "selectWithInput",
            title: "Title",
            radios: true,
            options: ["Mr", "Ms", "Mrs", "Miss", "Mx", "Other"],
            other: "Other",
            comment: "If other, please specify"
          },
          {
            key: "applicant_forename",
            title: "Forename(s)"
          },
          {
            key: "applicant_surname",
            title: "Surname"
          },
          {
            key: "applicant_othername",
            type: "radios",
            title: "Do you use, or have you ever used, any other names?"
          },
          {
            key: "applicant_othername_other",
            title: "Please specify",
            condition: 'model.applicant_othername === "Yes"'
          },
          {
            key: "applicant_nationality",
            title: "What is your nationality?"
          },
          {
            key: "applicant_dob",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            title: "Date of birth",
            validationMessage: "The date must be in the format dd/mm/yyyy and the age must be between 16 and 120.",
            maxDate: dateUtils.getYearsBeforeNow(16),
            minDate: dateUtils.getYearsBeforeNow(120)
          },
          {
            key: "applicant_ni",
            title: "National insurance number",
            description: "<p>You can find this on payslips, letters about your benefits or letters from the tax office.</p>"
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 2",
        title: "Where can we get in touch with you?",
        page: 2,
        items: [
          {
            key: "applicant_daytel",
            type: "validationExternal",
            title: "Daytime telephone number",
            watchFields: ["applicant_daytel", "applicant_mob", "applicant_email"],
            hasErrorFn: function (value, model) {
              if (!model.applicant_mob && !model.applicant_email && !value) {
                return 'One of "Daytime telephone number", "Mobile telephone number" and "Email address" is required';
              }
            }
          },
          {
            key: "applicant_mob",
            type: "validationExternal",
            title: "Mobile telephone number",
            watchFields: ["applicant_daytel", "applicant_mob", "applicant_email"],
            hasErrorFn: function (value, model) {
              if (!model.applicant_daytel && !model.applicant_email && !value) {
                return 'One of "Daytime telephone number", "Mobile telephone number" and "Email address" is required';
              }
            }
          },
          {
            key: "applicant_email",
            type: "validationExternal",
            title: "Email address",
            watchFields: ["applicant_daytel", "applicant_mob", "applicant_email"],
            hasErrorFn: function (value, model) {
              if (!model.applicant_mob && !model.applicant_daytel && !value) {
                return 'One of "Daytime telephone number", "Mobile telephone number" and "Email address" is required';
              }
            }
          },
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/FCR/templates/applicantAddressLookup.html"
          },
          {
            key: "applicant_uprn",
            title: "Property reference",
            type: "hidden"
          },
          {
            key: "applicant_address1",
            title: "Address line 1",
            type: "hidden"
          },
          {
            key: "applicant_address2",
            title: "Address line 2",
            type: "hidden"
          },
          {
            key: "applicant_address3",
            title: "Address line 3",
            type: "hidden"
          },
          {
            key: "applicant_postcode",
            title: "Postcode",
            type: "hidden"
          },
          {
            key: "applicant_contactmethod",
            title: "Preferred method of contact",
            type: "select"
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 3",
        page: 3,
        title: "Tell us about you",
        items: [{
            key: "applicant_student",
            title: "Are you a student, apprentice or on youth training?",
            type: "radios",
            description: "<p>By student we mean anyone who is attending a course of study at an educational establishment, including student nurses.</p><p>You count as a student if you are studying:</p><ul><li>a first degree, postgraduate or higher degree; or</li><li>courses for the further training of teachers and youth or community workers; or</li><li>courses for the Diploma of Higher Education, the BTEC/SVEC Higher National Diploma (HND) or Higher National Certificate (HNC), the Diploma in Management Studies, or the Certificate in Education; or</li><li>any other course at a higher level than GCSE, A Level or BTEC/SVEC Ordinary National Diploma (OND) or Ordinary National Certificate (ONC), whether or not leading to a qualification.</li></ul>"
          },
          {
            key: "applicant_fulltime_student",
            title: "Are you a full-time student?",
            type: "select",
            condition: conditions.toString('applicant_fulltime_student'),
            description: "<p>Answer Yes even if in vacation between terms.</p><p>You count as a full time student if:</p><ul><li>No one is in receipt of Child Benefit for you; and</li><li>Your course is classed as full time or part time by your place of education, and not necessarily by the number of hours you attend. eg studying for a GCE, A Level, BTEC National Diploma, National Certificate, first degree, postgraduate or higher degrees, teacher training courses, BTEC Higher National Diploma or Higher National Certificate</li></ul>"
          },
          {
            key: "couple",
            title: "Do you live with your partner as a couple?",
            type: "radios",
            description: "<p> A partner is:</p><ul><li>your husband or wife; or</li><li>the person you live with as if you are husband or wife; or</li><li>the person you have a same sex civil partnership with; or</li><li>the person you live with as if you have a same sex civil partnership.</li></ul><p>You should also answer Yes if your partner is currently living or working away from home.</p><p>You should answer No if your partner has been in legal custody for more than 52 weeks a year.</p>"
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 4",
        title: "Tell us who your partner is",
        page: 4,
        items: [
          {
            key: "partner_title",
            type: "selectWithInput",
            title: "Title",
            options: ["Mr", "Ms", "Mrs", "Miss", "Other"],
            other: "Other",
            comment: "If other, please specify",
            condition: conditions.toString('partner_title')
          },
          {
            key: "partner_forename",
            title: "Their Forename(s)",
            condition: conditions.toString('partner_forename')
          },
          {
            key: "partner_surname",
            title: "Their Surname",
            condition: conditions.toString('partner_surname')
          },
          {
            key: "partner_othername",
            title: "Does your partner use, or have they ever used, any other names?",
            type: "radios",
            condition: conditions.toString('partner_othername')
          },
          {
            key: "partner_othername_other",
            title: "Please specify",
            condition: conditions.toString('partner_othername_other')
          },
          {
            key: "partner_dob",
            title: "Their date of birth",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            validationMessage: "The date must be in the format dd/mm/yyyy and the age must be between 16 and 120.",
            maxDate: dateUtils.getYearsBeforeNow(16),
            minDate: dateUtils.getYearsBeforeNow(120),
            condition: conditions.toString('partner_dob')
          },
          {
            key: "partner_ni",
            title: "Their National insurance number",
            condition: conditions.toString('partner_ni'),
            description: "You can find this on payslips, letters about your benefits or letters from the tax office."
          },
          {
            key: "partner_movedin_date",
            title: "What date did your partner move into this property?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: 'model.couple === "Yes"',
          },
          {
            key: "partner_contact",
            title: "Partner's daytime phone number",
            condition: conditions.toString('partner_contact')
          },
          {
            key: "partner_email",
            title: "What is your partner's e-mail?",
            condition: conditions.toString('partner_email')
          }
        ]
      },
      {
        type: "fieldset",
        condition: "model.page === 5",
        page: 5,
        title: "Tell us about your partner",
        items: [
          {
            key: "partner_residentsincedate",
            title: "When did your partner come to live in the United Kingdom?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_residentsincedate'),
            description: "The United Kingdom (UK) is England, Northern Ireland, Scotland and Wales"
          },
          {
            key: "partner_righttostay",
            title: "Does your partner have the legal right to stay in the UK for as long as they want?",
            type: "radios",
            condition: conditions.toString('partner_righttostay'),
            description: "If you answer No to this question you may not be entitled to any help, you will need to contact us to discuss your circumstances"
          },
          {
            key: "partner_asylum",
            title: "Is your partner receiving support under Part VI of the Immigration and Asylum Act 1999?",
            type: "radios",
            condition: conditions.toString('partner_asylum')
          },
          {
            key: "partner_care",
            title: "Has your partner been under a care order or looked after under the Children Act?",
            type: "radios",
            condition: conditions.toString('partner_care'),
            description: "You may be entitled to more Housing Benefit. You must provide proof of your partner's care order or the fact that they were looked after under the Children Act (or both)."
          },
          {
            key: "partner_student",
            title: "Is your partner a student, an Apprentice or on Youth Training?",
            type: "radios",
            condition: conditions.toString('partner_student'),
            description: "<p>By student we mean anyone who is attending a course of study at an educational establishment, including student nurses..</p> <p>You count as a student if you are studying:</p> <ul> <li>a first degree, postgraduate or higher degree; or</li> <li>courses for the further training of teachers and youth or community workers; or</li> <li>courses for the Diploma of Higher Education, the BTEC/SVEC Higher National Diploma (HND) or Higher National Certificate (HNC), the Diploma in Management Studies, or the Certificate in Education; or</li> <li>any other course at a higher level than GCSE, A Level or BTEC/SVEC Ordinary National Diploma (OND) or Ordinary National Certificate (ONC), whether or not leading to a qualification.</li> </ul>"
          },
          {
            key: "partner_fulltime_student",
            title: "Is your partner a full-time student?",
            type: "select",
            condition: conditions.toString('partner_fulltime_student'),
            description: "<p>Answer Yes even if in vacation between terms.</p><p>You count as a full time student if:</p><ul><li>No one is in receipt of Child Benefit for you; and</li><li>Your course is classed as full time or part time by your place of education, and not necessarily by the number of hours you attend. eg studying for a GCE, A Level, BTEC National Diploma, National Certificate, first degree, postgraduate or higher degrees, teacher training courses, BTEC Higher National Diploma or Higher National Certificate</li></ul>"
          },
        ]
      },
      {
        type: "fieldset",
        condition: "model.page === 6",
        page: 6,
        title: "Universal Credit",
        items: [
          {
            key: "applicant_uc",
            title: "Do you get Universal Credit",
            type: "radios",
            condition: 'model.couple === "No"',
          },
          {
            key: "couple_uc",
            title: "Do you or your partner get Universal Credit?",
            type: "select",
            condition: 'model.couple === "Yes"',
          },
          {
            key: "applicant_pendingucdate",
            title: "When did you claim this benefit?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_pendingucdate')
          },
          {
            key: "couple_pendingucdate",
            title: "When did you claim this benefit?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('couple_pendingucdate')
          },
          {
            key: "applicant_ucvalue",
            title: "How much Universal Credit do you get?",
            condition: conditions.toString('applicant_ucvalue'),
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total before adjustments'."
          },
          {
            key: "applicant_ucnonworkincome",
            title: "How much 'non-work income and other benefits' do you receive, as well as savings and capital?",
            condition: conditions.toString('applicant_ucnonworkincome'),
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total we take off for other benefits'."
          },
          {
            key: "applicant_uctakehomepay",
            title: "What is the amount of take-home pay?",
            condition: conditions.toString('applicant_uctakehomepay'),
            description: "You can find this on your Universal Credit Statement. This figure follows the sentence 'Your total take-home pay for this period is'."
          },
          {
            key: "applicant_uctakenoff",
            title: "What is the total amount taken off for take-home pay by the DWP?",
            condition: conditions.toString('applicant_uctakenoff'),
            description: "You can find this on your Universal Credit Statement. This figure is named 'The total we take off for take-home pay is'."
          },
          {
            key: "partner_ucvalue",
            title: "What is the total amount of Universal Credit that applies to your partner?",
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total before adjustments'.",
            condition: conditions.toString('partner_ucvalue')
          },
          {
            key: "partner_nonworkincome",
            title: "How much 'non-work income and other benefits' does your partner receive, as well as savings and capital?",
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total we take off for other benefits'.",
            condition: conditions.toString('partner_nonworkincome')
          },
          {
            key: "partner_takehomepay",
            title: "What is the amount of take-home pay that applies to your partner?",
            description: "You can find this on your Universal Credit Statement. This figure follows the sentence 'Your total take-home pay for this period is'.",
            condition: conditions.toString('partner_takehomepay')
          },
          {
            key: "partner_takenoff",
            title: "What is the total amount taken off for take-home pay by the DWP that applies to your partner?",
            description: "You can find this on your Universal Credit Statement. This figure is named 'The total we take off for take-home pay is'.",
            condition: conditions.toString('partner_takenoff')
          },
          {
            key: "couple_ucvalue",
            title: "What is the total amount of Universal Credit that applies to you and your partner?",
            condition: conditions.toString('couple_ucvalue'),
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total before adjustments'."
          },
          {
            key: "couple_nonworkincome",
            title: "How much 'non-work income and other benefits' do you or your partner receive, as well as savings and capital?",
            condition: conditions.toString('couple_nonworkincome'),
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total we take off for other benefits'."
          },
          {
            key: "couple_takehomepay",
            title: "What is the amount of take-home pay that applies to you and your partner?",
            condition: conditions.toString('couple_takehomepay'),
            description: "You can find this on your Universal Credit Statement. This figure follows the sentence 'Your total take-home pay for this period is'."
          },
          {
            key: "couple_takenoff",
            title: "What is the total amount taken off for take-home pay by the DWP that applies to you or your partner?",
            description: "You can find this on your Universal Credit Statement. This figure is named 'The total we take off for take-home pay is'.",
            condition: conditions.toString('couple_takenoff')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 7",
        page: 7,
        title: "Benefits - Income Support",
        items: [
          {
            key: "applicant_incomesupport",
            title: "Do you get Income Support?",
            type: "radios"
          },
          {
            key: "applicant_incomesupport_date",
            title: "When did you start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you are not sure about the exact date please put an approximate date.",
            condition: conditions.toString('applicant_incomesupport_date')
          },
          {
            key: "applicant_incomesupport_claimdate",
            title: "When did you claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_incomesupport_claimdate')
          },
          {
            key: "partner_incomesupport",
            title: "Does your partner get Income Support?",
            type: "radios",
            condition: 'model.couple === "Yes"',
          },
          {
            key: "partner_incomesupport_date",
            title: "When did they start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you are not sure about the exact date please put an approximate date.",
            condition: conditions.toString('partner_incomesupport_date')
          },
          {
            key: "partner_incomesupport_claimdate",
            title: "When did they claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_incomesupport_claimdate')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 8",
        page: 8,
        title: "Benefits - Jobseeker's Allowance",
        items: [
          {
            key: "applicant_jsa",
            title: "Do you get income-based Jobseeker's Allowance (JSA)?",
            type: "radios",
            condition: conditions.toString('applicant_jsa'),
            description: "<p>There are two types of Jobseeker's Allowance:</p><ul><li>Income-based Jobseeker's Allowance, based on  income and savings</li><li>Contribution-based Jobseeker's Allowance, paid for up to 182 days and based on National Insurance contributions</li></ul><p>Only answer Yes to this question if you get <strong>income-based</strong> Jobseeker's Allowance, we will ask later about contribution-based Jobseeker's Allowance.</p><p>Please answer No if you receive contribution-based Jobseeker's Allowance or if you don't know what type of Jobseeker's Allowance you get.</p><p>If you don't know which type you get, it will say on your award letter letter from the Department for Work and Pensions (DWP).</p>"
          },
          {
            key: "applicant_jsa_date",
            title: "When did you start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_jsa_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "applicant_jsa_claimdate",
            title: "When did you claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_jsa_claimdate')
          },
          {
            key: "partner_jsa",
            title: "Does your partner get income-based Jobseeker's Allowance (JSA)?",
            type: "radios",
            titleMap: [
              {
                value: "Yes",
                name: "Yes"
              },
              {
                value: "Waiting",
                name: "They are waiting to hear"
              },
              {
                value: "No",
                name: "No"
              }],
            condition: conditions.toString('partner_jsa'),
            description: "<p>There are two types of Jobseeker's Allowance:</p><ul><li>Income-based Jobseeker's Allowance, based on  income and savings</li><li>Contribution-based Jobseeker's Allowance, paid for up to 182 days and based on National Insurance contributions</li></ul><p>Only answer Yes to this question if your partner gets <strong>income-based</strong> Jobseeker's Allowance, we will ask later about contribution-based Jobseeker's Allowance.</p><p>Please answer No if your partner receives contribution-based Jobseeker's Allowance or if you don't know what type of Jobseeker's Allowance they get.</p><p>If you don't know which type they get, it will say on their award letter letter from the Department for Work and Pensions (DWP).</p>"
          },
          {
            key: "partner_jsa_date",
            title: "When did your partner start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_jsa_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "partner_jsa_claimdate",
            title: "When did your partner claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_jsa_claimdate')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 9",
        page: 9,
        title: "Benefits - Income-related Employment and Support Allowance",
        items: [
          {
            key: "applicant_incomeesa",
            title: "Do you get income-related Employment and Support Allowance?",
            type: "radios",
            condition: conditions.toString('applicant_incomeesa'),
            description: "<p>Only answer Yes to this question if you get <strong>income-related</strong> Employment and Support Allowance, we will ask later about contribution-based Employment and Support Allowance.</p><p>Please answer No if you only receive contribution-based Employment and Support Allowance or do not know what type of Employment and Support Allowance you receive. If you don't know which type you get, look on your award letter from the Department for Work and Pensions (DWP).</p>"
          },
          {
            key: "applicant_incomeesa_date",
            title: "When did you start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_incomeesa_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "applicant_incomeesa_claimdate",
            title: "When did you claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_incomeesa_claimdate')
          },
          {
            key: "applicant_incomeesarate",
            title: "Which rate of Employment and Support Allowance are you getting?",
            type: "select",
            condition: conditions.toString('applicant_incomeesarate'),
            description: "Employment and Support Allowance is paid at several different rates. Initially, for up to 13 weeks, it is paid at the basic rate. After 13 weeks either a work-related activity component, or a support component can be added to that basic rate."
          },
          {
            key: "partner_incomeesa",
            title: "Does your partner get income-related Employment and Support Allowance?",
            type: "radios",
            condition: conditions.toString('partner_incomeesa'),
            description: "<p>Only answer Yes to this question if your partner gets <strong>income-related</strong> Employment and Support Allowance, we will ask later about contribution-based Employment and Support Allowance.</p><p>Please answer No if your partner only receives contribution-based Employment and Support Allowance or you don't know what type of Employment and Support Allowance they receive.</p><p>If you don't know which type your partner gets, it will say on their award letter from the Department for Work and Pensions (DWP).</p>"
          },
          {
            key: "partner_incomeesa_date",
            title: "When did they start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_incomeesa_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "partner_incomeesa_claimdate",
            title: "When did they claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_incomeesa_claimdate')
          },
          {
            key: "partner_incomeesarate",
            title: "Which rate of Employment and Support Allowance are they getting?",
            type: "select",
            condition: conditions.toString('partner_incomeesarate'),
            description: "Employment and Support Allowance is paid at several different rates. Initially, for up to 13 weeks, it is paid at the basic rate. After 13 weeks either a work-related activity component, or a support component can be added to that basic rate."
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 10",
        page: 10,
        title: "Benefits - Pension Credit",
        items: [
          {
            key: "applicant_pensioncreditguarantee",
            title: "Do you get Pension Credit (the Guarantee part)?",
            type: "radios",
            description: "<p>There are 2 parts to Pension Credit:</p><ul><li>Savings Credit, and</li><li>Guarantee Credit</li></ul><p>You can get one or both parts. Only answer Yes if your award contains the Guarantee part, we will ask about the Savings part later. If you are not sure, check your award letter from the Pension Service.</p>"
          },
          {
            key: "applicant_pensioncreditguarantee_date",
            title: "When did you start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_pensioncreditguarantee_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "applicant_pensioncreditguarantee_claimdate",
            title: "When did you claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_pensioncreditguarantee_claimdate')
          },
          {
            key: "partner_pensioncreditguarantee",
            title: "Does your partner get Pension Credit (the Guarantee part)?",
            type: "radios",
            description: "<p>There are 2 parts to Pension Credit:</p><ul><li>Savings Credit, and</li><li>Guarantee Credit</li></ul><p>You can get one or both parts. Only answer Yes if your partner's award contains the Guarantee part, we will ask about the Savings part later. If you are not sure, check your partner's award letter from the Pension Service.</p>",
            condition: conditions.toString('partner_pensioncreditguarantee')
          },
          {
            key: "partner_pensioncreditguarantee_date",
            title: "When did your partner start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_pensioncreditguarantee_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "partner_pensioncreditguarantee_claimdate",
            title: "When did your partner claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_pensioncreditguarantee_claimdate')
          },
          {
            key: "applicant_pensioncreditsavings",
            title: "Do you get Pension Credit (the Savings part)?",
            type: "radios"
          },
          {
            key: "applicant_pensioncreditsavings_date",
            title: "When did you start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_pensioncreditsavings_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "applicant_pensioncreditsavings_claimdate",
            title: "When did you claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_pensioncreditsavings_claimdate')
          },
          {
            key: "partner_pensioncreditsavings",
            title: "Does your partner get Pension Credit (the Savings part)?",
            type: "radios",
            condition: conditions.toString('partner_pensioncreditsavings')
          },
          {
            key: "partner_pensioncreditsavings_date",
            title: "When did they start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_pensioncreditsavings_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "partner_pensioncreditsavings_claimdate",
            title: "When did they claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_pensioncreditsavings_claimdate')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 11",
        page: 11,
        title: "Benefits - Disability Living Allowance",
        items: [
          {
            key: "applicant_dla",
            title: "Do you get Disability Living Allowance?",
            type: "radios",
            condition: 'model.couple === "No"',
          },
          {
            key: "applicant_dlafrequency",
            title: "How often do you get Disability Living Allowance?",
            type: "select",
            condition: conditions.toString('applicant_dlafrequency')
          },
          {
            key: "applicant_dlacare4weeks",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£72.65 (Middle)</li><li>£108.55 (High)</li></ul>",
            condition: conditions.toString('applicant_dlacare4weeks')
          },
          {
            key: "applicant_dlacareweek",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£72.65 (Middle)</li><li>£108.55 (High)</li></ul>",
            condition: conditions.toString('applicant_dlacareweek')
          },
          {
            key: "applicant_dlamot4weeks",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£75.75 (High)</li></ul>",
            condition: conditions.toString('applicant_dlamot4weeks')
          },
          {
            key: "applicant_dlamotweek",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£75.75 (High)</li></ul>",
            condition: conditions.toString('applicant_dlamotweek')
          },
          {
            key: "couple_dla",
            title: "Do you or your partner get Disability Living Allowance?",
            type: "select",
            condition: 'model.couple === "Yes"',
          },
          {
            key: "applicant_dlafrequency1",
            title: "How often do you get Disability Living Allowance?",
            type: "select",
            condition: conditions.toString('applicant_dlafrequency1')
          },
          {
            key: "applicant_dlacare4weeks1",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£72.65 (Middle)</li><li>£108.55 (High)</li></ul>",
            condition: conditions.toString('applicant_dlacare4weeks1')
          },
          {
            key: "applicant_dlacareweek1",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£72.65 (Middle)</li><li>£108.55 (High)</li></ul>",
            condition: conditions.toString('applicant_dlacareweek1')
          },
          {
            key: "applicant_dlamot4weeks1",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£75.75 (High)</li></ul>",
            condition: conditions.toString('applicant_dlamot4weeks1')
          },
          {
            key: "applicant_dlamotweek1",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£75.75 (High)</li></ul>",
            condition: conditions.toString('applicant_dlamotweek1')
          },
          {
            key: "partner_dlafrequency",
            title: "How often does your partner get Disability Living Allowance?",
            type: "select",
            condition: conditions.toString('partner_dlafrequency')
          },
          {
            key: "partner_dlacare4weeks",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£72.65 (Middle)</li><li>£108.55 (High)</li></ul>",
            condition: conditions.toString('partner_dlacare4weeks')
          },
          {
            key: "partner_dlacareweek",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£72.65 (Middle)</li><li>£108.55 (High)</li></ul>",
            condition: conditions.toString('partner_dlacareweek')
          },
          {
            key: "partner_dlamot4weeks",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£75.75 (High)</li></ul>",
            condition: conditions.toString('partner_dlamot4weeks')
          },
          {
            key: "partner_dlamotweek",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Low)</li><li>£75.75 (High)</li></ul>",
            condition: conditions.toString('partner_dlamotweek')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 12",
        page: 12,
        title: "Benefits - Personal Independence Payment",
        items: [
          {
            key: "applicant_pip",
            title: "Do you get Personal Independence Payment or Armed Forces Independence Payment?",
            type: "radios",
            condition: 'model.couple === "No"',
          },
          {
            key: "couple_pip",
            title: "Do you or your partner get Personal Independence Payment or Armed Forces Independence Payment?",
            type: "select",
            condition: 'model.couple === "Yes"',
          },
          {
            key: "applicant_pipdaily",
            title: "What rate of your Personal Independence Payment or Armed Forces Independence Payment is for Daily Living?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£72.65 (Standard)</li><li>£108.55 (Enhanced)</li></ul>",
            condition: conditions.toString('applicant_pipdaily')
          },
          {
            key: "applicant_pipmot",
            title: "What rate of your Personal Independence Payment or Armed Forces Independence Payment is for Mobility?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Standard)</li><li>£75.75 (Enhanced)</li></ul>",
            condition: conditions.toString('applicant_pipmot')
          },
          {
            key: "partner_pipdaily",
            title: "What rate of your partner's Personal Independence Payment or Armed Forces Independence Payment is for Daily Living?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£72.65 (Standard)</li><li>£108.55 (Enhanced)</li></ul>",
            condition: conditions.toString('partner_pipdaily')
          },
          {
            key: "partner_pipmot",
            title: "What rate of your partner's Personal Independence Payment or Armed Forces Independence Payment is for Mobility?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Standard)</li><li>£75.75 (Enhanced)</li></ul>",
            condition: conditions.toString('partner_pipmot')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 13",
        page: 13,
        title: "Benefits - Attendance Allowance",
        items: [
          {
            key: "applicant_aa",
            title: "Do you get Attendance Allowance?",
            type: "radios",
            condition: 'model.couple === "No"',
          },
          {
            key: "couple_aa",
            title: "Do you or your partner get Attendance Allowance?",
            type: "select",
            condition: 'model.couple === "Yes"',
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 14",
        page: 14,
        title: "Benefits - Carer's Allowance",
        items: [
          {
            key: "applicant_calookingafter",
            title: "Does anyone else get Carer's Allowance for looking after you?",
            type: "radios",
            condition: 'model.couple === "No"',
          },
          {
            key: "applicant_carername",
            title: "What's their name?",
            condition: conditions.toString('applicant_carername')
          },
          {
            key: "applicant_careraddress1",
            title: "What is their address? Address Line 1",
            condition: conditions.toString('applicant_careraddress1')
          },
          {
            key: "applicant_careraddress2",
            title: "Address line 2",
            condition: conditions.toString('applicant_careraddress2')
          },
          {
            key: "applicant_careraddress3",
            title: "Address line 3",
            condition: conditions.toString('applicant_careraddress3')
          },
          {
            key: "applicant_carerpostcode",
            title: "Postcode",
            condition: conditions.toString('applicant_carerpostcode')
          },
          {
            key: "applicant_ca_entitlement",
            title: "Do you have an underlying entitlement to Carer's Allowance?",
            condition: conditions.toString('applicant_ca_entitlement'),
            type: "radios"
          },
          {
            key: "applicant_ca8weeks",
            title: "In the last eight weeks have you received Carer's Allowance for looking after a person with a disability?",
            type: "radios",
            condition: conditions.toString('applicant_ca8weeks')
          },
          {
            key: "applicant_ca",
            title: "Are you getting Carer's Allowance now?",
            type: "radios",
            condition: conditions.toString('applicant_ca')
          },
          {
            key: "applicant_ca_receiving",
            title: "What is the name of the person you are receiving Carer's Allowance for?",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            type: "help",
            helpvalue: "<label>What is their address?</label>",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_ca_receiving_addressline1",
            title: "Address line 1",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_ca_receiving_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_ca_receiving_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_ca_receiving_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_cafrequency",
            title: "How often do you get Carer's Allowance?",
            type: "radios",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_castop",
            title: "Why have you stopped getting Carer's Allowance ?",
            type: "select",
            condition: conditions.toString('applicant_castop')
          },
          {
            key: "applicant_carate",
            title: "How much Carer's Allowance do you get?",
            condition: conditions.toString('applicant_carate')
          },
          {
            key: "couple_calookingafter",
            title: "Does anyone else get Carer's Allowance for looking after you or your partner?",
            type: "radios",
            condition: 'model.couple === "Yes"',
          },
          {
            key: "carer_name",
            title: "What's their name?",
            condition: conditions.toString('carer_name')
          },
          {
            key: "carer_address1",
            title: "What's their address? Address line 1",
            condition: conditions.toString('carer_address1')
          },
          {
            key: "carer_address2",
            title: "Address line 2",
            condition: conditions.toString('carer_address2')
          },
          {
            key: "carer_address3",
            title: "Address line 3",
            condition: conditions.toString('carer_address3')
          },
          {
            key: "carer_postcode",
            title: "Postcode?",
            condition: conditions.toString('carer_postcode')
          },
          {
            key: "couple_ca_entitlement",
            title: "Do you or your partner have an underlying entitlement to Carer's Allowance?",
            condition: conditions.toString('couple_ca_entitlement'),
            type: "radios",
            description: "Answer yes if either of the following apply to you or your partner:<ul><li>you or your partner have an underlying entitlement to Carer's Allowance but are not receiving it because you or your partner are receiving another state benefit</li><li>it has stopped or been sanctioned due to the loss of benefits for benefit offences rule</li></ul>"
          },
          {
            key: "couple_ca8weeks",
            title: "In the last eight weeks have you received Carer's Allowance for looking after a person with a disability?",
            type: "select",
            condition: conditions.toString('couple_ca8weeks')
          },
          {
            key: "applicant_ca1",
            title: "Are you getting Carer's Allowance now?",
            type: "radios",
            condition: conditions.toString('applicant_ca1')
          },

          {
            key: "applicant_ca_receiving1",
            title: "What is the name of the person you are receiving Carer's Allowance for?",
            condition: conditions.toString('applicant_ca1')
          },
          {
            type: "help",
            helpvalue: "<label>What is their address?</label>",
            condition: conditions.toString('applicant_ca1')
          },
          {
            key: "applicant_ca_receiving1_addressline1",
            title: "Address line 1",
            condition: conditions.toString('applicant_ca1')
          },
          {
            key: "applicant_ca_receiving1_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_ca1')
          },
          {
            key: "applicant_ca_receiving1_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_ca1')
          },
          {
            key: "applicant_ca_receiving1_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_ca1')
          },

          {
            key: "applicant_cafrequency1",
            title: "How often do you get Carer's Allowance?",
            type: "radios",
            condition: conditions.toString('applicant_cafrequency1')
          },
          {
            key: "applicant_castop1",
            title: "Why have you stopped getting Carer's Allowance ?",
            type: "select",
            condition: conditions.toString('applicant_castop1')
          },
          {
            key: "applicant_carate1",
            title: "How much Carer's Allowance do you get?",
            condition: conditions.toString('applicant_carate1')
          },
          {
            key: "partner_ca",
            title: "Is your partner getting Carer's Allowance now?",
            type: "select",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_ca_receiving",
            title: "What is the name of the person your partner is receiving Carer's Allowance for?",
            condition: conditions.toString('partner_ca')
          },
          {
            type: "help",
            helpvalue: "<label>What is their address?</label>",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_ca_receiving_addressline1",
            title: "Address line 1",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_ca_receiving_addressline2",
            title: "Address line 2",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_ca_receiving_addressline3",
            title: "Address line 3",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_ca_receiving_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_castop",
            title: "Why has your partner stopped getting Carer's Allowance?",
            type: "select",
            condition: conditions.toString('partner_castop')
          },
          {
            key: "partner_cafrequency",
            title: "How often does your partner get Carer's Allowance?",
            type: "select",
            condition: conditions.toString('partner_cafrequency')
          },
          {
            key: "partner_carate",
            title: "How much Carer's Allowance does your partner get?",
            condition: conditions.toString('partner_carate')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 15",
        page: 15,
        title: "Benefits",
        items: [
          {
            key: "applicant_disabled",
            title: "Are you registered blind, severely mentally impaired, long term sick or disabled?",
            type: "checkboxes",
            description: "If you have been registered blind within the previous 6 months then still select that you are blind"
          },
          {
            key: "partner_disabled",
            title: "Is your partner registered blind, severely mentally impaired, long term sick or disabled?",
            type: "checkboxes",
            description: "If your partner has been registered blind within the previous 6 months then still select that they are blind",
            condition: conditions.toString('partner_disabled')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 16",
        page: 16,
        title: "Living away from home",
        items: [
          {
            key: "applicant_livingaway",
            title: "Are you living away from your usual home at the moment?",
            type: "radios",
            condition: conditions.toString('applicant_livingaway')
          },
          {
            key: "couple_livingaway",
            title: "Are you or your partner living away from your usual home at the moment?",
            type: "select",
            condition: conditions.toString('couple_livingaway')
          },
          {
            key: "applicant_livingaway_reason",
            title: "Why are you not living at home?",
            condition: conditions.toString('applicant_livingaway_reason')
          },
          {
            key: "applicant_livingaway_lastlive",
            title: "When did you last live at home?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_livingaway_lastlive')
          },
          {
            key: "applicant_livingaway_intendtoreturn",
            title: "Do you intend to return home?",
            type: "radios",
            condition: conditions.toString('applicant_livingaway_intendtoreturn')
          },
          {
            key: "applicant_livingaway_returndate",
            title: "When do you expect to go back home?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_livingaway_returndate')
          },
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/FCR/templates/applicantLivingAwayLookup.html",
            condition: conditions.toString('template_applicant_livingaway_address')
          },
          {
            key: "applicant_livingaway_uprn",
            title: "Property reference",
            type: "hidden",
            condition: conditions.toString('applicant_livingaway_uprn')
          },
          {
            key: "applicant_livingaway_addressline1",
            title: "Address Line 1",
            type: "hidden",
            condition: conditions.toString('applicant_livingaway_addressline1')
          },
          {
            key: "applicant_livingaway_addressline2",
            title: "Address line 2",
            type: "hidden",
            condition: conditions.toString('applicant_livingaway_addressline2')
          },
          {
            key: "applicant_livingaway_addressline3",
            title: "Address line 3",
            type: "hidden",
            condition: conditions.toString('applicant_livingaway_addressline3')
          },
          {
            key: "applicant_livingaway_postcode",
            title: "Postcode",
            type: "hidden",
            condition: conditions.toString('applicant_livingaway_postcode')
          },
          {
            key: "applicant_livingaway_sublet",
            title: "Have you sublet your home?",
            type: "radios",
            description: "Answer Yes if you have a commercial arrangement with someone and you charge them a regular rent for living at the property.",
            condition: conditions.toString('applicant_livingaway_sublet')
          },
          {
            key: "applicant_livingaway_lives",
            title: "Who lives there now?",
            condition: conditions.toString('applicant_livingaway_lives')
          },
          {
            key: "partner_livingaway_reason",
            title: "Why is your partner not living at home?",
            condition: conditions.toString('partner_livingaway_reason')
          },
          {
            key: "partner_livingaway_lastlive",
            title: "When did your partner last live at home?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_livingaway_lastlive')
          },
          {
            key: "partner_livingaway_intendtoreturn",
            title: "Does your partner intend to return home?",
            type: "radios",
            condition: conditions.toString('partner_livingaway_intendtoreturn')
          },
          {
            key: "partner_livingaway_returndate",
            title: "When does your partner expect to go back home?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_livingaway_returndate')
          },
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/FCR/templates/partnerLivingAwayLookup.html",
            condition: conditions.toString('template_partner_livingaway_address')
          },
          {
            key: "partner_livingaway_uprn",
            title: "Property reference",
            type: "hidden",
            condition: conditions.toString('partner_livingaway_uprn')
          },
          {
            key: "partner_livingaway_addressline1",
            title: "Address line 1",
            type: "hidden",
            condition: conditions.toString('partner_livingaway_addressline1')
          },
          {
            key: "partner_livingaway_addressline2",
            title: "Address line 2",
            type: "hidden",
            condition: conditions.toString('partner_livingaway_addressline2')
          },
          {
            key: "partner_livingaway_addressline3",
            title: "Address line 3",
            type: "hidden",
            condition: conditions.toString('partner_livingaway_addressline3')
          },
          {
            key: "partner_livingaway_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_livingaway_postcode'),
            type: "hidden"
          },
          {
            key: "partner_livingaway_sublet",
            title: "Has your partner sublet their home?",
            type: "radios",
            description: "Answer Yes if they have a commercial arrangement with someone and charge them a regular rent for living at the property.",
            condition: conditions.toString('partner_livingaway_sublet')
          },
          {
            key: "partner_livingaway_lives",
            title: "Who lives there now?",
            condition: conditions.toString('partner_livingaway_lives')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 17",
        page: 17,
        title: "You and your household  Children",
        items: [
          {
            key: "applicant_pregnant",
            title: "Are you expecting a baby?",
            type: "radios"
          },
          {
            key: "partner_pregnant",
            title: "Is your partner expecting a baby?",
            type: "radios",
            condition: conditions.toString('partner_pregnant')
          },
          {
            key: "applicant_duedate",
            title: "When is the baby due?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_duedate')
          },
          {
            key: "partner_duedate",
            title: "When is the baby due?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_duedate')
          },
          {
            key: "couple_dependantchildren",
            title: "Do you or your partner live with any dependant children?",
            type: "radios",
            condition: conditions.toString('couple_dependantchildren'),
            description: "A dependant child is one who you or your partner usually get child benefit for. Answer Yes if you are responsible for a child, even if you do not get child benefit for that child. Do not count any child whose parent or guardian also lives with you or any child who has been in hospital continuously for 52 weeks (one year) or more."
          },
          {
            key: "applicant_dependantchildren",
            title: "Do you live with any dependant children?",
            type: "radios",
            condition: conditions.toString('applicant_dependantchildren'),
            description: "A dependant child is one who you or your partner usually get child benefit for. Answer Yes if you are responsible for a child, even if you do not get child benefit for that child. Do not count any child whose parent or guardian also lives with you or any child who has been in hospital continuously for 52 weeks (one year) or more."
          },
          {
            key: "dependantchildren_no",
            title: "How many dependant children live in your home?",
            condition: conditions.toString('dependantchildren_no')
          },
          {
            key: 'dependentChildren',
            condition: 'model.applicant_dependantchildren === "Yes" || model.couple_dependantchildren === "Yes"',
            add: "Add Child",
            title: 'Dependent children details',
            validationMessage: 'Complete all required fields for at least one child',
            items: [
              {
                key: "dependentChildren[].child_forename",
                title: "What's the Child's forename(s)?",
              },
              {
                key: "dependentChildren[].child_surname",
                title: "What is the Child's last name?",
              },
              {
                key: "dependentChildren[].child_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
              },
              {
                key: "dependentChildren[].child_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
              },
              {
                key: "dependentChildren[].child_relationship_applicant",
                title: "What is the Child's relationship to you?",
              },
              {
                key: "dependentChildren[].child_relationship_partner",
                title: "What is the Child's relationship to your partner?",
                condition: 'model.couple === "Yes"'
              },
              {
                key: "dependentChildren[].child_cb_applicant",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: 'model.couple === "No"'
              },
              {
                key: "dependentChildren[].child_cb_partner",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "My partner does",
                          "Someone else does"
                         ],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: 'model.couple === "Yes"'
              },
              {
                key: "dependentChildren[].child_applicantaddress",
                title: "Is the Child's usual address different to yours?",
                type: "radios",
                condition: 'model.couple === "No"'
              },
              {
                key: "dependentChildren[].child_coupleaddress",
                title: "Is the Child's usual address different to yours or your partners?",
                type: "radios",
                condition: 'model.couple === "Yes"'
              },
              {
                type: 'fieldset',
                key: "dependentChildren[].child_address",
                condition: 'model.dependentChildren[arrayIndex].child_applicantaddress === "Yes" || model.dependentChildren[arrayIndex].child_coupleaddress === "Yes"',
                items: [
                  {
                    type: "help",
                    helpvalue: "<label>What address does the Child usually live at?</label>",
                  },
                  {
                    key: "dependentChildren[].child_address.child_addressline1",
                    title: "Address line 1",
                  },
                  {
                    key: "dependentChildren[].child_address.child_addressline2",
                    title: "Address line 2",
                  },
                  {
                    key: "dependentChildren[].child_address.child_addressline3",
                    title: "Address line 3",
                  },
                  {
                    key: "dependentChildren[].child_address.child_postcode",
                    title: "Postcode",
                  },

                ],
              },
              {
                key: "dependentChildren[].child_childcare_couple",
                title: "Do you or your partner pay any childcare costs for this child to a registered childminder, a nursery or an out of school club?",
                type: "radios",
                condition: 'model.couple === "Yes"'
              },
              {
                key: "dependentChildren[].child_childcare_applicant",
                title: "Do you pay any childcare costs for this child to a registered childminder, a nursery or an out of school club?",
                type: "radios",
                condition: 'model.couple === "No"'
              },
              {
                key: "dependentChildren[].child_childcare_number",
                title: "How many childcare providers do you pay?",
                condition: 'model.dependentChildren[arrayIndex].child_childcare_applicant === "Yes" || model.dependentChildren[arrayIndex].child_childcare_couple === "Yes"',
              },
              {
                key: 'dependentChildren[].child_childcare',
                condition: 'model.dependentChildren[arrayIndex].child_childcare_applicant === "Yes" || model.dependentChildren[arrayIndex].child_childcare_couple === "Yes"',
                add: "Add Child Care Provider",
                title: 'Child Care Provider details',
                validationMessage: 'Complete all required fields for at least one child care provider',
                items: [


                  {
                    key: "dependentChildren[].child_childcare[].child_childcare_provider",
                    title: "What is the name of the childcare provider?",
                  },
                  {
                    key: "dependentChildren[].child_childcare[].child_childcare_addressline1",
                    title: "What is the address of the childcare provider? Address line 1",
                  },
                  {
                    key: "dependentChildren[].child_childcare[].child_childcare_addressline2",
                    title: "Address line 2",
                  },
                  {
                    key: "dependentChildren[].child_childcare[].child_childcare_addressline3",
                    title: "Address line 3",
                  },
                  {
                    key: "dependentChildren[].child_childcare[].child_childcare_postcode",
                    title: "Postcode",
                  },
                  {
                    key: "dependentChildren[].child_childcare[].child_childcare_tel",
                    title: "Telephone number",
                  },
                  {
                    key: "dependentChildren[].child_childcare[].child_childcare_ofsted",
                    title: "What is their OFSTED Registration Number?",
                  },
                  {
                    key: "dependentChildren[].child_childcare[].child_childcare_amount",
                    title: "How much do you pay?",
                    description: "If you do not pay the same amount each week put how much you pay on average?"
                  },
                  {
                    key: "dependentChildren[].child_childcare[].child_childcare_frequency",
                    title: "How often?",
                    type: "select",
                  },

                ]
              },
              {
                key: "dependentChildren[].child_blind",
                title: "Is this child registered blind?",
                type: "radios",
              },
              {
                key: "dependentChildren[].child_dla_applicant",
                title: "Do you get Disability Living Allowance for this child?",
                type: "radios",
                condition: 'model.couple === "No"'
              },
              {
                key: "dependentChildren[].child_dla_couple",
                title: "Do you or your partner get Disability Living Allowance for this child?",
                type: "radios",
                condition: 'model.couple === "Yes"'
              },
              {
                key: "dependentChildren[].child_dlarate",
                title: "How often do you get Disability Living Allowance for this child?",
                type: "radios",
                condition: 'model.dependentChildren[arrayIndex].child_dla_applicant === "Yes" || model.dependentChildren[arrayIndex].child_dla_couple === "I get DLA for this Child" || model.dependentChildren[arrayIndex].child_dla_couple === "My partner gets DLA for this Child"'
              },
              {
                key: "dependentChildren[].child_dlacareweek",
                title: "How much of the Disability Living Allowance for this child is for care?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: 'model.dependentChildren[arrayIndex].child_dla_applicant === "Yes" || model.dependentChildren[arrayIndex].child_dla_couple === "Yes"'
              },
              {
                key: "dependentChildren[].child_dlamotweek",
                title: "How much of the Disability Living Allowance for this child is for mobility (including any payment to Motability fund)?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: 'model.dependentChildren[arrayIndex].child_dla_applicant === "Yes" || model.dependentChildren[arrayIndex].child_dla_couple === "Yes"'
              },
              {
                key: "dependentChildren[].child_pip",
                title: "Do they get a Personal Independence Payment",
                type: "radios",
              },
              {
                key: "dependentChildren[].child_pipdaily",
                title: "What rate of this childs Personal Independence Payment is for Daily Living?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: 'model.dependentChildren[arrayIndex].child_pip === "Yes"'
              },
              {
                key: "dependentChildren[].child_pipmot",
                title: "What rate of this childs Personal Independence Payment is for Mobility?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: 'model.dependentChildren[arrayIndex].child_pip === "Yes"'
              },
              {
                key: "dependentChildren[].child_bedroom",
                title: "Is this child unable to share a bedroom because of their disability?",
                type: "radios",
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              }

            ]
          },
        ]
      },
      {
        type: "fieldset",
        condition: "model.page === 18",
        page: 18,
        title: "Other Adults",
        items: [
          {
            type: "help",
            helpvalue: "<p>Other adults in your household</p><ul><li>This question is about people over 16 who live in your home and who you do not get Child Benefit for. For example they could be your grown up son, an elderly relative or a long-term friend. They might be a joint tenant with you whose name is on your tenancy agreement, or someone who pays you 'board' or 'keep'.</li></ul><p>Do NOT include anyone who:</p><ul><li>is your wife, husband or same sex partner (you should have already told us about this person),</li><li>is a dependant child you or your partner get Child Benefit for; or</li><li>has a separate tenancy agreement with the landlord (in a shared house for example); or</li><li>is in legal custody (such as prison, on remand, or young offender institution); or</li><li>has been in hospital for 52 weeks (one year) or more.</li></ul>"
          },
          {
            key: "other_adults",
            title: "Do any other adults live in your home?",
            type: "radios"
          },
        ]
      },

      {
        type: "fieldset",
        condition: "model.page === 19",
        page: 19,
        title: "Joint Tenants",
        items: [
          {
            type: "help",
            helpvalue: "This section is about the Joint Tenants in your Household",
            condition: 'model.other_adults === "Yes"'
          },
          {
            key: "jointtenants",
            title: "How many of the other adults living with you are joint tenants?",
            type: "number",
            condition: 'model.other_adults === "Yes"',
            description: "Only list joint tenants on this page. You will be able to tell us about boarders and other non-dependant adults on the following pages."
          },
          {
            key: 'jointtenant',
            condition: 'model.jointtenants > 0',
            add: "Add Joint Tenant",
            title:'Joint Tenant details',
            items: [
              {
                key: "jointtenant[].jointtenant_forename",
                title: "What is their forename(s)?",
                condition: conditions.toString('jointtenant_forename')
              },
              {
                key: "jointtenant[].jointtenant_surname",
                title: "What is their last name?",
                condition: conditions.toString('jointtenant_surname')
              },
              {
                key: "jointtenant[].jointtenant_dob",
                title: "What is their date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('jointtenant_dob')
              },
              {
                key: "jointtenant[].jointtenant_gender",
                title: "Are they Male or Female?",
                type: "radios",
                condition: conditions.toString('jointtenant_gender')
              },
              {
                key: "jointtenant[].jointtenant_relationship_applicant",
                title: "What is this persons relationship to you?",
                condition: 'model.couple === "No"'
              },
              {
                key: "jointtenant[].jointtenant_relationship_couple",
                title: "What is this persons relationship to you or your partner",
                condition: 'model.couple === "Yes"'
              },
              {
                key: "jointtenant[].jointtenant_relationship_otheradults",
                title: "Is this person living with any other adult, who normally lives with you, as if they were married or civil partners?",
                type: "radios",
              },
              {
                key: "jointtenant[].jointtenant_relationship_otheradults_name",
                title: "Who are they in a relationship with?",
                condition: 'model.jointtenant[arrayIndex].jointtenant_relationship_otheradults === "Yes"'
              },
              {
                key: "jointtenant[].jointtenant_student",
                type: "radios",
                title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
                condition: conditions.toString('jointtenant_student')
              },
              {
                key: "jointtenant[].jointtenant_mental",
                title: "Is this person severely mentally impaired?",
                type: "radios",
                condition: conditions.toString('jointtenant_mental')
              },
              {
                key: "jointtenant[].jointtenant_blind",
                title: "Is this person registered blind",
                type: "radios",
                condition: conditions.toString('jointtenant_blind')
              },
            ]
          },
        ]
      },

      {
        type: "fieldset",
        condition: "model.page === 20",
        page: 20,
        title: 'Boarders',
        items: [
          {
            type: "help",
            helpvalue: "This section is about the Boarders, Lodgers and Sub-Tenants in your Household",
            condition: 'model.other_adults === "Yes"',
          },
          {
            key: "boarders",
            title: "How many of the other adults living with you are Boarders, Lodgers or Sub-Tenants?",
            type: "number",
            condition: 'model.other_adults === "Yes"',
            description: "Only list boarders on this page. You will be able to tell us about other non-dependant adults on the following page."
          },
          {
            key: 'boarder',
            condition: 'model.boarders > 0',
            add: "Boarder",
            title:'Boarder details',
            items: [
              {
                key: "boarder[].boarder_forename",
                title: "What is their forename(s)?",
              },
              {
                key: "boarder[].boarder_surname",
                title: "What is their last name?",
              },
              {
                key: "boarder[].boarder_dob",
                title: "What is their date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
              },
              {
                key: "boarder[].boarder_gender",
                title: "Are they Male or Female?",
                type: "radios",
              },
              {
                key: "boarder[].boarder_relationship_applicant",
                title: "What is this persons relationship to you?",
                condition: 'model.couple === "No"'
              },
              {
                key: "boarder[].boarder_relationship_couple",
                title: "What is this person's relationship to you or your partner",
                condition: 'model.couple === "Yes"'
              },
              {
                key: "boarder[].boarder_relationship_otheradults",
                title: "Is this person living with any other adult who normally lives with you, as if they were married or civil partners?",
                type: "radios",
              },
              {
                key: "boarder[].boarder_relationship_otheradults_name",
                title: "Who are they in a relationship with?",
                condition: 'model.boarder[arrayIndex].boarder_relationship_otheradults === "Yes"'
              },
              {
                key: "boarder[].boarder_student",
                title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
                type: "radios",
              },
              {
                key: "boarder[].boarder_rentamount",
                title: "How much per week do they pay?",
              },
              {
                key: "boarder[].boarder_rentheating",
                title: "Does this include money for food or heating?",
                type: "radios",
              },
              {
                key: "boarder[].boarder_custody",
                title: "Are they in legal custody at the moment?",
                type: "radios",
              },
              {
                key: "boarder[].boarder_custody_released",
                title: "When will they be released?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: 'model.boarder[arrayIndex].boarder_custody === "Yes"'
              },
              {
                key: "boarder[].boarder_hospital",
                title: "Are they in hospital at the moment?",
                type: "radios",
                condition: conditions.toString('boarder_hospital')
              },
              {
                key: "boarder[].boarder_hospital_in",
                title: "When did they go into hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: 'model.boarder[arrayIndex].boarder_hospital === "Yes"'
              },
              {
                key: "boarder[].boarder_hospital_knownout",
                title: "Do you know when they are due to come out of hospital?",
                type: "radios",
                condition: 'model.boarder[arrayIndex].boarder_hospital === "Yes"'
              },
              {
                key: "boarder[].boarder_hospital_out",
                title: "When are they due to come out of hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: 'model.boarder[arrayIndex].boarder_hospital_knownout === "Yes"'
              },
              {
                key: "boarder[].boarder_mental",
                title: "Is this person severely mentally impaired?",
                type: "radios",
              },

            ],
          }
        ]
      },

      {
        type: "fieldset",
        condition: "model.page === 21",
        page: 21,
        title: 'Non-Dependant Adults',
        items: [
          {
            type: "help",
            helpvalue: "This section is about Non-Dependant Adults in your Household",
            condition: 'model.other_adults === "Yes"'
          },

          {
            key: "nondependants",
            title: "How many of the other adults living with you are Non-dependants?",
            type: "number",
            condition: 'model.other_adults === "Yes"',
            description: "Only list non-dependant adults who are not joint tenants or boarders."
          },

          {
            key: 'nondependant',
            condition: 'model.nondependants > 0',
            add: "Non-Dependant Adult",
            title:'Non-Dependant Adult details',
            items: [

              {
                key: "nondependant[].nondependant_forename",
                title: "What is their forename(s)?",
              },
              {
                key: "nondependant[].nondependant_surname",
                title: "What is their last name?",
              },
              {
                key: "nondependant[].nondependant_dob",
                title: "What is their date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
              },
              {
                key: "nondependant[].nondependant_gender",
                title: "Are they Male or Female?",
                type: "radios",
              },
              {
                key: "nondependant[].nondependant_ni",
                title: "What's their National Insurance Number?",
                description: "Leave blank if you do not know the number."
              },
              {
                key: "nondependant[].nondependant_relationship_applicant",
                title: "What is this person's relationship to you?",
                condition: 'model.couple === "No"'
              },
              {
                key: "nondependant[].nondependant_relationship_couple",
                title: "What is this person's relationship to you or your partner?",
                condition: 'model.couple === "Yes"'
              },
              {
                key: "nondependant[].nondependant_relationship_otheradults",
                title: "Is this person living with any other adult, who normally lives with you, as if they were married or civil partners?",
                type: "radios",
                condition: 'model.nondependants > 1'
              },
              {
                key: "nondependant[].nondependant_relationship_otheradults_name",
                title: "Who are they in a relationship with?",
                condition: 'model.nondependants > 1 && model.nondependant[arrayIndex].nondependant_relationship_otheradults === "Yes"'
              },
              {
                key: "nondependant[].nondependant_student",
                title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
                type: "radios",
              },
              {
                key: "nondependant[].nondependant_care",
                title: "Does this person provide care or support (either paid or unpaid) for someone who is NOT their dependant child under 18, nor their partner?",
                type: "radios",
              },
              {
                key: "nondependant[].nondependant_care35",
                title: "Does this non-dependant adult provide care for another person (for at least 35 hours per week) who lives in your home; and who gets higher rate Attendance Allowance; or Disability Living Allowance with the higher rate for 'care'; or Personal Independence Payment or Armed Forces Independence Payment with the Enhanced rate of 'Daily Living' (if you are not sure, check their award letter); or a similar benefit; and the person they care for is NOT their dependant child under 18, nor their partner?",
                type: "radios",
                condition: 'model.nondependant[arrayIndex].nondependant_care === "Yes"'
              },
              {
                key: "nondependant[].nondependant_care24",
                title: "Does this non-dependant adult provide care for another person who lives in your home for at least 24 hours per week; and who earns no more than £44 a week for this; and resides (for the better performance of the work) in premises provided on behalf of that person; and was introduced by a charity, council, or government department?",
                type: "radios",
                condition: 'model.nondependant[arrayIndex].nondependant_care35 === "Yes"'
              },
              {
                key: "nondependant[].nondependant_vol",
                title: "Is this non-dependant adult engaged by a charitable or voluntary body, which charges you or partner, to provide care for you or your partner?",
                type: "radios",
                condition: 'model.nondependant[arrayIndex].nondependant_care35 === "Yes"'
              },
              {
                key: "nondependant[].nondependant_pip",
                title: "Do they get Personal Independence Payment or Armed Forces Independence Payment?",
                type: "radios",
              },
              {
                key: "nondependant[].nondependant_pipdaily",
                title: "What rate of their Personal Independence Payment or Armed Forces Independence Payment is for Daily Living?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£72.65 (Standard)</li><li>£108.55 (Enhanced)</li></ul>",
                condition: 'model.nondependant[arrayIndex].nondependant_pip === "Yes"'
              },
              {
                key: "nondependant[].nondependant_pipmot",
                title: "What rate of their Personal Independence Payment or Armed Forces Independence Payment is for Mobility?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£28.70 (Standard)</li><li>£75.75 (Enhanced)</li></ul>",
                condition: 'model.nondependant[arrayIndex].nondependant_pip === "Yes"'
              },
              {
                key: "nondependant[].nondependant_mental",
                title: "Is this person registered blind or severely mentally impaired?",
                type: "checkboxes",
              },
              {
                key: "nondependant[].nondependant_benefits",
                title: "Do they get any of the following benefits? Select all that apply to this person.",
                type: "checkboxes",
              },
              {
                key: "nondependant[].nondependant_dlacare",
                title: "What rate of their Disability Living Allowance is for care?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: 'model.nondependant[arrayIndex].nondependant_benefits.indexOf("Disability Living Allowance") > -1'
              },
              {
                key: "nondependant[].nondependant_dlamot",
                title: "What rate of their Disability Living Allowance is for mobility (including any payment to Motability fund)?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: 'model.nondependant[arrayIndex].nondependant_benefits.indexOf("Disability Living Allowance") > -1'
              },
              {
                key: "nondependant[].nondependant_esa",
                title: "Which rate of Income-related Employment and Support Allowance is this person getting?",
                type: "select",
                condition: 'model.nondependant[arrayIndex].nondependant_benefits.indexOf("Income-related Employment and Support Allowance") > -1'
              },
              {
                key: "nondependant[].nondependant_uc",
                title: "How much Universal Credit does this person get per month?",
                condition: 'model.nondependant[arrayIndex].nondependant_benefits.indexOf("Universal Credit") > -1'
              },
              {
                key: "nondependant[].nondependant_work",
                title: "Does this person usually work?",
                type: "radios",
              },
              {
                key: "nondependant[].nondependant_work_hours",
                title: "How many hours do they work on average per week?",
                condition: 'model.nondependant[arrayIndex].nondependant_work === "Yes"'
              },
              {
                key: "nondependant[].nondependant_work_pay",
                title: "How much do they earn on average, before any deductions?",
                condition: 'model.nondependant[arrayIndex].nondependant_work === "Yes"'
              },
              {
                key: "nondependant[].nondependant_work_pay_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: 'model.nondependant[arrayIndex].nondependant_work === "Yes"'
              },
              {
                key: "nondependant[].nondependant_contjsa",
                title: "Does this person receive Contributions based Jobseeker's Allowance?",
                type: "radios",
              },
              {
                key: "nondependant[].nondependant_contjsa_amount",
                title: "How much Contributions based Jobseeker's Allowance do they get?",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: 'model.nondependant[arrayIndex].nondependant_contjsa === "Yes"'
              },
              {
                key: "nondependant[].nondependant_contjsa_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: 'model.nondependant[arrayIndex].nondependant_contjsa === "Yes"'
              },
              {
                key: "nondependant[].nondependant_contesa",
                title: "Does this person receive Contribution-based Employment and Support Allowance?",
                type: "radios",
              },
              {
                key: "nondependant[].nondependant_contesarate",
                title: "Which rate of Contribution-based Employment and Support Allowance is this person getting?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: 'model.nondependant[arrayIndex].nondependant_contesa === "Yes"'
              },
              {
                key: "nondependant[].nondependant_contesafrequency",
                title: "How often does this person get Employment and Support Allowance?",
                type: "select",
                condition: 'model.nondependant[arrayIndex].nondependant_contesa === "Yes"'
              },
              {
                key: "nondependant[].nondependant_contesaamount",
                title: "How much Contribution-based Employment and Support Allowance does this person get paid in this period?",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: 'model.nondependant[arrayIndex].nondependant_contesa === "Yes"'
              },
              {
                key: "nondependant[].nondependant_otherincome",
                title: "Does this person have any other income at all?",
                type: "radios",
                description: "For example, private pensions, occupational pensions, annuities, Working Tax Credits or Child Tax Credits.",
              },
              {
                key: "nondependant[].nondependant_otherincome_number",
                title: "How many other incomes do you need to tell us about?",
                condition: 'model.nondependant[arrayIndex].nondependant_otherincome ==="Yes"',
              },

              {
                key: 'nondependant[].nondependant_otherincomes',
                condition: 'model.nondependant[arrayIndex].nondependant_otherincome_number > 0',
                add: "Add Other Income",
                title:'Other Income details',
                items: [


                  {
                    key: "nondependant[].nondependant_otherincomes[].nondependant_otherincome_type",
                    title: "What type of income is this?",
                  },
                  {
                    key: "nondependant[].nondependant_otherincomes[].nondependant_otherincome_amount",
                    title: "How much is it before deductions?",
                  },
                  {
                    key: "nondependant[].nondependant_otherincomes[].nondependant_otherincome_frequency",
                    title: "How often do they get this amount?",
                    type: "select",
                  },
                ]
              },


              {
                key: "nondependant[].nondependant_custody",
                title: "Is this Non-Dependant Adult  in legal custody at the moment?",
                type: "radios",
              },
              {
                key: "nondependant[].nondependant_custody_released",
                title: "When will they be released?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: 'model.nondependant[arrayIndex].nondependant_custody === "Yes"'
              },
              {
                key: "nondependant[].nondependant_hospital",
                title: "Are they in hospital at the moment?",
                type: "radios",
              },
              {
                key: "nondependant[].nondependant_hospital_in",
                title: "When did they go into hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: 'model.nondependant[arrayIndex].nondependant_hospital === "Yes"'
              },
              {
                key: "nondependant[].nondependant_hospital_knownout",
                title: "Do you know when they are due to come out of hospital?",
                type: "radios",
                condition: 'model.nondependant[arrayIndex].nondependant_hospital === "Yes"'
              },
              {
                key: "nondependant[].nondependant_hospital_out",
                title: "When are they due to come out of hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: 'model.nondependant[arrayIndex].nondependant_hospital === "Yes"'
              },


              {
                key: "nondependant[].nondependant_children",
                title: "Does this non-dependant live with any children in your household?",
                type: "radios",
                description: "A dependant child is one who they usually get child benefit for. Answer Yes if they are responsible for a child, even if they do not get child benefit for that child. Do not count any child who has been in hospital continuously for 52 weeks (one year) or more.",
              },
              {
                key: "nondependant[].nondependant_childrenno",
                title: "How many children does this non-dependant live with in your household?",
                condition: 'model.nondependant[arrayIndex].nondependant_children === "Yes"',
              },

              {
                key: 'nondependant[].nondependant_child',
                condition: 'model.nondependant[arrayIndex].nondependant_children === "Yes"',
                add: "Add Non-Dependant Child",
                title:'Non-Dependant Child details',
                items: [

                  {
                    key: "nondependant[].nondependant_child[].nondependant_child_forename",
                    title: "What's the Child's forenames(s)?",
                  },
                  {
                    key: "nondependant[].nondependant_child[].nondependant_child_lastname",
                    title: "What's the Childs last name?",
                  },
                  {
                    key: "nondependant[].nondependant_child[].nondependant_child_cb",
                    type: "selectWithInput",
                    title: "Who gets Child Benefit for this Child?",
                    options: ["I do", "Someone else does"],
                    radios: true,
                    other: "Someone else does",
                    comment: "Please specify",
                  },
                  {
                    key: "nondependant[].nondependant_child[].nondependant_child_dob",
                    title: "What is this child's date of birth?",
                    type: "memorabledate",
                    dateFormat: "YYYY-MM-DD",
                  },
                  {
                    key: "nondependant[].nondependant_child[].nondependant_child_gender",
                    title: "Is this child a girl or a boy?",
                    type: "radios",
                  },
                  {
                    key: "nondependant[].nondependant_child[].nondependant_child_bed",
                    title: "Is this child unable to share a bedroom due to disability?",
                    type: "radios",
                    description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
                  },

                ]
              }
            ]
          }
        ]
      },

      {
        type: "fieldset",
        condition: "model.page === 22",
        page: 22,
        title: "About being self-employed",
        items: [
          {
            key: "applicant_self_employed",
            title: "Are you self-employed?",
            type: "radios"
          },
          {
            key: "applicant_kind_of_work_se",
            title: "What kind of work do you do?",
            condition: conditions.toString('applicant_kind_of_work_se')
          },
          {
            key: "applicant_business_start_se",
            title: "When did the business start?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_business_start_se'),
            description: "If you do not know the exact date please enter an approximate date."
          },
          {
            key: "applicant_business_name_se",
            title: "What is the business name?",
            condition: conditions.toString('applicant_business_name_se')
          },
          {
            key: "applicant_business_addressline1",
            title: "What is the business address? Address Line 1",
            condition: conditions.toString('applicant_business_addressline1')
          },
          {
            key: "applicant_business_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_business_addressline2')
          },
          {
            key: "applicant_business_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_business_addressline3')
          },
          {
            key: "applicant_business_postcode_se",
            title: "Postcode",
            condition: conditions.toString('applicant_business_postcode_se')
          },
          {
            key: "applicant_business_partner",
            title: "Do you have any business partners?",
            type: "radios",
            condition: conditions.toString('applicant_business_partner')
          },
          {
            key: "applicant_business_partner_name",
            title: "Business partners' names ",
            condition: conditions.toString('applicant_business_partner_name')
          },
          {
            key: "applicant_business_partner_addressline1",
            title: "What's their address? Address Line 1",
            condition: conditions.toString('applicant_business_partner_addressline1')
          },
          {
            key: "applicant_business_partner_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_business_partner_addressline2')
          },
          {
            key: "applicant_business_partner_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_business_partner_addressline3')
          },
          {
            key: "applicant_business_partner_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_business_partner_postcode')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week do you usually work?</label>",
            condition: conditions.toString('help_applicant_self_employed_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_self_employed_hours_worked_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_self_employed_hours_worked_minutes",
                    type: "number",
                    title: "Minutes"
                  }]
              }],
            condition: conditions.toString('section_applicant_self_employed_time')
          },
          {
            key: "applicant_self_employed_startup_allow",
            title: "Do you get a Business Start-up Allowance?",
            type: "radios",
            condition: conditions.toString('applicant_self_employed_startup_allow')
          },
          {
            key: "applicant_self_employed_startup_allow_amount",
            title: "How much?",
            condition: conditions.toString('applicant_self_employed_startup_allow_amount')
          },
          {
            key: "applicant_self_employed_startup_allow_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_self_employed_startup_allow_frequency')
          },

          {
            key: "applicant_self_employed_seiss",
            title: "Did you receive a grant from the Self-Employment Income Support Scheme (SEISS)?",
            condition: 'model.applicant_self_employed === "Yes"'
          },

          {
            key: "applicant_self_employed_seiss_amount",
            title: "How much?",
            condition: 'model.applicant_self_employed === "Yes" && model.applicant_self_employed_seiss === "Yes"'
          },

          {
            key: "applicant_self_employed_seiss_included_in_gross",
            title: "Have you included this amount in your gross income on your accounts?",
            condition: 'model.applicant_self_employed === "Yes" && model.applicant_self_employed_seiss_amount > 0'
          },

          {
            key: "applicant_self_employed_pension",
            title: "Do you pay into a private pension scheme?",
            type: "radios",
            condition: conditions.toString('applicant_self_employed_pension')
          },
          {
            key: "applicant_self_employed_pension_amount",
            title: "How much?",
            condition: conditions.toString('applicant_self_employed_pension_amount')
          },
          {
            key: "applicant_self_employed_pension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_self_employed_pension_frequency')
          },
          {
            key: "partner_self_employed",
            title: "Is your partner self-employed?",
            type: "radios",
            condition: conditions.toString('partner_self_employed')
          },
          {
            key: "partner_kind_of_work_se",
            title: "What kind of work does your partner do?",
            condition: conditions.toString('partner_kind_of_work_se')
          },
          {
            key: "partner_business_start_se",
            title: "When did the business start?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you do not know the exact date please enter an approximate date.",
            condition: conditions.toString('partner_business_start_se')
          },
          {
            key: "partner_business_name_se",
            title: "What is the business name?",
            condition: conditions.toString('partner_business_name_se')
          },
          {
            key: "partner_business_address_se_1",
            title: "What is the business address? Address Line 1",
            condition: conditions.toString('partner_business_address_se_1')
          },
          {
            key: "partner_business_address_se_2",
            title: "Address Line 2",
            condition: conditions.toString('partner_business_address_se_2')
          },
          {
            key: "partner_business_address_se_3",
            title: "Address Line 3",
            condition: conditions.toString('partner_business_address_se_3')
          },
          {
            key: "partner_business_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_business_postcode')
          },
          {
            key: "partner_business_partner",
            title: "Does your partner have any business partners?",
            type: "radios",
            condition: conditions.toString('partner_business_partner')
          },
          {
            key: "partner_business_partner_name",
            title: "Business partners' names",
            condition: conditions.toString('partner_business_partner_name')
          },
          {
            key: "partner_business_partner_addressline1",
            title: "Address line 1",
            condition: conditions.toString('partner_business_partner_addressline1')
          },
          {
            key: "partner_business_partner_addressline2",
            title: "Address line 2",
            condition: conditions.toString('partner_business_partner_addressline2')
          },
          {
            key: "partner_business_partner_addressline3",
            title: "Address line 3",
            condition: conditions.toString('partner_business_partner_addressline3')
          },
          {
            key: "partner_business_partner_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_business_partner_postcode')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week does your partner usually work?</label>",
            condition: conditions.toString('help_partner_self_employed_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_self_employed_hours_worked_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_self_employed_hours_worked_minutes",
                    type: "number",
                    title: "Minutes"
                  }]
              }],
            condition: conditions.toString('section_partner_self_employed_time')
          },
          {
            key: "partner_self_employed_startup_allow",
            title: "Does your partner get a Business Start-up Allowance?",
            type: "radios",
            condition: conditions.toString('partner_self_employed_startup_allow')
          },
          {
            key: "partner_self_employed_startup_allow_amount",
            title: "How much?",
            condition: conditions.toString('partner_self_employed_startup_allow_amount')
          },
          {
            key: "partner_self_employed_startup_allow_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_self_employed_startup_allow_frequency')
          },

          {
            key: "partner_self_employed_seiss",
            title: "Did your partner receive a grant from the Self-Employment Income Support Scheme (SEISS)?",
            condition: 'model.partner_self_employed === "Yes"'
          },

          {
            key: "partner_self_employed_seiss_amount",
            title: "How much?",
            condition: 'model.partner_self_employed === "Yes" && model.partner_self_employed_seiss === "Yes"'
          },

          {
            key: "partner_self_employed_seiss_included_in_gross",
            title: "Has your partner included this amount in their gross income on their accounts?",
            condition: 'model.partner_self_employed === "Yes" && model.partner_self_employed_seiss_amount > 0'
          },

          {
            key: "partner_self_employed_pension",
            title: "Does your partner pay into a private pension scheme?",
            type: "radios",
            condition: conditions.toString('partner_self_employed_pension')
          },
          {
            key: "partner_self_employed_pension_amount",
            title: "How much?",
            condition: conditions.toString('partner_self_employed_pension_amount')
          },
          {
            key: "partner_self_employed_pension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_self_employed_pension_frequency')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 23",
        page: 23,
        title: "About working for an employer",
        items: [
          {
            key: "applicant_employed",
            title: "Do you work for an employer?",
            type: "radios",
            description: "Answer Yes even if you are off sick or on maternity/paternity or adoption leave"
          },

          {
            key: "applicant_number_of_employers",
            title: "How many employers do you work for?",
            condition: 'model.applicant_employed === "Yes"',
          },

          {
            key: 'applicant_employment',
            condition: 'model.applicant_employed === "Yes"',
            add: "Add Employer",
            title:'Employer details',
            items: [

              {
                key: "applicant_employment[].applicant_business_name",
                title: "What is the employer's name?",
              },
              {
                key: "applicant_employment[].applicant_job_start",
                title: "When did you start this job?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                description: "If you do not know the exact date please enter an approximate date.",
              },
              {
                key: "applicant_employment[].applicant_employed_director",
                title: "Are you a director of this company?",
                type: "radios",
              },
              {
                key: "applicant_employment[].applicant_employed_company_own",
                title: "Do you own all of this company?",
                type: "radios",
                condition: 'model.applicant_employment[arrayIndex].applicant_employed_director === "Yes"'
              },
              {
                key: "applicant_employment[].applicant_employed_company_dividend",
                title: "Do you receive a director's dividend for this company?",
                type: "radios",
                condition: 'model.applicant_employment[arrayIndex].applicant_employed_director === "Yes"'
              },
              {
                key: "applicant_employment[].applicant_employed_company_dividendvalue",
                title: "How much?",
                condition: 'model.applicant_employment[arrayIndex].applicant_employed_company_dividend === "Yes"',
              },
              {
                key: "applicant_employment[].applicant_employed_company_dividend_frequency",
                title: "How often?",
                type: "radios",
                condition: 'model.applicant_employment[arrayIndex].applicant_employed_company_dividend === "Yes"',
              },
              {
                key: "applicant_employment[].applicant_employed_temporary",
                title: "Is this employment for a temporary period (for example, agency work)?",
                type: "radios",
              },
              {
                key: "applicant_employment[].applicant_employed_temporary_finish_date",
                title: "When will you finish this employment?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                description: "If you do not know the exact date please enter an approximate date.",
                condition: 'model.applicant_employment[arrayIndex].applicant_employed_temporary === "Yes"'
              },
              {
                key: "applicant_employment[].applicant_employed_pay_frequency",
                title: "How often do you get paid for this employment?",
                type: "radios",
              },
              {
                key: "applicant_employment[].applicant_employed_pay_gross",
                title: "How much do you get paid before tax and National Insurance for this employment?",
                description: "This is the 'gross' amount shown on your payslip. If your wage varies please enter the average amount you receive.",
              },
              {
                type: "help",
                htmlClass: "form-group",
                helpvalue: "<label>How many hours a week do you usually work for this employer?</label>",
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                    items: [
                      {
                        key: "applicant_employment[].applicant_employed_hours_worked_hours",
                        type: "number",
                        title: "Hours"
                      }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                    items: [
                      {
                        key: "applicant_employment[].applicant_employed_hours_worked_minutes",
                        type: "number",
                        title: "Minutes",
                      }]
                  }],
                description: "If your hours vary from week to week, enter the average amount of hours you usually work."
              },
              {
                key: "applicant_employment[].applicant_employed_ssp",
                title: "Are you getting Statutory Sick Pay (SSP), Statutory Paternity Pay (SPP) or Statutory Maternity Pay (SMP) from this employer at the moment?",
                type: "radios",
              },
              {
                key: "applicant_employment[].applicant_employed_ssp_other",
                title: "Are you getting any other sick pay or maternity pay from this employer at the moment?",
                type: "radios",
                description: "For example: Some employers offer their staff an extra benefit via company sick pay or contractual maternity pay."
              },

            ],
          },



          {
            key: "applicant_employed_pension",
            title: "Do you pay into a private or company pension scheme?",
            type: "radios"
          },

          {
            key: "applicant_employed_pension_number",
            title: "How many private or company pensions do you pay into?",
            condition: 'model.applicant_employed_pension === "Yes"'
          },

          {
            key: 'applicant_employed_pensions',
            condition: 'model.applicant_employed_pension === "Yes"',
            add: "Add Pension",
            title:'Pension details',
            items: [
              {
                key: "applicant_employed_pensions[].applicant_employed_pension_name",
                title: "What is the company name?",
              },
              {
                key: "applicant_employed_pensions[].applicant_employed_pension_amount",
                title: "How much do you pay into this pension?",
              },
              {
                key: "applicant_employed_pensions[].applicant_employed_pension_frequency",
                title: "How often do you pay into this pension?",
                type: "radios",
              }

            ]

          },

          {
            key: "partner_employed",
            title: "Does your partner work for an employer?",
            type: "radios",
            condition: conditions.toString('partner_employed')
          },

          {
            key: "partner_number_of_employers",
            title: "How many employers do they work for?",
            condition: conditions.toString('partner_number_of_employers')
          },

          {
            key: 'partner_employment',
            condition: 'model.partner_employed === "Yes"',
            add: "Add Employer",
            title:'Employer details',
            items: [

              {
                key: "partner_employment[].partner_business_name",
                title: "What is the employer's name?",
              },
              {
                key: "partner_employment[].partner_business_addressline1",
                title: "What is the employers address? Address line 1",
              },
              {
                key: "partner_employment[].partner_business_addressline2",
                title: "Address line 2",
              },
              {
                key: "partner_employment[].partner_business_addressline3",
                title: "Address line 3",
              },
              {
                key: "partner_employment[].partner_business_postcode",
                title: "Postcode",
              },
              {
                key: "partner_employment[].partner_job_start",
                title: "When did they start this job?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
              },
              {
                key: "partner_employment[].partner_employed_director",
                title: "Are they a director of this company?",
                type: "radios",
              },
              {
                key: "partner_employment[].partner_employed_company_own",
                title: "Do they own all of this company?",
                type: "radios",
                condition: 'model.partner_employment[arrayIndex].partner_employed_director === "Yes"'
              },
              {
                key: "partner_employment[].partner_employed_company_dividend",
                title: "Do they receive a director's dividend for this company?",
                type: "radios",
                condition: 'model.partner_employment[arrayIndex].partner_employed_director === "Yes"'
              },
              {
                key: "partner_employment[].partner_employed_company_dividendvalue",
                title: "How much?",
                condition: 'model.partner_employment[arrayIndex].partner_employed_director === "Yes" && model.partner_employment[arrayIndex].partner_employed_company_dividend === "Yes"',
              },
              {
                key: "partner_employment[].partner_employed_company_dividend_frequency",
                title: "How often?",
                type: "radios",
                condition: 'model.partner_employment[arrayIndex].partner_employed_company_dividend === "Yes"',
              },
              {
                key: "partner_employment[].partner_employed_temporary",
                title: "Is this employment for a temporary period (for example, agency work)?",
                type: "radios",
              },
              {
                key: "partner_employment[].partner_employed_temporary_finish_date",
                title: "When will they finish this employment?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                description: "If you do not know the exact date please enter an approximate date.",
                condition: 'model.partner_employment[arrayIndex].partner_employed_temporary === "Yes"'
              },
              {
                key: "partner_employment[].partner_employed_pay_frequency",
                title: "How often do they get paid for this employment?",
                type: "radios",
              },
              {
                key: "partner_employment[].partner_employed_pay_gross",
                title: "How much do they get paid before tax and National Insurance for this employment?",
              },
              {
                type: "help",
                htmlClass: "form-group",
                helpvalue: "<label>How many hours a week do they usually work for this employer?</label>",
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                    items: [
                      {
                        key: "partner_employment[].partner_employed_hours_worked_hours",
                        type: "number",
                        title: "Hours"
                      }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                    items: [
                      {
                        key: "partner_employment[].partner_employed_hours_worked_minutes",
                        type: "number",
                        title: "Minutes",
                      }]
                  }],
              },
              {
                key: "partner_employment[].partner_employed_ssp",
                title: "Are they getting Statutory Sick Pay (SSP), Statutory Paternity Pay (SPP) or Statutory Maternity Pay (SMP) from this employer at the moment?",
                type: "radios",
              },
              {
                key: "partner_employment[].partner_employed_ssp_other",
                title: "Are they getting any other sick pay or maternity pay from this employer at the moment?",
                type: "radios",
                description: "For example, company sick or maternity pay.",
              },

            ]

          },

          {
            key: "partner_employed_pension",
            title: "Does your partner pay into a private or company pension scheme?",
            type: "radios",
            condition: conditions.toString('partner_employed_pension')
          },

          {
            key: "partner_employed_pension_number",
            title: "How many private or company pensions do they pay into?",
            condition: conditions.toString('partner_employed_pension_number')
          },

          {
            key: 'partner_employed_pensions',
            condition: 'model.partner_employed_pension === "Yes"',
            add: "Add Pension",
            title:'Pension details',
            items: [

              {
                key: "partner_employed_pensions[].partner_employed_pension_name",
                title: "What is the company name?",
              },
              {
                key: "partner_employed_pensions[].partner_employed_pension_amount",
                title: "How much do they pay into this pension?",
              },
              {
                key: "partner_employed_pensions[].partner_employed_pension_frequency",
                title: "How often do they pay into this pension?",
                type: "radios",
              },
            ]
          }
        ],
      },
      {
        type: "fieldset",
        condition: "model.page === 24",
        page: 24,
        title: "About other money coming in",
        items: [
          {
            key: "applicant_other_benefit_type",
            title: "Of the following benefits, pensions, or other income, select all that you are currently receiving, or have a claim in progress for:",
            type: "checkboxes"
          },
          {
            key: "applicant_other_benefit_adoptionpay_status",
            title: "Getting Adoption Pay now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_adoptionpay_status')
          },
          {
            key: "applicant_other_benefit_adoptionpay_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_adoptionpay_amount')
          },
          {
            key: "applicant_other_benefit_adoptionpay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_adoptionpay_frequency')
          },
          {
            key: "applicant_other_benefit_armedforcescompensation_status",
            title: "Getting armed forces compensation now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_armedforcescompensation_status')
          },
          {
            key: "applicant_other_benefit_armedforcescompensation_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_armedforcescompensation_amount')
          },
          {
            key: "applicant_other_benefit_armedforcescompensation_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_armedforcescompensation_frequency')
          },
          {
            key: "applicant_other_benefit_bereavementallowance_status",
            title: "Getting Bereavement Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_bereavementallowance_status')
          },
          {
            key: "applicant_other_benefit_bereavementallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_bereavementallowance_amount')
          },
          {
            key: "applicant_other_benefit_bereavementallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_bereavementallowance_frequency')
          },
          {
            key: "applicant_other_benefit_carersallowance_status",
            title: "Getting Carer's Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_carersallowance_status')
          },
          {
            key: "applicant_other_benefit_carersallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_carersallowance_amount')
          },
          {
            key: "applicant_other_benefit_carersallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_carersallowance_frequency')
          },
          {
            key: "applicant_other_benefit_childbenefit_status",
            title: "Getting Child Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_childbenefit_status')
          },
          {
            key: "applicant_other_benefit_childbenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_childbenefit_amount')
          },
          {
            key: "applicant_other_benefit_childbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_childbenefit_frequency')
          },
          {
            key: "applicant_other_benefit_childtaxcredit_status",
            title: "Getting Child Tax Credit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_childtaxcredit_status')
          },
          {
            key: "applicant_other_benefit_childtaxcredit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_childtaxcredit_amount')
          },
          {
            key: "applicant_other_benefit_childtaxcredit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_childtaxcredit_frequency')
          },
          {
            key: "applicant_other_benefit_contributionesa_status",
            title: "Getting Contribution-based Employment and Support Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_contributionesa_status')
          },
          {
            key: "applicant_other_benefit_contributionesa_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_contributionesa_amount')
          },
          {
            key: "applicant_other_benefit_contributionesa_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_contributionesa_frequency')
          },
          {
            key: "applicant_other_benefit_contributionjsa_status",
            title: "Getting Contribution-based Jobseeker's Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_contributionjsa_status')
          },
          {
            key: "applicant_other_benefit_contributionjsa_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_contributionjsa_amount')
          },
          {
            key: "applicant_other_benefit_contributionjsa_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_contributionjsa_frequency')
          },
          {
            key: "applicant_other_benefit_fosteringallowance_status",
            title: "Getting Fostering Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_fosteringallowance_status')
          },
          {
            key: "applicant_other_benefit_fosteringallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_fosteringallowance_amount')
          },
          {
            key: "applicant_other_benefit_fosteringallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_fosteringallowance_frequency')
          },
          {
            key: "applicant_other_benefit_guardiansallowance_status",
            title: "Getting Guardian's Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_guardiansallowance_status')
          },
          {
            key: "applicant_other_benefit_guardiansallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_guardiansallowance_amount')
          },
          {
            key: "applicant_other_benefit_guardiansallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_guardiansallowance_frequency')
          },
          {
            key: "applicant_other_benefit_incapacitybenefit_status",
            title: "Getting Incapacity Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_incapacitybenefit_status')
          },
          {
            key: "applicant_other_benefit_incapacitybenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_incapacitybenefit_amount')
          },
          {
            key: "applicant_other_benefit_incapacitybenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_incapacitybenefit_frequency')
          },
          {
            key: "applicant_other_benefit_industrialdeathbenefit_status",
            title: "Getting Industrial Death Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_industrialdeathbenefit_status')
          },
          {
            key: "applicant_other_benefit_industrialdeathbenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_industrialdeathbenefit_amount')
          },
          {
            key: "applicant_other_benefit_industrialdeathbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_industrialdeathbenefit_frequency')
          },
          {
            key: "applicant_other_benefit_injuriesdisablementbenefit_status",
            title: "Getting Industrial Injuries Disablement Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_injuriesdisablementbenefit_status')
          },
          {
            key: "applicant_other_benefit_injuriesdisablementbenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_injuriesdisablementbenefit_amount')
          },
          {
            key: "applicant_other_benefit_injuriesdisablementbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_injuriesdisablementbenefit_frequency')
          },
          {
            key: "applicant_other_benefit_maintenancepayment_status",
            title: "Getting Maintenance Payment now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_maintenancepayment_status')
          },
          {
            key: "applicant_other_benefit_maintenancepayment_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_maintenancepayment_amount')
          },
          {
            key: "applicant_other_benefit_maintenancepayment_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_maintenancepayment_frequency')
          },
          {
            key: "applicant_other_benefit_maternityallowance_status",
            title: "Getting Maternity Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_maternityallowance_status')
          },
          {
            key: "applicant_other_benefit_maternityallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_maternityallowance_amount')
          },
          {
            key: "applicant_other_benefit_maternityallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_maternityallowance_frequency')
          },
          {
            key: "applicant_other_benefit_severedisablementallowance_status",
            title: "Getting Severe Disablement Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_severedisablementallowance_status')
          },
          {
            key: "applicant_other_benefit_severedisablementallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_severedisablementallowance_amount')
          },
          {
            key: "applicant_other_benefit_severedisablementallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_severedisablementallowance_frequency')
          },
          {
            key: "applicant_other_benefit_stateretirementpension_status",
            title: "Getting State Retirement Pension now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_stateretirementpension_status')
          },
          {
            key: "applicant_other_benefit_stateretirementpension_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_stateretirementpension_amount')
          },
          {
            key: "applicant_other_benefit_stateretirementpension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_stateretirementpension_frequency')
          },
          {
            key: "applicant_other_benefit_statutorymaternitypay_status",
            title: "Getting Statutory Maternity Pay now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorymaternitypay_status')
          },
          {
            key: "applicant_other_benefit_statutorymaternitypay_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_statutorymaternitypay_amount')
          },
          {
            key: "applicant_other_benefit_statutorymaternitypay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorymaternitypay_frequency')
          },
          {
            key: "applicant_other_benefit_statutorypaternitypay_status",
            title: "Getting Statutory Paternity Pay now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorypaternitypay_status')
          },
          {
            key: "applicant_other_benefit_statutorypaternitypay_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_statutorypaternitypay_amount')
          },
          {
            key: "applicant_other_benefit_statutorypaternitypay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorypaternitypay_frequency')
          },
          {
            key: "applicant_other_benefit_statutorysickpay_status",
            title: "Getting Statutory Sick Pay now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorysickpay_status')
          },
          {
            key: "applicant_other_benefit_statutorysickpay_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_statutorysickpay_amount')
          },
          {
            key: "applicant_other_benefit_statutorysickpay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorysickpay_frequency')
          },
          {
            key: "applicant_other_benefit_trainingallowance_status",
            title: "Getting Training Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_trainingallowance_status')
          },
          {
            key: "applicant_other_benefit_trainingallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_trainingallowance_amount')
          },
          {
            key: "applicant_other_benefit_trainingallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_trainingallowance_frequency')
          },
          {
            key: "applicant_other_benefit_wardisablementbenefit_status",
            title: "Getting War Disablement Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_wardisablementbenefit_status')
          },
          {
            key: "applicant_other_benefit_wardisablementbenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_wardisablementbenefit_amount')
          },
          {
            key: "applicant_other_benefit_wardisablementbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_wardisablementbenefit_frequency')
          },
          {
            key: "applicant_other_benefit_warpension_status",
            title: "Getting War Pension or War Widow's Pension now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_warpension_status')
          },
          {
            key: "applicant_other_benefit_warpension_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_warpension_amount')
          },
          {
            key: "applicant_other_benefit_warpension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_warpension_frequency')
          },
          {
            key: "applicant_other_benefit_widowbenefit_status",
            title: "Getting Widow or Widower's Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_widowbenefit_status')
          },
          {
            key: "applicant_other_benefit_widowbenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_widowbenefit_amount')
          },
          {
            key: "applicant_other_benefit_widowbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_widowbenefit_frequency')
          },
          {
            key: "applicant_other_benefit_widowedparentsallowance_status",
            title: "Getting Widowed Parent's Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_widowedparentsallowance_status')
          },
          {
            key: "applicant_other_benefit_widowedparentsallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_widowedparentsallowance_amount')
          },
          {
            key: "applicant_other_benefit_widowedparentsallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_widowedparentsallowance_frequency')
          },
          {
            key: "applicant_other_benefit_workingtaxcredit_status",
            title: "Getting Working Tax Credit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_workingtaxcredit_status')
          },
          {
            key: "applicant_other_benefit_workingtaxcredit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_workingtaxcredit_amount')
          },
          {
            key: "applicant_other_benefit_workingtaxcredit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_workingtaxcredit_frequency')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 25",
        page: 25,
        title: "About other money coming in - Your Partner",
        items: [
          {
            key: "partner_other_benefit_type",
            title: "Of the following benefits, pensions, or other income, select all that your parner is currently receiving, or has a claim in progress for:",
            type: "checkboxes",
            condition: conditions.toString('partner_other_benefit_type')
          },
          {
            key: "partner_other_benefit_adoptionpay_status",
            title: "Getting Adoption Pay now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_adoptionpay_status')
          },
          {
            key: "partner_other_benefit_adoptionpay_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_adoptionpay_amount')
          },
          {
            key: "partner_other_benefit_adoptionpay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_adoptionpay_frequency')
          },
          {
            key: "partner_other_benefit_armedforcescompensation_status",
            title: "Getting armed forces compensation now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_armedforcescompensation_status')
          },
          {
            key: "partner_other_benefit_armedforcescompensation_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_armedforcescompensation_amount')
          },
          {
            key: "partner_other_benefit_armedforcescompensation_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_armedforcescompensation_frequency')
          },
          {
            key: "partner_other_benefit_bereavementallowance_status",
            title: "Getting Bereavement Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_bereavementallowance_status')
          },
          {
            key: "partner_other_benefit_bereavementallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_bereavementallowance_amount')
          },
          {
            key: "partner_other_benefit_bereavementallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_bereavementallowance_frequency')
          },
          {
            key: "partner_other_benefit_carersallowance_status",
            title: "Getting Carer's Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_carersallowance_status')
          },
          {
            key: "partner_other_benefit_carersallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_carersallowance_amount')
          },
          {
            key: "partner_other_benefit_carersallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_carersallowance_frequency')
          },
          {
            key: "partner_other_benefit_childbenefit_status",
            title: "Getting Child Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_childbenefit_status')
          },
          {
            key: "partner_other_benefit_childbenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_childbenefit_amount')
          },
          {
            key: "partner_other_benefit_childbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_childbenefit_frequency')
          },
          {
            key: "partner_other_benefit_childtaxcredit_status",
            title: "Getting Child Tax Credit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_childtaxcredit_status')
          },
          {
            key: "partner_other_benefit_childtaxcredit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_childtaxcredit_amount')
          },
          {
            key: "partner_other_benefit_childtaxcredit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_childtaxcredit_frequency')
          },
          {
            key: "partner_other_benefit_contributionesa_status",
            title: "Getting Contribution-based Employment and Support Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_contributionesa_status')
          },
          {
            key: "partner_other_benefit_contributionesa_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_contributionesa_amount')
          },
          {
            key: "partner_other_benefit_contributionesa_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_contributionesa_frequency')
          },
          {
            key: "partner_other_benefit_contributionjsa_status",
            title: "Getting Contribution-based Jobseeker's Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_contributionjsa_status')
          },
          {
            key: "partner_other_benefit_contributionjsa_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_contributionjsa_amount')
          },
          {
            key: "partner_other_benefit_contributionjsa_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_contributionjsa_frequency')
          },
          {
            key: "partner_other_benefit_fosteringallowance_status",
            title: "Getting Fostering Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_fosteringallowance_status')
          },
          {
            key: "partner_other_benefit_fosteringallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_fosteringallowance_amount')
          },
          {
            key: "partner_other_benefit_fosteringallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_fosteringallowance_frequency')
          },
          {
            key: "partner_other_benefit_guardiansallowance_status",
            title: "Getting Guardian's Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_guardiansallowance_status')
          },
          {
            key: "partner_other_benefit_guardiansallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_guardiansallowance_amount')
          },
          {
            key: "partner_other_benefit_guardiansallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_guardiansallowance_frequency')
          },
          {
            key: "partner_other_benefit_incapacitybenefit_status",
            title: "Getting Incapacity Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_incapacitybenefit_status')
          },
          {
            key: "partner_other_benefit_incapacitybenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_incapacitybenefit_amount')
          },
          {
            key: "partner_other_benefit_incapacitybenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_incapacitybenefit_frequency')
          },
          {
            key: "partner_other_benefit_industrialdeathbenefit_status",
            title: "Getting Industrial Death Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_industrialdeathbenefit_status')
          },
          {
            key: "partner_other_benefit_industrialdeathbenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_industrialdeathbenefit_amount')
          },
          {
            key: "partner_other_benefit_industrialdeathbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_industrialdeathbenefit_frequency')
          },
          {
            key: "partner_other_benefit_injuriesdisablementbenefit_status",
            title: "Getting Industrial Injuries Disablement Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_injuriesdisablementbenefit_status')
          },
          {
            key: "partner_other_benefit_injuriesdisablementbenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_injuriesdisablementbenefit_amount')
          },
          {
            key: "partner_other_benefit_injuriesdisablementbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_injuriesdisablementbenefit_frequency')
          },
          {
            key: "partner_other_benefit_maintenancepayment_status",
            title: "Getting Maintenance Payment now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_maintenancepayment_status')
          },
          {
            key: "partner_other_benefit_maintenancepayment_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_maintenancepayment_amount')
          },
          {
            key: "partner_other_benefit_maintenancepayment_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_maintenancepayment_frequency')
          },
          {
            key: "partner_other_benefit_maternityallowance_status",
            title: "Getting Maternity Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_maternityallowance_status')
          },
          {
            key: "partner_other_benefit_maternityallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_maternityallowance_amount')
          },
          {
            key: "partner_other_benefit_maternityallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_maternityallowance_frequency')
          },
          {
            key: "partner_other_benefit_severedisablementallowance_status",
            title: "Getting Severe Disablement Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_severedisablementallowance_status')
          },
          {
            key: "partner_other_benefit_severedisablementallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_severedisablementallowance_amount')
          },
          {
            key: "partner_other_benefit_severedisablementallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_severedisablementallowance_frequency')
          },
          {
            key: "partner_other_benefit_stateretirementpension_status",
            title: "Getting State Retirement Pension now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_stateretirementpension_status')
          },
          {
            key: "partner_other_benefit_stateretirementpension_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_stateretirementpension_amount')
          },
          {
            key: "partner_other_benefit_stateretirementpension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_stateretirementpension_frequency')
          },
          {
            key: "partner_other_benefit_statutorymaternitypay_status",
            title: "Getting Statutory Maternity Pay now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorymaternitypay_status')
          },
          {
            key: "partner_other_benefit_statutorymaternitypay_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_statutorymaternitypay_amount')
          },
          {
            key: "partner_other_benefit_statutorymaternitypay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorymaternitypay_frequency')
          },
          {
            key: "partner_other_benefit_statutorypaternitypay_status",
            title: "Getting Statutory Paternity Pay now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorypaternitypay_status')
          },
          {
            key: "partner_other_benefit_statutorypaternitypay_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_statutorypaternitypay_amount')
          },
          {
            key: "partner_other_benefit_statutorypaternitypay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorypaternitypay_frequency')
          },
          {
            key: "partner_other_benefit_statutorysickpay_status",
            title: "Getting Statutory Sick Pay now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorysickpay_status')
          },
          {
            key: "partner_other_benefit_statutorysickpay_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_statutorysickpay_amount')
          },
          {
            key: "partner_other_benefit_statutorysickpay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorysickpay_frequency')
          },
          {
            key: "partner_other_benefit_trainingallowance_status",
            title: "Getting Training Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_trainingallowance_status')
          },
          {
            key: "partner_other_benefit_trainingallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_trainingallowance_amount')
          },
          {
            key: "partner_other_benefit_trainingallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_trainingallowance_frequency')
          },
          {
            key: "partner_other_benefit_wardisablementbenefit_status",
            title: "Getting War Disablement Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_wardisablementbenefit_status')
          },
          {
            key: "partner_other_benefit_wardisablementbenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_wardisablementbenefit_amount')
          },
          {
            key: "partner_other_benefit_wardisablementbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_wardisablementbenefit_frequency')
          },
          {
            key: "partner_other_benefit_warpension_status",
            title: "Getting War Pension or War Widow's Pension now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_warpension_status')
          },
          {
            key: "partner_other_benefit_warpension_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_warpension_amount')
          },
          {
            key: "partner_other_benefit_warpension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_warpension_frequency')
          },
          {
            key: "partner_other_benefit_widowbenefit_status",
            title: "Getting Widow or Widower's Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_widowbenefit_status')
          },
          {
            key: "partner_other_benefit_widowbenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_widowbenefit_amount')
          },
          {
            key: "partner_other_benefit_widowbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_widowbenefit_frequency')
          },
          {
            key: "partner_other_benefit_widowedparentsallowance_status",
            title: "Getting Widowed Parent's Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_widowedparentsallowance_status')
          },
          {
            key: "partner_other_benefit_widowedparentsallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_widowedparentsallowance_amount')
          },
          {
            key: "partner_other_benefit_widowedparentsallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_widowedparentsallowance_frequency')
          },
          {
            key: "partner_other_benefit_workingtaxcredit_status",
            title: "Getting Working Tax Credit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_workingtaxcredit_status')
          },
          {
            key: "partner_other_benefit_workingtaxcredit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_workingtaxcredit_amount')
          },
          {
            key: "partner_other_benefit_workingtaxcredit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_workingtaxcredit_frequency')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 26",
        page: 26,
        title: "About other money coming in",
        items: [
          {
            key: "applicant_other_benefit_pension",
            title: "Are you currently receiving any private pension or company pension?",
            type: "radios"
          },

          {
            key: "applicant_other_benefit_pension_number",
            title: "How many private or company pensions are you currently receiving?",
            condition: 'model.applicant_other_benefit_pension === "yes"'
          },

          {
            key: 'applicant_other_benefit_pensions',
            condition: 'model.applicant_other_benefit_pension === "Yes"',
            add: "Add Pension",
            title: 'Pension details',
            items: [

              {
                key: "applicant_other_benefit_pensions[].applicant_other_benefit_pension_company",
                title: "What is the name of the company are you receiving this pension from?",
              },
              {
                key: "applicant_other_benefit_pensions[].applicant_other_benefit_pension_value",
                title: "How much?",
              },
              {
                key: "applicant_other_benefit_pensions[].applicant_other_benefit_pension_frequency",
                title: "How often?",
                type: "select",
              },
              {
                key: "applicant_other_benefit_pensions[].applicant_other_benefit_pension_increase",
                title: "When is this pension payment due to go up?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                description: "If you're not sure of the exact date please put an approximate date or leave blank.",
              },
            ]
          },

          {
            key: "applicant_other_benefit_pension_defer",
            title: "Have you put off drawing all or part of a State Retirement Pension, a personal pension or a company pension?",
            type: "radios",
          },
          {
            key: "applicant_other_benefit_pension_defer_lump",
            title: "Have you received a lump sum?",
            type: "radios",
          },
          {
            key: "applicant_other_benefit_pension_defer_lump_amount",
            title: "How much was the lump sum?",
            condition: conditions.toString('applicant_other_benefit_pension_defer_lump_amount')
          },

          {
            key: "partner_other_benefit_pension",
            title: "Is your partner currently receiving any private pension or company pension?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_pension')
          },
          {
            key: "partner_other_benefit_pension_number",
            title: "How many private or company pensions is your partner currently receiving?",
            condition: conditions.toString('partner_other_benefit_pension_number')
          },


          {
            key: 'partner_other_benefit_pensions',
            condition: 'model.partner_other_benefit_pension === "Yes"',
            add: "Add Pension",
            title: 'Pension details',
            items: [

              {
                key: "partner_other_benefit_pensions[].partner_other_benefit_pension_company",
                title: "What is the name of the company they are receiving this pension from?",
              },
              {
                key: "partner_other_benefit_pensions[].partner_other_benefit_pension_value",
                title: "How much?",
              },
              {
                key: "partner_other_benefit_pensions[].partner_other_benefit_pension_frequency",
                title: "How often?",
                type: "select",
              },
              {
                key: "partner_other_benefit_pensions[].partner_other_benefit_pension_increase",
                title: "When is the pension payment due to go up?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                description: "If you're not sure of the exact date please put an approximate date or leave blank.",
              },
            ]
          },

          {
            key: "partner_other_benefit_pension_defer",
            title: "Have they put off drawing all or part of a State Retirement Pension, a personal pension or a company pension?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_pension_defer')
          },
          {
            key: "partner_other_benefit_pension_defer_lump",
            title: "Have they received a lump sum?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_pension_defer_lump')
          },
          {
            key: "partner_other_benefit_pension_defer_lump_amount",
            title: "How much was the lump sum?",
            condition: conditions.toString('partner_other_benefit_pension_defer_lump_amount')
          },
          {
            key: "applicant_other_income",
            title: "Do you receive any other unearned income that you have not already told us about?",
            type: "radios",
            condition: conditions.toString('applicant_other_income'),
            description: "<p>This includes:</p><ul><li>money from a trust fund</li><li>money from business interests</li><li>any rental income</li><li>a student grant or loan</li><li>any cash payments</li><li>a home income plan</li><li>any mortgage payment protection insurance</li><li>a local authority member's allowance</li></ul><p>You do not need to tell us about payments from the Independent Living Fund, the Eileen Trust, the MacFarlane Trust or the Skipton Fund.</p>"
          },
          {
            key: "couple_other_income",
            title: "Do you or your partner receive any other unearned income that you have not already told us about?",
            type: "radios",
            condition: conditions.toString('couple_other_income'),
            description: "<p>This includes:</p><ul><li>money from a trust fund</li><li>money from business interests</li><li>any rental income</li><li>a student grant or loan</li><li>any cash payments</li><li>a home income plan</li><li>any mortgage payment protection insurance</li><li>a local authority member's allowance</li></ul><p>You do not need to tell us about payments from the Independent Living Fund, the Eileen Trust, the MacFarlane Trust or the Skipton Fund.</p>"
          },

          {
            key: 'applicant_other_incomes',
            condition: 'model.applicant_other_income === "Yes" || model.couple_other_income === "Yes"',
            add: "Add Income",
            title: 'Income details',
            items: [
              {
                key: "applicant_other_incomes[].other_income_use",
                type: "textarea",
                title: "What is the money for?",
              },
              {
                key: "applicant_other_incomes[].other_income_pay_method",
                type: "selectWithInput",
                title: "How is this paid, for example, in cash, by cheque or straight into a bank or building society account?",
                options: ["In cash", "By cheque",
                          "Straight into an account", "Other"
                         ],
                other: "Other",
                comment: "Please specify",
              },
              {
                key: "applicant_other_incomes[].other_income_recipient",
                title: "Who gets it?",
                type: "radios",
                condition: 'model.couple === "Yes"'
              },
              {
                key: "applicant_other_incomes[].other_income_amount",
                title: "How much?",
              },
              {
                key: "applicant_other_incomes[].other_income_frequency",
                title: "How often?",
                type: "radios",
              },
              {
                key: "applicant_other_incomes[].other_income_start_date",
                title: "When did this income start?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                description: "If you're not sure of the exact date please put an approximate date.",
              },
              {
                key: "applicant_other_incomes[].other_income_increase",
                title: "When is the income likely to go up?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                description: "If you're not sure of the exact date please put an approximate date. If you do not expect the income to go up please leave blank.",
              },
            ]
          },

          {
            key: "applicant_other_income_misc",
            title: "If you want to tell us about more money coming in, do so here",
            type: "textarea"
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 27",
        page: 27,
        title: "Bank accounts",
        items: [
          {
            key: "applicant_accounts",
            title: "How many bank, building society and post office accounts do you have in the UK or abroad?",
            condition: conditions.toString('applicant_accounts'),
            description: "<p>This includes cash, current accounts, savings accounts and card accounts with a bank, building society or the Post Office.</p><p>Tell us about them even if they are empty or overdrawn, whether they are currently in use or not.</p><p>Please enter 0 if you don't have any bank, building society or post office accounts.</p>"
          },

          {
            key: 'applicant_account',
            add: "Add Bank Account",
            title:'Bank Account details',
            condition: 'model.applicant_accounts > 0',
            items: [

              {
                key: "applicant_account[].applicant_account_bank",
                title: "Which bank or building society is your account with?",
                description: "For Post Office accounts write POST OFFICE",
              },
              {
                key: "applicant_account[].applicant_account_number",
                title: "What's your account number?",
                description: $translate.instant('forms.type.FCR.account_number_description')
              },
              {
                key: "applicant_account[].applicant_account_balance",
                title: "What's the balance of this account?",
                description: "If the account is overdrawn put 0",
              },

            ]

          },
          {
            key: "couple_accounts",
            title: "How many bank, building society and post office accounts do you or your partner have in the UK or abroad?",
            type: "number",
            condition: conditions.toString('couple_accounts'),
            description: "<p>This includes cash, current accounts, savings accounts and card accounts with a bank, building society or the Post Office.</p><p>Tell us about them even if they are empty or overdrawn, whether they are currently in use or not.</p><p>Please enter 0 if you don't have any bank, building society or post office accounts.</p>"
          },

          {
            key: 'couple_account',
            add: "Add Bank Account",
            title:'Bank Account details',
            condition: 'model.couple_accounts > 0',
            items: [

              {
                key: "couple_account[].couple_account_bank",
                title: "Which bank or building society is the account with?",
                description: "For Post Office accounts write POST OFFICE",
              },
              {
                key: "couple_account[].couple_account_name",
                title: "Whose name is the account in?",
                type: "radios",
              },
              {
                key: "couple_account[].couple_account_number",
                title: "What's the account number?",
                description: $translate.instant('forms.type.FCR.account_number_description')
              },
              {
                key: "couple_account[].couple_account_balance",
                title: "What's the balance of this account?",
                type: "number",
                description: "If the account is overdrawn put 0",
              },

            ]
          },
        ]
      },

      {
        type: "fieldset",
        condition: "model.page === 28",
        page: 28,
        title: "Savings, Investments and Property",
        items: [
          {
            key: "applicant_nsc",
            title: "Do you have any National Savings Certificates?",
            type: "radios",
            condition: conditions.toString('applicant_nsc')
          },
          {
            key: "applicant_stocks",
            title: "Do you have any stocks or shares?",
            type: "radios",
            condition: conditions.toString('applicant_stocks')
          },

          {
            key: "applicant_stocks_number",
            title: "How many different companies do you hold stocks or shares with?",
            type: "number",
            condition: 'model.applicant_stocks === "Yes"'
          },

          {
            key: 'applicant_stock',
            add: "Add Stocks",
            title:'Stocks details',
            condition: 'model.applicant_stocks === "Yes"',
            items: [
              {
                key: "applicant_stock[].applicant_stocks_company",
                title: "Company name",
              },
              {
                key: "applicant_stock[].applicant_stocks_no",
                title: "How many do you have with this company?",
              },

            ]

          },

          {
            key: "couple_nsc",
            title: "Do you or your partner have any National Savings Certificates?",
            type: "radios",
            condition: conditions.toString('couple_nsc')
          },
          {
            key: "couple_stocks",
            title: "Do you or your partner have any stocks or shares?",
            type: "radios",
            condition: conditions.toString('couple_stocks')
          },
          {
            key: "couple_stocks_number",
            title: "How many different companies do you and your partner hold stocks or shares with?",
            condition: conditions.toString('couple_stocks_number')
          },

          {
            key: 'couple_stock',
            add: "Add Stocks",
            title:'Stocks details',
            condition: 'model.couple_stocks === "Yes"',
            items: [
              {
                key: "couple_stock[].couple_stocks_company",
                title: "Company name",
              },
              {
                key: "couple_stock[].couple_stocks_no",
                title: "How many do you or your partner have with this company?",
              },
            ]
          },

          {
            key: "applicant_savings",
            title: "Do you have any Savings Investment Accounts?",
            type: "radios",
            condition: conditions.toString('applicant_savings')
          },
          {
            key: "applicant_savingsno",
            title: "How many Savings Investment Accounts do you have?",
            condition: conditions.toString('applicant_savingsno')
          },
          {
            key: "applicant_savingsvalue",
            title: "What's the total value?",
            condition: conditions.toString('applicant_savingsvalue')
          },
          {
            key: "applicant_premiumbonds",
            title: "Do you have any Premium Bonds?",
            type: "radios",
            condition: conditions.toString('applicant_premiumbonds')
          },
          {
            key: "applicant_premiumbondsno",
            title: "How many Premium Bonds do you have?",
            condition: conditions.toString('applicant_premiumbondsno')
          },
          {
            key: "applicant_premiumbondsvalue",
            title: "What's the total value?",
            condition: conditions.toString('applicant_premiumbondsvalue')
          },
          {
            key: "applicant_isa",
            title: "Do you have any Unit trusts, OEICs, Stocks and Shares ISAs or Cash ISAs?",
            type: "radios",
            condition: conditions.toString('applicant_isa')
          },
          {
            key: "applicant_isano",
            title: "How many Unit trusts, OEICs, Stocks and Shares ISAs or Cash ISAs do you have?",
            condition: conditions.toString('applicant_isano')
          },
          {
            key: "applicant_isavalue",
            title: "What's the total value?",
            condition: conditions.toString('applicant_isavalue')
          },
          {
            key: "applicant_incomebonds",
            title: "Do you have any Income bonds or Capital bonds?",
            type: "radios",
            condition: conditions.toString('applicant_incomebonds')
          },
          {
            key: "applicant_incomebondsno",
            title: "How many Income bonds or Capital bonds do you have?",
            condition: conditions.toString('applicant_incomebondsno')
          },
          {
            key: "applicant_incomebondsvalue",
            title: "What's the total value?",
            condition: conditions.toString('applicant_incomebondsvalue')
          },
          {
            key: "applicant_trust",
            title: "Do you have any Money or Property held in trust?",
            type: "radios",
            condition: conditions.toString('applicant_trust')
          },
          {
            key: "applicant_trust_property",
            title: "How many properties held in trust do you have?",
            condition: conditions.toString('applicant_trust_property')
          },
          {
            key: "applicant_trust_property_value",
            title: "What's the total value of the property?",
            condition: conditions.toString('applicant_trust_property_value')
          },
          {
            key: "applicant_trust_money",
            title: "How much Money do you have in trust?",
            condition: conditions.toString('applicant_trust_money')
          },
          {
            key: "applicant_savingsother",
            title: "Do you have any other savings or investments?",
            type: "radios",
            condition: conditions.toString('applicant_savingsother')
          },
          {
            key: "applicant_savingsotherdetails",
            title: "What are these savings or investments?",
            condition: conditions.toString('applicant_savingsotherdetails')
          },
          {
            key: "applicant_savingsothervalue",
            title: "What's the total value?",
            condition: conditions.toString('applicant_savingsothervalue')
          },
          {
            key: "applicant_savingsinclude",
            title: "Do any of your savings or investments include money from the sale of a house, or money from a charity?",
            type: "radios",
            condition: conditions.toString('applicant_savingsinclude')
          },
          {
            key: "applicant_property",
            title: "Do you own another property or land in the United Kingdom or abroad? If it is still on a mortgage or loan, answer 'Yes'",
            type: "select",
            condition: conditions.toString('applicant_property'),
            description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/FCR/templates/propertyDescription.html')
          },
          {
            key: "couple_savings",
            title: "Do you or your partner have any Savings Investment Accounts?",
            type: "radios",
            condition: conditions.toString('couple_savings')
          },
          {
            key: "couple_savingsno",
            title: "How many Savings Investment Accounts do you and your partner have?",
            condition: conditions.toString('couple_savingsno')
          },
          {
            key: "couple_savingsvalue",
            title: "What's the total value?",
            condition: conditions.toString('couple_savingsvalue')
          },
          {
            key: "couple_premiumbonds",
            title: "Do you or your partner have any Premium Bonds?",
            type: "radios",
            condition: conditions.toString('couple_premiumbonds')
          },
          {
            key: "couple_premiumbondsno",
            title: "How many Premium Bonds do you and your partner have?",
            condition: conditions.toString('couple_premiumbondsno')
          },
          {
            key: "couple_premiumbondsvalue",
            title: "What's the total value?",
            condition: conditions.toString('couple_premiumbondsvalue')
          },
          {
            key: "couple_isa",
            title: "Do you or your partner have any Unit trusts, OEICs, Stocks and Shares ISAs or Cash ISAs?",
            type: "radios",
            condition: conditions.toString('couple_isa')
          },
          {
            key: "couple_isano",
            title: "How many Unit trusts, OEICs, Stocks and Shares ISAs or Cash ISAs do you and your partner have?",
            condition: conditions.toString('couple_isano')
          },
          {
            key: "couple_isavalue",
            title: "What's the total value?",
            condition: conditions.toString('couple_isavalue')
          },
          {
            key: "couple_incomebonds",
            title: "Do you or your partner have any Income bonds or Capital bonds?",
            type: "radios",
            condition: conditions.toString('couple_incomebonds')
          },
          {
            key: "couple_incomebondsno",
            title: "How many Income bonds or Capital bonds do you and your partner have?",
            condition: conditions.toString('couple_incomebondsno')
          },
          {
            key: "couple_incomebondsvalue",
            title: "What's the total value?",
            condition: conditions.toString('couple_incomebondsvalue')
          },
          {
            key: "couple_trust",
            title: "Do you or your partner have any Money or Property held in trust?",
            type: "radios",
            condition: conditions.toString('couple_trust')
          },
          {
            key: "couple_trust_property",
            title: "How many properties held in trust do you and your partner have?",
            condition: conditions.toString('couple_trust_property')
          },
          {
            key: "couple_trust_property_trust",
            title: "What's the total value of the property?",
            condition: conditions.toString('couple_trust_property_trust')
          },
          {
            key: "couple_trust_money",
            title: "How much Money do you and your partner have in trust?",
            condition: conditions.toString('couple_trust_money')
          },
          {
            key: "couple_savingsother",
            title: "Do you or your partner have any other savings or investments?",
            type: "radios",
            condition: conditions.toString('couple_savingsother')
          },
          {
            key: "couple_savingsotherdetails",
            title: "What are these savings or investments?",
            condition: conditions.toString('couple_savingsotherdetails')
          },
          {
            key: "couple_savingsothervalue",
            title: "What's the total value?",
            condition: conditions.toString('couple_savingsothervalue')
          },
          {
            key: "couple_savingsinclude",
            title: "Do any of your or your partner's savings/investments include money from the sale of a house or from a charity?",
            type: "radios",
            condition: conditions.toString('couple_savingsinclude')
          },
          {
            key: "couple_property",
            title: "Do you own another property or land in the United Kingdom or abroad? If the property or land is still on a mortgage or loan, answer 'Yes'",
            type: "select",
            description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/FCR/templates/propertyDescription.html'),
            condition: conditions.toString('couple_property')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 29",
        page: 29,
        title: "About Rent",
        items: [
          {
            key: "applicant_rent",
            title: "Are you charged rent for your home?",
            description: "If you would pay rent but you already get Housing Benefit, you should still choose \"Yes\".",
          },
          {
            type: "fieldset",
            condition: 'model.applicant_rent === "Yes, I am charged rent / I already get Housing Benefit"',
            items: [

              {
                key: "applicant_rent_charger",
                title: "Who charges the rent for your home?",
              },
              {
                type: 'fieldset',
                condition: 'model.applicant_rent_charger === "I am charged rent by a housing association" || model.applicant_rent_charger === "I am charged rent by a private landlord"',
                items: [
                  {
                    key: "applicant_rent_accommodation_type",
                    title: "Do you live in permanent or in temporary accommodation?",
                  },
                  {
                    key: "applicant_rent_howmuch",
                    title: "How much rent are you charged?",
                    condition: 'model.couple === "No"'
                  },
                  {
                    key: "couple_rent_howmuch",
                    title: "How much rent are you and your partner charged?",
                    condition: 'model.couple === "Yes"'
                  },
                  {
                    key: "applicant_rent_frequency",
                    title: "How often are you charged rent?",
                    type: "radios",
                  },
                  {
                    key: "applicant_rent_increase_due",
                    title: "When is your next rent increase due?",
                    type: "kerpdate",
                    dateFormat: "YYYY-MM-DD",
                    description: "If you do not know the exact date please enter an approximate date or leave blank"
                  }

                ]
              },

            ]
          },
        ]

      },
      {
        type: "fieldset",
        condition: "model.page === 30",
        page: 30,
        title: "Anything else that you need to tell us?",
        items: [
          {
            key: "applicant_anythingelse",
            type: "textarea",
            title: "Please use this space to tell us anything else you think we should know about."
          }]

      },
      {
        type: "fieldset",
        condition: "model.page === 31",
        page: 31,
        title: "Filling in the form on someone else's behalf",
        items: [
          {
            type: "help",
            helpvalue: "If this form has been filled in by someone other than the person claiming, please tell us about this below"
          },
          {
            key: "applicant_completedby",
            title: "Has this form been filled in by someone other than the person claiming?",
            type: "radios"
          },
          {
            key: "applicant_completedby_reason",
            title: "Please tell us why",
            condition: conditions.toString('applicant_completedby_reason')
          },
          {
            key: "applicant_completedby_forename",
            title: "What is the first name of the person who filled in this form?",
            condition: conditions.toString('applicant_completedby_forename')
          },
          {
            key: "applicant_completedby_lastname",
            title: "What is the surname of the person who filled in this form?",
            condition: conditions.toString('applicant_completedby_lastname')
          },
          {
            key: "applicant_completedby_addressline1",
            title: "What's their address? Address Line 1",
            condition: conditions.toString('applicant_completedby_addressline1')
          },
          {
            key: "applicant_completedby_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_completedby_addressline2')
          },
          {
            key: "applicant_completedby_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_completedby_addressline3')
          },
          {
            key: "applicant_completedby_postcode",
            title: "Postcode of the person who filled in this form",
            condition: conditions.toString('applicant_completedby_postcode')
          },
          {
            key: "applicant_completedby_tel",
            title: "Daytime telephone number",
            condition: conditions.toString('applicant_completedby_tel')
          },
          {
            key: "applicant_completedby_relationship",
            title: "Relationship to the person claiming",
            condition: conditions.toString('applicant_completedby_relationship')
          },
          {
            key: "applicant_completedby_declaration",
            condition: conditions.toString('applicant_completedby_declaration')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 32",
        page: 32,
        title: "Letter of Authority",
        items: [
          {
            type: "help",
            helpvalue: "<p>If you want a relative, friend, neighbour or outside agency to be able to discuss your claim on your behalf, please tell us about this below.<br><br>You can cancel this letter of authority at any time by writing to Kirklees Council, Customer and Exchequer Services.</p>"
          },
          {
            key: "applicant_letterofauthority",
            title: "Do you want to give permission for someone else to discuss your claim on your behalf?",
            type: "radios"

          },
          {
            key: "applicant_letterofauthority_name",
            title: "Name of nominated person or agency",
            condition: conditions.toString('applicant_letterofauthority_name')
          },
          {
            key: "applicant_letterofauthority_addressline1",
            title: "Address line 1",
            condition: conditions.toString('applicant_letterofauthority_addressline1')
          },
          {
            key: "applicant_letterofauthority_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_letterofauthority_addressline2')
          },
          {
            key: "applicant_letterofauthority_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_letterofauthority_addressline3')
          },
          {
            key: "applicant_letterofauthority_postcode",
            title: "Their Postcode",
            condition: conditions.toString('applicant_letterofauthority_postcode')
          },
          {
            key: "applicant_letterofauthority_tel",
            title: "Nominee's daytime telephone number",
            condition: conditions.toString('applicant_letterofauthority_tel')
          },
          {
            key: "applicant_letterofauthority_relationship",
            title: "Nominee's relationship to you",
            condition: conditions.toString('applicant_letterofauthority_relationship')
          },
          {
            key: "applicant_letterofauthority_declaration",
            condition: conditions.toString('applicant_letterofauthority_declaration')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 33",
        page: 33,
        title: "Declaration",
        items: [
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/FCR/templates/declaration.html"
          },
          "applicant_declaration",
          {
            key: "partner_declaration",
            condition: conditions.toString('partner_declaration')
          }]
      },
      {
        type: "section",
        condition: "areFormNavigationButtonsVisible()",
        items: [
          {
            type: "actions",
            htmlClass: "formPaginationButtons",
            items: [
              {
                type: "button",
                style: "btn-default",
                title: "Previous",
                onClick: "prev()"
              },
              {
                type: "button",
                style: "btn-primary",
                title: "Next",
                onClick: "next()"
              }]
          }]
      }];

    formUI.setForm(form);

    var mergedFormAndSchema = schemaFormHelperService.mergeFormAndSchema(form, $injector.get('FCR_SCHEMA').getSchema());

    function getHiddenPages(model) {
      var hiddenPages = [];

      // the last element of the form is the section that with the prev/next buttons rather than a page
      var formLength = form.length - 1;

      for (var pageIndex = 0; pageIndex < formLength; pageIndex++) {
        if (schemaFormHelperService.isPageHidden(model, conditions, mergedFormAndSchema[pageIndex])) {
          var hiddenPageNumber = pageIndex + 1;
          hiddenPages.push(hiddenPageNumber);
        }
      }

      return hiddenPages;
    }

    // Retrieving the hidden pages is an expensive operation, that often returns the same results (because it is called
    // with the same model). As an optimisation, memoize the results, using a hash of the model as the memoization cache key
    formUI.getHiddenPages = _.memoize(getHiddenPages, function (model) {
      return formModelService.formFieldsHashCode(model);
    });

    return formUI;

  }
]);
