'use strict';

angular.module('kerp-forms.forms')
  .controller('ShowCtrl',
    ['$scope', '$state', '$sce', '$stateParams', '$injector', '$q', 'Forms', 'configuration', 'API',
      'formModelService', 'toaster', 'authSessionService', 'analyticsService', '$uibModal', 'FormModel',
      function ($scope, $state, $sce, $stateParams, $injector, $q, Forms, configuration, API,
                formModelService, toaster, authSessionService, analyticsService, $uibModal, FormModel) {

        // PRIVATE
        /////////////////////

        /**
         * Create reference to new form in DB and go to update
         * page of this new form.
         * @returns {Promise}
         * @private
         */
        function createAndSaveForm() {

          var newInstance = new FormModel({
            formTypeCode: $scope.form.id,
            formName: $scope.form.name,
            pageCount: $scope.formLength,
            lastValidPage: 0
          });

          if (!$scope.form.is_submit_once) {
            newInstance.fillablePdfUrl = [
              configuration.app.home + '/modules/forms/scripts/services/forms',
              $scope.form.id,
              $scope.form.id + '.pdf'
            ].join('/');
          }

          return formModelService.createForm(newInstance)
            .then(function (formModelInstance) {
              analyticsService.sendEvent('form', 'start', $scope.form.id);
              console.log('start event sent for form', $scope.form.id);
              return formModelInstance;
            });
        }

        function getlastUnsubmittedApplication() {
          var unsubmittedForms = ($scope.filteredFormModelInstances || [])
            .filter(function (form) {
              return !form.isSubmitted();
            })
            .sort(function (form) {
              // use the ID as a proxy for creation date
              return -1 * form.id;
            });

          return $q.resolve(unsubmittedForms.length ? unsubmittedForms[0] : null);
        }

        function hasApplicationsInProgress() {

          if (!$scope.isLoggedIn) {
            return initFormInstances(true).then(getlastUnsubmittedApplication);
          } else {
            return getlastUnsubmittedApplication();
          }
        }

        // INITIALIZATION
        /////////////////////////////////

        var allVisibleFormIds = Forms.getForms().map(function (formConfig) {
          return formConfig.id;
        });

        // KERP-1578 KERP-2658: if the form ID is invalid, redirect them to the list of forms
        if (allVisibleFormIds.indexOf($stateParams.formId) === -1) {
          return $state.go('main.forms.list');
        }

        $scope.isLoggedIn = authSessionService.hasSession();
        $scope.currentFormId = $stateParams.formId;

        //Get the form definition according to the query parameter formId
        $scope.form = Forms.getForm($scope.currentFormId);

        // TODO: extract formLength method to service
        $scope.formLength = 0;
        if ($scope.form.has_web_form) {
          var formService = $injector.get($scope.form.formService);

          // the last element in the form definition is not a page (it defines the prev/next buttons)
          $scope.formLength = formService.get().length - 1;
        }

        //Init all the links to access to the differents pdfs and images
        var baseUrl = configuration.app.home + '/modules/forms/scripts/services/forms/' + $scope.form.id + '/' + $scope.form.id;
        $scope.pdfLink = baseUrl + '.pdf';
        $scope.flatPdfLink = baseUrl + '_flat.pdf';
        $scope.imgLink = baseUrl + '.jpg';


        function initFormInstances(forceLogin) {

          if ($scope.isLoggedIn || forceLogin) {
            return formModelService.listForms({sort: 'lastUpdated', order: 'desc'}).then(
              function (formModelInstances) {
                $scope.filteredFormModelInstances = formModelInstances.filter(function (formModelInstance) {
                  return $scope.currentFormId === formModelInstance.formTypeCode;
                });
              }
            );
          } else {
            $scope.filteredFormModelInstances = [];
            return $q.when();
          }
        }

        initFormInstances();

        // PUBLIC
        /////////////////////

        $scope.removeAssessment = function (formModelInstance) {
          return formModelService.deleteForm(formModelInstance)
            .then(function () {
              $scope.filteredFormModelInstances = _.without($scope.filteredFormModelInstances, formModelInstance);
            })
            .catch(function (e) {
              console.error('Error:', e);
              toaster.pop('error', 'Failed to delete assessment data');
            });
        };

        /**
         * Go to a specific form
         */
        $scope.pickAssessment = function (assessment) {
          $state.go('main.forms.update', {formId: assessment.formTypeCode, id: assessment.id});
        };

        /**
         * Create an assessment using the form code, and go to the specific form page
         */
        $scope.createAssessment = function () {

          return hasApplicationsInProgress().then(function (lastUnsubmittedApplication) {
            return (function () {

              if (lastUnsubmittedApplication) {
                return $uibModal.open({
                  size: 'sm',
                  templateUrl: 'modules/forms/views/modals/confirmNewApplication.html',
                  backdrop: true,
                  controller: ['$scope', function (modalScope) {
                    modalScope.applicationInProgress = lastUnsubmittedApplication;
                    modalScope.form = $scope.form;
                  }]
                }).result.catch(function (reason) {
                  if (reason === 'continueApplication') {
                    $scope.pickAssessment(lastUnsubmittedApplication);
                  }
                  return $q.reject();
                });
              }
              return $q.resolve();
            }())
              .then(createAndSaveForm)
              .then(function (formModelInstance) {
                $state.go('main.forms.update', {
                  formId: $scope.form.id,
                  id: formModelInstance.id,
                  populateUserDetails: true
                });
              });
          });
        };

        /**
         * In case form is not finished, show this dialog
         * @param {FormModel} chosenApplication form
         */
        $scope.openDownloadPrompt = function (chosenApplication) {
          return $uibModal.open({
            size: 'sm',
            templateUrl: 'modules/forms/views/modals/promptFormIsNotFinishedYet.html',
            backdrop: true,
            resolve: {
              formModelInstance: function () {
                return chosenApplication;
              }
            },
            controller: ['$scope', 'formModelInstance', function (scope, formModelInstance) {
              scope.details = {
                formModelInstance: formModelInstance
              };
            }]
          }).result;
        };

        /**
         * Open a popup that displays PDF forms
         */
        $scope.choosePdfForm = function () {
          return $uibModal.open({
            templateUrl: 'modules/forms/views/modals/choosePdfForm.html',
            backdrop: true,
            controller: ['$scope', '$uibModalInstance', 'form', 'imgLink', 'pdfLink', 'flatPdfLink',
              function ($scope, $uibModalInstance, form, imgLink, pdfLink, flatPdfLink) {
                $scope.form = form;
                $scope.imgLink = imgLink;
                $scope.pdfLink = pdfLink;
                $scope.flatPdfLink = flatPdfLink;
              }],
            resolve: {
              form: function () {
                return $scope.form;
              },
              imgLink: function () {
                return $scope.imgLink;
              },
              pdfLink: function () {
                return $scope.pdfLink;
              },
              flatPdfLink: function () {
                return $scope.flatPdfLink;
              }
            }
          }).result;
        };

        $scope.clone = function (formId) {
          API.post('/form/' + formId + '/clone', {})
            .then(function (results) {
              initFormInstances().finally(function () {
                toaster.pop('success', 'The application has been successfully copied');
              });
            });
        };

        $scope.toggleFormSubmitted = function (formModelInstance) {

          if (!formModelInstance.isSubmitted()) {
            formModelInstance.setSubmittedFields(formModelInstance.dirtyFields);
          } else {
            formModelInstance.setSubmittedFields(null);
          }

          return formModelService.updateFormAndFacts(formModelInstance)
            .catch(function () {
              toaster.pop('error', 'Data could not be saved');
            });
        };
      }]);
