'use strict';
angular.module('kerp-forms.forms').factory(
  'DE_FCA_CONDITIONS',

  [
    'FormConditions',
    'configuration',
    function (
      FormConditions,
      configuration
    ) {

  return FormConditions.clone({
    conditions: {
      'person.property.value': function () {
        return this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
      },

      'person.income.attendanceAllowance': function () {
        var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
        var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

        return !hasDlaCare && !hasPipDla;
      },

      'person.income.dlaCare': function () {
        var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
        var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

        return !hasAA && !hasPipDla;
      },

      'person.income.pipDailyLiving': function () {
        var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
        var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

        return !hasAA && !hasDlaCare;
      },

      'person.income.universalCredit': function () {
        var uc = this.lib.getModelValue('person.income.universalCredit');
        console.log(uc);
        return uc > 0;
      },
    }
  });
}]);
