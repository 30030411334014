'use strict';
angular.module('kerp-forms.forms').factory('KS_SINGLE_PAGE_OPTIONAL_FORM',
  ['FormUI', 'dateUtils', 'KS_CONDITIONS',
    function (FormUI, dateUtils, conditions) {

      var formUI = new FormUI();

      var form = [
        {
          type: "fieldset",
          condition: "model.page === 1",
          page: 1,
          items: [
            {
              type: "fieldset",
              title: 'An array of objects',
              items: [
                {
                  key: 'children',
                  add: "Add Child",
                  title: 'Tell us about your children'
                }
              ]
            },
            {
              type: "fieldset",
              title: 'Hiding fields',
              items: [
                'textInputHide',
                'numberSelectionDateInputHide'
              ]
            },
            {
              type: "fieldset",
              title: 'Text input with different regex constraints',
              condition: conditions.toString('textInputFieldSet'),
              items: [
                {
                  key: 'string.name',
                  condition: conditions.toString('string.name')
                },
                {
                  key: "string.nationalInsuranceNumber",
                  condition: conditions.toString('string.nationalInsuranceNumber')
                },
                {
                  key: "string.email",
                  condition: conditions.toString('string.email')
                }
              ]
            },
            {
              type: "fieldset",
              title: 'Number input fields',
              condition: conditions.toString('numberInputFieldSet'),
              items: [
                {
                  key: "number.minAndMaxInteger",
                  condition: conditions.toString('number.minAndMaxInteger')
                },
                {
                  key: "number.integer",
                  condition: conditions.toString('number.integer')
                },
                {
                  key: "number.positiveInteger",
                  condition: conditions.toString('number.positiveInteger')
                },
                {
                  key: "number.currency",
                  condition: conditions.toString('number.currency')
                },
                {
                  key: "number.decimal",
                  condition: conditions.toString('number.decimal')
                }
              ]
            },

            {
              type: "fieldset",
              htmlClass: 'form-group',
              title: 'Boolean fields',
              condition: conditions.toString('booleanFieldSet'),
              items: [
                {
                  key: "boolean.radios",
                  condition: conditions.toString("boolean.radios")
                }
              ]
            },
            {
              type: "fieldset",
              htmlClass: 'form-group',
              title: 'Single selection enum string fields',
              condition: conditions.toString('singleSelectionFieldSet'),
              items: [
                {
                  key: "string.genderRadios",
                  condition: conditions.toString("string.genderRadios")
                },
                {
                  key: "string.genderSelect",
                  condition: conditions.toString("string.genderSelect")
                }
              ]
            },
            {
              type: "fieldset",
              htmlClass: 'form-group',
              title: 'Multi selection enum string array field',
              condition: conditions.toString('multiSelectionFieldset'),
              items: [
                {
                  key: "multiSelect",
                  type: "checkboxes",
                  condition: conditions.toString('multiSelect')
                }
              ]
            },
            {
              type: "fieldset",
              htmlClass: 'form-group',
              title: 'Date and time addons',
              condition: conditions.toString('dateTimeFieldSet'),
              items: [
                {
                  key: "string.pastDate",
                  condition: conditions.toString("string.pastDate")
                },
                {
                  key: "string.timePicker",
                  condition: conditions.toString("string.timePicker")
                },
                {
                  key: "string.memorableDate",
                  type: "memorabledate",
                  dateFormat: "YYYY-MM-DD",
                  validationMessage: "The date must be in the format dd/mm/yyyy and the age must be between 16 and 120.",
                  maxDate: dateUtils.getYearsBeforeNow(16),
                  minDate: dateUtils.getYearsBeforeNow(120),
                  condition: conditions.toString("string.memorableDate")
                }
              ]
            },

            {
              type: "fieldset",
              title: 'School address lookup',
              items: [
                {
                  type: "template",
                  templateUrl: "modules/forms/scripts/services/forms/kitchenSink/templates/schoolAddressLookup.html"
                },
                "schoolLookupSchoolName",
                "schoolLookupLine1",
                "schoolLookupLine2",
                "schoolLookupLine3",
                "schoolLookupPostcode"
              ]
            },

            {
              type: "fieldset",
              title: 'Landlord address lookup',
              items: [
                {
                  type: "template",
                  templateUrl: "modules/forms/scripts/services/forms/kitchenSink/templates/landlordAddressLookup.html"
                },
                "landlordLookupLandlordName",
                "landlordLookupLine1",
                "landlordLookupLine2",
                "landlordLookupLine3",
                "landlordLookupPostcode"
              ]
            },

            {
              type: "fieldset",
              title: 'Sundry addons',
              items: [
                {
                  key: "string.chooseOrEnterTitle",
                  type: 'selectWithInput',
                  radios: true,
                  options: ["Mr", "Ms", "Mrs", "Miss", "Other"],
                  other: "Other",
                  comment: "If other, please specify",
                  title: 'Choose one of the following or enter an alternative'
                }
              ]
            }
          ]

        },

        {
          type: "section",
          condition: "areFormNavigationButtonsVisible()",
          items: [
            {
              type: "actions",
              htmlClass: "formPaginationButtons",
              items: [
                {
                  type: "button",
                  style: "btn-default",
                  title: "Previous",
                  onClick: "prev()"
                },
                {
                  type: "button",
                  style: "btn-primary",
                  title: "Next",
                  onClick: "next()"
                }]
            }]
        }

      ];

      formUI.setForm(form);

      return formUI;

    }]);
