'use strict';

angular.module('kerp-forms.forms').factory('FCA_CONDITIONS', ['FormConditions', function (FormConditions) {

  return FormConditions.clone({
    conditions: {
      'agent.applicantConfirmationDeclaration': function () {
        return this.lib.isAnyOf('agent.details.relationship', ['advocate', 'friend']);
      },
      'agent.agentDeclaration': function () {
        return this.lib.isAnyOf('agent.details.relationship',
          ['appointeeship', 'powerOfAttorney', 'lastingPowerOfAttorney', 'deputyship']);
      },
      'person.expenses.rent': function () {
        return !this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedByPartner', 'ownedJointly']);
      },
      'person.expenses.mortgage': function () {
        return !this.lib.isAnyOf('person.property.ownership', ['councilTenant', 'rentedPrivately']);
      }
    }
  });
}]);
