'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'KR_CA_FORM',

    [
      'FormUI',
      'fieldDefinitionService',
      'kirkleesCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      '$window',

      function (
        FormUI,
        fieldDefinitionService,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        $window
      ) {

        const constants = $window.kerpCfa.kirklees.getConstants();

        var formUI = new FormUI();

        formUI.getHiddenPages = function (model) {
          var intro = (model.person || {}).intro || {};
          var withholdFinancialDetails = intro.provideFinancialDetails !== 'agree';
          let hiddenPages = [];

          if (withholdFinancialDetails) hiddenPages = [2];
          return hiddenPages;
        };

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel, true);
        };

        formUI.setForm([
          {
            type: 'section',
            condition: 'model.page === 1',
            page: 1,
            items: [
              {
                key: 'schemaVersion',
                type: 'schemaversion',
              },
              {
                key: 'clientReference',
                description: 'You will find this on any letters you have received from Adult Social Care Services. If you do not know your client reference please leave this blank.'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'About you',
                    htmlClass: "col-md-6",
                    items: [
                      'person.details.title',
                      'person.details.firstName',
                      'person.details.lastName',
                      'person.details.dob',
                      'person.details.phoneNumber',
                      'person.details.nationalInsuranceNumber',
                      {
                        key: 'person.details.email',
                        description: 'If you provide an email address then we can use this to contact you in the future if we need more information.'
                      },
                      {
                        type: "template",
                        templateUrl: "modules/forms/scripts/services/forms/KR_CA/templates/addressLookup.html"
                      },
                      // FIXME: these fields are already defined in the addressSuggestion directive's template, but they are not included in the submitted model unless they are repeated here
                      {
                        key: 'person.details.address.line1',
                        title: 'Address line 1',
                        type: "hidden"
                      },
                      {
                        key: 'person.details.address.line2',
                        title: 'Address line 2',
                        type: "hidden"
                      },
                      {
                        key: 'person.details.address.line3',
                        title: 'Address line 3',
                        type: "hidden"
                      },
                      {
                        key: 'person.details.address.postcode',
                        title: 'Postcode',
                        type: "hidden"
                      },
                      {
                        key: 'person.details.address.uprn',
                        title: 'Property Reference',
                        type: "hidden"
                      },
                      'person.intro.ownsProperty',
                      {
                        key: 'person.intro.hasAdditionalProperties',
                        condition: 'model.person.intro.ownsProperty'
                      },
                      {
                        key: 'person.intro.dependentChildrenLivingInProperty',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/KR_CA/templates/dependentChildrenDescription.html')
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: 'Partner details',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'partner.details',
                        title: false
                      }
                    ],
                    condition: 'model.person.intro.hasPartner'
                  }
                ]
              },
              {
                key: 'person.intro.hasPartner',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/KR_CA/templates/partnerDescription.html')
              },
              {
                key: 'partner.intro.discloseFinances',
                description: "By declaring your partner's finances you may benefit from extra allowances.",
                condition: 'model.person.intro.hasPartner'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Declaration',
                    htmlClass: "col-md-6",
                    items: [
                      'person.intro.provideFinancialDetails'
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 2',
            page: 2,
            items: [
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/KR_CA/templates/intro.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your capital',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'person.capital.individual',
                        description: 'Include any capital you hold in bank accounts, building societies, stocks, shares, investments and any second properties where you are the sole named owner.'
                      },
                      {
                        key: 'person.capital.joint',
                        description: 'Include any capital you and someone else hold in joint bank accounts, building societies, stocks, shares, investments and any second properties, where you are both the named owners.'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's capital",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'partner.capital.individual',
                        description: 'Include any capital your partner holds in bank accounts, building societies, stocks, shares, investments and any second properties where they are the sole named owner.'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: "Universal Credit",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'person.income.hasUniversalCredit',
                        title: 'Are you in receipt of Universal Credit?'
                      },
                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/KR_CA/templates/universalCreditIntro.html',
                        condition: 'model.person.income.hasUniversalCredit'
                      },
                      {
                        key: 'person.income.universalCreditBreakdown',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.person.income.hasUniversalCredit'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your income',
                    htmlClass: "col-md-6",
                    items: [
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.retirementPension'}),
                      {
                        key: 'person.income.benefitsPaidAsCouple',
                        condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                        description: "Answer Yes if your JSA/ESA payment includes an amount for your partner. If you do not receive JSA or ESA or if the payment is only for you, answer No."
                      },
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:
                                                                               'person.income.incapacityBenefit',
                                                                               description: "This includes Jobseeker's Allowance, Employment and Support Allowance, Incapacity Benefit, or Income Support."}
                                                                             ),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.privatePension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.warPension'}),
                      {
                        type: 'section',
                        htmlClass: 'row',
                        condition: 'model.person.income.warPension',
                        items: [
                          {
                            key: 'person.income.warSpecialPayment',
                            htmlClass: "col-md-8",
                            feedback: false
                          },
                          {
                            key: 'person.income.warSpecialPaymentPeriod',
                            condition: 'model.person.income.warSpecialPayment',
                            htmlClass: "col-md-4"
                          }
                        ]
                      },
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.warDisablementPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.childBenefit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.earningsFromPaidEmployment'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.carersAllowance'}),
                      fieldDefinitionService.groups.paymentFormField({key:'person.income.disabilityBenefits'}),
                      fieldDefinitionService.groups.paymentFormField({key:'person.income.mobilityAllowance'}),

                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.income.pensionGuaranteeCredit',
                        description: "Pension Credit is made up of two parts, Guarantee Credit and Savings Credit. You can get either one or both parts. Your Pension Credit letter will show what part/s you get."}),

                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.income.pensionSavingsCredit',
                        description: "Pension Credit is made up of two parts, Guarantee Credit and Savings Credit. You can get either one or both parts. Your Pension Credit letter will show what part/s you get."}),

                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.workingTaxCredit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.childTaxCredit'}),

                      {
                        key: 'person.intro.carersAllowanceIsEntitled',
                        title: "Do you have an underlying entitlement to Carer’s Allowance?",
                        description: 'Answer Yes if you have claimed Carer’s Allowance but are not receiving the payment because you are receiving another benefit instead. This will be shown as “underlying entitlement” on your benefit award letter.'
                      },

                      'person.income.hasOtherBenefit',
                      {
                        key: 'person.income.otherBenefit',
                        condition: 'model.person.income.hasOtherBenefit'
                      },
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Your partner's income",
                    htmlClass: "col-md-6",
                    items: [
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.retirementPension'}),
                      {
                        type: 'section',
                        htmlClass: 'row',
                        condition: '!model.person.income.benefitsPaidAsCouple',
                        items: [
                          {
                            key: 'partner.income.incapacityBenefit',
                            description: "This includes Jobseeker's Allowance, Employment and Support Allowance, Incapacity Benefit, or Income Support.",
                            htmlClass: "col-md-8",
                            feedback: false
                          },
                          {
                            key: 'partner.income.incapacityBenefitPeriod',
                            condition: 'model.partner.income.incapacityBenefit',
                            htmlClass: "col-md-4"
                          }
                        ]
                      },
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.privatePension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.warPension'}),
                      {
                        type: 'section',
                        htmlClass: 'row',
                        condition: 'model.partner.income.warPension',
                        items: [
                          {
                            key: 'partner.income.warSpecialPayment',
                            htmlClass: "col-md-8",
                            feedback: false
                          },
                          {
                            key: 'partner.income.warSpecialPaymentPeriod',
                            condition: 'model.partner.income.warSpecialPayment',
                            htmlClass: "col-md-4"
                          }
                        ]
                      },
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.warDisablementPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.childBenefit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.earningsFromPaidEmployment'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.carersAllowance'}),
                      fieldDefinitionService.groups.paymentFormField({key:'partner.income.disabilityBenefits'}),
                      fieldDefinitionService.groups.paymentFormField({key:'partner.income.mobilityAllowance'}),

                      'partner.income.hasOtherBenefit',
                      {
                        key: 'partner.income.otherBenefit',
                        condition: 'model.partner.income.hasOtherBenefit'
                      },
                    ],
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances'
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your expenses',
                    htmlClass: "col-md-6",
                    items: [
                      fieldDefinitionService.groups.paymentFormField({key:'person.expenses.councilTax',
                                                                      description: 'Enter your yearly council tax charge minus any discounts, exemptions and reductions.',
                                                                      htmlClass: "col-md-8"
                                                                     }),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.expenses.compulsoryCourtPayments',
                                                                               description: "Enter the total amount of any court ordered payments made (for example, Maintenance or Child Support)."
                                                                              }),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.expenses.rent',
                                                                               description: "Enter the total amount of rent, as shown on your tenancy agreement or most recent rent letter, minus any Housing Benefit you receive."
                                                                              }),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.expenses.mortgage'})
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 3',
            page: 3,
            items: [
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/KR_CA/templates/costOfCareIntro.html'
              },
              {
                key: 'careAssessment.carephone.maxContribution',
                type: 'careAssessmentResult',
                formTypeCode: 'KR_CA',
                calculator: careAssessmentCalculator,
                careType: constants.careTypes.carephone,
                title: 'Maximum carephone package contribution'
              },
              {
                type: "fieldset",
                htmlClass: 'margin-top20 collapsible',
                notitle: true,
                collapse: true,
                items: [
                  'careAssessment.carephone.workings'
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/KR_CA/templates/costOfCareHelp.html'
              },
              {
                key: "careAssessment.careDecision",
                title: "Carephone package preference",
                type: "radios",
                titleMap: {
                  continue: "I want to continue with my application for a Carephone package",
                  cancel: "I do not want a Carephone package at this time"
                },
                description: 'Based on the calculations above please select one of these options'
              }
            ]
          },
          {
            type: "section",
            condition: "areFormNavigationButtonsVisible()",
            items: [
              {
                type: "actions",
                htmlClass: "formPaginationButtons",
                items: [
                  {
                    type: "button",
                    style: "btn-default",
                    title: "Previous",
                    onClick: "prev()"
                  },
                  {
                    type: "button",
                    style: "btn-primary",
                    title: "Next",
                    onClick: "next()"
                  }]
              }
            ]
          }
        ]);

        return formUI;

      }]);
