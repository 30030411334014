'use strict';

(function () {

  var mod = angular.module('kerp-forms.forms');

  mod.directive('kerpTime', ['$moment', function ($moment) {

    return {
      restrict: 'EA',
      require: 'ngModel',
      scope: {
        // scope.ngModel is bound to the ASF model value, e.g. "07:30"
        ngModel: '=',
        form: '='
      },
      link: function (scope, element, attrs, ngModel) {

        function parseTime(timeString) {
          // only the time part of the date is used, so it doesn't matter which day we choose
          var date = new Date();

          if (timeString) {
            var timeParts = timeString.split(':');

            date.setHours(timeParts[0]);
            date.setMinutes(timeParts[1]);
          }
          return date;
        }

        scope.date = parseTime(scope.ngModel);

        function isValidDate(date) {
          return !date ? !scope.form.required : angular.isDate(date);
        }

        // update the ASF model time when the timepicker is changed
        scope.$watch('date', function (newValue, oldValue) {

          var validDate = isValidDate(newValue);

          if (validDate) {

            if (newValue) {
              var timeString = $moment(newValue).format('HH:mm');
              ngModel.$setViewValue(timeString);
            } else {
              ngModel.$setViewValue(null);
            }
          }

          ngModel.$setValidity("kerpTime", validDate);
          ngModel.$setDirty();

        }, true);

        /*
         * When the model is updated, we copy and cast to Date the value into the variable date
         */
        scope.$watch('ngModel', function (newValue, oldValue) {

          if (newValue) {
            scope.date = parseTime(newValue);
          }
        });
      },

      template: '<div uib-timepicker ng-model="date" hour-step="1" minute-step="15"></div>'
    };
  }]);
}());
