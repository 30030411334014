'use strict';

angular.module('kerp-forms.forms').factory('LI_CA_CONDITIONS', ['FormConditions', function (FormConditions) {

  return FormConditions.clone({
    conditions: {

      'person.income.attendanceAllowance': function () {
        var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
        var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

        return !hasDlaCare && !hasPipDla;
      },

      'person.income.dlaCare': function () {
        var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
        var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

        return !hasAA && !hasPipDla;
      },

      'person.income.pipDailyLiving': function () {
        var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
        var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

        return !hasAA && !hasDlaCare;
      }

    }
  });
}]);
