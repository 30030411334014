'use strict';

angular.module('kerp-forms.forms')
  .service('LI_FCA_MAPPINGS', ['FormFieldMappings', 'addressFormatterService', 'objectUtils',
    function (FormFieldMappings, addressFormatterService, objectUtils) {

      this.mappings = function () {
        return FormFieldMappings
          .buildWithMappings({
            'person.details.title': 'title',
            'person.details.firstName': 'firstName',
            'person.details.lastName': 'lastName',
            'person.details.nationalInsuranceNumber': 'nationalInsuranceNumber',
            'person.details.phoneNumber': 'phoneNumber',
            'person.details.hasPartner': 'careAssessment.hasPartner',
            'person.details.dob': 'dateOfBirth',
            'person.details.address': 'address.formatted',

            'clientReference': 'careAssessment.clientReference',

            'person.details.declarePartnerFinances': 'careAssessment.declarePartnerFinances',
            'person.income.isEntitledToCarersPremium': 'careAssessment.isEntitledToCarersPremium',

            'person.income.sources.retirementPension': 'careAssessment.retirementPension.amount',
            'person.income.sources.retirementPensionPeriod': 'careAssessment.retirementPension.frequency',

            'person.income.sources.incapacityBenefit': 'careAssessment.incapacityBenefit.amount',
            'person.income.sources.incapacityBenefitPeriod': 'careAssessment.incapacityBenefit.frequency',

            'person.income.sources.warPension': 'careAssessment.warPension.amount',
            'person.income.sources.warPensionPeriod': 'careAssessment.warPension.frequency',

            'person.income.sources.warSpecialPayment': 'careAssessment.warSpecialPayment.amount',
            'person.income.sources.warSpecialPaymentPeriod': 'careAssessment.warSpecialPayment.frequency',

            'person.income.sources.childBenefit': 'careAssessment.childBenefit.amount',
            'person.income.sources.childBenefitPeriod': 'careAssessment.childBenefit.frequency',

            'person.income.sources.carersAllowance': 'careAssessment.carersAllowance.amount',
            'person.income.sources.carersAllowancePeriod': 'careAssessment.carersAllowance.frequency',

            'person.income.sources.pensionGuaranteeCredit': 'careAssessment.pensionGuaranteeCredit.amount',
            'person.income.sources.pensionGuaranteeCreditPeriod': 'careAssessment.pensionGuaranteeCredit.frequency',

            'person.income.sources.pensionSavingsCredit': 'careAssessment.pensionSavingsCredit.amount',
            'person.income.sources.pensionSavingsCreditPeriod': 'careAssessment.pensionSavingsCredit.frequency',

            'person.income.sources.attendanceAllowance': 'careAssessment.attendanceAllowance',
            'person.income.sources.mobilityAllowance': 'careAssessment.mobilityAllowance',

            'person.expenses.councilTax': 'careAssessment.councilTax',

            'person.expenses.compulsoryPayments': 'careAssessment.compulsoryPayments.amount',
            'person.expenses.compulsoryPaymentsPeriod': 'careAssessment.compulsoryPayments.frequency',

            'person.expenses.rent': 'careAssessment.rent.amount',
            'person.expenses.rentPeriod': 'careAssessment.rent.frequency',

            'person.expenses.mortgage': 'careAssessment.mortgage.amount',
            'person.expenses.mortgagePeriod': 'careAssessment.mortgage.frequency',

            'person.expenses.waterRates': 'careAssessment.waterRates.amount',
            'person.expenses.waterRatesPeriod': 'careAssessment.waterRates.frequency',

            'person.expenses.homeInsurance': 'careAssessment.homeContentsInsurance.amount',
            'person.expenses.homeInsurancePeriod': 'careAssessment.homeContentsInsurance.frequency',

            'person.expenses.groundRent': 'careAssessment.groundRent.amount',
            'person.expenses.groundRentPeriod': 'careAssessment.groundRent.frequency',

            'partner.income.sources.retirementPension': 'careAssessment.partner.retirementPension.amount',
            'partner.income.sources.retirementPensionPeriod': 'careAssessment.partner.retirementPension.frequency',

            'partner.income.sources.incapacityBenefit': 'careAssessment.partner.incapacityBenefit.amount',
            'partner.income.sources.incapacityBenefitPeriod': 'careAssessment.partner.incapacityBenefit.frequency',

            'partner.income.sources.warPension': 'careAssessment.partner.warPension.amount',
            'partner.income.sources.warPensionPeriod': 'careAssessment.partner.warPension.frequency',

            'partner.income.sources.warSpecialPayment': 'careAssessment.partner.warSpecialPayment.amount',
            'partner.income.sources.warSpecialPaymentPeriod': 'careAssessment.partner.warSpecialPayment.frequency',

            'partner.income.sources.childBenefit': 'careAssessment.partner.childBenefit.amount',
            'partner.income.sources.childBenefitPeriod': 'careAssessment.partner.childBenefit.frequency',

            'partner.income.sources.carersAllowance': 'careAssessment.partner.carersAllowance.amount',
            'partner.income.sources.carersAllowancePeriod': 'careAssessment.partner.carersAllowance.frequency',

            'partner.income.sources.attendanceAllowance': 'careAssessment.partner.attendanceAllowance',
            'partner.income.sources.mobilityAllowance': 'careAssessment.partner.mobilityAllowance',

            'partner.details.dob': 'careAssessment.partner.dateOfBirth'
          })
          .factToFieldConverters({
            'partner.details.dob': function (facts) {
              var dateOfBirth = objectUtils.getPropertyPath(facts, 'careAssessment.partner.dateOfBirth');

              if (dateOfBirth) {
                return this.ISOStringToDDMMYYYY(dateOfBirth);
              }
            },

            'person.details.dob': function (facts) {
              return this.ISOStringToDDMMYYYY(facts.dateOfBirth);
            },

            // We don't map the formatted (string) address fact to the address form fields because there's no reliable
            // way to convert a comma-delimited address string into an address object with separate properties for
            // line1, line2, postcode, etc.
            //
            // We also don't want to map the address (object) fact to the address form fields, because the UPRN is not
            // included in the former.
            'person.details.address': function (facts) {
              return null;
            }
          })
          .fieldToFactConverters({
            'careAssessment.partner.dateOfBirth': function (model) {
              var dateOfBirth = objectUtils.getPropertyPath(model, 'partner.details.dob');

              if (dateOfBirth) {
                return this.DDMMYYYYToISOString(dateOfBirth);
              }
            },
            dateOfBirth: function (model) {
              var dateOfBirth = objectUtils.getPropertyPath(model, 'person.details.dob');
              return this.DDMMYYYYToISOString(dateOfBirth);
            },
            'address.formatted': function (model) {
              var addressParts = objectUtils.getPropertyPath(model, 'person.details.address');
              if (!addressParts) {
                return null;
              }
              return addressFormatterService.formatAsString(
                [addressParts.line1, addressParts.line2, addressParts.line3, addressParts.postcode]);
            }
          });
      };
    }]);
