'use strict';

(function (angular) {

  var mod = angular.module('kerp-forms.forms');

  function downloadDeclarationButtonDirective() {

    return {
      restrict: 'EA',
      scope: {
        declarationPath: "@",
        btnClass: "@",
        btnTitle: "@",
      },
      templateUrl: 'modules/forms/scripts/directives/downloadDeclarationButton/downloadDeclarationButtonDirective.html'
    };
  }

  mod.directive('downloadDeclarationButton', downloadDeclarationButtonDirective);

}(angular));
