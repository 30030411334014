'use strict';

angular.module('kerp-forms.forms').factory('LI_FCA_CONDITIONS', ['FormConditions', function (FormConditions) {

  return FormConditions.clone({
    conditions: {
      'agent.applicantConfirmationDeclaration': function () {
        return this.lib.isAnyOf('agent.details.relationship', ['advocate', 'friend']);
      },
      'agent.agentDeclaration': function () {
        return this.lib.isAnyOf('agent.details.relationship',
          ['appointeeship', 'powerOfAttorney', 'lastingPowerOfAttorney', 'deputyship']);
      },
      'person.expenses.rent': function () {
        return !this.lib.isAnyOf('person.details.propertyTenancy', ['ownedByYou', 'ownedByPartner', 'ownedJointly']);
      },
      'person.expenses.mortgage': function () {
        return !this.lib.isAnyOf('person.details.propertyTenancy', ['councilTenant', 'rentedPrivately']);
      },
      'longTermResidents.property.stillOwnProperty': ['longTermResidents.property.haveOwnedProperty', function () {
        return this.lib.getModelValue('longTermResidents.property.haveOwnedProperty');
      }],

      'longTermResidents.property.isStillOccupied': ['longTermResidents.property.stillOwnProperty', function () {
        return this.lib.getModelValue('longTermResidents.property.stillOwnProperty');
      }],

      'longTermResidents.property.ownershipCeased.date': ['longTermResidents.property.stillOwnProperty', function () {
        return this.lib.getModelValue('longTermResidents.property.stillOwnProperty') === false;
      }],

      'longTermResidents.property.ownershipCeased.howDisposed': ['longTermResidents.property.stillOwnProperty', function () {
        return this.lib.getModelValue('longTermResidents.property.stillOwnProperty') === false;
      }],

      'longTermResidents.property.ownershipCeased.transferredTo': ['longTermResidents.property.ownershipCeased.howDisposed', function () {
        return this.lib.getModelValue('longTermResidents.property.ownershipCeased.howDisposed') === 'transferred';
      }],

      'longTermResidents.property.occupiers': ['longTermResidents.property.isStillOccupied', function () {
        return this.lib.getModelValue('longTermResidents.property.isStillOccupied');
      }],

      'longTermResidents.property.confirmPropertyNeedsSale': ['longTermResidents.property.stillOwnProperty', function () {
        return this.lib.getModelValue('longTermResidents.property.stillOwnProperty');
      }],

      'person.income.sources.attendanceAllowance': function () {
        var hasDlaCare = this.lib.isAnyOf('person.income.sources.dlaCare', ['high', 'medium', 'low']);
        var hasPipDla = this.lib.isAnyOf('person.income.sources.pipDailyLiving', ['high', 'low']);

        return !hasDlaCare && !hasPipDla;
      },

      'person.income.sources.dlaCare': function () {
        var hasAA = this.lib.isAnyOf('person.income.sources.attendanceAllowance', ['high', 'low']);
        var hasPipDla = this.lib.isAnyOf('person.income.sources.pipDailyLiving', ['high', 'low']);

        return !hasAA && !hasPipDla;
      },

      'person.income.sources.pipDailyLiving': function () {
        var hasAA = this.lib.isAnyOf('person.income.sources.attendanceAllowance', ['high', 'low']);
        var hasDlaCare = this.lib.isAnyOf('person.income.sources.dlaCare', ['high', 'medium', 'low']);

        return !hasAA && !hasDlaCare;
      }
    }
  });
}]);
