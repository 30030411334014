'use strict';

function DynamicEvidenceService(
  EnumTranslatorModel,
  $http,
  API,
  $q,
  $timeout,
  configuration,
  Upload,
  Forms,
  formModelService,
  $rootScope,
  htmlService,
  markdownCompiler,
  numberUtils,
  objectUtils,
  calculatorHelpers,
  FormErrorType,
  $templateCache,
  $exceptionHandler
) {

  function getEvidenceBaseUrl(formId) {
    return sprintf('/form/%s/evidence/', formId);
  }

  this.getEvidenceTypes = function (formId) {
    var formConfig = Forms.getForm(formId);

    if (formConfig.evidence && formConfig.evidence.types) {
      return formConfig.evidence.types.map(function (evidenceType) {
        return new EnumTranslatorModel('evidence_type.' + formId, evidenceType.id);
      });
    }
  };

  /**
   * @param formTypeCode {String}
   * @param requiredEvidence {Object}
   * @param contactDetails {Object}
   * @return {Promise<String>}
   */
  this.generateEvidenceListHtmlEmail = function (formTypeCode, requiredEvidence, contactDetails) {

    if (!angular.isString(formTypeCode)) {
      throw new Error('formTypeCode is required');
    }

    if (!angular.isObject(requiredEvidence)) {
      throw new Error('requiredEvidence is required');
    }

    if (!angular.isObject(contactDetails)) {
      throw new Error('contactDetails is required');
    }

    var fileName = 'evidence_email_body.html';
    var cacheBuster = {t: new Date().getTime()};

    var filePathParts = [
      configuration.app.home,
      '_content/customer',
      configuration.customer,
      'forms',
      formTypeCode,
      fileName
    ];

    var tmpScope = $rootScope.$new(true);

    return $http.get(filePathParts.join('/'), {params: cacheBuster})
      .catch(function () {
        // try removing form specific part and fall back to default one for a customer
        filePathParts.splice(3, 2); // remove 3rd and 4th elements
        return $http.get(filePathParts.join('/'), {params: cacheBuster});
      })
      .catch(function (err) {
        if (configuration.customer === 'default') {
          throw err;
        }
        // fall back to application default
        filePathParts[2] = 'default';
        return $http.get(filePathParts.join('/'), {params: cacheBuster});
      })
      .then(function (markdownOrHtmlContentResponse) {
        var tmpEl$ = $('<div></div>').text(markdownOrHtmlContentResponse.data);
        tmpScope.requiredEvidence = requiredEvidence;
        tmpScope.contactDetails = contactDetails;
        tmpScope.homeUrl = configuration.app.home;
        markdownCompiler.compile(tmpEl$, tmpScope);
        return tmpEl$;
      })
      .then(function (compiledHtmlElement) {
        return $timeout(function () {
          return compiledHtmlElement.html();
        });
      })
      .finally(function () {
        tmpScope.$destroy();
      });
  };

  /**
   * @param postPrams {Object}
   * @return {Promise<Array<String>>}
   */
  this.sendEvidence = function (postPrams) {
    return API.post('/form/sendEvidenceList', postPrams, {suppressFlashErrors: true})
      .then(function () {
        var successMsgs = [];

        if (postPrams.phoneNumber) {
          successMsgs.push(sprintf('A link to the evidence has been sent by text message to %s.', postPrams.phoneNumber));
        }
        if (postPrams.email) {
          successMsgs.push(sprintf('The evidence list has been sent by email to %s.', postPrams.email));
        }
        return successMsgs;
      });
  };

  /**
   * @param formId {String}
   * @param postParams {Object}
   * @param customAuthToken
   * @return {Promise<HttpResponse>}
   */
  this.uploadEvidenceFile = function (formId, postParams, customAuthToken) {
    var file = postParams.file;

    if (file) {
      var serverUrl = configuration.api.baseUrl + getEvidenceBaseUrl(formId) + 'upload';

      var fileUploadParams = {
        url: serverUrl,
        data: postParams,
        suppressFlashErrors: true
      };

      if (customAuthToken) {
        fileUploadParams.customToken = customAuthToken;
      }

      // upload the file with ng-file-upload http://jsfiddle.net/danialfarid/maqbzv15/1118/
      file.upload = Upload.upload(fileUploadParams);
      return file.upload;
    }

    return $q.reject({
      data: {
        errors: [{error: 'Nothing to upload'}]
      }
    });
  };

  /**
   * @param formConfigIdentifier {String}
   * @param formFields {Object}
   * @return {Promise<Object>}
   */
  this.getEvidenceFromJson = function (formConfigIdentifier, formFields) {
    // load the list of required evidence
    var formConfig = Forms.getForm(formConfigIdentifier);

    if (!formConfig.evidence) {
      return $q.when();
    }

    var evidenceSpec = configuration.app.home + '/_content/forms/' + formConfigIdentifier + '_evidence.json';

    return $http.get(evidenceSpec)
      .then(function (allEvidenceItems) {
        var canonicalFormModel = formModelService.getSubmittableFormModelFields(formConfig, formFields);

        return getDynamicEvidence(canonicalFormModel, allEvidenceItems.data || []);
      });
  };

  /**
   *
   * @param {Array|undefined} array array of objects that you need to filter and count
   * @param {string} property key of the object in the array you want to check the value of
   * @param value property value you are trying to count
   * @returns {number} the number of objects in the array that match the criteria
   */
  function getFilteredCount(array, property, value) {
    return (array || []).filter(function(item) {
      return item[property] === value;
    }).length;
  }

  /**
   * Compare 2 items to define they are the same
   * Allows for the definition of a match to be refined
   * @param lhs {string|number|null|undefined}
   * @param rhs {string|number|null|undefined}
   */
  function isMatch(lhs, rhs) {
    if (lhs === undefined && rhs === undefined) return true;
    if (lhs === null && rhs === null) return true;
    if (typeof lhs === 'string' && typeof rhs === 'string') {
      return lhs.localeCompare(rhs, undefined, { sensitivity: 'accent' }) === 0;
    }
    return lhs === rhs;
  }

  /**
   * Defines that value is not one of the values defined by notValues
   * @param value {string|number|undefined} a value from a set that represent numeric values
   * @param notValues {string|number|null|undefined|(string|number|null|undefined)[]} a set of values that equate to 0
   * @returns {boolean} If value is not in the notValues variable
   */
  function hasValueNotIn(value, notValues) {
    if (Array.isArray(notValues)) {
      return !notValues.some(v => isMatch(v, value));
    } else {
      return !isMatch(value, notValues);
    }
  }

  /**
   * @param model {Object}
   * @param allEvidenceItems {Array}
   *
   * @return {Object}
   */
  function getDynamicEvidence(model, allEvidenceItems) {

    var requiredEvidence = {};
    var templateScope = $rootScope.$new(true);
    var evidenceItemScope = $rootScope.$new(true);

    for (var i = 0; i < allEvidenceItems.length; i++) {

      var evidenceItem = allEvidenceItems[i];
      var isConditionalItem = evidenceItem.hasOwnProperty('requiredIf');

      if (!isConditionalItem || evidenceItemScope.$eval(evidenceItem.requiredIf, {
        model: model,
        getWeeklyAmount: calculatorHelpers.getWeeklyAmount.bind(calculatorHelpers),
        getWeeklyAmountOverFiveYears: calculatorHelpers.getWeeklyAmountOverFiveYears.bind(calculatorHelpers),
        sumNamedValues: calculatorHelpers.sumNamedValues.bind(calculatorHelpers),
        sumAllValues: calculatorHelpers.sumAllValues.bind(calculatorHelpers),
        getFilteredCount,
        hasValueNotIn,
        isMatch,
      })) {
        requiredEvidence[evidenceItem.label] = evidenceItem;

        if (evidenceItem.template) {

          const evidenceTemplateExists = $templateCache.get(evidenceItem.template);
          if (evidenceTemplateExists) {
            evidenceItem.text = htmlService.interpolateTemplate(evidenceItem.template, {scope: templateScope});
          } else {
            var err = new Error(FormErrorType.EVIDENCE_TEMPLATE_ERROR);
            $exceptionHandler(err, 'Evidence template error', undefined, {
              data: evidenceItem
            });
          }
        }
      }
    }
    return requiredEvidence;
  }

  this.getDynamicEvidence = getDynamicEvidence;

}

DynamicEvidenceService.$inject = [
  'EnumTranslatorModel',
  '$http',
  'API',
  '$q',
  '$timeout',
  'configuration',
  'Upload',
  'Forms',
  'formModelService',
  '$rootScope',
  'htmlService',
  'markdownCompiler',
  'numberUtils',
  'objectUtils',
  'calculatorHelpers',
  'FormErrorType',
  '$templateCache',
  '$exceptionHandler'
];

angular.module('kerp-forms.forms').service('dynamicEvidenceService', DynamicEvidenceService);
