'use strict';
angular.module('kerp-forms.forms').factory('KS_OPTIONAL_SCHEMA', ['fieldDefinitionService', '$translate',
  'kitchenSinkSchemaService',
  function (fieldDefinitionService, $translate, kitchenSinkSchemaService) {

    return {
      getSchema: function () {
        return kitchenSinkSchemaService.getSchema(false);
      }
    };
  }]);
