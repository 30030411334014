'use strict';

angular
  .module('kerp-forms.forms')
  .factory('ESA50_FORM', ['FormUI', function (FormUI) {
    var formUI = new FormUI();
    var form = [

      {
        type: 'section',
        condition: 'model.page === 1',
        page: 1,
        items: [
          {
            type: 'template',
            template: '<div> <h2>About you</h2><p>"<a href="https://www.youtube.com/watch?v=Fz7US5wocmQ" title="ESA50: Before you start">Watch this video before you start your ESA50 claim.</a>"</p></div>'
          },
          {
            key: '2_2',
            autocomplete: 'family-name',
            name: 'surname'
          },
          {
            key: '2_3',
            autocomplete: 'given-name',
            name: 'firstname'
          },
          "2_4",
          {
            key: '2_5',
            type: 'textarea',
            autocomplete: 'street-address',
            name: 'address'
          },
          {
            key: '2_6',
            type: 'memorabledate',
            title: 'Date of birth',
            validationMessage: 'The date of birth is incorrect',
            maxDate: new Date(),
            placeholder: '01/01/1985'

          },
          {
            key: '2_7',
            placeholder: 'AA999999A'

          },
          {
            key: '2_8',
            type: 'radios',
            titleMap: [{value: 'No', name: 'No'}, {
              value: 'Yes. Use the next box to tell us the date your baby is due',
              name: 'Yes'
            }],
            style: {selected: 'btn-primary', unselected: 'btn-default'}
          },
          {
            type: 'section',
            condition: 'model["2_8"] != null && model["2_8"] !== "No"',
            items: [{
              key: "2_9",
              title: 'When is your baby due?',
              mode: 'month',
              type: 'kerpdate',
              minDate: new Date()
            }]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 2',
        page: 2,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About your GP or doctor’s surgery</h2><br /></div>'
          },
          {
            key: '3_1',
            autocomplete: 'organization',
            name: 'organization'

          },
          {
            key: '3_2',
            type: 'textarea',
            autocomplete: 'street-address',
            name: 'address'
          },
          {
            "type": "section",
            "htmlClass": "row",
            "items": [
              {
                "type": "section",
                "htmlClass": "col-xs-12",
                "items": [
                  {
                    type: 'help',
                    helpvalue: '<div><label>Their phone number</label></div>'
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "col-xs-4 col-sm-2",
                "items": [
                  {
                    key: '3_3',
                    autocomplete: 'tel-area-code',
                    name: 'tel-area-code',
                    placeholder: '01484',
                    type: 'tel'

                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "col-xs-8 col-sm-6",
                "items": [
                  {
                    key: '3_4',
                    autocomplete: 'tel',
                    name: 'tel',
                    placeholder: '422222',
                    type: 'tel'

                  }
                ]
              }
            ]
          },

          {
            type: 'help',
            helpvalue: "<div><h2>About other professionals or carer's who know the most about your health conditions, illnesses or disabilities</h2></div>"
          },
          {
            key: '3_5',
            autocomplete: 'name',
            name: 'name'
          },

          "3_6",
          {
            key: '3_7',
            type: 'textarea',
            autocomplete: 'street-address',
            name: 'address'

          },
          {
            "type": "section",
            "htmlClass": "row",
            "items": [
              {
                "type": "section",
                "htmlClass": "col-xs-12",
                "items": [
                  {
                    type: 'help',
                    helpvalue: '<div><label>Their phone number</label></div>'
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "col-xs-4 col-sm-2",
                "items": [
                  {
                    key: '3_8',
                    autocomplete: 'tel-area-code',
                    name: 'tel-area-code',
                    placeholder: '01484',
                    type: 'tel'

                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "col-xs-8 col-sm-6",
                "items": [
                  {
                    key: '3_9',
                    autocomplete: 'tel',
                    name: 'tel',
                    placeholder: '422222',
                    type: 'tel'
                  }
                ]
              }
            ]
          },

          {
            type: 'help',
            helpvalue: '<div><h2>About medical or other information you may already have</h2></div>'
          },
          {
            type: 'help',
            htmlClass: "text-success",
            helpvalue: '<div><h3><i class="glyphicon glyphicon-ok-circle"></i>  Examples of things you should send</h3></div>'
          },
          {
            "type": "section",
            "htmlClass": "row text-success",
            "items": [
              {
                "type": "section",
                "htmlClass": "col-sm-6",
                "items": [
                  {
                    type: 'help',
                    helpvalue: "<div><p class='lead'>Reports, care or treatment plans about you from:</p></div>"
                  },
                  {
                    type: 'help',
                    helpvalue: "<div><ul><li>GPs</li><li>hospital doctors</li><li>specialist nurses</li><li>community psychiatric nurses</li><li>occupational therapists</li><li>physiotherapists</li><li>social workers</li><li>support workers</li><li>learning disability support teams</li><li>counsellors or carers</li></ul></div>"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "col-sm-6",
                "items": [
                  {
                    type: 'help',
                    helpvalue: "<div><p class='lead'>Medical test results including:</p></div>"
                  },
                  {
                    type: 'help',
                    helpvalue: "<div><ul><li>scans</li><li>audiology</li><li>the results of x-rays, but not the x-rays themselves</li></ul></div>"
                  },
                  {
                    type: 'help',
                    helpvalue: "<div><p class='lead'>Things like</p></div>"
                  },
                  {
                    type: 'help',
                    helpvalue: "<div><ul><li>your current prescription list</li><li>your statement of special educational needs</li><li>epilepsy seizure diary</li><li>your certificate of visual impairment</li></ul></div>"
                  }
                ]
              }
            ]
          },

          {
            type: 'help',
            htmlClass: "text-danger",
            helpvalue: '<div><h3><i class="glyphicon glyphicon-remove-circle"></i>  Examples of things not to send</h3></div>'
          },
          {
            "type": "section",
            "htmlClass": "row text-danger",
            "items": [
              {
                "type": "section",
                "htmlClass": "col-sm-6",
                "items": [
                  {
                    type: 'help',
                    helpvalue: "<div><ul><li>Appointment letters.</li><li>General information about your medical conditions that are not about you personally.</li><li>Photographs.</li><li>Letters about other benefits.</li></ul></div>"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "col-sm-6",
                "items": [
                  {
                    type: 'help',
                    helpvalue: "<div><ul><li>Fact sheets about your medication.</li><li>Internet printouts.</li><li>Medical statements, otherwise known as fit notes, medical certificates, doctor’s statements or sick notes.</li></ul></div>"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 3',
        page: 3,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About your health conditions, illnesses or disabilities</h2></div>'
          },
          {
            type: 'template',
            template: '<div><p class="lead">We will ask you specific questions about how your health conditions, illnesses or disabilities affect your ability to do things on a daily basis in the rest of this form.</p><p><a href="https://www.youtube.com/watch?v=HeNS-Nkey0o" title="ESA50: Help filling in the ESA50 form">Watch this video for guidance on filling in your ESA50 form.</a></p></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><h3>Cancer treatment</h3></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><p class="lead">If your <b>only</b> health problem is the way your cancer treatment is affecting you,<br>you may not have to answer all the questions on this form.</br></p></div>'
          },
          {
            key: '4_1',
            type: 'radios',
            titleMap: [{
              value: 'No. Go to Your other health conditions, illnesses and disabilities, on the next page',
              name: 'No'
            }, {value: 'Yes. Answer the next question', name: 'Yes'}],
            style: {selected: 'btn-primary', unselected: 'btn-default'}
          },
          {
            type: 'section',
            condition: 'model["4_1"].indexOf("Yes.") > -1',
            items: [
              {
                key: "4_2",
                type: 'radios',
                titleMap: [
                  {
                    value: 'No. Go to Your other health conditions, illnesses and disabilities, on the next page',
                    name: 'No'
                  },
                  {
                    value: "Yes. You don't have to fill in the rest of this form if you don't want to. Sign page 18 and make sure page 20 is filled in by a healthcare professional. This may include a GP, hospital doctor or clinical nurse who is aware of your condition",
                    name: 'Yes'
                  }
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                type: 'help',
                helpvalue: '<div><p class="alert alert-warning">You will need to print this form and get a healthcare professional to fill in page 20. This may include a GP, hospital doctor or clinical nurse who is aware of your condition.</p></div>'
              }
            ]
          },

          {
            type: 'section',
            condition: 'model["4_2"].indexOf("Yes.") > -1',
            items: [
              {
                type: 'help',
                helpvalue: "<div><p class='lead'>You don't have to fill in the rest of this form if you don't want to. Sign page 18 and make sure that page 20 is filled in by a healthcare professional. This may include a GP, hospital doctor or clinical nurse who is aware of your condition.</p></div>"
              },
              {
                type: 'help',
                helpvalue: "<div><p class='lead'>If you have other health problems, as well as cancer and the problems resulting from your cancer treatment, please fill in the rest of the form.</p></div>"
              },
              {
                key: 'wantToFillAll',
                type: "radios",
                titleMap: [
                  {"name": "Yes", "value": true},
                  {"name": "No", "value": false}
                ]
              }
            ]
          },


          {
            condition: '!(model["wantToFillAll"] === false && model["4_1"].indexOf("Yes.") > -1 && model["4_2"].indexOf("Yes.") > -1)',
            type: 'help',
            helpvalue: '<div><h3>Your other health conditions, illnesses and disabilities</h3></div>'
          },
          {
            condition: '!(model["wantToFillAll"] === false && model["4_1"].indexOf("Yes.") > -1 && model["4_2"].indexOf("Yes.") > -1)',
            key: '5_1',
            type: 'textarea'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 4',
        page: 4,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>About your medication</h2></div>'
          },
          {
            key: '6_1',
            type: 'textarea'
          },
          {
            type: 'help',
            helpvalue: '<div><h2>About other treatment</h2></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><p class="lead">Hospital, clinic or special treatment like dialysis or rehabilitation treatment</p></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><p class="lead">Use this section to tell us about any hospital, clinic or other special treatment, like dialysis or rehabilitation treatment, you are having or expect to have in the near future.</p></div>'
          },
          {
            key: '6_2',
            type: 'textarea'
          },
          {
            key: '6_3',
            type: 'radios',
            titleMap: [
              {name: 'No', value: 'No. Go to Part 1 on the next page'},
              {name: 'Yes', value: 'Yes'}
            ],
            style: {selected: 'btn-primary', unselected: 'btn-default'}
          },
          {
            type: 'section',
            condition: 'model["6_3"] != null && model["6_3"] === "Yes"',
            items: [{
              key: "6_4",
              type: 'radios',
              titleMap: [
                {name: 'No', value: 'Go to Part 1 on the next page'},
                {
                  name: 'Yes',
                  value: 'Yes. Use the next box to tell us the name of the organisation running your scheme, when your treatment began, or is due to begin, and when you expect it to end'
                }
              ],
              style: {selected: 'btn-primary', unselected: 'btn-default'}
            }]
          },
          {
            type: 'section',
            condition: 'model["6_4"] != null && model["6_4"].indexOf("Yes.") > -1',
            items: [{
              key: '6_5',
              type: 'textarea'
            }]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 5',
        page: 5,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Part 1: Physical capabilities</h2></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><p class="alert alert-warning">To answer Yes to any of the following questions, you must be able to do the activity safely, to an acceptable standard, as often as you need to and in a reasonable length of time.</p></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><h3>1. Moving around and using steps</h3></div>'
          },
          {
            type: 'help',
            helpvalue: "<div><p class='lead'>By moving we mean including the use of aids you usually use such as a manual wheelchair, crutches or a walking stick but without the help of another person.</p></div>"
          },
          {
            key: '7_1',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["7_1"] != null && model["7_1"] === false',
            items: [
              {
                key: '7_2',
                type: 'radios',
                titleMap: [
                  {
                    name: '50 metres. This is about the length of 5 double decker buses or twice the length of an average public swimming pool',
                    value: '50 meters. This is about the length of 5 double decker buses or twice the length of an average public swimming pool'
                  },
                  {
                    name: '100 metres. This is about the length of a football pitch',
                    value: '100 meters. This is about the length of a football pitch'
                  },
                  {name: '200 metres or more', value: '200 meters or more'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '7_3',
                type: 'textarea'
              },
              {
                type: 'help',
                helpvalue: '<div><h4>Going up or down two steps</h4></div>'
              },
              {
                key: '7_4',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes. Now go to question 2 on the next page'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '7_5',
                type: 'textarea'
              }
            ]
          },

          {
            type: 'help',
            helpvalue: '<div><h3>2. Standing and sitting</h3></div>'
          },
          {
            key: '8_1',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["8_1"] != null && model["8_1"] === false',
            items: [
              {
                key: '8_2',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '8_3',
                type: 'radios',
                titleMap: [
                  {name: 'Less than 30 minutes', value: 'Less than 30 minutes'},
                  {name: '30 minutes to one hour', value: '30 minutes to one hour'},
                  {name: 'More than one hour', value: 'More than one hour'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '8_4',
                type: 'textarea'
              }
            ]
          },
          {
            type: 'help',
            helpvalue: '<div><h3>3. Reaching</h3></div>'
          },
          {
            key: '8_5',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["8_5"] != null && model["8_5"] === false',
            items: [
              {
                key: '8_6',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '8_7',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '8_8',
                type: 'textarea'
              }
            ]
          },

          {
            type: 'help',
            helpvalue: '<div><h3>4. Picking up and moving things</h3></div>'
          },
          {
            key: '9_1',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["9_1"] != null && model["9_1"] === false',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h4>Picking up things using your upper body and either arm</h4></div>'
              },
              {
                key: '9_2',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '9_3',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '9_4',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '9_5',
                type: 'textarea'
              }
            ]
          },
          {
            type: 'help',
            helpvalue: '<div><h3>5. Manual dexterity (using your hands)</h3></div>'
          },
          {
            key: '9_6',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["9_6"] != null && model["9_6"] === false',
            items: [
              {
                key: '9_7',
                type: 'radios',
                titleMap: [
                  {name: 'Some of these things', value: 'Some of these things'},
                  {name: 'None of these things', value: 'None of these things'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '9_8',
                type: 'textarea'
              }
            ]
          }

        ]
      },
      {
        type: 'section',
        condition: 'model.page === 6',
        page: 6,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Part 1: Physical capabilities <small>Continued</small></h2></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><h3>6. Communicating – speaking, writing and typing</h3></div>'
          },
          {
            type: 'help',
            helpvalue: "<div><p class='lead'>By communicating, we don’t mean communicating in another language.</p></div>"
          },
          {
            type: 'help',
            helpvalue: "<div><p class='lead'>This section asks about how you can communicate with other people.</p></div>"
          },
          {
            key: '10_1',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["10_1"] != null && model["10_1"] === false',
            items: [
              {
                key: '10_2',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '10_3',
                type: 'textarea'
              }
            ]
          },
          {
            type: 'help',
            helpvalue: '<div><h3>7. Communicating – hearing and reading</h3></div>'
          },
          {
            type: 'help',
            helpvalue: "<div><p class='lead'>This section asks about how you can understand other people.</p></div>"
          },
          {
            key: '10_4',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["10_4"] != null && model["10_4"] === false',
            items: [
              {
                key: '10_5',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '10_6',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '10_7',
                type: 'textarea'
              }
            ]
          },

          {
            type: 'help',
            helpvalue: '<div><h3>8. Getting around safely</h3></div>'
          },
          {
            type: 'help',
            helpvalue: "<div><p class='lead'>This section asks about problems with your vision. If you normally use glasses or contact lenses, a guide dog or any other aid, tell us how you manage when you are using them. Please also tell us how well you see in daylight or bright electric light.</p></div>"
          },
          {
            key: '11_1',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["11_1"] != null && model["11_1"] === false',
            items: [
              {
                key: '11_2',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '11_3',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '11_4',
                type: 'textarea'
              }
            ]
          },
          {
            type: 'help',
            helpvalue: '<div><h3>9. Controlling your bowels and bladder and using a collecting device</h3></div>'
          },
          {
            key: '11_5',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["11_5"] != null && model["11_5"] === false',
            items: [
              {
                key: '11_6',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes, weekly', value: 'Yes, weekly'},
                  {name: 'Yes, monthly', value: 'Yes, monthly'},
                  {name: 'Yes', value: 'Yes'},
                  {
                    name: 'Yes, but only if I cannot reach a toilet quickly',
                    value: 'Yes, but only if I cannot reach a toilet quickly'
                  }
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '11_7',
                type: 'textarea'
              }
            ]
          },

          {
            type: 'help',
            helpvalue: '<div><h3>10. Staying conscious when awake</h3></div>'
          },
          {
            type: 'help',
            helpvalue: "<div><p class='lead'>By problems staying conscious we do not mean falling asleep just because you are tired.</p></div>"
          },
          {
            key: '12_1',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["12_1"] != null && model["12_1"] === false',
            items: [
              {
                key: '12_2',
                type: 'radios',
                titleMap: [
                  {name: 'Daily', value: 'Daily'},
                  {name: 'Weekly', value: 'Weekly'},
                  {name: 'Monthly', value: 'Monthly'},
                  {name: 'Less than monthly', value: 'Less than monthly'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '12_3',
                type: 'textarea'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 7',
        page: 7,
        items: [
          {
            type: 'help',
            helpvalue: '<h2>Part 2: Mental, cognitive and intellectual capabilities</h2>'
          },

          {
            type: 'help',
            helpvalue: "<p class='lead'>In this part we ask how your mental health, cognitive or intellectual problems affect how you can do things on a daily basis. By this we mean problems you may have from mental illnesses like schizophrenia, depression and anxiety, or conditions like autism, learning difficulties, the effects of head injuries and brain or neurological conditions. </p>"
          },
          {
            type: 'help',
            helpvalue: "<div class='lead alert alert-warning'>To answer Yes to any of the following questions, you must be able to do the activity safely, to an acceptable standard, as often as you need to and in a reasonable length of time.</div>"
          },
          {
            type: 'help',
            helpvalue: "<p class='lead'>If you have difficulties completing this section, please refer to the guidance on page 1. You can ask a friend, a relative or a representative to help you. Or get in touch with Jobcentre Plus on 0345 608 8545. </p>"
          },
          {
            type: 'help',
            helpvalue: "<p class='lead'>If you would like any additional information to be considered, for example from your doctor, Community Psychiatric Nurse, occupational therapist, counsellor, psychotherapist, cognitive therapist, social worker, support worker or carer please send it with this form. This includes information that tells us how your health condition, illness or disability affects your ability to do things on a daily basis and information about how this affects you when you are most unwell.</p>"
          },

          {
            type: 'help',
            helpvalue: '<h3>11. Learning how to do tasks</h3>'
          },
          {
            key: '12_4',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["12_4"] != null && model["12_4"] === false',
            items: [
              {
                key: '12_5',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '12_6',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '13_1',
                type: 'textarea'
              }
            ]
          },


          {
            type: 'help',
            helpvalue: '<div><h3>12. Awareness of hazards or danger</h3></div>'
          },
          {
            key: '13_2',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["13_2"] != null && model["13_2"] === false',
            items: [
              {
                key: '13_3',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '13_4',
                type: 'textarea'
              }
            ]
          },

          {
            type: 'help',
            helpvalue: '<div><h3>13. Starting and finishing tasks</h3></div>'
          },
          {
            type: 'help',
            helpvalue: "<div><p class='lead'>This section asks about whether you can manage to start and complete daily routines and tasks like getting up, washing and dressing, cooking a meal or going shopping.</p></div>"
          },
          {
            key: '13_5',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["13_5"] != null && model["13_5"] === false',
            items: [
              {
                key: '13_6',
                type: 'radios',
                titleMap: [
                  {name: 'Never', value: 'Never'},
                  {name: 'Sometimes', value: 'Sometimes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '13_7',
                type: 'textarea'
              }
            ]
          },

          {
            type: 'help',
            helpvalue: '<div><h3>14. Coping with changes</h3></div>'
          },
          {
            key: '14_1',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["14_1"] != null && model["14_1"] === false',
            items: [
              {
                key: '14_2',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '14_3',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '14_4',
                type: 'textarea'
              }
            ]
          },

          {
            type: 'help',
            helpvalue: '<div><h3>15. Going out</h3></div>'
          },
          {
            type: "help",
            helpvalue: "<div><p class='lead'>This question is about your ability to cope mentally or emotionally with going out. If you have physical problems which mean you can’t go out, you should tell us about this in Part 1 of this form.</p></div>"
          },
          {
            key: '14_5',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["14_5"] != null && model["14_5"] === false',
            items: [
              {
                key: '14_6',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes, if someone goes with me', value: 'Yes, if someone goes with me'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '14_7',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes, if someone goes with me', value: 'Yes, if someone goes with me'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '14_8',
                type: 'textarea'
              }
            ]
          },

          {
            type: 'help',
            helpvalue: '<div><h3>16. Coping with social situations</h3></div>'
          },
          {
            type: "help",
            helpvalue: "<div><p class='lead'>By social situations we mean things like meeting new people and going to meetings or appointments.</p></div>"
          },
          {
            key: '15_1',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["15_1"] != null && model["15_1"] === false',
            items: [
              {
                key: '15_2',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '15_3',
                type: 'radios',
                titleMap: [
                  {name: 'No', value: 'No'},
                  {name: 'Yes', value: 'Yes'},
                  {name: 'It varies', value: 'It varies'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '15_4',
                type: 'textarea'
              }
            ]
          },

          {
            type: 'help',
            helpvalue: '<div><h3>17. Behaving appropriately</h3></div>'
          },
          {
            type: "help",
            helpvalue: "<div><p class='lead'>This section asks about whether your behaviour upsets other people.</p></div>"
          },
          {
            type: "help",
            helpvalue: "<div><p class='lead'>By this we do not mean minor arguments between couples.</p></div>"
          },
          {
            key: '15_5',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            type: 'section',
            condition: 'model["15_5"] != null && model["15_5"] === false',
            items: [
              {
                key: '15_6',
                type: 'radios',
                titleMap: [
                  {name: 'Every day', value: 'Every day'},
                  {name: 'Frequently', value: 'Frequently'},
                  {name: 'Occasionally', value: 'Occasionally'}
                ],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '15_7',
                type: 'textarea'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 8',
        page: 8,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Part 3: Eating and drinking</h2></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><h3>18. Eating and drinking</h3></div>'
          },
          {
            key: '16_1',
            type: 'radios',
            titleMap: [
              {name: 'No', value: 'No'},
              {name: 'Yes', value: 'Yes'},
              {name: 'It varies', value: 'It varies'}
            ],
            style: {selected: 'btn-primary', unselected: 'btn-default'}
          },
          {
            key: '16_2',
            type: 'radios',
            titleMap: [
              {name: 'No', value: 'No'},
              {name: 'Yes', value: 'Yes'},
              {name: 'It varies', value: 'It varies'}
            ],
            style: {selected: 'btn-primary', unselected: 'btn-default'}
          },
          {
            key: '16_3',
            type: 'textarea'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 9',
        page: 9,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>Face-to-face assessment</h2></div>'
          },

          {
            type: 'template',
            template: '<div><p class="lead">You may be asked to attend a face-to-face assessment with a qualified Healthcare Professional who works for our Assessment Provider. If we need you to do this, the Health Assessment Advisory Service will telephone you between 9.00am and 8.30pm on Monday to Friday, or 9.00am and 5.00pm on Saturday to arrange a suitable date and time for your appointment. They will send you a letter with details of the appointment and a leaflet that explains what happens at an assessment and who you can take with you. Please give us at least one telephone number below that they can call you on.</p><p><a href="https://www.youtube.com/watch?v=c6NIe_FoEig" title="ESA50: Face to face interview">Watch this video to learn more about the face to face interview.</a></p><p> <a href="https://www.youtube.com/watch?v=thONFUXc8zU" title="ESA50: What to expect at an assessment">Watch this video to find out what to expect at the assessment.</a></p></div>'
          },

          {
            type: 'help',
            helpvalue: '<div><p class="lead">If you want more information about the face-to-face assessment, visit www.gov.uk/employment-support-allowance</p></div>'
          },

          {
            "type": "section",
            "htmlClass": "row",
            "items": [
              {
                "type": "section",
                "htmlClass": "col-xs-12",
                "items": [
                  {
                    type: 'help',
                    helpvalue: '<div><label>Daytime phone number</label></div>'
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "col-xs-4 col-sm-2",
                "items": [
                  {
                    "key": "16_4",
                    "title": "Code"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "col-xs-8 col-sm-6",
                "items": [
                  {
                    "key": "16_5",
                    "title": "Number"
                  }
                ]
              }
            ]
          },
          {
            "key": "16_6",
            "title": 'Mobile phone number'
          },
          {
            "type": "section",
            "htmlClass": "row",
            "items": [
              {
                "type": "section",
                "htmlClass": "col-xs-12",
                "items": [
                  {
                    type: 'help',
                    helpvalue: '<div><label>Any other number including Textphone number</label></div>'
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "col-xs-4 col-sm-2",
                "items": [
                  {
                    "key": "16_7",
                    "title": "Code"
                  }
                ]
              },
              {
                "type": "section",
                "htmlClass": "col-xs-8 col-sm-6",
                "items": [
                  {
                    "key": "16_8",
                    "title": "Number"
                  }
                ]
              }
            ]
          },

          {
            key: '16_9',
            type: 'radios',
            titleMap: [
              {name: 'No', value: 'No'},
              {
                name: 'Yes. Use the next box to tell us what language you want to use',
                value: 'Yes. Use the next box to tell us what language you want to use'
              }
            ],
            style: {selected: 'btn-primary', unselected: 'btn-default'}
          },

          {
            type: 'section',
            condition: 'model["16_9"] != null && model["16_9"].indexOf("Yes.") > -1',
            items: [
              {
                key: '16_10',
                type: 'textarea'
              }
            ]
          },

          {
            key: '16_11',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },

          {
            key: '16_12',
            type: 'radios',
            titleMap: [
              {name: 'No', value: 'No'},
              {name: 'Yes', value: 'Yes'}
            ],
            style: {selected: 'btn-primary', unselected: 'btn-default'}
          },

          {
            key: '16_13',
            type: 'radios',
            titleMap: [
              {name: 'No', value: 'No'},
              {name: 'Yes', value: 'Yes'}
            ],
            style: {selected: 'btn-primary', unselected: 'btn-default'}
          },

          {
            key: '17_1',
            type: 'textarea'
          },
          {
            key: '17_2',
            type: 'textarea'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 10',
        page: 10,
        items: [
          {
            type: 'template',
            template: '<div><h2>Other information</h2><p><a href="https://www.youtube.com/watch?v=m53WpfgluIo" title="ESA50: Page 10 Extra information">Watch this video to find out what to add in the Extra information section.</a></p></div>'
          },
          {
            key: '17_3',
            type: 'textarea'
          }
        ]
      },

      // If returning late
      // Return date
      // For people filling in this form for someone else
      // What to do next

      {
        type: 'section',
        condition: 'model.page === 11',
        page: 11,
        items: [
          {
            key: '2_1',
            type: 'textarea'
          },
          {
            key: '18_1',
            title: 'Date',
            type: 'memorabledate',
            validationMessage: 'The date is incorrect',
            maxDate: new Date()
          },
          {
            key: 'fillingInForSomeoneElse',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },

          {
            type: 'section',
            condition: 'model["fillingInForSomeoneElse"] != null && model["fillingInForSomeoneElse"] === true',
            items: [
              {
                type: 'template',
                template: '<div><h3>For people filling in this form for someone else</h3></div>'
              },
              {
                type: 'help',
                helpvalue: "<div><p class='lead'>If you are filling in this form on behalf of someone else, please tell us some details about yourself.</p></div>"
              },
              {
                key: "18_2",
                type: 'textarea',
                autocomplete: 'name',
                name: 'name'
              },
              {
                key: '18_3',
                type: 'textarea',
                autocomplete: 'street-address',
                name: 'address'
              },
              {
                "type": "section",
                "htmlClass": "row",
                "items": [
                  {
                    "type": "section",
                    "htmlClass": "col-xs-12",
                    "items": [
                      {
                        type: 'help',
                        helpvalue: '<div><label>Daytime phone number</label></div>'
                      }
                    ]
                  },
                  {
                    "type": "section",
                    "htmlClass": "col-xs-4 col-sm-2",
                    "items": [
                      {
                        "key": "18_4",
                        "title": "Code"
                      }
                    ]
                  },
                  {
                    "type": "section",
                    "htmlClass": "col-xs-8 col-sm-6",
                    "items": [
                      {
                        "key": "18_5",
                        "title": "Number"
                      }
                    ]
                  }
                ]
              },
              {
                key: '18_6',
                type: 'textarea'
              }
            ]
          },
          {
            key: '19_1',
            type: "radios",
            titleMap: [
              {"name": "Yes", "value": true},
              {"name": "No", "value": false}
            ]
          },
          {
            key: '19_2',
            type: 'textarea'
          }
        ]
      },
      {
        type: 'section',
        condition: 'areFormNavigationButtonsVisible()',
        htmlClass: '',
        items: [{
          type: 'actions',
          htmlClass: 'formPaginationButtons',
          items: [
            {type: 'button', style: 'btn-default', title: 'Previous', onClick: 'prev()'},
            {type: 'button', style: 'btn-primary', title: 'Next', onClick: 'next()'}
          ]
        }]
      }
    ];
    formUI.setForm(form);

    formUI.getHiddenPages = function (model) {
      //If the user is undergoing cancer treatment they can skip to the end of the form after page 3
      return model["wantToFillAll"] === false && model["4_1"].indexOf("Yes.") > -1 && model["4_2"].indexOf("Yes.") > -1 ?
        [4, 5, 6, 7, 8, 9, 10, 11] : [];
    };

    return formUI;
  }
  ]);
