'use strict';

angular.module('kerp-forms.forms').constant('FormErrorType', {
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  CALCULATION_ERROR: 'CALCULATION_ERROR',
  FORM_SCHEMA_MISMATCH_ERROR: 'FORM_SCHEMA_MISMATCH_ERROR',
  OFFLINE_CALCULATION_ERROR: 'OFFLINE_CALCULATION_ERROR',
  FORM_SUBMISSION_ERROR: 'FORM_SUBMISSION_ERROR',
  FORM_MIGRATION_ERROR: 'FORM_MIGRATION_ERROR',
  EVIDENCE_TEMPLATE_ERROR: 'EVIDENCE_TEMPLATE_ERROR',
  EVIDENCE_GENERATION_ERROR: 'EVIDENCE_GENERATION_ERROR'
});
