'use strict';
(function () {

  var mod = angular.module('kerp-forms.forms');

  /**
   * A custom field of which the validation can be based on values of fields external to this field
   *
   * Usage example:
   * {
   *   key: "applicant_daytel",
   *   type: "validationExternal",
   *   title: "Daytime telephone number",
   *   watchFields: ["applicant_daytel", "applicant_mob", "applicant_email"],
   *   hasErrorFn: function (value, model) {
   *   if (!model.applicant_mob && !model.applicant_email && !value) {
   *         return 'One of "Daytime telephone number", "Mobile telephone number" and "Email address" is required';
   *      }
   *   }
   * }
   *
   * The watchFields attribute indicate the fields involved in the validation of this field
   *
   * hasErrorFn attribute indicates the function on logic of the involved fields in validation for this field
   * and it returns the error message if validation fails.
   *
   */
  mod.directive('validationExternal', function () {
    return {
      restrict: 'EA',
      require: 'ngModel',
      scope: {
        validationExternalErrorFn: "&",
        validationExternalFields: "=",
        validationExternalContext: "="
      },
      link: function (scope, element, attrs, ngModelController) {

        scope.$parent.vm = {};

        var VALIDATION_ID = 'customValidationExpression';

        function runValidation() {
          var error = null;
          var value = ngModelController.$modelValue || ngModelController.$viewValue;
          if (angular.isFunction(scope.validationExternalErrorFn())) {
            error = scope.validationExternalErrorFn().apply(scope, [value, scope.validationExternalContext]);
          }

          if (error) {
            ngModelController.$error[VALIDATION_ID] = error;
            scope.$parent.vm.customErrorMessage = error;
          } else {
            scope.$parent.vm.customErrorMessage = null;
          }

          return error;
        }

        function validate() {
          var error = runValidation();

          ngModelController.$setValidity(VALIDATION_ID, !error);

          return error;
        }

        var DEPENDENT_FIELD_WATCHER = null;
        scope.$watch('validationExternalFields', function (newValue, oldValue) {

          if (angular.isArray(newValue) && newValue.length) {

            ngModelController.$validators[VALIDATION_ID] = function (modelValue, viewValue) {
              var error = runValidation();
              return !error;
            };

            var modelWatchers = newValue.map(function (val) {
              return function () {
                return scope.validationExternalContext[val];
              };
            });

            DEPENDENT_FIELD_WATCHER = scope.$watchGroup(modelWatchers, function () {
              validate();
            }, true);

          }

        });

        scope.$on('schemaFormValidate', function () {
          validate();
        });

        scope.$on('destroy', function () {
          if (DEPENDENT_FIELD_WATCHER) {
            DEPENDENT_FIELD_WATCHER();
          }
        });

      }
    };
  });
}());
