'use strict';
angular.module('kerp-forms.forms').factory('KS_REQUIRED_SCHEMA', ['fieldDefinitionService', '$translate', 'kitchenSinkSchemaService',
  function (fieldDefinitionService, $translate, kitchenSinkSchemaService) {

    return {
      getSchema: function () {
        return kitchenSinkSchemaService.getSchema(true);
      }
    };
  }]);
