'use strict';

angular.module('kerp-forms.forms').directive('formHtmlContent',
  ['$http', 'configuration', function ($http, configuration) {

    return {
      restrict: 'EA',
      replace: true,
      transclude: false,
      scope: {
        fileName: '@',
        formId: '@',
        fallbackTranslateKey: '@'
      },
      link: function (scope, elem, attrs) {

        var filePathParts = [
          configuration.app.home,
          '_content/customer',
          configuration.customer,
          'forms',
          scope.formId,
          scope.fileName
        ];

        $http.get(filePathParts.join('/')).then(
          function (response) {
            scope.formContent = response.data;
          },
          function () {
            // customer specific content was not found, so try to get default content instead
            filePathParts[2] = 'default';

            $http.get(filePathParts.join('/')).then(
              function (response) {
                scope.formContent = response.data;
              }
            );
          }
        );
      },
      templateUrl: 'modules/forms/scripts/directives/formHtmlContent/formHtmlContent.html'
    };
  }]
);
