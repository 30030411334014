'use strict';

/**
 * Populates a hidden form field with a value from an external controller
 *
 */
angular
  .module('kerp-forms.forms')
  .directive(
    'effectiveDate', [
      '$rootScope',
      function ($rootScope) {

        return {
          restrict: 'EA',
          require: 'ngModel',
          scope: {
            form: "="
          },
          templateUrl: 'modules/forms/scripts/directives/effectiveDate/effectiveDateDirective.html',
          link: function (scope, element, attrs, ngModelController) {

            let effectiveDate;
            let description = scope.description = `Select a financial year for this assessment.`;

            scope.vm = { };

            const options = scope.form.options || [];
            scope.form.titleMap = options.map((option) => ({ value: option.start, name: option.label }));

            scope.$watch(function () {
              return ngModelController.$modelValue;
            }, function (newValue) {
              if (newValue !== effectiveDate) {
                scope.vm.selected = effectiveDate = newValue;
                $rootScope.$broadcast('effectiveDate-change', effectiveDate);
                updateDescription();
              }
            });

            scope.setSelected = function() {
              effectiveDate = scope.vm.selected;
              $rootScope.$broadcast('effectiveDate-change', effectiveDate);
              updateDescription();
            };

            function updateDescription() {
              if (effectiveDate !== undefined) {
                const item = options.find((item) => item.start === effectiveDate);
                const label = item.label;
                description = `The assessment will use rates from ${label} financial year`;
              }

              scope.description = description;
            }

          },
        };
      }
    ]);
