'use strict';

function FormOfflineMergeModalCtrl($uibModalInstance, $scope, currentOfflineFormInstance, remoteFormModelInstance) {

  $scope.mergeStrategy = {
    strategy: null
  };

  var MERGE_STRATEGY = {
    REMOTE_WINS: 'REMOTE_WINS',
    LOCAL_WINS: 'LOCAL_WINS'
  };

  $scope.radios = [
    {
      id: 1,
      value: MERGE_STRATEGY.REMOTE_WINS,
      name: 'Online wins - conflicting offline changes will be overwritten'
    },
    {
      id: 2,
      value: MERGE_STRATEGY.LOCAL_WINS,
      name: 'Offline wins - conflicting online changes will be overwritten'
    }
  ];

  $scope.mergeForm = function (form) {

    $scope.errorMessage = null;

    if (form.$valid && $scope.mergeStrategy.strategy) {

      $scope.inProgress = true;

      var mergedFields;

      switch ($scope.mergeStrategy.strategy) {
        case MERGE_STRATEGY.REMOTE_WINS:
          mergedFields = angular.merge({}, currentOfflineFormInstance.getDirtyFields(), remoteFormModelInstance.dirtyFields);
          break;
        case MERGE_STRATEGY.LOCAL_WINS:
          mergedFields = angular.merge({}, remoteFormModelInstance.dirtyFields, currentOfflineFormInstance.getDirtyFields());
          break;
        default:
          $scope.inProgress = false;
          throw new Error('Unrecognized MERGE_STRATEGY ' + $scope.mergeStrategy.strategy);
      }

      remoteFormModelInstance.dirtyFields = mergedFields;
      currentOfflineFormInstance.setStoredFormMetaData(remoteFormModelInstance);
      $uibModalInstance.close(currentOfflineFormInstance);
    }
  };
}

FormOfflineMergeModalCtrl.$inject = ['$uibModalInstance', '$scope', 'currentOfflineFormInstance', 'remoteFormModelInstance'];
angular.module('kerp-forms.forms').controller('FormOfflineMergeModalCtrl', FormOfflineMergeModalCtrl);
