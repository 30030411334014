'use strict';

angular
  .module('kerp-forms.forms')
  .constant('FORM_ERROR_CODES', {
    CALCULATION_ERROR: {
      title: 'Your contribution could not be calculated',
      message: 'There has been an error calculating your contribution. The details have been sent to our technical support team. Please select "Return to this later" to save your assessment, and try again tomorrow. If this problem persists, please contact the council.'
    },
    FORM_SCHEMA_MISMATCH_ERROR: {
      title: 'There was an error while processing this form.',
      message: 'Unexpected data was found while processing this form - details have been sent to our support team. You can continue to use the service.'
    },
    OFFLINE_CALCULATION_ERROR: {
      title: 'Your contribution could not be calculated',
      message: 'There has been an error calculating your contribution. Please try again when you are online.'
    }
  });

angular
  .module('kerp-forms.forms')
  .service('formErrorService', ['$rootScope', 'FORM_ERROR_CODES', function ($rootScope, FORM_ERROR_CODES) {

    this.setErrorState = function(val, errorCode) {
      var msg = FORM_ERROR_CODES[errorCode] || {
        title: 'An error has occurred',
        message: 'An error has occured, please contact the council'
      };

      $rootScope.$emit(`form-error-${val}`, { error: msg });
    };
  }]);
