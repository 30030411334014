'use strict';
angular.module('kerp-forms.forms').factory(
  'COV_FCA_CONDITIONS',
  ['FormConditions', function (FormConditions) {

  return FormConditions.clone({
    conditions: {
      'person.property.value': function () {
        return this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
      },

      'person.intro.excludeNonResidentialCaretypes': function() {
        var excludeNonResidentialCaretypes = this.lib.getModelValue('person.intro.excludeNonResidentialCaretypes');
        var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');

        // residential care types should be visible only if the user has chosen to complete DRE
        // OR they have chosen not to agree to provide their financial details
        return !excludeNonResidentialCaretypes || provideFinancialDetails !== 'agree';
      },

      'person.income.attendanceAllowance': function () {
        var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
        var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

        return !hasDlaCare && !hasPipDla;
      },

      'person.income.dlaCare': function () {
        var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
        var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

        return !hasAA && !hasPipDla;
      },

      'person.income.pipDailyLiving': function () {
        var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
        var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

        return !hasAA && !hasDlaCare;
      }
    }
  });
}]);
