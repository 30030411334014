'use strict';

/**
 * Sets the text content of an element to something like
 *
 * - applications and assessments
 * - applications, assessments, and appeals
 * - application or appeal
 *
 * Depending on which of these form types are defined in the Forms service.
 *
 * Example usage
 * =============
 * <h3 application-types join-word="and" pluralize="true" prefix="Go to"></h3>
 *
 * This will render:
 * <h3>Go to applications, appeals, and assessments</h3>
 */
angular.module('kerp-forms.forms').directive('applicationTypes', ['Forms', function (Forms) {

    var verbs = {
      application: 'apply',
      assessment: 'assess',
      appeal: 'appeal'
    };

    return {
      restrict: 'EA',
      scope: {
        /**
         * If truthy "apply, appeal, or assess" will be rendered instead of "application, appeal, or assessment".
         * The pluralize attribute is ignored if this is truthy
         */
        useVerbs: "@?",

        /**
         * The word used to join the last two types, typically "and" or "or". Defaults to "or".
         */
        joinWord: "@?",

        /**
         * Indicates whether an "s" should be appended to each form type. Defaults to false.
         */
        pluralize: "@?",

        prefix: "@?"
      },
      link: function (scope, element, attrs) {

        scope.joinWord = scope.joinWord || 'or';

        var allFormTypes = Forms.getForms().map(function (form) {

          if (scope.useVerbs) {
            var verb = verbs[form.type];
            if (!verb) {
              throw new Error("Missing verb for application type: " + form.type);
            }
            return verb;

          } else if (scope.pluralize) {
            return form.type + 's';

          } else {
            return form.type;
          }
        });

        allFormTypes = _.uniq(allFormTypes);

        var formTypesText = allFormTypes[0];

        if (allFormTypes.length > 1) {
          var lastType = allFormTypes.pop();
          allFormTypes.push(scope.joinWord + ' ' + lastType);

          var separator = allFormTypes.length === 2 ? ' ' : ', ';
          formTypesText = allFormTypes.join(separator);
        }

        if (scope.prefix) {
          formTypesText = scope.prefix + ' ' + formTypesText;
        }
        element.text(formTypesText);
      }
    };
  }]
);
