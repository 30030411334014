'use strict';

angular.module('kerp-forms.forms')
  .service('MRR_MAPPINGS', ['FormFieldMappings', function (FormFieldMappings) {
    this.mappings = function () {
      return FormFieldMappings.buildWithMappings({
        '1_1': 'title',
        '1_2': 'lastName',
        '1_3': 'firstName',
        '1_10': 'phoneNumber',
        '1_4': 'dateOfBirth',
        '1_5': 'nationalInsuranceNumber'

      }).factToFieldConverters({

        '1_4': function (facts) {
          return this.ISOStringToDDMMYYYY(facts.dateOfBirth);
        }
      }).fieldToFactConverters({
        dateOfBirth: function (model) {
          return this.toISOString(model['1_4']);
        }
      });
    };
  }]);
