'use strict';

angular.module('kerp-forms.forms').factory(
  'TO_FCA_CONDITIONS',
  [
    'FormConditions',
    'numberUtils',

    function (
      FormConditions,
      numberUtils
    ) {

      function hasContribution(careAssessment) {
        let hasContribution = false;
        if (careAssessment) {
          hasContribution = numberUtils.isNumeric(careAssessment.maxContribution);
        }
        return hasContribution;
      }

      function mandatoryDisregardsApply(ctx) {
        var protectedCohabitants = (ctx.lib.getModelValue('peopleLivingWithYou.otherPeople') || []).filter(person => person.relationshipType !== 'other');
        var partnerCohabits = ctx.lib.getModelValue('partner.details.sameAddress') === true;
        return protectedCohabitants.length || partnerCohabits;
      }

      return FormConditions.clone({
        conditions: {
          'person.property.value': function () {
            return this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
          },

          'person.intro.excludeNonResidentialCaretypes': function() {
            var excludeNonResidentialCaretypes = this.lib.getModelValue('person.intro.excludeNonResidentialCaretypes');
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');

            // residential care types should be visible only if the user has chosen to complete DRE
            // OR they have chosen not to agree to provide their financial details
            return !excludeNonResidentialCaretypes || provideFinancialDetails !== 'agree';
          },

          'person.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'person.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'person.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'partner.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'partner.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'partner.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'person.income.universalCredit': function () {
            var uc = this.lib.getModelValue('person.income.universalCredit');
            return uc > 0;
          },

          'declarations.applicantName': function () {
            var isCompletedByAgent = this.lib.getModelValue('agent.isCompletedByAgent');
            var hasPowerOfAttorney = this.lib.getModelValue('agent.hasPowerOfAttorney');
            var hasDeputy = this.lib.getModelValue('agent.hasDeputy');
            var hasAppointee = this.lib.getModelValue('agent.hasAppointee');
            return isCompletedByAgent  || hasPowerOfAttorney || hasDeputy || hasAppointee;
          },

          showBreakdown: function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            return provideFinancialDetails === 'agree';
          },

          'person.income.pipMobilityAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            return !hasDlaCare;
          },

          'person.income.dlaMobilityAllowance': function () {
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'medium', 'low']);

            return !hasPipDla;
          },

          'partner.income.pipMobilityAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            return !hasDlaCare;
          },

          'partner.income.dlaMobilityAllowance': function () {
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasPipDla;
          },

          showBreakdownNonResidential: function () {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.nonResidential'));
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails') === 'agree';
            return contribution && provideFinancialDetails;
          },

          showBreakdownRespite: function () {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.respite'));
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails') === 'agree';
            return contribution && provideFinancialDetails;
          },

          showBreakdownPermWeek1Residential: function () {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.permWeek1Residential'));
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails') === 'agree';
            return contribution && provideFinancialDetails;
          },

          showBreakdownPermWeek5Residential: function () {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.permWeek5Residential'));
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails') === 'agree';
            return contribution && provideFinancialDetails;
          },

          showBreakdownPermWeek13Residential: function () {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.permWeek13Residential'));
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails') === 'agree';
            return contribution && provideFinancialDetails;
          },

          'person.hasMobilityAllowance': function() {
            var hasPIPMobility = this.lib.isAnyOf('person.income.pipMobilityAllowance', ['high', 'low']);
            var hasDLAMobility = this.lib.isAnyOf('person.income.dlaMobilityAllowance', ['high', 'low']);
            return hasPIPMobility || hasDLAMobility;

          },

          'partner.hasMobilityAllowance': function () {
            var hasPIPMobility = this.lib.isAnyOf('partner.income.pipMobilityAllowance', ['high', 'low']);
            var hasDLAMobility = this.lib.isAnyOf('partner.income.dlaMobilityAllowance', ['high', 'low']);
            return hasPIPMobility || hasDLAMobility;

          }
        }
      });
    }]);
