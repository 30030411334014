'use strict';

angular
  .module('kerp-forms.forms')
  .factory('ESA50_SCHEMA', ['fieldDefinitionService', function (fieldDefinitionService) {

    var schema = {
      type: 'object',
      properties: {
        '2_2': {
          title: 'Surname',
          type: 'string',
          validationMessage: 'You must tell us your surname'
        },
        '2_3': {
          title: 'Other names',
          type: 'string',
          validationMessage: 'You must tell us your first and any middle names'
        },
        '2_4': {
          title: 'Title',
          type: 'string'
        },
        '2_5': {
          title: 'Address including postcode',
          type: 'string',
          validationMessage: 'You must tell us your address'
        },
        '2_6': fieldDefinitionService.time.date({
          title: 'Date of birth',
          validationMessage: 'You must tell us your date of birth'
        }),
        '2_7': fieldDefinitionService.NINO.fullNumber({
          title: 'National Insurance (NI) number',
          description: 'You can find your National Insurance number on your P60, Pay slips or any letters from HMRC.<br /> If you can’t find your National Insurance number then get in touch with the Job Centre.',
          validationMessage: 'Your National Insurance Number is two letters followed by six numbers and a letter (XX123456Y)'
        }),
        '2_8': {
          type: 'string',
          title: 'Are you pregnant?',
          'enum': [
            'No',
            'Yes. Use the next box to tell us the date your baby is due'
          ],
          validationMessage: 'You must select Yes or No'
        },
        '2_9': fieldDefinitionService.time.date({
          title: 'When is your baby due?',
          validationMessage: 'If you are pregnant, you must tell us the approximate date your baby is due'
        }),
        '3_1': {
          title: 'What is your GP’s name or the name of your doctor’s surgery?',
          description: 'If you don’t know your GP’s name, give the name of your doctor’s surgery.<br /> This may be used to contact your doctor about how your health conditions, illnesses or disabilities affect you on a daily basis.',
          type: 'string'
        },
        '3_2': {
          title: 'Their address including postcode',
          type: 'string'
        },
        '3_3': {
          title: 'Code',
          type: 'string',
          pattern: '^[0-9]{5}$',
          validationMessage: 'e.g.: 01484'
        },
        '3_4': {
          title: 'Number',
          type: 'string',
          pattern: '^[0-9]{6}$',
          validationMessage: 'e.g.: 422222'
        },
        '3_5': {
          title: 'Their name',
          description: '<p>Give the details of the healthcare professional who knows the <b>most</b> about your health conditions, illnesses or disabilities and about the impact they have on your ability to do things on a daily basis when you are most unwell. Sometimes the Department for Work and Pensions will need to contact them to ask for medical or other information that shows how your health condition, illness or disability affects your ability to do things on a daily basis. They do not always have to do this, so it is important that you send any medical or other information you already have with your claim.</p><p>For example:</p><ul><li>consultant or specialist doctor</li><li>psychiatrist</li><li>specialist nurse, such as Community Psychiatric Nurse</li><li>physiotherapist</li><li>occupational therapist</li><li>social worker</li><li>support worker or personal assistant</li><li>carer</li></ul>',
          type: 'string'
        },
        '3_6': {
          title: 'Job title',
          type: 'string'
        },
        '3_7': {
          title: 'Their address including postcode',
          type: 'string'
        },
        '3_8': {
          title: 'Code',
          type: 'string',
          pattern: '^[0-9]{5}$',
          validationMessage: 'e.g.: 01484'
        },
        '3_9': {
          title: 'Number',
          type: 'string',
          pattern: '^[0-9]{6}$',
          validationMessage: 'e.g.: 422222'
        },
        '4_1': {
          type: 'string',
          title: 'Do you have cancer?',
          'enum': [
            'No. Go to Your other health conditions, illnesses and disabilities, on the next page',
            'Yes. Answer the next question'
          ],
          validationMessage: 'Choice is required'
        },
        '4_2': {
          type: 'string',
          title: 'Are you having, waiting for or recovering from chemotherapy or radiotherapy treatment for cancer?',
          'enum': [
            'No. Go to Your other health conditions, illnesses and disabilities, on the next page',
            'Yes. You don\'t have to fill in the rest of this form if you don\'t want to. Sign page 18 and make sure page 20 is filled in by a healthcare professional. This may include a GP, hospital doctor or clinical nurse who is aware of your condition'
          ]
        },
        wantToFillAll: {
          type: 'boolean',
          title: 'Do you want to continue filling in the form?'
        },
        '5_1': {
          title: 'Tell us more',
          description: '<p>Give details on:</p><ul><li>what your health conditions, illnesses or disabilities are</li><li>how they affect you, and</li><li>when they started</li><li>if you think any of your conditions are linked to drugs or alcohol.</li></ul><p>If your conditions vary over time, describe how.</p><p>Also give details about:</p><ul><li>any aids you use, such as a wheelchair or hearing aid</li><li>anything else you think we should know about your health conditions, illnesses or disabilities.</li></ul>',
          type: 'string'
        },
        '6_1': {
          title: 'Please tell us about any tablets or other medication you are taking and any side effects you have',
          description: 'Give details about any tablets or other medication you are taking and any side effects you have. Also talk about any tablets or other medication you <b>will</b> be taking.',
          type: 'string'
        },
        '6_2': {
          title: 'Tell us about all your hospital, clinic or special treatment here.',
          description: '<p>Talk about</p><ul><li>what the treatment is</li><li>where you have to go to get the treatment</li><li>how often you go for the treatment</li></ul><p>If you are expecting to have treatment in the near future, describe what the treatment will be and when it’s due to start.</p>',
          type: 'string'
        },
        '6_3': {
          type: 'string',
          title: 'Are you having or waiting for any treatment which needs you to stay somewhere overnight or longer?',
          'enum': [
            'No. Go to Part 1 on the next page',
            'Yes'
          ]
        },
        '6_4': {
          type: 'string',
          title: 'Are you in, or due to start, a residential rehabilitation scheme?',
          'enum': [
            'Go to Part 1 on the next page',
            'Yes. Use the next box to tell us the name of the organisation running your scheme, when your treatment began, or is due to begin, and when you expect it to end'
          ]
        },
        '6_5': {
          title: 'Tell us the name of the organisation running your scheme, when your treatment began, or is due to begin, and when you expect it to end.',
          type: 'string'
        },
        '7_1': {
          type: 'boolean',
          title: 'I can move around and use steps without difficulty.',
          description: 'Can you do this safely and more than once without the help of another person?'
        },
        '7_2': {
          type: 'string',
          title: 'How far can you move safely and repeatedly on level ground without needing to stop?',
          'enum': [
            '50 meters. This is about the length of 5 double decker buses or twice the length of an average public swimming pool',
            '100 meters. This is about the length of a football pitch',
            '200 meters or more',
            'It varies'
          ],
          description: 'For example, think about how far you could walk before you became tired and out of breath. How much pain would you feel, would you be able to do it safely without losing your balance? How much support would you need from aids or your carer.'
        },
        '7_3': {
          title: 'Use this space to tell us how far you can move and why you might have to stop. If it varies, tell us how.',
          description: 'Talk about any aids you use. Such as a walking stick, crutches, a wheelchair or anything else to help you. Talk about how it affects the way you move around. Would you experience pain or be at risk of falling?',
          type: 'string'
        },
        '7_4': {
          type: 'string',
          title: 'Can you go up or down two steps without help from another person, if there is a rail to hold on to?',
          'enum': [
            'No',
            'Yes. Now go to question 2 on the next page',
            'It varies'
          ],
          description: 'Talk about any aids you might need. Could you do this more than once? Could you do this safely and how much pain would it cause you?'
        },
        '7_5': {
          title: 'Use this space to tell us more about using steps. If it varies, tell us how.',
          description: 'Talk about what makes this action easier for you. What does a good day mean for you and likewise could you carry out the action on a bad day?',
          type: 'string'
        },
        '8_1': {
          type: 'boolean',
          title: 'I can stand and sit without difficulty',
          description: 'Could you do this without help and unaided? Could you do this safely and more than once?'
        },
        '8_2': {
          type: 'string',
          title: 'Can you move from one seat to another right next to it without help from someone else?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ]
        },
        '8_3': {
          type: 'string',
          title: 'While you are standing or sitting (or a combination of the two) how long can you stay in one place and be pain free without the help of another person?',
          'enum': [
            'Less than 30 minutes',
            '30 minutes to one hour',
            'More than one hour',
            'It varies'
          ],
          description: 'Consider how you might feel stood or sat at a bus stop. How long would it be before your discomfort or pain is so severe that you need to move away and rest? Remember, it is about a combination of sitting and standing.'
        },
        '8_4': {
          title: 'Use this space to tell us more about standing and sitting and why this might be difficult for you.',
          description: 'Talk about how long you can sit for and how long you can stand for.</p><ul><li>What might make it difficult for you?</li><li>How much pain or discomfort would you be in?</li><li>Would your back hurt?</li><li>Would you feel dizzy?',
          type: 'string'
        },
        '8_5': {
          type: 'boolean',
          title: 'Can you reach up with both your arms without difficulty.',
          description: 'If you experience difficulty in only one of your arms then still tick no.'
        },
        '8_6': {
          type: 'string',
          title: 'Can you lift at least one of your arms high enough to put something in the top pocket of a coat or jacket while you are wearing it?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ],
          description: 'Does it hurt or cause severe discomfort. Can you do it repeatedly? Do you need to support your elbow with your free arm?'
        },
        '8_7': {
          type: 'string',
          title: 'Can you lift one of your arms above your head?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ],
          description: 'Does it hurt or cause severe discomfort. Do you need to use one arm to support the other at the elbow? Can you do it repeatedly?'
        },
        '8_8': {
          title: 'Use this space to tell us more.',
          description: 'Talk about why you might not be able to reach up, and whether it affects both arms. For example pain or discomfort.',
          type: 'string'
        },
        '9_1': {
          type: 'boolean',
          title: 'I can pick things up and move them without difficulty.',
          description: 'Can you pick up and move a lightweight item for example a milk carton from a table or worktop? Could you do this safely and more than once?'
        },
        '9_2': {
          type: 'string',
          title: 'Can you pick up and move a half- litre (one pint) carton full of liquid?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ],
          description: 'Does this cause pain, numbness or tremors in your hands or arms? Does it affect both your hands?'
        },
        '9_3': {
          type: 'string',
          title: 'Can you pick up and move a litre (two pint) carton full of liquid?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ],
          description: 'Does this cause pain, numbness or tremors in your hands or arms? Does it affect both your hands?'
        },
        '9_4': {
          type: 'string',
          title: 'Can you pick up and move a large, light object like an empty cardboard box?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ],
          description: 'For example, from one surface to another at waist height.'
        },
        '9_5': {
          title: 'Use this space to tell us more about picking things up and moving them.',
          description: 'Talk about why you might not be able to pick things up. If it varies, explain how. Does picking things up and moving them repeatedly cause you pain? Do you regularly drop things?',
          type: 'string'
        },
        '9_6': {
          type: 'boolean',
          title: 'I can use my hands without any difficulty.'
        },
        '9_7': {
          type: 'string',
          title: 'Can you use either hand to: press a button, such as a telephone keypad? or turn the pages of a book? or pick up a £1 coin? or use a pen or pencil? or use a suitable keyboard or mouse?',
          'enum': [
            'Some of these things',
            'None of these things',
            'It varies'
          ]
        },
        '9_8': {
          title: 'Use this space to tell us more.',
          description: 'Tell us which of these things you have problems with and why. If it varies, explain how. For example, does using both your hands cause any pain, numbness or tingling? Do you have difficulty with your grip in your hands? Talk about things you cannot do with both hands?',
          type: 'string'
        },
        '10_1': {
          type: 'boolean',
          title: 'I can communicate with other people without any difficulty.',
          description: 'Choose No if you have difficulty speaking and making yourself understood.'
        },
        '10_2': {
          type: 'string',
          title: 'Can you communicate a simple message to other people such as the presence of something dangerous?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ],
          description: 'This can be by speaking, writing, typing or any other means, but without the help of another person.'
        },
        '10_3': {
          title: 'Use this space to tell us more about how you communicate and why you might not be able to communicate with other people.',
          description: 'For example, difficulties with speech, writing or typing. If it varies, explain how.',
          type: 'string'
        },
        '10_4': {
          type: 'boolean',
          title: 'I can understand other people without any difficulty.'
        },
        '10_5': {
          type: 'string',
          title: 'Can you understand simple messages from other people by hearing or lip reading without the help of another person?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ],
          description: 'A simple message means things like someone telling you the location of a fire escape.'
        },
        '10_6': {
          type: 'string',
          title: 'Can you understand simple messages from other people by reading large size print or using Braille?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ]
        },
        '10_7': {
          title: 'If you need to communicate in another way or use aids, such as a hearing aid, talk about this here.',
          description: 'Think about the difficulties you have with your hearing. Do you have to ask people to face you when they are talking? Do you have problems just in one ear or both?',
          type: 'string'
        },
        '11_1': {
          type: 'boolean',
          title: 'I can get around safely on my own.'
        },
        '11_2': {
          type: 'string',
          title: 'Can you see to cross the road on your own?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ]
        },
        '11_3': {
          type: 'string',
          title: 'Can you get around a place that you haven’t been to before without help?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ]
        },
        '11_4': {
          title: 'Use this space to tell us more about your eyesight and any problems you have finding your way around safely.',
          description: 'If you are registered blind or partially sighted mention that here. Do you use any aids to help with you with finding your way around? Do you have a guide dog? Talk about any difficulties you have or think you might have with using a guide dog.',
          type: 'string'
        },
        '11_5': {
          type: 'boolean',
          title: 'I can control my bowels and bladder without any difficulty.'
        },
        '11_6': {
          type: 'string',
          title: 'Do you have to wash or change your clothes because of difficulty controlling your bladder, bowels or collecting device?',
          'enum': [
            'No',
            'Yes, weekly',
            'Yes, monthly',
            'Yes',
            'Yes, but only if I cannot reach a toilet quickly'
          ],
          description: 'Collecting devices include stoma bags and catheters.'
        },
        '11_7': {
          title: 'Use this space to tell us more about controlling your bowels and bladder or managing your collecting device.',
          description: 'Tell us if you experience problems if you cannot reach a toilet quickly.<br>Talk about how often you need to wash or change your clothes because of difficulty controlling your bladder, bowels or collecting device?<br>Include all information on this form that describes your difficulties. Please do not feel embarrassed by this. The details you give will help the Department for Work and Pensions to understand your situation fully.</br>',
          type: 'string'
        },
        '12_1': {
          type: 'boolean',
          title: 'I do not have any problems staying conscious while awake.'
        },
        '12_2': {
          type: 'string',
          title: 'While you are awake, how often do you faint or have fits or blackouts?',
          'enum': [
            'Daily',
            'Weekly',
            'Monthly',
            'Less than monthly'
          ],
          description: 'This includes epileptic seizures such as fits, partial or focal seizures, absences and diabetic hypos.'
        },
        '12_3': {
          title: 'Use this space to tell us more.',
          description: 'Talk about any episodes where you have been confused, or have not been aware of what was going around you. Is this because of low or high blood sugar levels? How many times has this happened to you recently? Do you get any warning that this will happen, for example, feeling dizzy or sick?',
          type: 'string'
        },


        // Part 2: Mental, cognitive and intellectual capabilities
        ////////////////////////////////////////////////////////////////


        "12_4": {
          title: "I can learn to do everyday tasks without difficulty.",
          description: "Do you have difficulty learning tasks? Do you have to be reminded frequently to carry out tasks or have any difficulty understanding how to do everyday tasks?",
          type: 'boolean'
        },
        '12_5': {
          type: 'string',
          title: 'Can you learn how to do an everyday task such as setting an alarm clock?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ]
        },
        '12_6': {
          type: 'string',
          title: 'Can you learn how to do a more complicated task such as using a washing machine?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ]
        },
        '13_1': {
          title: 'Use this space to tell us about any difficulties you have learning to do tasks, and why you find it difficult.',
          description: 'If your ability to do tasks varies, tell us how.',
          type: 'string'
        },
        '13_2': {
          type: 'boolean',
          title: 'I can stay safe when doing everyday tasks such as boiling water or using sharp objects.'
        },
        '13_3': {
          type: 'string',
          title: 'Do you need supervision (someone to stay with you) for most of the time to stay safe?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ]
        },
        '13_4': {
          title: 'Use this space to tell us how you cope with danger.',
          description: 'Please give us examples of problems you have with doing things safely.<ul><li>Do you leave cookers, fires or taps on?</li><li>Are you aware of the dangers from boiling water and sharp knives for example?</li><li>If you have someone who helps you with staying safe mention that here.</li><li>Do you get into fights and/or arguments without realising the risks?</li><li>Have you ever self harmed?</li></ul>Talk about examples of when and how these things have happened to you.',
          type: 'string'
        },
        '13_5': {
          type: 'boolean',
          title: 'I can manage to do daily tasks without difficulty.'
        },
        '13_6': {
          type: 'string',
          title: 'Can you manage to plan, start and finish daily tasks?',
          'enum': [
            'Never',
            'Sometimes',
            'It varies'
          ],
          description: 'If you cannot do these things most of the time, select never.'
        },
        '13_7': {
          title: 'Use this space to tell us what difficulties you have doing your daily routines.',
          description: 'For example, remembering to do things, planning and organising how to do them, and concentrating to finish them.<br>Think about the difficulties you have with looking after your personal hygiene, changing clothes when you should, eating full meals.<br>Do you neglect yourselves due to poor motivation? Give examples of such difficulties.<br>Talk about what might make it difficult for you and how often you need other people to help you.',
          type: 'string'
        },
        '14_1': {
          type: 'boolean',
          title: 'I can cope with changes to your daily routine.'
        },
        '14_2': {
          type: 'string',
          title: 'Can you cope with small changes to your routine if you know about them before they happen?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ],
          description: 'For example, things like having a meal earlier or later than usual, or an appointment time being changed.'
        },
        '14_3': {
          type: 'string',
          title: 'Can you cope with small changes to your routine if they are unexpected?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ],
          description: 'This means things like your bus or train not running on time, or a friend or carer coming to your house earlier or later than planned.'
        },
        '14_4': {
          title: 'Use this space to tell us more about how you cope with change.',
          description: 'Try to explain how you cope with any planned or unplanned change to your routine.<br>How do you feel? Is the rest of the day unbearable/ very difficult to cope with due to anxiety, stress or agitation resulting from the change?<br>Give examples, if you can, of the last time it happened. Did you feel panicky or anxious? Were you angry or upset?',
          type: 'string'
        },
        '14_5': {
          type: 'boolean',
          title: 'I can go out on my own.'
        },
        '14_6': {
          type: 'string',
          title: 'Can you leave home and go out to places you know?',
          'enum': [
            'No',
            'Yes, if someone goes with me',
            'It varies'
          ]
        },
        '14_7': {
          type: 'string',
          title: 'Can you leave home and go to places you don’t know?',
          'enum': [
            'No',
            'Yes, if someone goes with me',
            'It varies'
          ]
        },
        '14_8': {
          title: 'Use this space to tell us why you cannot always get to places.',
          description: 'Consider how you feel if you have to go out by yourself even to places you know well.<ul><li>Do you feel unsafe or afraid in unfamiliar places?</li><li>Do you feel better if someone accompanies you?</li></ul>Mention difficulties you have such as feeling anxious, panicky, getting lost etc.',
          type: 'string'
        },
        '15_1': {
          type: 'boolean',
          title: 'I can cope with social situations without feeling too anxious or scared.'
        },
        '15_2': {
          type: 'string',
          title: 'Can you meet people you know without feeling too anxious or scared?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ]
        },
        '15_3': {
          type: 'string',
          title: 'Can you meet people you don’t know without feeling too anxious or scared?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ]
        },
        '15_4': {
          title: 'Use this space to tell us why you find it distressing to meet other people and what makes it difficult.',
          description: 'Talk about how often you feel like this. Explain your problems, and give examples if you can. If it varies, explain how.<br>If you have difficulties meeting people then you need to describe how this makes you feel in this section.<br>Do you feel increased anxiety?<br>Do you feel threatened?<br>Do you think people are talking about you?<br>If you have ever had to leave a social event such as a wedding or a party because of these feelings, talk about that here.',
          type: 'string'
        },
        '15_5': {
          type: 'boolean',
          title: 'My behaviour does not upset other people.'
        },
        '15_6': {
          type: 'string',
          title: 'How often do you behave in a way which upsets other people?',
          'enum': [
            'Every day',
            'Frequently',
            'Occasionally'
          ],
          description: 'For example, this might be because your health condition, illness or disability results in you behaving aggressively or acting in an unusual way.'
        },
        '15_7': {
          title: 'Use this space to tell us or provide examples of how your behaviour upsets other people and how often this happens.',
          description: 'Explain your problems, and give examples if you can. Talk what happened the last time someone became upset because of your behaviour. Ask someone who knows you well if you struggle to answer this question. If it varies, explain how.',
          type: 'string'
        },


        // Part 3: Eating and drinking
        ////////////////////////////////////////////////////////////


        "16_1": {
          title: "Can you get food and drink to your mouth without help or being prompted by another person?",
          type: 'string',
          "enum": [
            "No",
            "Yes",
            "It varies"
          ]
        },
        '16_2': {
          type: 'string',
          title: 'Can you chew and swallow food and drink without help or being prompted by another person?',
          'enum': [
            'No',
            'Yes',
            'It varies'
          ]
        },
        '16_3': {
          title: 'Use this space to tell us about how you eat and drink, and why you might need help.',
          description: 'If you have issues with motivating yourself to eat and drink, talk about this here.<br>If this is because of a mental health issue mention that here and explain how this affects your eating and drinking. It may be helpful to ask a person who knows you well to help you answer this question.',
          type: 'string'
        },


        // Face-to-face assessment
        //////////////////////////////////////////////////

        "16_4": fieldDefinitionService.phone.areaCode(),
        "16_5": fieldDefinitionService.phone.localNumber(),
        "16_6": fieldDefinitionService.phone.fullNumber(),
        "16_7": fieldDefinitionService.phone.areaCode(),
        "16_8": fieldDefinitionService.phone.localNumber(),
        "16_9": {
          title: "If you do not understand English or Welsh, or cannot talk easily in these languages, do you need an interpreter?",
          description: "You can bring your own interpreter to the assessment, but they must be over 16.",
          type: 'string',
          "enum": [
            "No",
            "Yes. Use the next box to tell us what language you want to use"
          ]
        },
        '16_10': {
          title: 'What language do you want to use?',
          type: 'string'
        },
        '16_11': {
          type: 'boolean',
          title: 'I will bring my own interpreter.'
        },
        '16_12': {
          type: 'string',
          title: 'Would you like your telephone call in Welsh?',
          'enum': [
            'No',
            'Yes'
          ]
        },
        '16_13': {
          type: 'string',
          title: 'Would you like your face-to-face assessment in Welsh?',
          'enum': [
            'No',
            'Yes'
          ]
        },
        '17_1': {
          title: 'Tell us about any times or dates in the next 3 months when you cannot go to a face-to-face assessment.',
          description: 'For example, because of a hospital appointment.',
          type: 'string'
        },
        '17_2': {
          title: 'Tell us about any help you would need if you have to go for a face-to-face assessment.',
          description: 'Tell us if: <ul><li>you cannot get up and down stairs;</li><li>have difficulty travelling or using public transport;</li><li>you need a British Sign Language or Makaton signer, speech to text software or a deaf/blind manual.</li></ul>Tell us about any other help you might need.',
          type: 'string'
        },


        // Other information
        //////////////////////////////////////////////////

        "17_3": {
          title: "Add more information here",
          description: "If you need more space to answer any of the questions, please use this space. If any of your carers, friends or relatives want to add any information, they can do it here. This may be because they know the effects your health conditions, illnesses or disabilities have on how you can do things on a daily basis.<br> We might contact these people for more information to support your claim.",
          type: 'string'
        },
        '2_1': {
          title: 'If returning late',
          type: 'string',
          description: 'If you are returning this form late, please tell us why'
        },
        '18_1': fieldDefinitionService.time.date({
          title: 'Date of signature',
          validationMessage: 'Date is mandatory'
        }),
        // For people filling in this form for someone else
        //////////////////////////////////////////////////
        fillingInForSomeoneElse: {
          type: 'boolean',
          title: 'Are you filling in this form on behalf of someone else?',
          'default': false
        },
        '18_2': {
          title: 'Your name',
          type: 'string'
        },
        '18_3': {
          title: 'Your address including postcode',
          type: 'string'
        },
        '18_4': fieldDefinitionService.phone.areaCode(),
        '18_5': fieldDefinitionService.phone.localNumber(),
        '18_6': {
          title: 'Explanation',
          description: 'Explain why you are filling in the form for someone else, which organisation, if any, you represent, or your connection to the person the form is about.',
          type: 'string'
        },
        // What to do next
        //////////////////////////////////////////////////
        '19_1': {
          type: 'boolean',
          title: 'I am including any information such as medical reports'
        },
        '19_2': {
          title: 'Would you like us to tell anyone else about this assessment?',
          description: 'For example, support worker, social worker, friends or family.<br>Let us know who this is, their phone number and explain why you would like the Health Assessment Advisory Service to contact them instead of you.',
          type: 'string'
        }

        // Cancer treatment DISABLED
        //////////////////////////////////////////////////

      },
      required: []
    };

    return {
      getSchema: function () {
        return schema;
      }
    };

  }]);
