'use strict';

angular.module('kerp-forms.forms')
  .service('HB_CTR_FSM_MAPPINGS', ['FormFieldMappings', function (FormFieldMappings) {

    function hasEmployer(facts) {
      return facts.income && facts.income.employmentStatus === 'Yes' && facts.income.employerDetails.name;
    }

    this.mappings = function () {
      return FormFieldMappings.buildWithMappings({
        applicant_title: 'title',
        applicant_forename: 'firstName',
        applicant_surname: 'lastName',
        applicant_email: 'email',
        applicant_mob: 'phoneNumber',
        applicant_daytel: 'alternativePhoneNumber',
        applicant_dob: 'dateOfBirth',
        applicant_ni: 'nationalInsuranceNumber',
        applicant_gender: 'gender',
        applicant_employed: 'income',
        applicant_number_of_employers: 'income',
        applicant_business_name_e1: 'income',
        applicant_employed_hours_worked1_hours: 'income',
        applicant_employed_hours_worked1_minutes: 'income'
      }).factToFieldConverters({
        applicant_employed: function (facts) {
          return facts.income && facts.income.employmentStatus;
        },
        applicant_number_of_employers: function (facts) {
          if (hasEmployer(facts)) {
            return 1;
          }
        },
        applicant_business_name_e1: function (facts) {
          if (hasEmployer(facts)) {
            return facts.income.employerDetails.name;
          }
        },
        applicant_employed_hours_worked1_hours: function (facts) {
          if (facts.income) {
            return this.stringToNumber(facts.income.employerDetails.hoursPerWeek);
          }
        },
        applicant_employed_hours_worked1_minutes: function () {
          return 0;
        }

      }).fieldToFactConverters({
        income: function (model, originalFacts) {

          return {
            employmentStatus: model.applicant_employed,
            employerDetails: {
              name: model.applicant_business_name_e1,
              hoursPerWeek: model.applicant_employed_hours_worked1_hours,
              frequency: originalFacts.frequency
            }
          };
        }
      });
    };
  }]);
