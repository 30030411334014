'use strict';

angular.module('kerp-forms.forms').factory(
  'WO_FCA_CONDITIONS',
  [
    'FormConditions',
    'numberUtils',

    function (
      FormConditions,
      numberUtils
    ) {

      function hasContribution(careAssessment) {
        let hasContribution = false;
        if (careAssessment) {
          hasContribution = numberUtils.isNumeric(careAssessment.maxContribution);
        }
        return hasContribution;
      }

      function mandatoryDisregardsApply(ctx) {
        var protectedCohabitants = (ctx.lib.getModelValue('peopleLivingWithYou.otherPeople') || []).filter(person => person.relationshipType !== 'other');
        var partnerCohabits = ctx.lib.getModelValue('partner.details.sameAddress') === true;
        return protectedCohabitants.length || partnerCohabits;
      }

      return FormConditions.clone({
        conditions: {
          'person.property.value': function () {
            return this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
          },

          'person.intro.excludeNonResidentialCaretypes': function() {
            var excludeNonResidentialCaretypes = this.lib.getModelValue('person.intro.excludeNonResidentialCaretypes');
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');

            // residential care types should be visible only if the user has chosen to complete DRE
            // OR they have chosen not to agree to provide their financial details
            return !excludeNonResidentialCaretypes || (provideFinancialDetails !== 'agree' && provideFinancialDetails !== 'limitedExcessCapital');
          },

          'person.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'person.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'person.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'person.income.receivesExtraCare': function() {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return hasAA || hasDlaCare || hasPipDla;
          },

          'partner.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'partner.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'partner.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'partner.income.receivesExtraCare': function() {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return hasAA || hasDlaCare || hasPipDla;
          },

          'person.income.mobilityAllowance': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);

            return !hasAA;
          },

          'partner.income.mobilityAllowance': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);

            return !hasAA;
          },

          'person.income.universalCredit': function () {
            var uc = this.lib.getModelValue('person.income.universalCredit');
            return uc > 0;
          },

          showCostOfCare: function() {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            return provideFinancialDetails !== 'arrangeMyOwn';
          },

          hideCostOfCare: function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            return provideFinancialDetails === 'arrangeMyOwn';
          },

          showNonResBreakdown: function() {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.nonResidential'));
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails') !== 'arrangeMyOwn';

            const totalCapital = this.lib.getModelValue('careAssessment.nonResidential.workings.totalCapital');
            const capitalExceedsThreshold = !contribution && totalCapital > 23250;
            return provideFinancialDetails && !capitalExceedsThreshold;
          },

          showRespiteBreakdown: function() {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.respite'));
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails') !== 'arrangeMyOwn';

            const totalCapital = this.lib.getModelValue('careAssessment.respite.workings.totalCapital');
            const capitalExceedsThreshold = !contribution && totalCapital > 23250;
            return provideFinancialDetails && !capitalExceedsThreshold;
          },

          showWeek1Breakdown: function() {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.week5Residential'));
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails') !== 'arrangeMyOwn';

            const totalCapital = this.lib.getModelValue('careAssessment.week1Residential.workings.totalCapital');
            const capitalExceedsThreshold = !contribution && totalCapital > 23250;
            return provideFinancialDetails && !capitalExceedsThreshold;
          },

          showWeek5Breakdown: function() {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.week5Residential'));
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails') !== 'arrangeMyOwn';

            const totalCapital = this.lib.getModelValue('careAssessment.week5Residential.workings.totalCapital');
            const capitalExceedsThreshold = !contribution && totalCapital > 23250;
            return provideFinancialDetails && !capitalExceedsThreshold;
          },

          showWeek13Breakdown: function() {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.week13Residential'));
            var ownsPrimaryProperty = this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
            var hasProtectedCohabitants = mandatoryDisregardsApply(this);
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails') !== 'arrangeMyOwn';

            const totalCapital = this.lib.getModelValue('careAssessment.week13Residential.workings.totalCapital');
            const capitalExceedsThreshold = !contribution && totalCapital > 23250;

            const isFullCostDueToProperty = !contribution && ownsPrimaryProperty && !hasProtectedCohabitants;
            return provideFinancialDetails && (!isFullCostDueToProperty && !capitalExceedsThreshold);
          }
        }
      });
    }]);
