'use strict';

angular
  .module('kerp-forms.forms')
  .factory('MRR_SCHEMA', ['fieldDefinitionService', '$translate', function (fieldDefinitionService, $translate) {

    var requiredValidationMessage = $translate.instant('forms.validation.required');

    var schema = {
      type: 'object',
      properties: {
        '1_1': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '1_2': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '1_3': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '1_4': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '1_5': fieldDefinitionService.NINO.fullNumber(),
        '1_6': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '1_7': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '1_8': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '1_9': {
          type: 'string'
        },
        '1_10': fieldDefinitionService.phone.fullNumber(),
        '2_0': {
          type: 'string',
          enum: [
            'no',
            'yes'
          ]
        },
        '2_1': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '2_2': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '2_3': fieldDefinitionService.NINO.fullNumber(),
        '3_0': {
          type: 'string',
          enum: [
            'no',
            'yes'
          ]
        },
        '3_1': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '3_2': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '3_3': fieldDefinitionService.phone.fullNumber(),
        '4_1': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '4_2': {
          type: 'string',
          validationMessage: requiredValidationMessage
        },
        '5_1': {
          type: 'string',
          validationMessage: requiredValidationMessage
        }
      },
      required: [
        '1_1',
        '1_2',
        '1_4',
        '1_5',
        '1_6',
        '1_7',
        '1_8',
        '2_8',
        '2_1',
        '2_2',
        '3_1',
        '3_2',
        '3_3',
        '3_4',
        '4_1',
        '4_2',
        '5_1'
      ]
    };

    return {
      getSchema: function () {
        return schema;
      }
    };
  }]);
