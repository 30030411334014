'use strict';

(function () {

  var mod = angular.module('kerp-forms.forms');

  /**
   * This directive is for gazetteer address lookup
   */
  mod.directive('addressSuggestion', ['$http', 'configuration', 'addressFormatterService', 'fieldDefinitionService', '$translate',
    function ($http, configuration, addressFormatterService, fieldDefinitionService, $translate) {

      return {
        restrict: 'EA',
        scope: {
          label: "@",
          address1: "@",
          address2: "@",
          address3: "@",
          postcode: "@",
          propertyReference: "@",
          region: "@?",
          modelObject: "=",
          lineLengthLimit: "<?",
          extraDescription: "="
        },
        templateUrl: 'modules/forms/scripts/directives/addressSuggestion/addressSuggestionDirective.html',
        link: function (scope, element, attrs) {

          scope.region = scope.region || $translate.instant('app.area');

          var addressField = fieldDefinitionService.address;

          var ADDRESS_FIELD_PROPERTIES = ['address1', 'address2', 'address3', 'postcode', 'propertyReference'];
          var postcodeApiEndpoint = configuration.api.baseUrl + "/location/index?postcode=";
          var postcodeMatcher = new RegExp(addressField.postcode().pattern);

          var unlimitedLineLength = !scope.lineLengthLimit;

          scope.validations = {
            address1: addressField.requiredLine(null, unlimitedLineLength),
            address2: addressField.requiredLine(null, unlimitedLineLength),
            address3: addressField.optionalLine(null, unlimitedLineLength),
            postcode: addressField.postcode()
          };

          scope.isAddressEditable = !scope.propertyReference;
          scope.NULL_UPRN = "PROPNOTFOUND";

          var isSuggestionSelected = false;

          // obtain reference to inputs' ngModelController
          var fieldControllers = {};

          ADDRESS_FIELD_PROPERTIES.forEach(function (prop) {
            scope.$watch(prop, function (propVal) {
              if (propVal) {
                fieldControllers[propVal] = element.find('[name="' + propVal + '"]').first().controller('ngModel');
              }
            });
          });

          scope.hasAnyError = function () {
            return ADDRESS_FIELD_PROPERTIES.some(function (prop) {
              return scope.hasError(scope[prop]);
            });
          };

          scope.hasError = function (field) {
            if (!fieldControllers[field]) {
              return false;
            }
            return isFieldModified(field) && fieldControllers[field].$invalid;
          };

          scope.hasSuccess = function (field) {
            if (!fieldControllers[field]) {
              return false;
            }
            return isFieldModified(field) && fieldControllers[field].$valid;
          };

          function setFieldsPristine() {
            isSuggestionSelected = false;
            angular.forEach(fieldControllers, function (fieldController) {
              fieldController.$setPristine();
              fieldController.$setUntouched();
            });
          }

          function isFieldModified(field) {
            return isSuggestionSelected || fieldControllers[field].$touched;
          }

          function getAddressSuggestions(postcode) {

            scope.loading = true;
            scope.hasWarning = false;

            $http.get(postcodeApiEndpoint + postcode, {suppressFlashErrors: true}).then(function (response) {

              scope.suggestions = [];

              if (angular.isArray(response.data) && response.data.length) {
                response.data.forEach(function (item) {

                  var addressParts = item.fullAddress.split(",");
                  var address = addressFormatterService.formatAsObject(addressParts, item.postCode, scope.lineLengthLimit);

                  var suggestedAddress = {
                    fullAddress: item.fullAddress,
                    address1: address.addr1,
                    address2: address.addr2,
                    address3: address.addr3,
                    postcode: address.postcode,
                    propertyReference: item.propertyReference
                  };

                  scope.suggestions.push(suggestedAddress);
                });
              }

              scope.hasWarning = !scope.suggestions.length;

              if (scope.hasWarning) {
                scope.switchToAddressFinder();
              }
            }).catch(function (e) {
              scope.hasRemoteError = true;
              scope.switchToManualEditing();

            }).finally(function () {
              scope.loading = false;
            });
          }

          scope.$watch("suggestedPostcode", function (newValue, oldValue) {

            if (newValue !== oldValue) {

              setFieldsPristine();
              scope.hasWarning = false;
              scope.isSearchPostcodeMatched = false;

              if (!newValue) {
                return;
              }

              if (postcodeMatcher.test(newValue)) {
                scope.isSearchPostcodeMatched = true;
                getAddressSuggestions(newValue);
              } else {
                scope.suggestions = [];
                scope.hasWarning = true;
              }
            }
          });

          /**
           * remove address from form data model
           */
          function resetAddress() {

            if (scope.modelObject) {
              ADDRESS_FIELD_PROPERTIES.forEach(function (name) {
                delete scope.modelObject[scope[name]];
              });
            }
            setFieldsPristine();
          }

          scope.$on('schemaFormValidate', function () {
            isSuggestionSelected = true;
          });

          scope.switchToManualEditing = function () {
            scope.isAddressEditable = true;
          };

          scope.addressUpdated = function () {
            // KERP-1584, KERP-1581: if the address is manually updated, set the UPRN to 'PROPNOTFOUND'
            if (scope.isAddressEditable) {
              scope.modelObject[scope.propertyReference] = scope.NULL_UPRN;
            }
          };

          scope.setSelectedSuggestedAddress = function () {

            if (!scope.selectedAddress) {
              resetAddress();
              return;
            }

            scope.modelObject = scope.modelObject || {};

            ADDRESS_FIELD_PROPERTIES.forEach(function (addressPart) {
              var selected = scope.selectedAddress[addressPart];
              if (!angular.isString(selected)) {
                selected = "";
              }
              var addressPropertyValue = selected.trim();
              var addressPropertyName = scope[addressPart];
              scope.modelObject[addressPropertyName] = addressPropertyValue;
            });

            isSuggestionSelected = true;
          };

          scope.switchToAddressFinder = function () {
            scope.isAddressEditable = false;
            scope.hasWarning = false;
            resetAddress();
          };

        }
      };
    }]);
}());
