'use strict';

(function (angular) {

  var mod = angular.module('kerp-forms.forms');

  function schemaFormValidatorService(sfValidator, sfSelect, schemaFormHelperService) {

    /**
     * Run TV4 validation on each field(merged schema field)
     * @param formFields {Array} - should be flattened and contain a key
     * @param model {Object}
     * @return {Array}
     */
    function runTv4ValidationOnFields(formFields, model) {
      return formFields.map(function (field) {
        if (!field.key || !field.key.length) {
          throw new Error('Form field did not contain key', field);
        }

        var key = field.key;
        var value;
        if (angular.isArray(key)) {
          value = sfSelect(key, model);
        } else {
          value = model[key];
        }

        return sfValidator(field, value);
      });
    }

    /**
     * @param merged {Array} - merged form with schema
     * @param model {Object}
     * @param conditionLibrary {Object}
     * @param scope {Object} scope used for evaluation of form conditions
     * @return {Array} validation result
     */
    function validate(merged, model, conditionLibrary, scope) {

      var formFields = [];

      schemaFormHelperService.forEachFieldInForm(merged, model, {
        scope: scope,
        conditionLibrary: conditionLibrary,
        fieldHandler: function (field) {
          formFields.push(field);
        }
      });

      return runTv4ValidationOnFields(formFields, model);
    }

    return {
      validate: validate
    };

  }

  schemaFormValidatorService.$inject = ['sfValidator', 'sfSelect', 'schemaFormHelperService'];

  mod.factory('schemaFormValidatorService', schemaFormValidatorService);

}(angular));
