'use strict';

angular.module('kerp-forms.forms').directive('kerpCurrency', ['$window', function ($window) {

  return {
    restrict: 'EA',
    require: 'ngModel',
    templateUrl: 'modules/forms/scripts/directives/kerpCurrency/kerpCurrencyDirective.html',
    link: function (scope, element, attrs, ngModelController) {

      scope.$watch(function () {
        return ngModelController.$modelValue;
      }, function (newValue) {

        var numberToUse;
        if (angular.isDefined(newValue) && angular.isString(newValue)) {
          numberToUse = parseFloat(newValue);
        } else if (angular.isDefined(newValue) && angular.isNumber(newValue)) {
          numberToUse = newValue;
        }

        if (!angular.isDefined(numberToUse) || $window.isNaN(numberToUse)) {
          numberToUse = 0;
        }

        scope.val = numberToUse.toFixed(2);
      });
    }
  };
}]);
